type NameOrGetter = ((item: any) => any) | string;

// Usage:
// const sort = localeCompareSortNumeric(item => item.displayName ?? item.name);
// const sort = localeCompareSortNumeric("name");
export function localeCompareSortNumeric(attributeNameOrGetter: NameOrGetter) {
    return function(itemA, itemB) {
        const a = (typeof attributeNameOrGetter === "function" ? attributeNameOrGetter(itemA) : itemA[attributeNameOrGetter]) ?? "";
        const b = (typeof attributeNameOrGetter === "function" ? attributeNameOrGetter(itemB) : itemB[attributeNameOrGetter]) ?? "";
        return a.localeCompare(b, [], { numeric: true });
    };
}