import React, { PureComponent } from 'react'
import Dashboard from '../core/authentication/dashboard'
import { UICenter } from '../components/ui/alignment.jsx'
import { CometLogo } from '../components/comet'
import appConfig from 'config'

const App = (props) => {
	const { children } = props;

	let environmentName;
	switch (appConfig.env) {
		case "Staging":
			environmentName = <span>Comet&nbsp;<mark>Stage</mark></span>;
			break;
		case "Development":
			environmentName = <span>Comet&nbsp;<mark>Dev</mark></span>;
			break;
		case "Acceptance":
			environmentName = <span>Comet&nbsp;<mark>Next</mark></span>
			break;
		default:
			environmentName = "Comet";
	}

	const fallback = (
		<div className="c6-welcome scroll">
			<div>
				<UICenter>
					<CometLogo />
					<h1>{environmentName}</h1>
				</UICenter>
				<Dashboard />
			</div>
		</div>
	);

	return children ? children : fallback;
}

export default App;