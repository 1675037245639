import React from 'react'
import PropTypes from 'prop-types'

import * as AMPutils from './utils'

import './amp.css'

export default class AzureMediaPlayer extends React.Component {
	playerId = null;
	playerRef = React.createRef();

	async componentDidMount() {
		this.playerId = await AMPutils.initPlayer({
			playerRef: this.playerRef.current,
			asset: this.props.asset,
			options: {
				hideDL: this.props.hideDL,
				hideEC: this.props.hideEC,
				autoplay: this.props.autoplay,
				watermark: this.props.watermark,
			},
		});
		if (this.props.sources) {
			AMPutils.setPlayerSources(this.playerId, this.props.sources);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.sources) {
			AMPutils.setPlayerSources(this.playerId, this.props.sources);
		}
	}

	componentWillUnmount() {
		AMPutils.disposePlayer(this.playerId);
	}

	render() {
		return (
			<div className={`c6-amp ${this.props.sources?.length ? "has-sources" : ""}`}>
				<video className="azuremediaplayer amp-flush-skin amp-big-play-centered" ref={this.playerRef}></video>
			</div>
		);
	}
}


AzureMediaPlayer.propTypes = {
	asset: PropTypes.shape({
		assetGuid: PropTypes.string,
	}),
	sources: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string,
		type: PropTypes.string,
	})),
	hideDL: PropTypes.bool,
	hideEC: PropTypes.bool,
	autoplay: PropTypes.bool,
	watermark: PropTypes.string,
};
