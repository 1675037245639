import Qs from 'qs'
import { get, put, post, del } from '../core/c6'

const API = 'star';

/* **************** CONTAINERS ***************** */
export const fetchContainer 			= 					 ({id}) =>  get(API, `containers/${id}`);
export const fetchChildContainers 		= 					filters =>  get(API, `containers`, filters);
export const removeAssetsFromContainer  = 	 (containerId, payload) =>  put(API, `containers/${containerId}/assets`, payload);
export const downloadMetadataTemplate	= 			  (containerId) =>  get(API, `containers/${containerId}/metadataTemplate`);
export const createContainer 			=  	(payload, seasonNumber, episodeNumber, contentSource) => {
	const query = Qs.stringify({ seasonNumber, episodeNumber, contentSource });
	return post(API, `containers?${query}`, payload);
};

/* ************** THREADS ***************** */
export const fetchThread   =          				  ({id}) =>  get(API, `threads/${id}/comments`);
export const postComment   = ({threadId, objectId}, payload) => post(API, `threads/${threadId}?objectType=worklistitem&objectId=${objectId}`, payload);
export const updateComment = 				 ({id}, payload) =>  put(API, `threads/comments/${id}`, payload);

/* ************** WORKLISTS ***************** */
export const fetchWorklists = filters => get(API, "worklists", filters);
export const fetchWorklist  =  ({id}) => get(API, `worklists/${id}`);

/* ************** TODOS ***************** */
export const fetchTodos = 		(filters) =>  get(API, `worklists/items`, filters);
export const fetchTodo  = 	   	   ({id}) =>  get(API, `worklists/items/${id}`);
export const updateTodo = ({id}, payload) =>  put(API, `worklists/items/${id}`, payload);
export const markTodo 	= 	   	   ({id}) =>  put(API, `worklists/items/${id}`);
export const createTodo = 		(payload) => post(API, `worklists/items`, payload);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);