import React from 'react'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

import './comments.css'

const Comments = ({
    commentsLoading,
    comments,
    className = "",
    updateComment,
    deleteComment,
    postComment,
    dismissWarningOrError,
    startEditingComment,
    stopEditingComment,
    allowEdit = false,
    allowDelete = true,
}) => {
    const [newCommentText, setNewCommentText] = React.useState("");

    const jwt = localStorage.getItem("userJWT");
    const { username } = jwt_decode(jwt);
    const writtenByCurrentUser = comment => comment.createdBy === username;

    const onCommentFieldKeyUp = React.useCallback(
        (e) => {
            if (e.key === "Enter" && newCommentText?.length > 0) {
                e.preventDefault();
                e.stopPropagation();
                postComment(newCommentText);
                setNewCommentText("");
            } else if (e.key === "Escape") {
                e.preventDefault();
                e.stopPropagation();
                setNewCommentText("");
                e.target.blur();
            }
        },
        [newCommentText, setNewCommentText, postComment]
    );

    return (
        <div className={`c6-comments ${className}`}>
            <div>
                {commentsLoading && <p>Loading comments...</p>}
                {!commentsLoading && !!comments.length && (
                    <div className="wrapper">
                        {comments.sort((a, b) => a.created.localeCompare(b.created)).map((comment, index, arr) => {
                            const previousCommentUsername = arr[index - 1]?.createdBy;
                            const sameUserAsPreviousComment = previousCommentUsername === comment.createdBy;
                            const nextCommentUsername = arr[index + 1]?.createdBy;
                            const sameUserAsNextComment = nextCommentUsername === comment.createdBy;
                            return (
                                <Comment
                                    key={comment.id ?? index}
                                    comment={comment}
                                    updateComment={updateComment}
                                    deleteComment={deleteComment}
                                    dismissWarningOrError={dismissWarningOrError}
                                    startEditingComment={startEditingComment}
                                    stopEditingComment={stopEditingComment}
                                    byCurrentUser={writtenByCurrentUser(comment)}
                                    allowEdit={allowEdit}
                                    allowDelete={allowDelete}
                                    sameUserAsPreviousComment={sameUserAsPreviousComment}
                                    sameUserAsNextComment={sameUserAsNextComment}
                                    isLastComment={index === arr.length - 1}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="new-comment">
                <TextField
                    variant="standard"
                    placeholder="Type your comment here, please."
                    fullWidth={true}
                    value={newCommentText}
                    onChange={(e) => setNewCommentText(e.target.value)}
                    onKeyUp={onCommentFieldKeyUp}
                    inputProps={{ maxLength: 450 }}
                />
            </div>
        </div>
    );
};

export default Comments;
        
const Comment = ({
    comment,
    byCurrentUser,
    updateComment,
    deleteComment,
    dismissWarningOrError,
    startEditingComment,
    stopEditingComment,
    allowEdit,
    allowDelete,
    sameUserAsPreviousComment = false,
    sameUserAsNextComment = false,
    isLastComment,
}) => {
    let className = "comment";
    className += byCurrentUser ? " by-current-user" : "";
    className += comment.warning ? " warning" : "";
    className += comment.error ? " error" : "";
    className += !comment.error && !comment.warning && sameUserAsPreviousComment ? " same-user-as-previous" : "";
    className += !comment.error && !comment.warning && sameUserAsNextComment ? " same-user-as-next" : "";

    const isErrorOrWarning = comment.error || comment.warning;
    
    return (
        <div
            key={comment.id}
            className={className}
        >
            {!isErrorOrWarning && !sameUserAsPreviousComment && (
                <p className="comment-info">
                    <strong>{byCurrentUser ? "You" : comment.createdBy}</strong>
                </p>
            )}
            {!comment.isEditing && (
                <div className="comment-message">
                    <span>{moment(comment.created).format("D/M HH:mm")}</span>
                    <div className="text">
                        {comment.text}
                        {!isErrorOrWarning && byCurrentUser && allowEdit && <a className="c6-link" onClick={() => startEditingComment(comment.id)}>Edit</a>}
                        {!isErrorOrWarning && byCurrentUser && allowDelete && <a className="c6-link" onClick={() => deleteComment(comment.id)}>Delete</a>}
                        {isErrorOrWarning && isLastComment && <a className="c6-link" onClick={() => dismissWarningOrError(comment.id)}>Dismiss</a>}
                    </div>
                </div>
            )}
            {comment.isEditing && (
                <TextField
                    defaultValue={comment.text}
                    onKeyUp={e => editingCommentKeyUp(comment.id, e, updateComment, stopEditingComment)} // Use KeyUp instead of KeyDown because we need to stop the modal "Escape"-listener
                    onBlur={e => updateComment(comment.id, e.target.value)}
                    name="comment-textfield"
                    style={{ width: "100%" }}
                    maxLength="450"
                />
            )}
        </div>
    );
};
        
function editingCommentKeyUp(commentId, e, updateComment, stopEditingComment) {
    if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        updateComment(commentId, e.target.value);
    } else if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        stopEditingComment();
    }
}