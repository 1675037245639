import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { browserHistory } from '../services/browserHistory'

import { isExpired } from '../services/auth'
import * as Alert from '../services/alert'
import { configureErrorTrackingUser, setSupportBeaconUser } from '../services/tracking'

import * as ShieldAPI from '../../apis/shield'
// import * as UserAPI from '../../apis/user'

import alt from '../services/alt'

// import modules from './modules.json' // C5 SEC

import appConfig from 'config'

class Actions {

	loginFailed(error) {
		return error;
	}

	loginWithUserPass({ username, password, api, ...routing }) {
		return (dispatch) => {
			dispatch(); // Loading

			(api || ShieldAPI).login(username, password)
				.then(jwt => {

					if(appConfig.features && appConfig.features.setCookieOnShieldLogin) {
						let requestHeaders = new Headers();
						const bearer = `Bearer ${jwt}`.replace("\n", ""); // HACK: Remove possible newlines to prevent an invalid header.
						requestHeaders.append("Authorization", bearer);
						//console.log("Headers: %o", requestHeaders);
						fetch(appConfig.app.authCookie, {
							method: "POST",
							headers: requestHeaders,
							credentials: "same-origin"
							// mode: 'cors',
						})
							.then(() => {
								console.log("Got cookie OK!");
							})
							.catch(error => {
								console.error("Error getting cookie: %o", error);
							});
					}

					this.loginWithToken(jwt, routing);

				}, error => {
					console.error("Error: %o", error);
					this.loginFailed(error);
				});
		};
	}

	loginWithToken(jwt, routing) {
		return (dispatch) => {
			dispatch(); // Loading

			// Handle corrupt JWT
			if(typeof(jwt) === "object" || jwt === "[object Object]") {
				this.logout();
				return false;
			}

			const userData = jwt_decode(jwt);
			if(isExpired(userData)) {
				this.logout(userData);
			}
			else {
				localStorage.setItem("userJWT", jwt);
				this.login(userData, routing);
			}
		};
	}

	login(userData, routing) {
		return (dispatch) => {
			dispatch(userData);

			try {
				// Make Sentry aware of the logged in user (& Helpscout)
				const email = userData.username.includes("@") ? userData.username : `${userData.username}@cometUserWithNoRealEmail`;
				configureErrorTrackingUser({ email });
				setSupportBeaconUser(userData);
			} catch {
				// Ignore
			}

			if(routing && !routing.modal) {
				const { location, router, modal } = routing;

				if (location.state?.nextLocation) {
					router.replace(location.state.nextLocation);
				}
				else {
					// TODO!!!: Navigate to last used app, last used module or first available module
					router.replace("/");
				}
			}
		}
	}

	logout(user = {}, stayOnPage = false, api, functionToRunAfterLogin) {
		return (dispatch) => {
			dispatch({ stayOnPage, functionToRunAfterLogin });

			// Let the Shield API know that the user is logged out
			if(user.username) {
				(api || ShieldAPI).logout({ name: user.username});
			}

			// Remove our user token
			localStorage.removeItem("userJWT");

			// Also remove every API token we have stored
			for(const key in localStorage) {
				if(key.includes("jwt-")) {
					localStorage.removeItem(key);
				}
			}

			try {
				// Remove the logged out user from our Sentry tracking (& Helpscout)
				configureErrorTrackingUser(null);
				setSupportBeaconUser(null);
			} catch {
				// Ignore
			}

			// We also need to do a server POST since .ASPXAUTH is a HTTP Only-cookie
			if(appConfig.features && appConfig.features.setCookieOnShieldLogin && appConfig.app.clearAuthCookie) {
				fetch(appConfig.app.clearAuthCookie, {
					method: "POST",
					credentials: "include"
					//credentials: "same-origin"
				})
					.then(() => {
						console.log("Cleared authCookie OK!");
						this.logoutOrStay(stayOnPage);
					})
					.catch(error => {
						console.error("Error clearing authCookie: %o", error);
						this.logoutOrStay(stayOnPage);
					});
			}
			else {
				this.logoutOrStay(stayOnPage);
			}
		}
	}

	logoutOrStay(stayOnPage) {
		return (dispatch) => {
			dispatch();

			if(!stayOnPage) {
				browserHistory.push({
					pathname: "/logout",
					state: location.pathname.endsWith("/logout") || location.pathname.endsWith("/login")
						? location.state ?? undefined
						: { nextLocation: { ...location } },
				});
			}
		}
	}

	forgotPassword(name, api, successMessage) {
		return (dispatch) => {
			dispatch(); // Loading

			const module = appConfig.features.moduleNameForShieldReset;
			(api || ShieldAPI).forgotPassword({ name, module })
				.then(response => {
					this.forgotPasswordSent();
					const email = response.email || response.result.email;
					const message = successMessage
						? successMessage.replace("{email}", email)
						: `A new password reset link has been emailed to ${email}.`;
					Alert.displayAlert("success", message);
					browserHistory.push("/login");
				}, error => {
					const message = error.message === "Not Found"
						? `Could not find the user ${name}. Please check your spelling and try again!`
						: "Something went wrong, please try again!"

					// Alert.displayAlert("error", message);
					this.actionFailed(error);
				});
		};
	}
	forgotPasswordSent() {
		return true;
	}


	fetchResetState(key, api) {
		return (dispatch) => {
			dispatch(); // Loading

			(api || ShieldAPI).fetchResetState(key)
				.then(response => {
					this.updateResetState(response);
				}, error => this.fetchResetStateFailed);
		};
	}
	fetchResetStateFailed(error) {
		return error;
	}
	updateResetState(state) {
		return state;
	}

	resetPassword({ resetKey, password, confirmPassword, api, successMessage }) {
		return (dispatch) => {
			dispatch();

			const payload = {
				NewPassword: password,
				ConfirmPassword: confirmPassword,
			};

			(api || ShieldAPI).resetPassword(resetKey, payload)
				.then(response => {
					this.resetPasswordDone();
					Alert.displayAlert("success", successMessage || "Your password has been updated, please log in below.");
					browserHistory.push("/login");
				}, error => {
					this.actionFailed(error);
				});
		};
	}
	resetPasswordDone() {
		return true;
	}

	actionFailed(error) {
		return error;
	}

	displayLogin() {
		browserHistory.push("/login");
		return true;
	}

	displayForgotPassword(modal) {
		browserHistory.push({
			pathname: "/forgotpassword",
			state: { modal }
		});
		// browserHistory.push("/forgotpassword");
		return true;
	}

	display() { return true }

	// // C5 Security
	// getUserData() {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		const isDevMode = appConfig.env == "Development";

	// 		if(isDevMode) {
	// 			localStorage.removeItem("userJWT");
	// 			this.userDataRecieved({
	// 				modules: modules.items,
	// 				name: "DEVELOPMENT MODE (C5 SEC)"
	// 			});
	// 		} else {
	// 			fetchBrowserToken()
	// 				.then(token => {
	// 					// console.log("Valid token found.", token);
	// 					this.userDataRecieved(token);
	// 				}, error => {
	// 					if(error === "token invalid") {
	// 						// console.log("No valid token found. Fetching one...");
	// 						fetchToken()
	// 							.then(token => {
	// 								// console.log("Token received.", token);
	// 								this.userDataRecieved(token);
	// 							}, innererror => {
	// 								console.error(innererror);
	// 							});
	// 					}
	// 					else {
	// 						console.error(error);
	// 					}
	// 				});
	// 		};
	// 	}
	// }

	userDataRecieved(data) {
		if(typeof(data) === "object") {
			return data;
		}
		localStorage.setItem("userJWT", data);
		return jwt_decode(data);
	}

	unmount() { return true; }

	requestFailed(errorPayload) {
		const { error } = errorPayload;
		Alert.displayAlert("error", error.exceptionMessage);
		return errorPayload;
	}
}

export default alt.createActions(Actions);


// // Cookie function from StackOverflow
// function getCookie(cname) {
// 	const name = cname + "=";
// 	const ca = document.cookie.split(';');
// 	for (let i = 0; i < ca.length; i++) {
// 		let c = ca[i];
// 		while (c.charAt(0) == ' ') {
// 			c = c.substring(1);
// 		}
// 		if (c.indexOf(name) == 0) {
// 			return c.substring(name.length, c.length);
// 		}
// 	}
// 	return "";
// }

// function fetchBrowserToken() {
// 	return new Promise((resolve, reject) => {

// 		// Get loginCookie and it's value
// 		const loginCookie = getCookie("loginCookie");
// 		const usernameInCookie = loginCookie.length ? loginCookie : null;

// 		// Get JWT from localStorage
// 		const userJWT = localStorage.getItem("userJWT");
// 		let token = null;
// 		try {
// 			if(userJWT) {
// 				token = jwt_decode(userJWT);
// 			}
// 		} catch (err) {
// 			localStorage.removeItem("userJWT");
// 			reject("token invalid");
// 		}

// 		// Check if name in token is same as name in cookie
// 		const sameUser = token && usernameInCookie && token.username === usernameInCookie;

// 		// We have token and it has not expired. AND username in token is same as logged in user.
// 		if (token && token.exp > Math.round(Date.now() / 1000) && sameUser) {
// 			resolve(token);
// 		}
// 		// There is no token or the token has expired: get new token from API and use that
// 		else {
// 			localStorage.removeItem("userJWT");
// 			reject("token invalid");
// 		}
// 	});
// }

// function fetchToken() {
// 	return new Promise((resolve, reject) => {

// 		UserAPI.fetchUserToken()
// 			.then(token => {
// 				resolve(token);
// 			}, (error, api) => {
// 				reject({error, api});
// 			});
// 	});
// }