import React from 'react'
import PropTypes from 'prop-types'

import './scroll.css'

export default class Scroll extends React.Component {

	static propTypes = {
		flex: PropTypes.string,
		name: PropTypes.string,
		secondary: PropTypes.bool,
		nopadding: PropTypes.bool,
		inModal: PropTypes.bool,
		setOwnHeight: PropTypes.bool,
		inDialog: PropTypes.bool
	}

	constructor(props) {
		super(props);
		this.state = {
			scrollHeight: getScrollHeight(props.inModal, props.inDialog)
		}
	}

	handleResize = (e) => {
		if (this._scroll) {
			const { inModal, inDialog } = this.props;
			this.setState({scrollHeight: getScrollHeight(inModal, inDialog, this._scroll)});
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		window.setTimeout(this.handleResize, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		const { name, nopadding } = this.props;
		const flexClass = this.props.flex ? `flex flex-${this.props.flex}` : "";
		const paddingClass = nopadding ? "" : "pad26";
		const classNames = `scroll ${paddingClass} ${flexClass} ${name}`;
 		const child = this.props.setOwnHeight
			? <div style={{ height: this.state.scrollHeight }}>{React.cloneElement(this.props.children)}</div>
			: (React.cloneElement(this.props.children, { scrollHeight: this.state.scrollHeight }));

		return !this.props.secondary
			? <main ref={c => this._scroll = c} className={classNames}>{child}</main>
			: <aside ref={c => this._scroll = c} className={classNames}>{child}</aside>
  	}
}

// 2022-12-07:
// After upgrading to latest mui, it seems like editor/dialog/modal heights
// work better when not setting scrollHeight at all?
function getScrollHeight(inModal, inDialog, scroll) {
	const modal = inModal && document.querySelector(".c6-modal-body");
	const dialog = inDialog && document.querySelector(".c6-dialog-body");

	if (modal) {
		return undefined;
		// const actionbar = modal.querySelector(".c6-actionbar");
		// const filter = modal.querySelector(".c6-filter");

		// return (
		// 	modal.offsetHeight
		// 	- (actionbar && actionbar.offsetHeight || 0)
		// 	- (filter && filter.offsetHeight || 0)
		// );
	}
	
	if (dialog) {
		return undefined;
		// const filter = dialog.querySelector(".c6-filter, .c6-dialog-header");
		// const tabsbar = dialog.querySelector(".c6-tabs > div");

		// return (
		// 	dialog.offsetHeight
		// 	- (filter && filter.offsetHeight || 0)
		// 	// For some reason the height is correct if the dialog has tabs at the top?
		// 	+ (tabsbar ? 0 : 48)
		// );
	}

	return window.innerHeight - (scroll && scroll.offsetTop || 111);
}