import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './alertContainer.css'

export default class AlertContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: []
        };

        window.addEventListener("display_alert_event", event => {
            const { alertData } = event.detail;
            this.displayAlert(alertData);
        });

        window.addEventListener("remove_alert_event", event => {
            const { alertData } = event.detail;
            this.removeAlert(alertData);
        });
    }

    createAlert = ({ id, type, message, htmlMessage, details, action, duration }) => {
        // Default options
        const options = {
            autoHideDuration: 5000,
            btnColor: "black"
        };

        // Default style
        const style = {
            background: "white",
            color: "black"
        };

        switch(type) {
            case "info":
                message = "INFO: " + message;
                break;

            case "error":
                style.background = "#E33B5C";
                style.color = "white";
                options.autoHideDuration = 20000;
                options.btnColor = "white";
                break;

            case "warning":
                style.background = "#F4DF99";
                style.color = "black";
                options.autoHideDuration = 10000;
                options.btnColor = "black";
                break;

            case "success":
                style.background = "#66BB6A";
                style.color = "white";
                options.btnColor = "white";
                break;
        }

        return {
            id,
            type,
            message,
            htmlMessage,
            details,
            action,
            style,
            btnColor: options.btnColor,
            autoHideDuration: duration ?? options.autoHideDuration,
        };
    }

    removeAlert = ({ id }) => {
        const alerts = this.state.alerts.filter(a => a.id !== id);
        this.setState({ alerts });
    }

    displayAlert = (alertData) => {
        // Create the alert
        const alert = this.createAlert(alertData);

        // Remove any existing alert that is the same so that we don't spam the user
        const alerts = this.state.alerts.filter(a =>
            a.type !== alert.type
            || a.message !== alert.message
            || a.htmlMessage !== alert.htmlMessage
            || a.details !== alert.details
        );
        alerts.push(alert);
        this.setState({ alerts });
    }

    render() {
        return (
            <div className="alert-container">
                {this.state.alerts.map(a => (
                    <Alert
                        {...a}
                        key={a.id}
                        removeAlert={this.removeAlert}
                    />
                ))}
            </div>
        );
    }
}


class Alert extends Component {
    static propTypes = {
        message: PropTypes.string,
        htmlMessage: PropTypes.string,
        details: PropTypes.any,
        btnColor: PropTypes.string,
        style: PropTypes.object,
        action: PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            title: PropTypes.string.isRequired
        })
    };

    constructor(props) {
        super(props);

        this.timerFadeOut = null;
        this.timerRemove = null;

        this.state = {
            fadeIn: false,
            hidden: false,
            expanded: false
        };
    }

    componentDidMount() {
        // Starts a timer that will add the fade-in class
        setTimeout(function() {
            this.setState({ fadeIn: true });
        }.bind(this), 100);

        this.setTimeouts();
    }

    componentWillUnmount() {
        window.clearTimeout(this.timerFadeOut);
        window.clearTimeout(this.timerRemove);
    }

    setTimeouts = () => {
        window.clearTimeout(this.timerFadeOut);
        window.clearTimeout(this.timerRemove);

        // Starts a timer that will remove the fade-in class (causing it to fade out)
        this.timerFadeOut = setTimeout(function() {
            this.setState({ fadeIn: false });
        }.bind(this), this.props.autoHideDuration - 500);

        // Starts a timer that will hide/remove the alert
        this.timerRemove = setTimeout(function() {
            this.hide();
        }.bind(this), this.props.autoHideDuration);
    }

    hide = () => {
        this.setState({ hidden: true });
        window.clearTimeout(this.timerRemove);

        this.props.removeAlert({ id: this.props.id });
    }

    toggleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
        this.setTimeouts();
    }

    render() {
        const { fadeIn, hidden, expanded } = this.state;
        const { message, htmlMessage, details, btnColor, style, action } = this.props;

        // Render no component if state is hidden
        if (hidden) {
            return null;
        }

        const fadeInClass = fadeIn ? " fade-in" : "";
        return (
            <div className={"alert" + fadeInClass} style={style}>
                {details && (
                    <button onClick={this.toggleExpanded} className="action-left" style={{ color: btnColor }}>
                        <span className={`icon-${expanded ? "expand_less" : "expand_more"}`}></span>
                    </button>
                )}
                <span className="message">
                    {htmlMessage ? <div dangerouslySetInnerHTML={{ __html: htmlMessage }} /> : null}
                    {message}
                    {action && (
                        <a
                            onClick={() => {
                                action.onClick();
                                this.hide();
                            }}
                        >
                            {action.title}
                        </a>
                    )}
                </span>
                {details && (
                    <div className={`details ${expanded ? "" : "hide"}`}>
                        {renderDetails(details)}
                    </div>
                )}
                <button onClick={this.hide} className="action-right" style={{ color: btnColor }}>
                    <span className="icon-close"></span>
                </button>
            </div>
        );
    }
}

function renderDetails(details) {
    if (Array.isArray(details)) {
        return details.map((d, i) => <div key={i}>{d}</div>);
    }
    return details;
}