import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Image from '../../../image'
import StarConstants from '../../../../core/constants/star'

import Actions from '../actions'
import Store from '../store'

import appConfig from 'config'

const PREVIEW_WIDTH = 200;

export default class CropPreviews extends Component {

	static propTypes = {
		asset: PropTypes.object,
		disabled: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	onSelectCrop = selectedCrop => {
		// TODO!!!: Possibly do this in the store instead to keep as little logic in the component as possible?
		// See if the selected crop is available
		let crop = this.state.assetCrops.find(assetCrop => assetCrop.id === selectedCrop.id);
		if (!crop) {
			crop = selectedCrop.cropData
				? selectedCrop
				: { ...selectedCrop, cropData: getDefaultCropData(selectedCrop) };
		}

		Actions.selectCrop(crop);
	}

	render() {
		const { asset } = this.props;
		const { cropTypes, activeCrop, assetCrops, previewsRefreshed } = this.state;


		if (!cropTypes.length) {
			return (
				<div className="crop-previews">
					<p>No crops configured for this type of image.</p>
				</div>
			);
		}

		const crops = asset.crops || [];
		const mappedCropTypes = mapCropsToCropTypes(crops, cropTypes);
		if (!mappedCropTypes.length) {
			return null;
		}

		const original = asset.files && asset.files.find(f => f.fileFormatId === 0 && f.status === "Ok");
		const modifiedCropsIds = assetCrops.map(assetCrop => assetCrop.id);

		return (
			<div className="crop-previews">
				{mappedCropTypes.map(crop => (
					<CropPreview
						key={crop.id}
						crop={crop}
						asset={asset}
						original={original}
						active={activeCrop.id === crop.id}
						approved={crop.cropData && crop.cropData.approved}
						modified={modifiedCropsIds.includes(crop.id)}
						onEditClick={() => this.onSelectCrop(crop)}
						forceRefreshImage={!!Object.keys(previewsRefreshed).length && (previewsRefreshed[crop.id] === false)}
						onLoad={() => Actions.previewDidRefresh(crop.id)}
					/>
				))}
			</div>
		);
	}
}

class CropPreview extends Component {
	constructor(props) {
		super(props);

		this.timestamp = Date.now();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			(nextProps.asset.id !== this.props.asset.id)
			|| (nextProps.active !== this.props.active)
			|| (nextProps.forceRefreshImage !== this.props.forceRefreshImage)
			|| (nextProps.modified !== this.props.modified)
		);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (nextProps.forceRefreshImage !== this.props.forceRefreshImage) {
			this.timestamp = Date.now();
		}
	}

	render() {
		const {
			asset,
			original,
			active,
			approved,
			forceRefreshImage,
			onLoad,
			crop,
			onEditClick,
		} = this.props;

		const hasCrop = Boolean(crop.cropData);

		const thumbnailFormatId = getThumbnailFormatName(crop);

		const imageSmallerThanCropMinimum = (original.height < crop.minHeight || original.width < crop.minWidth);
		const refreshImageParam = forceRefreshImage ? `&forceRefresh=true` : "";
		const extension = asset.assetData && asset.assetData.type.toLowerCase() === "png" ? "png" : "jpg";

		return (
			<div
				key={crop.id}
				disabled={imageSmallerThanCropMinimum}
				className={`preview${active ? " sel" : ""}${approved ? " approved" : ""}${hasCrop ? ` has-crop` : ""}`}
			>
				{thumbnailFormatId && (
					<Image
						minWidth={`${PREVIEW_WIDTH}px`}
						minHeight={`${PREVIEW_WIDTH / crop.xRatio * crop.yRatio}px`}
						onClick={onEditClick}
						src={`${appConfig.media.image}${asset.assetGuid}/${thumbnailFormatId}.${extension}?timestamp=${this.timestamp}${refreshImageParam}`}
						alt={crop && crop.displayName}
						onLoad={forceRefreshImage ? onLoad : null}
						aspectRatio={`${crop.xRatio} / ${crop.yRatio}`}
					/>
				)}
				{thumbnailFormatId && <div className={`${approved ? " icon-check_circle" : ""}`}>{getCropInfo(crop, imageSmallerThanCropMinimum)}</div>}
				{!thumbnailFormatId && <p>No preview available</p>}
			</div>
		);
	}
}

// HELPERS
function mapCropsToCropTypes(crops, cropTypes) {
	return cropTypes.map(cropType => ({
		...cropType,
		cropData: crops.find(crop => crop.cropTypeId === cropType.id),
	}));
};

function getCropInfo(crop, imageSmallerThanCropMinimum) {
	const cropName = crop.displayName || crop.name;
	return imageSmallerThanCropMinimum ? cropName + " (Image is too small for this crop)" : cropName;
}

function getThumbnailFormatName(crop) {
	const cropFormat = StarConstants.CROPS.find(i => i.id === crop.id);
	return cropFormat?.fileFormatName ?? "original";
}

function getDefaultCropData(crop) {
	return {
		approved: true,
		cropTypeId: crop.id,
		offsetX: 0,
		offsetY: 0,
		height: 0,
		width: 0,
	};
};