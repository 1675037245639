import React from 'react'
import PropTypes from 'prop-types'

import appConfig from 'config'

import './drawertoggler.css'

const ModuleDrawerToggler = props => {
    const {
        toggle,
        primaryText = "Comet",
        highlightPrimaryText = false,
        secondaryText = "",
        icon = "icon-apps",
        open,
    } = props;

    const openClass = open ? "drawer-open" : "";
    const toggler = highlightPrimaryText
        ? <span className="highlighted-primary-text" style={getHighlightedTextStyle()}>{primaryText}</span>
        : primaryText;
    return (
        <button className={`drawer-toggler ${openClass}`} onClick={toggle}>
            <span className={`${icon} drawer-toggler-title`}>{toggler}</span>
            <span className="drawer-toggler-module">{secondaryText} <span className="newVersionAvailable hide">(please <a className="c6-link" onClick={reloadBrowser}>reload your browser</a> for a newer version)</span></span>
        </button>
    );
};
ModuleDrawerToggler.propTypes = {
    toggle: PropTypes.func.isRequired,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    icon: PropTypes.string,
    open: PropTypes.bool
}
export default ModuleDrawerToggler;

function reloadBrowser(e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload(true);
}

function getHighlightedTextStyle() {
    if (appConfig.features.headerHighlightedTextBackgroundColor) {
        return { backgroundColor: appConfig.features.headerHighlightedTextBackgroundColor };
    }

    return {};
}