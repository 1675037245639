import React from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './crop.css'

const Cropper = props => {
	const [crop, setCrop] = React.useState(() => getInitialCrop(props));

	React.useEffect(() => {
		const initialCrop = getInitialCrop(props);
		setCrop(initialCrop.crop);
		if (initialCrop.didSetNew) {
			props.onComplete(convertPercentCropToPixelCrop(initialCrop.crop, props.originalWidth, props.originalHeight));
		}
	}, [props.cropId]);

	// react-image-crop expects minWidth and minHeight in pixels relative to the rendered image
	// so we calculate that here
	const [minWidthPxRelativeToCropperImage, setMinWidthPxRelativeToCropperImage] = React.useState();
	const [minHeightPxRelativeToCropperImage, setMinHeightPxRelativeToCropperImage] = React.useState();
	const setMinValuesAfterImageLoad = React.useCallback(
		(e) => {
			const imageEl = e.currentTarget;
			const imageDimensions = imageEl.getBoundingClientRect();
			
			if (props.minWidth) {
				const minWidthPercentage = props.minWidth / props.originalWidth;
				setMinWidthPxRelativeToCropperImage(minWidthPercentage * imageDimensions.width);
			}
			if (props.minHeight) {
				const minHeightPercentage = props.minHeight / props.originalHeight;
				setMinHeightPxRelativeToCropperImage(minHeightPercentage * imageDimensions.height);
			}
		},
		[]
	);

	return (
		<ReactCrop
			crop={crop}
			disabled={props.disabled}
			keepSelection={true}
			ruleOfThirds={true}
			aspect={props.aspect}
			minWidth={minWidthPxRelativeToCropperImage}
			minHeight={minHeightPxRelativeToCropperImage}
			onChange={(pixelCrop, percentageCrop) => {
				setCrop(percentageCrop);
			}}
			onComplete={(pixelCrop, percentageCrop) => {
				const correctPixelCrop = convertPercentCropToPixelCrop(percentageCrop, props.originalWidth, props.originalHeight);
				if (props.onComplete && !(correctPixelCrop.width === 0 && correctPixelCrop.height === 0)) {
					props.onComplete(correctPixelCrop);
				}
			}}
		>
			<img
				src={props.src}
				onLoad={setMinValuesAfterImageLoad}
			/>
		</ReactCrop>
	);
}

export default Cropper;

function convertPixelCropToPercentCrop(pixelCrop, originalWidth, originalHeight) {
	return {
		unit: "%",
		x: (pixelCrop.x / originalWidth) * 100,
		y: (pixelCrop.y / originalHeight) * 100,
		width: (pixelCrop.width / originalWidth) * 100,
		height: (pixelCrop.height / originalHeight) * 100,
	};
}

function convertPercentCropToPixelCrop(percentageCrop, originalWidth, originalHeight) {
	return {
		unit: "px",
		x: Math.floor((percentageCrop.x * originalWidth) / 100),
		y: Math.floor((percentageCrop.y * originalHeight) / 100),
		width: Math.ceil((percentageCrop.width * originalWidth) / 100),
		height: Math.ceil((percentageCrop.height * originalHeight) / 100),
	};
}

function getInitialCrop(props) {
	const shouldSetNewCrop = (
		props.crop.x === 0
		&& props.crop.y === 0
		&& props.crop.width === 0
		&& props.crop.height === 0
	);

	if (!shouldSetNewCrop) {
		return {
			crop: convertPixelCropToPercentCrop(props.crop, props.originalWidth, props.originalHeight),
			didSetNew: false,
		};
	}

	const crop = {
		unit: "%",
		x: 0,
		y: 0,
	};

	// If original image is wider than the crop aspect ratio, set height to 100.
	// If original image is taller than the crop aspect ratio, set width to 100.
	const originalAspect = props.originalWidth / props.originalHeight;
	if (originalAspect > props.aspect) {
		crop.width = (props.aspect / originalAspect) * 100;
		crop.height = 100;
	} else {
		crop.width = 100;
		crop.height = (originalAspect / props.aspect) * 100;
	}

	return {
		crop,
		didSetNew: true,
	};
}