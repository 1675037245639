import React, { Component } from 'react'

import './iframedialog.css'

export default class IFrameDialog extends Component {

	render() {
		const { open = false, src } = this.props;

		const iFrameStyle = {
			height: "100%",
			width: "100%",
			border: "none",
		};

		if (open) {
			return (
				<div className="iframe-dialog-wrapper" onClick={this.props.onClose}>
					<div className="iframe-dialog" onClick={e => e.stopPropagation()}>
						<iframe src={src} style={iFrameStyle} allowFullScreen={true}></iframe>
					</div>
				</div>
			);
		}

		return null;
	}
}