export default {
	CONTAINER_TYPES: [
		{ id: 0, name: "Other" },
		{ id: 1, name: "Single Program" },
		{ id: 2, name: "Episode" },
		{ id: 3, name: "Season" },
		{ id: 4, name: "Series" },
		{ id: 5, name: "Sport single" },
		{ id: 6, name: "Sport episode" },
		{ id: 7, name: "Sport series" },
		{ id: 8, name: "Sport season" },
		{ id: 9, name: "Program family" },
	],
	ASSET_TYPES: [
		{ key: 0, text: "Undefined" },
		{ key: 1, text: "Image" },
		{ key: 2, text: "Video" },
		{ key: 3, text: "Document" },
		{ key: 4, text: "Trailer" },
	],
	IMAGE_FORMATS: ["jpeg", "jpg", "jif", "jfif", "gif", "tif", "tiff", "jp2", "jpx", "j2k", "j2c", "fpx", "pcd", "png", "bmp", "thm", "svg", "webp", "cr2", "nef", "orf", "arw"],
	DOCUMENT_FORMATS: ["doc", "docx", "log", "msg", "pages", "rtf", "txt", "wpd", "wps", "ai", "psd", "lsr", "drw", "ps", "csv", "dat", "efx", "gbr", "key", "pps", "ppt", "pptx", "sdf", "tax2010", "vcf", "xml", "indd", "pct", "pdf", "qxd", "qxp", "rels", "xlr", "xls", "xlsx", "asp", "cer", "csr", "css", "htm", "html", "js", "jsp", "php", "rss", "xhtml", "fnt", "fon", "otf", "ttf", "cfg", "ini", "keychain", "prf", "7z", "deb", "gz", "pkg", "rar", "rpm", "sit", "sitx", "tar.gz", "zip", "zipx", "c", "class", "cpp", "cs", "dtd", "fla", "java", "m", "pl", "py", "aep", "ai", "eps", "cr3", "nrw", "rw2", "srf", "sr2"],
	CLIP_FORMATS: ["3g2", "3gp", "asf", "asx", "avi", "flv", "mkv", "mov", "mp4", "mpg", "rm", "swf", "vob", "wmw", "pkf", "wav", "m4a"],
	CATEGORY_TYPES: [
		{ id: 4, name: "Tag", content: "☗" },
		{ id: 5, name: "Person", content: "☺" }
	],
	ACCESS_TYPES: [
		{ id: "Private", name: "Private" },
		{ id: "Admin", name: "Admin" },
		{ id: "All", name: "All" }
	],
	LICENSING: [
		{ id: "Public", name: "Public" },
		{ id: "Protected", name: "Protected" },
		{ id: "Internal", name: "Internal" },
		{ id: "Watermarked", name: "Watermarked" },
		{ id: "SpecialRights", name: "Special rights" }
	],
	RECENT_SEARCHES_KEY: "recentStarSearches",
	CROPS: [
		{ id: 2, name: "4x3", fileFormatId: 38, file2XFormatId: 39, fileFormatName: "Star_4x3_200x", downloadFormatId: 18, downloadFormatName: "crop4x3" },
		{ id: 3, name: "16x9", fileFormatId: 36, file2XFormatId: 37, fileFormatName: "crop16x9_w200",downloadFormatId: 19, downloadFormatName: "crop16x9", download1920x1080FormatId: 2414, download1920x1080FormatName: "app_1920_1080" },
		{ id: 10, name: "16x9 extra space", fileFormatId: 4214, file2XFormatId: 4215, fileFormatName: "crop16x9extramargin_w200_h113", downloadFormatId: 4216, downloadFormatName: "crop16x9extramargin", download1920x1080FormatId: 4213, download1920x1080FormatName: "crop16x9extramargin_w1920_h1080" },
		{ id: 4, name: "7x10", fileFormatId: 40, file2XFormatId: 41, fileFormatName: "crop7x10_w200_h286", downloadFormatId: 20, downloadFormatName: "crop7x10" },
		{ id: 5, name: "4x1", fileFormatId: 42, file2XFormatId: 43, fileFormatName: "crop4x1_w200_h50", downloadFormatId: 30, downloadFormatName: "crop4x1" },
		{ id: 6, name: "UltraForge 69x20", fileFormatId: 32, file2XFormatId: 35, fileFormatName: "ultraforge_w200_h58", downloadFormatId: 28, downloadFormatName: "ultraforge" },
		{ id: 7, name: "16x7", fileFormatId: 55, file2XFormatId: 56, fileFormatName: "crop16x7_w200_h88", downloadFormatId: 57, downloadFormatName: "crop16x7" },
		{ id: 8, name: "2x3", fileFormatId: 52, file2XFormatId: 53, fileFormatName: "crop2x3_w200_h300", downloadFormatId: 54, downloadFormatName: "crop2x3" },
		{ id: 9, name: "1x1", fileFormatId: 59, file2XFormatId: 60, fileFormatName: "crop1x1_w200_h200", downloadFormatId: 61, downloadFormatName: "crop1x1" },
	],
	// DEPRECATED: Will be removed when old STAR is gone
	CROP_FORMAT_IDS: [
		{ cropId: 1, fileFormatId: 15, downloadFormatId: 21, name: "freeform" },
		{ cropId: 2, fileFormatId: 10, downloadFormatId: 18, name: "4x3" },
		{ cropId: 3, fileFormatId: 11, downloadFormatId: 19, name: "16x9" },
		{ cropId: 10, fileFormatId: 4214, downloadFormatId: 4216, name: "16x9 extra space" }, /* Used for the secondary extra space 16:9 crop */
		{ cropId: 4, fileFormatId: 12, downloadFormatId: 20, name: "7x10" },
		{ cropId: 5, fileFormatId: 29, downloadFormatId: 30, name: "4x1" },
		{ cropId: 6, fileFormatId: 27, downloadFormatId: 28, name: "UltraForge 69x20" },
		{ cropId: 7, fileFormatId: 55, downloadFormatId: 57, name: "16x7" },
		{ cropId: 8, fileFormatId: 52, downloadFormatId: 54, name: "2x3" },
		{ cropId: 9, fileFormatId: 59, downloadFormatId: 61, name: "1x1" },
	]
};
