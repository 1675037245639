import jsonmergepatch from 'json-merge-patch'
import cloneDeep from 'lodash/cloneDeep'

import alt from '../../../core/services/alt'
import * as TagsAPI from '../../../apis/tags'
import * as MetadataAPI from '../../../apis/metadata'
import * as StarAPI from '../../../apis/star'
import * as OsdAPI from '../../../apis/osd'
import { handleRequestFailed } from '../../../core/services/errorhandling'
import * as Alert from '../../../core/services/alert'

import appConfig from 'config'

class Actions {

	fetchProgram({ programId, programGuid, useContentSourceMetadata }) {
		return dispatch => {
			dispatch();

			let metadataProgram = {};
			MetadataAPI.fetchProgram({ id: programId })
				.then(async metadataResponse => {
					metadataProgram = metadataResponse;
					if (useContentSourceMetadata) {
						const container = await StarAPI.fetchContainerByGuid(programGuid);
						metadataProgram.versions = container?.contentSourceMetadata?.versions ?? metadataProgram.versions;
						const finnishVersion = metadataProgram.versions?.find(v => v.versionId === 4);
						if (finnishVersion && (appConfig.features.metadataC80 || appConfig.features.metadataC70)) {
							// Star API only saves finnish*** fields, regardless if we use C60 or C80 Metadata API...
							Object.entries(finnishVersion).forEach(([key, value]) => {
								if (key.startsWith("finnish") && !key.includes("Approval")) {
									finnishVersion[key.replace("finnish", "local")] = value;
									delete finnishVersion[key];
								}
							});
						}
						metadataProgram.statuses = container?.contentSourceMetadata?.statuses;// ?? metadataProgram.status;
					}
					const payload = {
						name: encodeURIComponent(metadataProgram.name || ""),
						itemTypeId: 0,
					};

					if (appConfig.features.tagsC80) {
						payload.provider = metadataProgram?.provider.name;
						payload.reference = metadataProgram.guid;
						payload.inputSource = "manual";
					} else if (appConfig.features.tagsC70) {
						payload.provider = metadataProgram?.provider.name;
						payload.reference = metadataProgram.reference;
						payload.inputSource = "manual";
					} else {
						payload.referenceId = metadataProgram.id;
					}
					
					return TagsAPI.fetchOrCreateItem(payload);
				}, this.requestFailed)
				.then(tagsResponse => {
					this.programLoaded({
						metadataProgram,
						tagsProgram: tagsResponse,
					})
				}, this.requestFailed);
		};
	}

	saveProgram({ metadataProgram, tagsProgram, pristineMetadataProgram }, useContentSourceMetadata, location) {
		return async dispatch => {
			dispatch();

			const metadataPatch = jsonmergepatch.generate(pristineMetadataProgram, metadataProgram);
			const qParams = appConfig.features.localRatingSource ? `localRatingSource=${appConfig.features.localRatingSource}` : {};
			const saveMetadataProgram = MetadataAPI.patchProgram({ id: metadataProgram.id }, metadataPatch, qParams);
			onlySendTagIdsToAPI(tagsProgram);
			const saveTagsProgram = TagsAPI.updateItem({ id: tagsProgram.id }, tagsProgram);
			let saveContentSourceMetadata = Promise.resolve(null);
			if (useContentSourceMetadata) {
				const contentSourceMetadataPayload = cloneDeep(metadataProgram);
				const finnishVersion = contentSourceMetadataPayload.versions?.find(v => v.versionId === 4) ?? {};
				// Star API only saves finnish*** fields, regardless if we use C60 or C80 Metadata API...
				Object.entries(finnishVersion).forEach(([key, value]) => {
					if (key.startsWith("local")) {
						finnishVersion[key.replace("local", "finnish")] = value;
						delete finnishVersion[key];
					}
				});
				saveContentSourceMetadata = StarAPI.updateContentSourceMetadata(metadataProgram.guid, contentSourceMetadataPayload, location?.query?.todoId);
			}

			let saveTodo = Promise.resolve();
			if (location?.query?.todoId) {
				// Mark workListItem/todo as in progress
				const todo = await OsdAPI.fetchTodo({ id: location.query.todoId });
				if (todo?.status === "Unhandled") {
					saveTodo = OsdAPI.updateTodo(
						{ id: todo.workListItemId },
						{ ...todo, status: "InProgress" }
					);
				}
			}

			Promise.all([saveMetadataProgram, saveTagsProgram, saveContentSourceMetadata, saveTodo])
				.then(([newMetadataProgram, newTagsProgram, container]) => {
					const program = {
						...newMetadataProgram,
						versions: container?.contentSourceMetadata?.versions ?? newMetadataProgram.versions,
						statuses: container?.contentSourceMetadata?.statuses ?? [],
					};
					const finnishVersion = program.versions?.find(v => v.versionId === 4);
					if (finnishVersion && (appConfig.features.metadataC80 || appConfig.features.metadataC70)) {
						// Star API only saves finnish*** fields, regardless if we use C60 or C80 Metadata API...
						Object.entries(finnishVersion).forEach(([key, value]) => {
							if (key.startsWith("finnish") && !key.includes("Approval")) {
								finnishVersion[key.replace("finnish", "local")] = value;
								delete finnishVersion[key];
							}
						});
					}
					this.programLoaded({
						metadataProgram: program,
						tagsProgram: newTagsProgram,
					});
					this.metadataProgramSaved(newMetadataProgram);
					// Let OSD know that we can fetch updated todo in case the todo status was updated after completing an item
					this.contentSourceMetadataContainerLoaded(container, location?.query?.todoId);
					Alert.displayAlert("success", "Ratings were saved!");
				}, this.requestFailed);
		};
	}

	fetchDataSources() {
		return dispatch => {
			dispatch();

			const fetchDescriptionTags = TagsAPI.fetchDescriptionTags();
			const fetchSymbols = MetadataAPI.fetchSymbols();

			Promise.all([fetchDescriptionTags, fetchSymbols])
				.then(([descriptionTags, symbols]) => {
					this.dataSourceLoaded({ descriptionTags, symbols });
				}, this.requestFailed);
		};
	}

	programLoaded(payload) { return payload }
	metadataProgramSaved(payload) { return payload }
	dataSourceLoaded(payload) { return payload }
	contentSourceMetadataContainerLoaded(payload, todoId) { return { payload, todoId } }
	updateTags(payload) { return payload }
	updateSymbols(payload) { return payload }
	updateRatingName(value) { return value }
	updateRatingDate(value) { return value }
	updateApproval(value) { return value }
	updateRatingType(value) { return value }
	updateAge(value) { return value }
	updateStatus(value) { return value }
	updateCountryAgeRating(versionId, value) { return { versionId, value } }
	
	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// Remove everything except id from tags (criterias)
// This is a workaround for when the API (for unknown reasons) in the original fetch will say that the tags are disabled,
// if we then send the tags back with "disabled:true", the tags will be removed from the program...
function onlySendTagIdsToAPI(tagsProgram) {
	tagsProgram.tags = tagsProgram.tags?.map(tag => {
		return {
			id: tag.id,
		};
	});
}