import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { ExtraErrorData } from '@sentry/integrations'
import appConfig from 'config'

import { getUser } from './auth'

const currentVersion = document.querySelector("html").getAttribute("data-version");

export function addErrorTracking() {
	if (appConfig.features.enableSentryTracking && !appConfig.features.sentryUrl) {
		console.error(`Sentry tracking enabled but no sentryUrl provided in the ${appConfig.env} app.config`);
	} else if (appConfig.features.enableSentryTracking) {
		Sentry.init({
			dsn: appConfig.features.sentryUrl,
			release: currentVersion,
			integrations: [
				new Integrations.BrowserTracing(),
				new ExtraErrorData({ depth: 10 }),
			],
			environment: appConfig.env,
			tracesSampleRate: 1.0,

			beforeSend: (event, hint) => {
				// console.log("event", event);
				// console.log("hint", hint);
				return event; // send event
				// return null; // discard event
			},
		});
	}
}

export async function configureErrorTrackingUser(user) {
	if (appConfig.features.enableSentryTracking) {
		if (!user) {
			// Remove the logged out user from our Sentry tracking
			Sentry.setUser(null);
		} else {
			// Make Sentry aware of the logged in user
			Sentry.setUser({ email: user.email });
		}
	}
}

export function addUsageTracking() {
	if (appConfig.features.enableHeapTracking && !appConfig.features.heapId) {
		console.error(`Heap tracking enabled but no heapId provided in the ${appConfig.env} app.config`);
	} else if (appConfig.features.enableHeapTracking) {
		window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
		heap.load(appConfig.features.heapId);
	}
}

export function addSupportBeacon() {
	if (appConfig.features.enableSupportBeacon && !appConfig.features.supportBeaconId) {
		console.error(`Support Beacon enabled but no supportBeaconId provided in the ${appConfig.env} app.config`);
	} else if (appConfig.features.enableSupportBeacon) {
		!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
		window.Beacon("init", appConfig.features.supportBeaconId);
		window.Beacon("config", {
			color: "#06a7b1",
		});
		window.Beacon("session-data", {
			"Comet version": document.querySelector("html").getAttribute("data-version"),
		});
	}
}

export function setSupportBeaconUser(user) {
	if (window.Beacon) {
		window.Beacon("identify", {
			name: user?.name ?? user?.username,
			email: user?.email ?? user?.username,
		});
	}
}

export function resetSupportBeacon() {
	if (window.Beacon) {
		window.Beacon("reset"); // reset form inputs
		window.Beacon("logout"); // clear data set by "identify"
	}
}

let adobeAnalyticsLoaded = false;
export function addAdobeAnalytics() {
	if (appConfig.features.adobeAnalyticsURL) {
		const script = document.createElement("script");
		script.setAttribute("src", appConfig.features.adobeAnalyticsURL);
		script.onload = () => {
			adobeAnalyticsLoaded = true;
		}
		document.head.appendChild(script);
	}
}

export function setAdobeAnalyticsUser() {
	if (appConfig.features.adobeAnalyticsURL) {
		window.digitalData = window.digitalData || {};

		const user = getUser();
		if (user?.username?.includes("@")) {
			const usernameDomain = user.username.match(/@(.*)$/)[1]; // get the email domain (john.eriksson@june.se => june.se)
			window.digitalData.user = { id: usernameDomain };
		} else {
			window.digitalData.user = { id: null };
		}
		// console.log("digitalData.user set to:", window.digitalData.user);
	}
}

function onAdobeAnalyticsLoaded(callback) {
	const interval = setInterval(() => {
		if (adobeAnalyticsLoaded) {
			clearInterval(interval);
			callback();
		}
	}, 100);
}

export function trackAdobeAnalyticsPageView(pageName) {
	if (appConfig.features.adobeAnalyticsURL) {
		onAdobeAnalyticsLoaded(() => {
			setAdobeAnalyticsUser();

			window.digitalData = window.digitalData || {};
			const name = (pageName || window.location.pathname)
				.replace(/\//g, ":") // replace forward slash with colon
				.replace(/^:/, "") // remove leading colon
				.replace(/:$/, "") // remove trailing colon
			window.digitalData.page = { name };
			// console.log("digitalData.page set to:", window.digitalData.page);
			if (window._satellite?.track) {
				try {
					window._satellite.track("pageview");
				} catch (e) {
					console.error("Failed to notify Adobe Analytics: ", e);
				}
			}	
		});
	}
}

export function trackAdobeAnalyticsImageDownload(filename) {
	if (appConfig.features.adobeAnalyticsURL) {
		onAdobeAnalyticsLoaded(() => {
			setAdobeAnalyticsUser();

			window.digitalData.helper.event("d", filename, {}, false);
		});
	}
}

export function trackAdobeAnalyticsPressPreview(pageName) {
	if (appConfig.features.adobeAnalyticsURL) {
		onAdobeAnalyticsLoaded(() => {
			setAdobeAnalyticsUser();

			window.digitalData.helper.event("d", `press-preview-${pageName}`, {}, false);
		});
	}
}

export function addOneTrustCookieConsent() {
	if (appConfig.features.pressOneTrustCookiesConsentId) {
		const script1 = document.createElement("script");
		script1.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
		script1.setAttribute("data-language", "fi");
		script1.setAttribute("charset", "UTF-8");
		script1.setAttribute("type", "text/javascript");
		script1.setAttribute("data-domain-script", appConfig.features.pressOneTrustCookiesConsentId);
		document.body.appendChild(script1);

		const script2 = document.createElement("script");
		script2.setAttribute("type", "text/javascript");
		script2.innerHTML = "function OptanonWrapper() {}";
		document.body.appendChild(script2);
	}
}