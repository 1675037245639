import React from 'react'
import TextField from '@mui/material/TextField'

import { getCharacterCountState, getCharacterCountStyles, sanitizeTextInput } from './utils'

const MuiText = (props) => {
	const {
		value,
		required,
		placeholder = "",
		label,
		schema,
		options = {},
		readonly = false,
		onChange,
		formContext = {},
		id,
	} = props;

	const {
		maxLength: length,
		recommendedLength,
		deviation,
		floatingLabelFixed,
		liveUpdate = false,
		sendInt32MinWhenEmpty,
		fullWidth,
		halfWidth,
		thirdWidth,
		hideValueBehindLock,
		min,
		max,
	} = options;
	
	const [charactersState, setCharactersState] = React.useState(value?.length ?? 0);
	const [focus, setFocus] = React.useState(false);
	const [unlocked, setUnlocked] = React.useState(false);
	const ref = React.useRef(null);

	React.useEffect(
		() => {
			if (ref.current) {
				ref.current.value = getDefaultValue(value, schema);
				setCharactersState(ref.current.value?.length ?? 0);
			}
		},
		[value]
	);

	// Update the editor model on enter key or blur event
	const commitChange = React.useCallback(
		(e) => {
			if (
				//e.type === "change" ||
				e.type === "blur" ||
				e.which == 13 ||
				e.keyCode == 13
			) {
				let { value: newValue } = e.target;
				if (!newValue && sendInt32MinWhenEmpty) {
					newValue = -2147483648;
				}

				e.preventDefault();
				e.target.blur();

				// Only update model if value actually has changed
				const type = schema?.type;
				newValue = type === "number" ? parseInt(newValue ?? 0) : sanitizeTextInput(String(newValue ?? ""));
				const oldValue = type === "number" ? parseInt(value ?? 0) : sanitizeTextInput(String(value ?? ""));
				if (newValue !== oldValue) {
					onChange(newValue, e);
				}
			}
		},
		[onChange, value]
	);

	const handleTextChange = React.useCallback(
		(e) => {
			const text = sanitizeTextInput(e.target.value);
			setCharactersState(text.length);
			if (liveUpdate) {
				onChange(text, e);
			} else if (formContext.editorActions?.setDirty) {
				formContext.editorActions.setDirty();
			}
		},
		[onChange]
	);

	const maxLength = length || schema?.maxLength || null; // maxLength can either come from the ui schema or the json-schema
	const characters = liveUpdate ? (value ?? "").length : charactersState;
	const { recommended, error } = getCharacterCountState(characters, recommendedLength, deviation, maxLength);

	const labelText = `${label}${required ? " *" : ""}`;

	const containerStyles = {
		root: {
			position: 'relative',
			width: 'calc(100% - 40px)',
			overflow: "hidden",
			paddingTop: "15px"
		},
		fullWidth: {
			clear: 'both',
		},
		halfWidth: {
			width: 'calc(50% - 40px)',
			float: 'left',
		},
		thirdWidth: {
			width: 'calc(33% - 40px)',
			float: 'left',
		},
	};

	let style = Object.assign({}, containerStyles.root);
	if (fullWidth || labelText === 'Short description') style = Object.assign(style, containerStyles.fullWidth);
	if (halfWidth) style = Object.assign(style, containerStyles.halfWidth);
	if (thirdWidth) style = Object.assign(style, containerStyles.thirdWidth);

	const locked = hideValueBehindLock && !unlocked;

	const { ccStyle } = getCharacterCountStyles(recommended, error, readonly);

	let color = "primary";
	if (error) {
		color = "error";
	} else if (recommended) {
		color = "success";
	}

	const defaultValue = getDefaultValue(value, schema);
	const valueProps = liveUpdate
		? { value: defaultValue }
		: { defaultValue: defaultValue };

	return (
		<div style={style}>
			{!locked && (
				<React.Fragment>
					<TextField
						id={id}
						inputRef={ref}
						variant="standard"
						className="c6-mui"
						name="muiText"
						color={color}
						label={labelText}
						placeholder={placeholder}
						onBlur={(e) => {
							setFocus(false);
							commitChange(e);
						}}
						onFocus={() => setFocus(true)}
						onKeyDown={commitChange}
						onChange={handleTextChange}
						focused={error ? true : undefined}
						style={{ width: '100%' }}
						type={schema?.type || "text"}
						min={min}
						max={max}
						inputProps={{
							readOnly: Boolean(readonly),
						}}
						InputLabelProps={floatingLabelFixed || defaultValue?.length || defaultValue >= 0 ? { shrink: true } : {}}
						{...valueProps}
					/>
					{(maxLength) && (
						<span style={{ ...ccStyle, visibility: error || focus ? "visible" : "hidden" }}>
							{characters} / {maxLength}
						</span>
					)}
				</React.Fragment>
			)}
			{locked && (
				<React.Fragment>
					<TextField
						id={id}
						variant="standard"
						className="c6-mui"
						name="muiText"
						color={color}
						focused={error ? true : undefined}
						label={label}
						defaultValue="<Hidden>"
						style={{ width: '100%' }}
						InputLabelProps={floatingLabelFixed ? { shrink: true } : {}}
					/>
					<div
						style={lockOverlayStyle}
						onClick={() => setUnlocked(true)}
					>
						<span className="icon-lock_outline large-icon"></span>
						Click to unlock field
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default MuiText;

const lockOverlayStyle = {
	position: "absolute",
	bottom: 0,
	left: 0,
	paddingLeft: "5px",
	width: "100%",
	height: "32px",
	background: "var(--modal-area-color)",
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
};

function getDefaultValue(value, schema) {
	if (schema?.type === "number" && (isNaN(value) || value === -2147483648)) {
		return 0;
	}

	return value ?? "";
}
