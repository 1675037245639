import React from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'

const ItemTypes = { TAG: "tag" };

const tagSource = {
	beginDrag(props) {
		return {
			id: props.id,
			index: props.index
		};
	},
	endDrag(props) {
		props.dropTag();
	},
	canDrag(props) {
		// Allow dragging when there is a moveTag() function provided and readOnly is false
		return props.moveTag && (!props.readOnly || props.allowReorder);
	}
};

const tagTarget = {
	hover(props, monitor, component) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Do nothing if hovered tag is same as dragging tag
		if (dragIndex === hoverIndex)
			return;

		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
		const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
		const clientOffset = monitor.getClientOffset();
		const hoverClientX = clientOffset.x - hoverBoundingRect.left;

		// Only move tags when the mouse has crossed half the width
		if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX)
			return;
		if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX)
			return;

		props.moveTag(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	},
	canDrop(props) {
		return !props.readOnly || props.allowReorder;
	}
};

const dragSource = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging()
});
const dropCollect = (connect, mintor) => ({
	connectDropTarget: connect.dropTarget()
});

const RemoveComponent = props => {
	if (props.readOnly)
		return (<span />);

	return (
		<button
			type="button"
			onClick={props.onClick}>
			×
		</button>
	);
};


@DragSource(ItemTypes.TAG, tagSource, dragSource)
@DropTarget(ItemTypes.TAG, tagTarget, dropCollect)
export default class Tag extends React.Component {
	static propTypes = {
		id: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string
		]),
		name: PropTypes.string,
		moveTag: PropTypes.func,
		dropTag: PropTypes.func,
		readOnly: PropTypes.bool,
		// From react-dnd
		connectDragSource: PropTypes.func.isRequired,
		isDragging: PropTypes.bool.isRequired,
		connectDropTarget: PropTypes.func.isRequired
	};

	static defaultProps = {
		readOnly: false
	};

	render() {
		const {
			name,
			readOnly,
			onClick,
			allowReorder,

			connectDragSource,
			isDragging,
			connectDropTarget
		} = this.props;

		const className = `c6-tag ${readOnly ? "read-only" : ""} ${allowReorder ? "allow-reorder" : ""}`;

		return connectDragSource(connectDropTarget(
			<div className={className} style={{ opacity: isDragging ? 0 : 1 }}>
				{name}
				<RemoveComponent
					onClick={onClick}
					readOnly={readOnly}
				/>
			</div>
		));
	}
}