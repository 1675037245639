import React, { PureComponent } from 'react'
import moment from 'moment'
import TextField from '@mui/material/TextField'

import { Filter, Left, Right } from '../../../components/filter'
import { formatBytes } from '../../../utils/misc'

import Const from '../../../core/constants'

import Actions from './actions'

export default class AssetHeader extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			fileName: "",
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.asset) {
			this.setState({
				fileName: nextProps.asset.displayName || "",
			});
		}
	}

	handleUpdateTitle = e => {
		this.setState({
			fileName: e.target.value,
		});
	}

	handleSaveTitle = e => {
		if (this.state.fileName !== this.props.asset.displayName) {
			Actions.updateTitle(this.props.asset.id, e.target.value);
		}
	}

	render() {
		const { asset, disabled } = this.props;
		const { fileName } = this.state;

		if (!asset || !asset.id) return null;

		return (
			<Filter>
				<Left>
					<TextField
						className="asset-title"
						variant="standard"
						color="primary"
						InputProps={{ readOnly: disabled }}
						value={fileName}
						onBlur={this.handleSaveTitle}
						onChange={this.handleUpdateTitle}
						fullWidth={true}
					/>
					<div className="metadata">
						{getMetadata(asset, fileName)}
					</div>
				</Left>
			</Filter>
		);
	}
}

function getMetadata(asset, fileName) {
	const orgFile = asset.files && asset.files.find(f => f.fileFormatId === 0 && (f.status === "Ok" || f.status === "Undefined"));
	let dimensions, size, duration; 
	if (orgFile) {
		if (["Image", "Clip", "Trailer"].includes(asset.type)) {
			dimensions = orgFile.width && orgFile.height ? <span>{orgFile.width}×{orgFile.height} px.</span> : <span>Dimensions unknown.</span>;
		}
		size = <span>{formatBytes(orgFile.size, 0)}.</span>;
		duration = orgFile.duration ? <span>Duration: {orgFile.duration}</span> : null;
	}

	let updated, updatedBy;
	if (asset.modifiedByUser) {
		updated = moment(asset.modified);
		updatedBy = `Modified by ${asset.modifiedByUser}`;
	} else if (asset.createdByUser) {
		updated = moment(asset.added || asset.files[0].created);
		updatedBy = `Created by ${asset.createdByUser}`;
	}

	const originalFileName = orgFile && fileName !== orgFile.name ? <span>Original name: {orgFile.name}</span> : null;

	const contentSource = asset.contentSource !== "Undefined" ? <span>Provided by: {asset.contentSource}</span> : null;

	return (
		<React.Fragment>
			{dimensions}
			{size}
			{duration}
			{updated && (
				<span>
					{updatedBy}&nbsp;<time title={updated.format()} dateTime={updated.format()}>{updated.fromNow()}</time>.
				</span>
			)}
			{originalFileName}
			{contentSource}
		</React.Fragment>
	);
}