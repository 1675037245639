import { get, put, post, patch, del } from '../core/c6'
// import Qs from 'qs'

const API = "exchange";

/* **************** Items ***************** */
export const fetchItems 		= filters 			=> get(API, "items", filters);
export const fetchItem 			= ({id})			=> get(API, `items/${id}/data`);
export const updateItem			= ({id}, payload) 	=> put(API, `items/${id}/data`, payload);

/* **************** Files ***************** */
export const fetchFiles 		= filters 	=> get(API, "files", filters);
export const fetchFile			= ({id})	=> get(API, `files/${id}`);
export const fetchOriginalFile 	= ({id}) 	=> get(API, `files/${id}/original`);

/* **************** Programs ***************** */
export const fetchPrograms 		= filters 	=> get(API, "programs", filters);

/* **************** Schedules ***************** */
export const fetchSchedules 	= filters 	=> get(API, "schedules", filters);

/* **************** Assets ***************** */
export const fetchAssets 		= filters 	=> get(API, "assets", filters);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);