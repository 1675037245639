import React from 'react'

import { getServiceToken } from '../../../core/services/auth'
import IFrameDialog from '../../../dialogs/iFrameDialog'

import appConfig from 'config'

export default function QCLink(props) {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const token = useStarToken();

	const openDialog = () => {
        setDialogOpen(true);
	};

    const iFrameSrc = getQCUrl(props.program, token);

    const dialog = (
        <IFrameDialog
            src={iFrameSrc}
            open={props.open ?? dialogOpen}
            onClose={props.onClose ?? (() => setDialogOpen(false))}
        />
    );

    if (props.renderOnlyDialog) {
        return dialog;
    }

    return (
        <dl>
            <dt>QC</dt>
            <dd><a className="c6-link" onClick={openDialog}>Play</a></dd>
            {dialog}
        </dl>
    );
}

export function QCWithoutLink(props) {
    return <QCLink {...props} renderOnlyDialog />;
}

export function getQCUrl(program, token, light) {
    const params = new URLSearchParams();
    params.set("tkn", token);
    params.set("programGuid", program?.guid);
    params.set("config", appConfig.features.metadataQCConfigName);
    params.set("cachebuster", Date.now());
    params.set("light", light);
    const testPlayerBaseUrl = appConfig.features.metadataQCBaseUrl ?? "http://testplayer.june.se:8080";
    return `${testPlayerBaseUrl}?${params.toString()}`;
}

export function useStarToken(disabled = false) {
    const [token, setToken] = React.useState(null);

    React.useEffect(
        () => {
            if (!disabled) {
                const fetchToken = async () => {
                    // Get Star token since we need to pass it to the iframe to make STAR-requests from there
                    const starToken = await getServiceToken("star");
                    setToken(starToken);
                }
                fetchToken();
            }
        },
        [disabled]
    );

    return token;
}