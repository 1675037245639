import React from 'react'
import PropTypes from 'prop-types'
import Picker from './picker'

const DateTimePicker = props => (
	<Picker
		openIcon="insert_invitation"
		dateFormat="Y-m-d H:i"
		enableTime={true}
		{...props} />
);

export default DateTimePicker;

DateTimePicker.propTypes = {
	showConfirmButton: PropTypes.bool,
	showTodayButton: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	format: PropTypes.string,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string, // Single date
		PropTypes.array // Date range
	]),
	value: PropTypes.oneOfType([
		PropTypes.string, // Single date,
		PropTypes.array // Date range
	])
};

DateTimePicker.defaultProps = {
	showConfirmButton: true,
	showTodayButton: true,
	format: "YYYY-MM-DD HH:mm"
};