import React, { Component } from 'react'

import appConfig from 'config'

import { useLocalStorage } from '../../core/hooks/useStorage'
import DropDown from '../ui/controls/dropdown'
import { getUser, getUserAccessLevelForModule } from '../../core/services/auth'

import './usercontrols.css'

// const storageKey = "hasSeenWhatsNewVersion";
const storageKey = "hasSeenWhatsNew";
// const htmlWithVersion = document.querySelector("html[data-version]");
// const currentVersion = htmlWithVersion && htmlWithVersion.getAttribute("data-version");

export default class UserControls extends Component {
    
    state = {
        showWhatsNew: appConfig.product === "Horizon" && !localStorage.getItem(storageKey),
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.toggleSettingsArea);
    }

    closeWhatsNew = () => {
        this.setState({ showWhatsNew: false });
        // localStorage.setItem(storageKey, currentVersion);
        localStorage.setItem(storageKey, true);
    }

    renderWhatsNew = () => {
        return (
            <div style={{ display: this.state.showWhatsNew ? "block" : "none" }}>
                <a className="c6-link icon-cancel" onClick={this.closeWhatsNew}></a>
                <h1>Horizon has been updated!</h1>

                <br/>
                <h4>Batch operations</h4>
                <p>You can resend or update a bigger batch of assets by using batch operations. To start, click "Perform batch operations" in the upper right corner of the list. Select packages by clicking them and then choose your action.</p>

                <br/>
                <h4>Search for local title</h4>
                <p>The search function will now find assets by local title as well as the original title. You can also limit your search results to a specific season, for example "Peppa Pig S1".</p>

                <br/>
                <h4>Configure a package rate limit</h4>
                <p>In the service configuration editor (blue cogwheel in the upper left corner) you can configure a maximum number of new packages sent every hour.</p>

                <br/>
                <h4>Check the package log</h4>
                <p>If you are interested in what's been happening to a package, click the "History log" found under the 3 dots on a package. This shows a list of events with information about what and when something happened and who carried out the action.</p>

                <br/>
                <h4>When was a package sent?</h4>
                <p>To see when a package was distributed and/or updated, put your cursor on top of the "i" that appears for packages with the "Delivered" status.</p>

                <br/>
                <h4>See what's queued</h4>
                <p>To see packages that are waiting to be distributed or updated, use the "Queued" or "Pending updates" status filters.</p>

                <br/>
                <h4>Find metadata & images</h4>
                <p>On each package in the list, you will find a "Metadata" link. This link will take you to the C More Press site where you can find metadata & images for that program. If your service is configured to receive metadata in multiple languages, there will be links for those next to the "Metadata" link.</p>
            </div>
        );
    }

    toggleBeacon = () => {
        if (window.Beacon) {
            window.Beacon("open");
        } else {
            console.warn("No window.Beacon :(");
        }
    }

    render() {
        const { username = "No user", onLogOut } = this.props;

        const user = getUser();

        return (
            <div className="c6-user-controls">
				<div className="wrapper">
					{/* check BUILD_PRODUCT like below? */}
					{appConfig.product === "Horizon" && (
						<div className="whats-new">
							<a className="icon-info" onClick={() => this.setState({ showWhatsNew: true })}>&nbsp;What's new?</a>
							{this.renderWhatsNew()}
						</div>
					)}
                    {appConfig.features.enableSupportBeacon && user && (
                        <span className="icon-help beacon-toggle" onClick={this.toggleBeacon}>&nbsp;Help</span>
                    )}
					<span className="icon-person settings-toggle" onClick={this.props.onToggleUserSettingsOpen}>&nbsp;{username}</span>
					<span className="icon-cancel" onClick={onLogOut}></span>
				</div>
            </div>
        );
    }
}
