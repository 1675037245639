import React, { Component } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import groupBy from 'lodash/groupBy'
import upperFirst from 'lodash/upperFirst'

import Tags from '../../../components/ui/editorFields/tags'
import DatePicker from '../../../components/ui/controls/pickers/datepicker'
import Switch from '../../../components/ui/controls/switch'

import Actions from './actions'
import { Ages, Symbols, RatingPersons, AgeRatings } from './data'

import appConfig from 'config'

const FINNISH_DESCRIPTION = 8;
const localRatingType = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingType" : "finnishRatingType";
const localRatingSymbols = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingSymbols" : "finnishRatingSymbols";
const localRatingCreatedDate = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingCreatedDate" : "finnishRatingCreatedDate";
const localRatingAge = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingAge" : "finnishRatingAge";
const localRatingContactPerson = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingContactPerson" : "finnishRatingContactPerson";

export class DescriptionsContent extends Component {
	state = {
		closedDescriptionGroups: [],
	};

	toggleOpen = groupKey => {
		const closedDescriptionGroups = this.state.closedDescriptionGroups;
		const index = closedDescriptionGroups.indexOf(groupKey);
		if (index >= 0) {
			closedDescriptionGroups.splice(index, 1);
		} else {
			closedDescriptionGroups.push(groupKey);
		}
		this.setState({ closedDescriptionGroups });
	}

	render() {
		const { tagsProgram, dataSource = [], isLoading, language, setLanguage, readonly } = this.props;
		if (isLoading && !tagsProgram.id) {
			return <h1 className="step-content-loading">Loading...</h1>;
		}

		const tags = tagsProgram.tags?.filter(tag => tag.tagType.id === FINNISH_DESCRIPTION) || [];
		const groupedDescriptions = groupDescriptions(dataSource);

		return (
			<div className="step-content descriptions">
				<div className="content-full">
					<LanguageSwitch language={language} setLanguage={setLanguage} />
				</div>
				{Object.keys(groupedDescriptions).map(groupKey => {
					const symbolName = Symbols.find(s => s.key === groupKey)[language];
					const open = !this.state.closedDescriptionGroups.includes(groupKey);
					return (
						<div key={groupKey} className={`description-group ${open ? "open" : ""}`}>
							<p onClick={() => this.toggleOpen(groupKey)}>{symbolName}<span className={open ? "icon-expand_less" : "icon-expand_more"}></span></p>
							<div style={{ display: open ? "block" : "none" }}>
								{groupedDescriptions[groupKey].sort(sortDescriptions).map(tag => {
									const descriptionNumber = parseInt(tag.displayName.split(".")[0]);
									const age = Ages.find(a => a.descriptions.includes(descriptionNumber));
									const checked = tags.some(t => t.id === tag.id);
									return (
										<FormControlLabel
											key={tag.id}
											className="description"
											disabled={readonly}
											control={
												<Checkbox
													checked={checked}
													onChange={() => Actions.updateTags({ tags: tagsProgram.tags, tag })}
												/>
											}
											value={tag.id}
											label={(
												<span>
													<span
														style={{ color: age.color }}
														className="description-age"
													>
														{age.key === "S" && language === "swedish" ? "T" : age.key}
													</span>
													{language === "finnish" ? tag.versions.find(v => v.version === "Finnish")?.title ?? tag.displayName : tag.displayName}
												</span>
											)}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);	
	}
}

const translateSymbols = (symbols, language) => {
	symbols.forEach(symbol => {
		const symbolData = Symbols.find(s => s.swedish === symbol.name || s.finnish === symbol.name);
		symbol.displayName = symbolData[language];
		symbol.name = symbolData.key;
	});
};

export const SymbolsAgeContent = ({ version, dataSource = [], isLoading, language, setLanguage, ikluSearchText, readonly }) => {
	if (isLoading && !version.versionId) {
		return <h1 className="step-content-loading">Loading...</h1>;
	}

	const symbols = version[localRatingSymbols] || [];
	translateSymbols(symbols, language);
	translateSymbols(dataSource, language);

	const isIklu = version[localRatingType] === "iklu";

	return (
		<div className="step-content symbols-age">
			{appConfig.features.metadataOtherCountriesRatings && (
				<h2>Finnish age rating</h2>
			)}
			<div className="content-left">
				<p>Age rating</p>
				<RadioGroup
					name="age"
					value={version[localRatingAge] || "S"}
					onChange={(e) => Actions.updateAge(e.target.value)}
				>
					{Ages.map(age => (
						<FormControlLabel
							key={age.key}
							value={age.key}
							label={(
								<span className="age" style={{ color: age.color }}>
									{age.key === "S" && language === "swedish" ? "T" : age.key}
								</span>
							)}
							disabled={readonly || !isIklu}
							control={<Radio inputProps={{ style: readonly || !isIklu ? { opacity: 0 } : {}}} />}
						/>
					))}
				</RadioGroup>
			</div>
			<div className="content-right">
				<Tags
					formData={symbols}
					uiSchema={{
						"ui:options": {
							dataSource,
							dataSourceConfig: { text: "displayName", value: "name" }
						},
					}}
					schema={{ title: "Symbols" }}
					idSchema={{}}
					readonly={readonly || !isIklu}
					allowReorder={!readonly}
					onChange={symbols => Actions.updateSymbols({ symbols })}
				/>

				<LanguageSwitch language={language} setLanguage={setLanguage} />
				<p>Press space to list the symbols you can add and order them by drag and drop.</p>

				{isIklu && (
					<p>
						<a className="c6-link" target="_blank" href={`https://luokittelu.kavi.fi/public.html#haku/${ikluSearchText}//`}>Search kavi.fi for ratings</a>
					</p>
				)}
			</div>
		</div>
	);
};

export const NameDateContent = ({ version, dataSource, isLoading, readonly, freeTextContactPerson }) => {
	if (isLoading && !version.versionId) {
		return <h1 className="step-content-loading">Loading...</h1>;
	}

	const name = version[localRatingContactPerson];
	const tagValue = name && name.length ? [{ id: 1, displayName: name, name: name }] : [];
	return (
		<div className="step-content name-date">
			<div className="content-left">
				{freeTextContactPerson && (
					<TextField
						variant="standard"
						className="c6-mui"
						name="newSeasonName"
						value={name}
						onChange={(e) => Actions.updateRatingName(e.target.value)}
						label="Rated by"
						readOnly={readonly || version[localRatingType] === "iklu"}
						sx={{ marginTop: "20px" }}
					/>
				)}
				{!freeTextContactPerson && (
					<Tags
						formData={tagValue}
						uiSchema={{
							"ui:options": {
								dataSource: RatingPersons,
								dataSourceConfig: { text: "displayName", value: "name" }
							},
							maxTags: 1,
						}}
						schema={{ title: "Rated by" }}
						idSchema={{}}
						readonly={readonly || version[localRatingType] === "iklu"}
						onChange={tags => Actions.updateRatingName(tags[0] ? tags[0].displayName : "")}
					/>
				)}
			</div>
			<div className="content-right">
				<DatePicker
					label="When? (optional)"
					onChange={newValue => Actions.updateRatingDate(newValue)}
					value={version[localRatingCreatedDate]}
					disabled={readonly}
				/>
			</div>
		</div>
	);
};

export const OtherCountriesRatingsContent = ({ swedishVersion, norwegianVersion, danishVersion, isLoading, readonly }) => {
	if (isLoading && !swedishVersion.versionId) {
		return <h1 className="step-content-loading">Loading...</h1>;
	}

	return (
		<div className="step-content other-countries">
			<div className="content-full">
				<h2>Other countries</h2>
				{[swedishVersion, norwegianVersion, danishVersion].map((version) => (
					<FormControl key={version.versionId} variant="standard" className="country-age-rating">
						<InputLabel>{upperFirst(version.versionName)}</InputLabel>
						<Select
							className="c6-mui-select"
							value={version.localRatingAge}
							onChange={(e) => Actions.updateCountryAgeRating(version.versionId, e.target.value)}
							variant="standard"
							disabled={readonly}
							MenuProps={{ className: "c6-mui-select-menu" }}
							defaultValue={AgeRatings[version.versionName.toLowerCase()][0].key}

							// HACK because mui hides the input using opacity=0, but we use opacity=0.45 on disabled elements
							inputProps={readonly ? { style: { opacity: 0 }} : {}}
						>
							{AgeRatings[version.versionName.toLowerCase()].map(({ key, text }) => (
								<MenuItem key={key} value={key}>
									{text}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				))}
			</div>
		</div>
	);
}

const LanguageSwitch = ({ language, setLanguage }) => {
	return (
		<div className="language-switch">
			<Switch
				name="language"
				title="Show names in"
				onChange={(e) => setLanguage(e.target.value)}
				states={[{ key: "swedish", text: "Swedish" }, { key: "finnish", text: "Finnish" }]}
				currentState={language}
			/>
		</div>
	);
};

function groupDescriptions(descriptions) {
	const grouped = groupBy(descriptions, (description, i) => {
		const descriptionNumber = parseInt(description.name.split(".")[0]);
		const symbol = Symbols.find(s => s.descriptions.includes(descriptionNumber));
		return symbol.key;
	});
	const sortedGroups = Object.entries(grouped) // to array
		.sort((a, b) => b[1].length - a[1].length) // sort by descriptions length
		.reduce((acc, [key, val]) => { // back to object
			acc[key] = val;
			return acc;
		}, {});
	return sortedGroups;
}

function sortDescriptions(descA, descB) {
	return parseInt(descA.displayName.split(".")[0]) - parseInt(descB.displayName.split(".")[0]);
}