import React from 'react'
import PropTypes from 'prop-types'

import Error from './error'
import Spinner from './spinner'

import styles from './app.css'

const App = ({name, errorMessage, isLoading, children, layoutDirection, layout}) => {

	const loading = isLoading ? "c6-loading" : "";
	const layoutMode = layout ? `c6-layout-${layout}` : "";
	const flexDirection = layoutDirection ? `flex-dir-${layoutDirection}` : "";

	const classNames = `c6-app ${name} ${layoutMode} ${loading} ${flexDirection}`;
	const error = errorMessage ? <Error>{errorMessage}</Error> : null;
	const spinner = isLoading ? <Spinner loading={isLoading} /> : null;

	return (
		<div className={classNames}>
			{error}
			{children}
			{spinner}
		</div>
	);
};

App.propTypes = {
	name: PropTypes.string.isRequired,
	errorMessage: PropTypes.string,
	isLoading: PropTypes.bool
}

export default App;