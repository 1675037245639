import React from 'react'
import Form from '@rjsf/core'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'
import jsonmergepatch from 'json-merge-patch'
import keydown from 'react-keydown'

import validator from '@rjsf/validator-ajv8'

import App from '../../components/app'
import Editor from '../../components/editor'

import Scroll from '../../components/scroll'

import EditorNavigationController from './editorNavigationController'
import { getReturnTo } from '../../core'

import {
	MuiText,
	MuiTextArea,
	MuiCheckbox,
	MuiCheckboxes,
	MuiRadio,
	MuiSelect,
	MuiAutoComplete,
	MuiToggle,
	Label,
	Header,
	HeaderWithDropdown,
	WYSIWYG,
} from '../../components/ui/editorWidgets'

import Tags from '../../components/ui/editorFields/tags'
import MultiSelect from '../../components/ui/editorFields/multiSelect'
import { Filter, Left, Right } from '../../components/filter'
import Button from '../../components/ui/controls/button'
import { DateTimePicker, DatePicker, TimePicker} from '../../components/ui/controls/pickers'

import appConfig from 'config'
import { createPatch } from '../cms/utils'
import { KEYBOARD_SHORTCUTS } from '../constants'

@withRouter
class EditorCore extends React.Component {
	constructor(props) {
		super(props);

		this.handleModelChange = this.handleModelChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleDebug = this.toggleDebug.bind(this);
		this.save = this.save.bind(this);

		this.formRef = null;

		this.state = {
			showDebug: false,
		}
	}

	componentDidMount() {
		if(appConfig.features && appConfig.features.alertOnDirty) { // TODO: Remove when #stableOnleave
			// Prevent the user from navigating away from unsaved editors
			// TODO: Currently the only available leave dialog is the ugly built in browser one
			// https://github.com/ReactTraining/react-router/issues/4265
			// TODO: Maybe in react-router v4?
			// https://github.com/ReactTraining/react-router/blob/v4/website/examples/PreventingTransitions.js
			this.props.router.setRouteLeaveHook(this.props.route, () => {
				if(this.props.isDirty) {
					return 'You have unsaved information, are you sure you want to leave this page?'
				}
			});
		}
    }

	handleModelChange(data, changeFieldId) {
		// HACK: For certain schemas, RJSF will call onChange on the initial load when it shouldn't
		// It seems to be working as expected if we check that the second parameter is defined before calling our onChange
		// https://github.com/rjsf-team/react-jsonschema-form/issues/2626
		if (changeFieldId) {
			const { pristineModel, rfc6902 } = this.props;
			let patch;
			if (pristineModel?.id) {
				patch = rfc6902
					? createPatch(pristineModel, data.formData)
					: jsonmergepatch.generate(pristineModel, data.formData);
			}
	
			this.props.onChange(data, patch, changeFieldId);
		}
	}

	handleSubmit(data) {
		const { pristineModel, rfc6902 } = this.props;
		let patch;
		if (pristineModel?.id) {
			patch = rfc6902
				? createPatch(pristineModel, data.formData)
				: jsonmergepatch.generate(pristineModel, data.formData);
		}

		this.props.onSubmit(data, patch);
	}

	toggleDebug(e) {
		e.preventDefault();
		this.setState({showDebug: !this.state.showDebug})
	}
	
	@keydown(KEYBOARD_SHORTCUTS.approve)
	approve(e) {
		e.preventDefault();
		const approvalInput = document.querySelector(`.c6-editor .rjsf-grid .c6-editor-approval input[type="checkbox"]`);
		if (approvalInput) {
			approvalInput.click();
		}
	}

	@keydown(KEYBOARD_SHORTCUTS.save)
	save(e) {
		e.preventDefault();
		if (this.formRef) {
			this.formRef.submit();
		}
	}

	@keydown(KEYBOARD_SHORTCUTS.prev)
	left(e) {
		e.preventDefault();
		EditorNavigationController.goLeft();
	}

	@keydown(KEYBOARD_SHORTCUTS.next)
	right(e) {
		e.preventDefault();
		EditorNavigationController.goRight();
	}

	@keydown(KEYBOARD_SHORTCUTS.translate)
	translate(e) {
		e.preventDefault();
		const translateButton = document.querySelector(".c6-editor button.translate");
		if (translateButton) {
			translateButton.click();
		}
	}

	// TODO: It would be much better if we can provide custom error messages from the
	// widgets themselves instead of with the transformErrors rjsf prop
	transformErrors = (errors) => {
		const errorsAfterGenericTransform = errors.map(error => {
			if (error.name === "pattern" && error.property === ".nid") {
				error.message = "Please use letters (a-ö), numbers (0-9) or dashes (-)";
			}
			else if (error.name === "pattern" && error.property === ".imdbId") {
				error.message = "Please type an IMDb ID (i.e. tt1234567)";
			}
			else if (
				error.name === "const" && error.message.includes("equal to constant")
				|| error.name === "oneOf" && error.message.includes("must match exactly one schema in oneOf")
			) {
				error.message = "Please select one of the options from the list";
			}
			return error;
		});

		if (this.props.customTransformErrors) {
			const errorsAfterCustomTransform = this.props.customTransformErrors(errorsAfterGenericTransform);
			return errorsAfterCustomTransform;
		}

		return errorsAfterGenericTransform;
	}

	render() {
		const {
			formContext = {},
			isModal,
			isDirty = false,
			renderForm = false,
			model,
			schema,
			uiSchema,
			customFields = {},
			customWidgets = {},
			objectFieldTemplate = null,
			liveValidate = false,
			validate = null,
			onError,
			children,
			extraActions = null,
			disableActions = false,
			enableEditorNavigation = false,
			className = "rjsf",
			location,
			routes,
			params,
		} = this.props;

		const editorActions = getActions(this.toggleDebug, extraActions, disableActions, isDirty, location, routes, params, enableEditorNavigation);
		const templates = { FieldTemplate: fieldTemplate };
		if (objectFieldTemplate) {
			templates.ObjectFieldTemplate = objectFieldTemplate;
		}

		const editorForm = renderForm
			? (
				<Form
					ref={(ref) => this.formRef = ref}
					className={className}
					formContext={formContext}
					templates={templates}
					schema={schema}
					uiSchema={uiSchema}
					widgets={getWidgets(customWidgets)}
					fields={getFields(customFields)}
					formData={model}
					onChange={this.handleModelChange}
					onSubmit={this.handleSubmit}
					onError={onError}
					showErrorList={false}
					liveValidate={liveValidate}
					customValidate={validate}
					transformErrors={this.transformErrors}
					autoComplete="off"
					validator={validator}
				>
					{editorActions}
				</Form>
			)
			: editorActions;

		const topComponents = children;

		const content = this.state.showDebug
			? getDebugEditor(model, editorActions)
			: getFormEditor(topComponents, editorForm);

		return getEditorApp(content, isModal);
	}
}

export default EditorCore;

// HELPERS
const handleCancel = (e, location, routes, params) => {
	e.preventDefault();
	// TODO!!!!: goBack() logic should not be needed since browserHistory.replace "Replaces the current entry on the history stack"
	// The problem is that goBack does not give us control of the URL, so we can't remove URL hashes for example :(

	const returnTo = getReturnTo(location, routes, params);
	if (returnTo !== "/" || !location.state?.modal) {
		browserHistory.replace(returnTo);
	} else {
		browserHistory.goBack();
	}
}

const getDebugEditor = (model, editorActions) => (
	<Editor>
		{editorActions}
		<pre>{ JSON.stringify(model,undefined,2,2) }</pre>
	</Editor>
);

const getFormEditor = (topComponents, editorForm) => (
	<Editor>
		{topComponents}
		{editorForm}
	</Editor>
);

const getEditorApp = (content, inModal) => (
	<App name="c6-editor" isLoading={false}>
		<Scroll nopadding={true} inModal={inModal}>
			{content}
		</Scroll>
	</App>
);

export const getWidgets = customWidgets => {
	return {
		text: MuiText,
		textarea: MuiTextArea,
		date: DatePicker,
		dateTime: DateTimePicker,
		time: TimePicker,
		radio: MuiRadio,
		select: MuiSelect,
		toggle: MuiToggle,
		checkbox: MuiCheckbox,
		checkboxes: MuiCheckboxes,
		autocomplete: MuiAutoComplete,
		label: Label,
		header: Header,
		headerWithDropdown: HeaderWithDropdown,
		wysiwyg: WYSIWYG,
		...customWidgets,
	}
}

export const getFields = customFields => {
	return {
		tags: Tags,
		multiSelect: MultiSelect,
		...customFields
	}
}

function getActions(toggleDebug, extraActions, disableActions, isDirty, location, routes, params, enableEditorNavigation) {
	return (
		<Filter className="c6-actionbar">
			<Left>
				<Button
					title="Save"
					type="submit"
					noBackground={true}
					disabled={disableActions || !isDirty}
					hoverTitle={`Save (${KEYBOARD_SHORTCUTS.save})`}
				/>
				<Button
					title="Close"
					type="cancel"
					noBackground={true}
					onClick={e => handleCancel(e, location, routes, params)}
					disabled={disableActions}
					hoverTitle="Close (ESC)"
				/>
				{extraActions}
				{(appConfig.env === "Development" || window.location.hostname.includes("localhost")) && (
					<Button
						title="JSON"
						type="json"
						noBackground={true}
						onClick={toggleDebug}
					/>
				)}
				{appConfig.features.enableEditorNavigation && enableEditorNavigation && (
					<>
						<Button
							type="prev"
							hoverTitle={`Navigate to the previous item in the list (${KEYBOARD_SHORTCUTS.prev})`}
							noBackground={true}
							onClick={() => EditorNavigationController.goLeft()}
							disabled={!EditorNavigationController.canGoLeft()}
						/>
						<Button
							type="next"
							hoverTitle={`Navigate to the next item in the list (${KEYBOARD_SHORTCUTS.next})`}
							noBackground={true}
							onClick={() => EditorNavigationController.goRight()}
							disabled={!EditorNavigationController.canGoRight()}
						/>
					</>
				)}
			</Left>
			<Right>
			</Right>
		</Filter>
	);
	// TODO: Check if we should show or hide JSON-button in appConfig //this.showDebug = appConfig.env === "Production" ? false : true;
}

export const fieldTemplate = props => {
	const { classNames, help, errors, children, uiSchema } = props;
	const hoverTitle = uiSchema?.["ui:hoverTitle"];
	// Only show the first error since some fields can have many errors and that just looks weird
	if (errors?.props?.errors?.length > 1) {
		errors.props.errors.splice(1, errors.props.errors.length);
	}
	return (
		<div className={classNames} title={hoverTitle}>
			{children}
			{errors?.props?.errors?.length ? errors : <div className="errors-placeholder"></div>}
			{help}
		</div>
	);
}

//  <div className={classNames}>
//       <label htmlFor={id}>{label}{required ? "*" : null}</label>
//       {description}
//       {children}
//       {errors}
//       {help}
//     </div>