

/* 
	https://github.com/react-dnd/react-dnd/issues/186#issuecomment-282789420
	This function enables two or more drag-and-drop "areas" on the same page

	Usage:

	MyComponent.js
	--------------
	import withDragDropContext from 'path-to-this-file'

	class MyComponent extends React.Component {
		...
		...
		...
	}

	export default withDragDropContext(MyComponent);

*/

import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

export default DragDropContext(HTML5Backend);