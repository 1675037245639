import React, { PureComponent } from 'react'

import ContentItem, { Content } from '../../../components/list/contentItem'

import Actions from '../actions'

import appConfig from 'config'

class ProgramContent extends React.Component {

	render() {
		const {
			item = {},
			disabled,
			todo,
			container,
		} = this.props;

		const {
			id,
			guid,
			displayName,
			versions,
			type,
		} = item;

		let ratingsApproved = false;
		if (appConfig.features.useContentSourceMetadata) {
			ratingsApproved = container?.contentSourceMetadata?.statuses?.find(s => s.workListItemId === parseInt(todo?.workListItemId))?.complete;
		} else {
			ratingsApproved = versions?.find(v => v.finnishRatingApproval !== undefined)?.finnishRatingApproval?.status?.toLowerCase() === "approved";
		}

		return (
			<ContentItem data={item}>
				<Content className="metadata">
					<h1>{displayName}</h1>
				</Content>
				{["Single", "Episode"].includes(type) && (
					<Content className="c6-content-actions">
						<button
							disabled={disabled}
							className={ratingsApproved ? "fg-green icon-check" : "fg-red icon-close"}
							onClick={() => {
								Actions.edit({
									pathname: `/onestopdrop/ratings/${id}/edit`,
									query: { guid, todoId: todo?.workListItemId },
									returnTo: window.location.pathname,
								});
							}}
						>
							<span>{"Age rating"}</span>
						</button>
					</Content>
				)}
			</ContentItem>
		);
	}
}

export default ProgramContent;
