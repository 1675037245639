import * as Alert from '../core/services/alert'
import appConfig from 'config'

export const formatBytes = (bytes, decimals) => {
	if (bytes === 0) return "0 Byte";
	const k = 1024; // 1024 for binary
	const dm = decimals + 1 || 3;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const copyTextToClipboard = (textToCopy, alertText, e) => {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}

	if (!navigator.clipboard) {
		const el = document.createElement("textarea");
		el.value = textToCopy;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		if (alertText) {
			Alert.displayAlert("info", alertText);
		}
	} else {
		navigator.clipboard.writeText(textToCopy).then(() => {
			if (alertText) {
				Alert.displayAlert("info", alertText);
			}
		});
	}
};