import React from 'react'
import './header.css'

const Header = (props) => {
	const text = props.value ?? props.label;
	if (!text || text === "Undefined") {
		return null;
	}

	return (
		<div className="c6-widget-header">{text}</div>
	);
};

export default Header;