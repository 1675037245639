


/*
 *	This is a copy of https://github.com/chmln/flatpickr/blob/master/dist/plugins/confirmDate/confirmDate.js
 *  with some added/customized functionality
 */
const flatpickrButtonPlugin = pluginConfig => {
	const defaultConfig = {
		confirmIcon: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'> <g> </g> <path d='M15.418 1.774l-8.833 13.485-4.918-4.386 0.666-0.746 4.051 3.614 8.198-12.515 0.836 0.548z' fill='#000000' /> </svg>",
		confirmText: "OK ",
		showAlways: false,
		todayText: "SHOW TODAY",
		theme: "light",
		showConfirmButton: false,
		showTodayButton: false
	};

	const config = {};
	for (let key in defaultConfig) {
		config[key] = pluginConfig && pluginConfig[key] !== undefined ? pluginConfig[key] : defaultConfig[key];
	}

	// fp == flatpicker instance
	const fpFunc = fp => {
		const hooks = {
			onKeyDown: (_, __, ___, e) => {
				if (fp.config.enableTime && e.key === "Tab" && e.target === fp.amPM) {
					e.preventDefault();
					fp.confirmContainer.focus();
				} else if (e.key === "Enter" && e.target === fp.confirmContainer) {
					fp.close();
				}
			},
			onReady: () => {
				if (fp.calendarContainer === undefined) {
					return;
				}

				if (config.showConfirmButton) {
					fp.confirmContainer = fp._createElement("div", `flatpickr-confirm ${config.showAlways ? "visible" : ""} ${config.theme}Theme`, config.confirmText);
					fp.confirmContainer.tabIndex = -1;
					fp.confirmContainer.innerHTML += "&nbsp;" + config.confirmIcon;
					fp.confirmContainer.addEventListener("click", fp.close);
					fp.calendarContainer.appendChild(fp.confirmContainer);
				}

				if (config.showTodayButton) {
					fp.todayContainer = fp._createElement("div", `flatpickr-go-today ${config.theme}Theme visible`, config.todayText);
					fp.todayContainer.tabIndex = -1;
					fp.todayContainer.addEventListener("click", e => fp.jumpToDate(new Date()));
					fp.calendarContainer.appendChild(fp.todayContainer);
					if (fp.confirmContainer) {
						fp.confirmContainer.classList.add("has-sibling");
					}
				}
			}
		};

		if (!config.showAlways) {
			hooks.onChange = (dateObj, dateStr) => {
				const showCondition = fp.config.enableTime ||  fp.config.mode === "multiple";
				if (dateStr && !fp.config.inline && showCondition && fp.confirmContainer) {
					return fp.confirmContainer.classList.add("visible");
				}
				if (fp.confirmContainer) {
					fp.confirmContainer.classList.remove("visible");
				}
			};
		}

		return hooks;
	};

	return fpFunc;
};

export default flatpickrButtonPlugin;