// export Tags from './tags'
// export Links from './links'
// export Crew from './crew'
// export Approval from './approval'
// export MultiSelect from './multiSelect'


// https://stackoverflow.com/a/8809472/5743505
export function generateKey() {
	let d = new Date().getTime();
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
}