import { get, put, post, patch, del } from '../core/c6'

import appConfig from 'config'

const API = "vod";
const API_CORE = "vodcore";

/* **************** Packages ***************** */
export const fetchPackages    	= filters	=> get(API, "packages", filters);
export const fetchPackage     	= id		=> get(API, `packages/${id}`);
export const blockPackage     	= id		=> put(API, `packages/${id}/block`);
export const unblockPackage   	= id		=> put(API, `packages/${id}/unblock`);
export const unpublishPackage 	= id		=> put(API, `packages/${id}/unpublish`);
export const republishPackage 	= id		=> put(API, `packages/${id}/republish`);
export const resendPackage    	= id		=> put(API, `packages/${id}/resend`);
export const updatePackage		= id		=> put(API, `packages/${id}/update`);

/* **************** Deliveries ***************** */
export const fetchDeliveries = filters => get(API, "deliveries", filters);

/* **************** DeliveryItems ***************** */
export const fetchDeliveryItems = filters => get(API, "deliveryItems", filters);
export const fetchDeliveryItem = filters => get(API, `deliveryItems/${id}`);

/* **************** Services ***************** */
export const fetchServices 	= filters 	=> get(API, "services", filters);
export const fetchService 	= ({id}) 	=> get(API, `services/${id}`);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);