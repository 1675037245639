import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import './header.css'
import './headerWithDropdown.css'

const HeaderWithDropdown = ({
    value,
    schema,
    onChange,
    options,
}) => {
    if (value === "Undefined") {
        return null;
    }

	return (
        <div className="c6-widget-header c6-widget-header-dropdown">
            <Select
                className="c6-mui-select"
                value={value ?? ""}
                onChange={(e) => {
                    schema.onDropdownChange(e.target.value);
                    onChange(value); // Call onChange to trigger schema/model updates
                }}
                variant="standard"
                MenuProps={{ className: "c6-mui-select-menu" }}
                autoWidth={true}
                sx={{ minWidth: "100px" }}
            >
                {options.enumOptions?.map(opt => (
                    <MenuItem
                        key={opt.value}
                        value={opt.value}
                        disabled={opt.schema.disabled}
                    >
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default HeaderWithDropdown;