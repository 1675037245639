import 'regenerator-runtime/runtime'

import React from 'react'
import { render } from 'react-dom'
import { Router, applyRouterMiddleware } from 'react-router'
import { browserHistory } from 'browserHistory'
import { useScroll } from 'react-router-scroll'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { loginWithToken } from './core/services/auth'
import { addErrorTracking, addUsageTracking, addSupportBeacon } from './core/services/tracking'
import ErrorBoundary from './components/errorBoundary'
import { DEFAULT_QUERY_CLIENT } from './core/constants'
import { getCometTheme, setHtmlTheme } from './core/hooks/useDarkMode'

import routes from 'routes'
import appConfig from 'config'
console.log("index appConfig", appConfig);

addErrorTracking();
addUsageTracking();
addSupportBeacon();

// In case base paths start showing problems again we can always use a custom history with basename.
// Will cause problems when using the browserHistory.push singleton to programatically navigate though.
// I think we need to get hold of this.context.router or even better use the withRouter HOC to get the router object to do router.push

// import {Router, useRouterHistory} from 'react-router'
// import { createHistory } from 'history'
//import appConfig from 'config'
//const bh = useRouterHistory(createHistory)({ basename: appConfig.app.basePath });
//render(<Router history={bh}>{routes}</Router>, document.getElementById("root"));

document.getElementsByTagName("html")[0].classList.add(`env-${appConfig.env}`.toLowerCase());

// Make sure the user is logged in with Shield if this product uses Shield
const skipApiTokens = appConfig.features.skipApiTokens;
// Don't check if token is valid or expired if the user tries to reset a password or opens a resetpassword link. Otherwise a user might open a resetpassword link and be sent to the login page which is confusing
const ignoreTokenValidation = window.location.pathname.includes("resetpassword") || window.location.pathname.includes("forgotpassword");

if (!skipApiTokens && !ignoreTokenValidation) {
	const jwt = localStorage.getItem("userJWT");
	if (jwt) {
		const stayOnPage = ["press"].includes(__BUILD_PRODUCT__); // __BUILD_PRODUCT__ is a constant created in webpack.common.js
		loginWithToken(jwt, stayOnPage);
	}
}

const theme = getCometTheme();
setHtmlTheme(theme);

const Root = () => (
	<QueryClientProvider client={DEFAULT_QUERY_CLIENT}>
		<ErrorBoundary>
			<Router history={browserHistory} onUpdate={onRouterUpdate} render={applyRouterMiddleware(useScroll())}>
				{routes}
			</Router>
		</ErrorBoundary>

		{true && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
	</QueryClientProvider>
);
render(<Root />, document.getElementById("root"));

function onRouterUpdate() {
	const product = appConfig.product ?? "Comet";

	// Find route name in path
	const pathname = browserHistory.getCurrentLocation().pathname;
	const parts = pathname.split("/");
	const currentModule = parts[1];

	if (product?.includes("Comet") && currentModule && currentModule.length > 0) {
		// We are in a module or at login, unauthorized etc

		// Special case for "forgotpassword"
		if (currentModule.toLowerCase() == "forgotpassword") {
			document.title = `Forgot password - ${product}`;
			return;
		}

		// Make first letter uppercase
		const formattedModule = currentModule.charAt(0).toUpperCase() + currentModule.slice(1).toLowerCase();
		document.title = `${formattedModule} - ${product}`;
	} else {
		// We are at root /
		document.title = product;
	}
}