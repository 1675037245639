import React from 'react'
import PropTypes from 'prop-types'
import keydown from 'react-keydown'

import Button from '../../../components/ui/controls/button'
import Image from '../../../components/image'

import * as StarData from '../../../apis/star'
import { handleRequestFailed } from '../../../core/services/errorhandling'
import { download } from './assetActions'
import appConfig from 'config'

const MAX_FILE_INDEX = 5;

class DocumentAsset extends React.Component {
	state = {
		currentIndex: 0,
		fileIndexCount: null,
	}

	componentDidMount() {
		const { asset } = this.props;
		if (asset.assetData && asset.assetData.fileIndexCount !== undefined) {
			this.setState({ fileIndexCount: asset.assetData.fileIndexCount });
		} else if (appConfig.features.starDocumentAddonEnabled) {
			StarData.fetchFiles({ assetId: asset.id })
				.then(response => {
					const files = response.items || [];
					const fileWithHighestIndex = files.find(f => !files.find(f2 => f2.index > f.index));
					this.setState({ fileIndexCount: fileWithHighestIndex ? fileWithHighestIndex.index : 0 });
				}, handleRequestFailed);
		}
	}

	@keydown("left", "up")
	prevPage() {
		if (this.state.currentIndex > 0) {
			this.setState({
				currentIndex: this.state.currentIndex - 1,
			});
		}
	}

	@keydown("right", "down")
	nextPage() {
		const maxIndex = Math.min(this.state.fileIndexCount, MAX_FILE_INDEX);
		if (this.state.currentIndex < maxIndex - 1) {
			this.setState({
				currentIndex: this.state.currentIndex + 1,
			});
		}
	}

	render() {
		const { asset } = this.props;
		const imageLink = asset.links.find(link => link.rel === "image");
		const imageSource = appConfig.features.starDocumentAddonEnabled && imageLink && `${imageLink.href}/Star_None_x600.jpg`;

		const maxIndex = Math.min(this.state.fileIndexCount, MAX_FILE_INDEX);

		return (
			<div className="c6-asset c6-asset-document">
				<div style={{ padding: "0 10px" }}>
					{imageSource && (
						<p>
							{this.state.fileIndexCount === 0 && <span>It seems we have not yet been able to make a preview for this document. </span>}
							{this.state.fileIndexCount === MAX_FILE_INDEX && <span>Previewing the first 5 pages of the document. </span>}
							<span>Please <a className="c6-link" onClick={() => download(asset, false, null)}>download the document</a> to view all pages.</span>
						</p>
					)}
					{!imageSource && <p>This file format can not be previewed in Comet. Try <a className="c6-link" onClick={() => download(asset, false, null)}>downloading</a> and opening it on your computer.</p>}
				</div>
				{imageSource && (
					<div className="image-wrapper">
						<div className="document-index-info">
							<Button
								type="prev"
								onClick={this.prevPage.bind(this)}
								disabled={this.state.currentIndex <= 0}
							/>
							<h2>Page {this.state.currentIndex + 1}</h2>
							<Button
								type="next"
								onClick={this.nextPage.bind(this)}
								disabled={this.state.currentIndex >= maxIndex - 1}
							/>
						</div>
						{Array.from({ length: MAX_FILE_INDEX }).map((v, i) => (
							<div key={i} className="document-index-image" style={{ display: this.state.currentIndex === i ? undefined : "none" }}>
								<Image
									src={`${imageSource}?index=${i + 1}`}
									alt={`${asset.name} (page ${i + 1})`}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
};

DocumentAsset.propTypes = {
	asset: PropTypes.shape({
		id: PropTypes.number,
	}).isRequired,
};

export default DocumentAsset;
