import { browserHistory } from 'browserHistory'

import alt from '../../core/services/alt'
import { handleRequestFailed } from '../../core/services/errorhandling'

import { saveBlobToDisc } from '../../utils/save'
import Const from '../../core/constants'

import * as OSDData from '../../apis/osd'
import * as MetadataData from '../../apis/metadata'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;
const SEASON = 3;
const EPISODE = 2;

class Actions {

	/* **************** Actions **************** */
	edit({ id, pathname, search, query, modal = true, returnTo }) {
		const route = {
			pathname: pathname || `/onestopdrop/worklists/${id}`,
			search,
			query,
			state: {
				modal,
				returnTo,
			},
		};

		browserHistory.push(route)

		return route;
	}

	create({ pathname, search, returnTo }) {
		const route = {
			pathname,
			search,
			state: {
				modal: true,
				returnTo,
			}
		};

		browserHistory.push(route);

		return route;
	}

	search(searchText, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				searchText,
				targetStore,
			});
		}
	}

	filter(filterValue, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
				targetStore,
			});
		};
	}

	/* **************** Items **************** */
	fetchItems(entity, payload, targetStore = null, preventRedirect = false) {
		return (dispatch) => {
			const requestTime = Date.now();
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = targetStore || getStore(entity, multiple);

			dispatch({ payload, store, requestTime });

			// HACK?: Return nothing when searchtext is less than 2 chars
			const { searchText } = payload;
			if(searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
				this.itemsUpdated(store, [], false, 0, null, requestTime);
			}
			else {
				OSDData[command](payload)
					.then(response => {
						const { pageIndex, numberOfItems, items, links } = response;
						const appendToExistingItems = pageIndex > 0;

						const nextPageUrl = getNextPageUrl(links);
						this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);
						
						if (entity === "worklists" && numberOfItems === 1 && !preventRedirect) {
							browserHistory.replace(`/onestopdrop/worklists/${items[0].workListId}`);
							return;
						}
					}, this.requestFailed);
			}
		};
	}
	pageItems(entity, url, targetStore = null) {
		return (dispatch) => {
			const multiple = true;
			const store = targetStore || getStore(entity, multiple);

			dispatch({ entity, url, store });

			OSDData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			datastore,
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	/* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			OSDData[command](payload)
				.then(model => {
					this.itemLoaded({entity, model});
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			OSDData[command](data, payload)
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id, targetStore });
				}, this.requestFailed);
		}
	}

	itemUpdated(payload) { return payload; }

	/* **************** Misc **************** */
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Custom **************** */
	fetchTodo(id) {
		return dispatch => {
			dispatch();
			
			OSDData.fetchTodo({ id })
				.then(todo => {
					this.itemLoaded({ entity: "todo", model: todo });

					OSDData.fetchContainer({ id: todo.reference })
						.then(container => {
							this.itemLoaded({ entity: "container", model: container });

							// if (todo?.workType === "AgeRating") {
								MetadataData.fetchPrograms({ programGuid: container.programGuid })
									.then(programs => {
										this.itemLoaded({ entity: "program", model: programs.items?.[0] ?? {} });
									}, this.requestFailed);

								MetadataData.fetchPrograms({ parentProgramGuid: container.programGuid, orderBy: "title", pageSize: 1500 })
									.then(programs => {
										const { pageIndex, numberOfItems, items, links } = programs;
										const appendToExistingItems = pageIndex > 0;

										const nextPageUrl = getNextPageUrl(links);
										this.itemsUpdated("childPrograms", items, appendToExistingItems, numberOfItems, nextPageUrl);
									}, this.requestFailed);

								const includeParent = container.contentSourceMetadata?.statuses?.find(s => s.workListItemId === todo.workListItemId && s.includeParent);
								if (todo?.workType === "Content" && includeParent) {
									MetadataData.fetchPrograms({ programGuid: container.parentProgramGuid})
										.then(programs => {
											this.itemLoaded({ entity: "parentProgram", model: programs.items?.[0] ?? {} });
										}, this.requestFailed);

									OSDData.fetchContainer({ id: container.parentId })
										.then(parentContainer => {
											this.itemLoaded({ entity: "parentContainer", model: parentContainer });
										}, this.requestFailed);
								}
							// }

						}, this.requestFailed);
					
					OSDData.fetchChildContainers({ parent: todo.reference, containerTypeId: EPISODE, orderBy: "title", pageSize: 1500  })
						.then(childContainers => {
							const { pageIndex, numberOfItems, items, links } = childContainers;
							const appendToExistingItems = pageIndex > 0;

							const nextPageUrl = getNextPageUrl(links);
							this.itemsUpdated("childContainers", items, appendToExistingItems, numberOfItems, nextPageUrl);
						}, this.requestFailed);
					
					
					if (todo.thread.id > 0) {
						OSDData.fetchThread({ id: todo.thread.id })
							.then(comments => {
								const { pageIndex, numberOfItems, items, links } = comments;
								const appendToExistingItems = pageIndex > 0;

								const nextPageUrl = getNextPageUrl(links);
								this.itemsUpdated("comments", items, appendToExistingItems, numberOfItems, nextPageUrl);
							});
					} else {
						this.itemsUpdated("comments", [], false, 0, null);
					}
				}, this.requestFailed)
		};
	}



	postComment(threadId, objectId, text, callback) {
		return dispatch => {
			dispatch();

			const payload = {
				text,
			};

			OSDData.postComment({ threadId, objectId }, payload)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated("comments", items, appendToExistingItems, numberOfItems, nextPageUrl);
					if (callback && typeof callback === "function") {
						callback();
					}
				}, this.requestFailed);
		};
	}

	startEditingComment(commentId) { return commentId }
	stopEditingComment() { return true }

	updateComment(id, text) {
		return dispatch => {
			dispatch();

			OSDData.updateComment({ id }, { text })
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated("comments", items, appendToExistingItems, numberOfItems, nextPageUrl);
				}, this.requestFailed);
		};
	}

	threadLoaded(thread) { return thread }

	updateContainer(container, isChildContainer = false, isParentContainer = false) {
		return dispatch => {
			dispatch();

			if (isParentContainer) {
				this.itemLoaded({
					entity: "parentContainer",
					model: container
				});
			} else if (isChildContainer) {
				this.childContainerUpdated(container);
			} else {
				this.itemLoaded({
					entity: "container",
					model: container
				});
			}
		};
	}

	childContainerUpdated(container) {
		return container;
	}

	removeAssetFromContainer(containerId, assetIds, category, isChildContainer) {
		return (dispatch) => {
			dispatch();

			const payload = {
				assetIds,
				category,
				containerId,
			};
			OSDData.removeAssetsFromContainer(containerId, payload)
				.then(response => {
					this.updateContainer(response, isChildContainer);
				}, this.requestFailed);
		};
	}

	downloadMetadataTemplate(containerId, filename) {
		return dispatch => {
			dispatch();

			OSDData.downloadMetadataTemplate(containerId)
				.then(response => {
					saveBlobToDisc(response, filename);
				}, this.requestFailed);
		};
	}

	createEpisode(container, childContainers) {
		return dispatch => {
			dispatch();

			let season, newEpisodeNumber;
			const lastChild = childContainers.length && childContainers[childContainers.length - 1];
			if (lastChild) {
				const sortNameParts = lastChild.sortName.split(" "); // sortName = Game of Thrones S0001 E0001
				season = sortNameParts[sortNameParts.length - 2]; // S0001
				const episodeNumber = parseInt(sortNameParts[sortNameParts.length - 1].substring(1, 5)); // E0001 => 1
				newEpisodeNumber = episodeNumber + 1;
			} else {
				const seasonRegex = /S\d{1,}$/;
				season = seasonRegex.exec(container.sortName)[0];
				newEpisodeNumber = 1;
			}

			const seriesNameRegex = /(^.*)(?=\sS\d{1,})/;
			const seriesName = seriesNameRegex.exec(container.displayName)[0];
			const seasonNumber = parseInt(season.substring(1, 5)); // S0001 => 1
			
			const newEpisodePayload = {
				containerTypeId: EPISODE,
				parentId: container.id,
				displayName: seriesName,
				sortName: `${seriesName} ${season} E${("0000" + newEpisodeNumber).slice(-4)}`,
			};

			OSDData.createContainer(newEpisodePayload, seasonNumber, newEpisodeNumber)
				.then(response => {
					this.itemsUpdated("childContainers", [response], true, 1, null, null);
				}, this.requestFailed);
		};
	}

	unmountTodo() { return true; }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if (typeof (entity) === "object") {
		const extra = multiple ? "s" : "";

		if (entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if (typeof (entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" ||  l.Rel === "next");

	return nextLink
		? nextLink.href ||  nextLink.Href
		: null;
}

function getEmptySeries(seasonId) {
	return {
		id: -100,
		type: "Series",
		seasonId,
	}
}