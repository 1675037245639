import Moment from 'moment'

import alt from '../services/alt'
import Actions from './actions'

const MAX_TIMER = 3600 * 48; // 48h is the maximum for new users (so don't add a new user on a friday)

class UserStore {
	constructor() {
		this.loggedIn = false;
		this.isLoading = false;
		this.modules = [];
		this.accesslevels = [];
		this.accessroles = [];
		this.user = {};
		this.resetState = {
			secondsUntilExpiry: MAX_TIMER,
		};

		this.error = false;
		this.errorMessage = "";
		this.functionsToRunAfterLogin = [];

		this.bindListeners({
			// SHIELD
			onLoginFailed: [Actions.LOGIN_FAILED, Actions.ACTION_FAILED],
			onLogin: Actions.LOGIN,
			onLogout: Actions.LOGOUT,
			onLoginWithUserPass: Actions.LOGIN_WITH_USER_PASS,

			onForgotPassword: Actions.FORGOT_PASSWORD,
			onUpdateResetState: Actions.UPDATE_RESET_STATE,

			onDisplay: [
				Actions.DISPLAY,
				Actions.DISPLAY_LOGIN,
				Actions.DISPLAY_FORGOT_PASSWORD,
				Actions.RESET_PASSWORD,
				Actions.FORGOT_PASSWORD_SENT,
				Actions.RESET_PASSWORD_DONE,
			],

			// // C5 SEC
			// onGetUserData: Actions.GET_USER_DATA,
			// onUserDataRecieved: Actions.USER_DATA_RECIEVED,
		});
	}

	// // C5 SEC
	// onGetUserData() {
	// 	this.isLoading = true;
	// }

	// onUserDataRecieved({ modules, name, email = null }) {
	// 	this.modules = filterModules(modules);
	// 	this.user = {
	// 		name,
	// 		email,
	// 	};

	// 	this.loggedIn = true;
	// 	this.isLoading = false;
	// }

	// SHIELD
	onLoginFailed(error) {
		this.errorMessage = error.exceptionMessage || "Error";
		this.error = true;
		this.isLoading = false;
	}

	onLogin({ accesslevels, accessroles, modules, name, username, email, expiry = 0, exp = 0 }) {
		this.modules = filterModules(modules);
		this.accesslevels = accesslevels;
		this.accessroles = accessroles;
		this.user = {
			name,
			username,
			email,
			expiry,
			exp,
		};

		this.loggedIn = true;

		this.isLoading = false;

		this.error = false;
		this.errorMessage = "";

		this.functionsToRunAfterLogin.forEach(f => f());
		this.functionsToRunAfterLogin = [];
	}

	onLogout({ stayOnPage = false, functionToRunAfterLogin }) {
		this.loggedIn = false;

		this.isLoading = false;

		this.resetState = {
			secondsUntilExpiry: MAX_TIMER,
		};

		if(!stayOnPage) {
			this.modules = [];
			this.accesslevels = [];
			this.accessroles = [];
			this.user = {};
		}

		this.error = false;
		this.errorMessage = "";

		if (functionToRunAfterLogin) {
			this.functionsToRunAfterLogin.push(functionToRunAfterLogin);
		}
	}

	onLoginWithUserPass() {
		this.isLoading = true;
	}

	onForgotPassword() {
		this.isLoading = true;

		this.error = false;
		this.errorMessage = "";
	}

	onUpdateResetState(resetState) {
		this.resetState = {
			...resetState,
			secondsUntilExpiry: Moment(resetState.expires).diff(Moment(), 'seconds')
		}
	}

	onDisplay() {
		this.isLoading = false;

		this.error = false;
		this.errorMessage = "";
	}
}

export default alt.createStore(UserStore, '[Core]UserStore');



function filterModules(modules) {
	return modules.filter(module => module.apps === undefined || module.apps.length > 0);
}