export default {
	// Documents
	"doc": "icon-file-word",
	"docx": "icon-file-word",
	"pdf": "icon-file-pdf",
	"ppt": "icon-file-powerpoint",
	"pptx": "icon-file-powerpoint",
	"xls": "icon-file-excel",
	"xlsx": "icon-file-excel",
	// Text
	"rtf": "icon-description",
	"txt": "icon-description",
	"log": "icon-description",
	"msg": "icon-description",
	"pages": "icon-description",
	"wpd": "icon-description",
	"wps": "icon-description",
	"ai": "icon-description",
	"psd": "icon-description",
	"drw": "icon-description",
	"ps": "icon-description",
	"csv": "icon-description",
	"dat": "icon-description",
	"efx": "icon-description",
	"gbr": "icon-description",
	"key": "icon-description",
	"pps": "icon-description",
	"sdf": "icon-description",
	"tax2010": "icon-description",
	"vcf": "icon-description",
	"xml": "icon-description",
	"indd": "icon-description",
	"pct": "icon-description",
	"qxd": "icon-description",
	"qxp": "icon-description",
	"rels": "icon-description",
	"xlr": "icon-description",
	"asp": "icon-description",
	"cer": "icon-description",
	"csr": "icon-description",
	"css": "icon-description",
	"htm": "icon-description",
	"html": "icon-description",
	"js": "icon-description",
	"jsp": "icon-description",
	"php": "icon-description",
	"rss": "icon-description",
	"xhtml": "icon-description",
	"fnt": "icon-description",
	"fon": "icon-description",
	"otf": "icon-description",
	"ttf": "icon-description",
	"cfg": "icon-description",
	"ini": "icon-description",
	"keychain": "icon-description",
	"prf": "icon-description",
	"7z": "icon-description",
	"deb": "icon-description",
	"gz": "icon-description",
	"pkg": "icon-description",
	"rpm": "icon-description",
	"sit": "icon-description",
	"sitx": "icon-description",
	"zipx": "icon-description",
	"c": "icon-description",
	"class": "icon-description",
	"cpp": "icon-description",
	"cs": "icon-description",
	"dtd": "icon-description",
	"fla": "icon-description",
	"java": "icon-description",
	"m": "icon-description",
	"pl": "icon-description",
	"py": "icon-description",
	// Video
	"3g2": "icon-play_circle_outline",
	"3gp": "icon-play_circle_outline",
	"asf": "icon-play_circle_outline",
	"asx": "icon-play_circle_outline",
	"avi": "icon-play_circle_outline",
	"flv": "icon-play_circle_outline",
	"ism": "icon-play_circle_outline",
	"mkv": "icon-play_circle_outline",
	"mov": "icon-play_circle_outline",
	"mp4": "icon-play_circle_outline",
	"m4v": "icon-play_circle_outline",
	"mpg": "icon-play_circle_outline",
	"rm": "icon-play_circle_outline",
	"swf": "icon-play_circle_outline",
	"vob": "icon-play_circle_outline",
	"wmw": "icon-play_circle_outline",
	// Other
	"gz": "icon-file-zip",
	"tar": "icon-file-zip",
	"zip": "icon-file-zip",
	"rar": "icon-file-zip",
}