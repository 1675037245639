import React from 'react'
import Qs from 'qs'

import Editor, { parseUi } from '../../../components/editor/'
import { hasAccessToPath, hasRole } from '../../../core/services/auth'
import Crew from '../../../components/ui/editorFields/crew'
import Approval from '../../../components/ui/editorFields/approval'

import ProgramPreview from './preview'

import * as API from '../../../apis/'
import appConfig from 'config'

import schemaSingle from './singleSchema'
import schemaSeason from './seasonSchema'
import schemaEpisode from './episodeSchema'
import schemaContentSingle from './contentSingleSchema'
import schemaContentSeason from './contentSeasonSchema'
import schemaContentEpisode from './contentEpisodeSchema'
import schemaContentSeries from './contentSeriesSchema'

import uiSingle from './singleUI'
import uiSeason from './seasonUI'
import uiEpisode from './episodeUI'
import uiContentSingle from './contentSingleUI'
import uiContentSeason from './contentSeasonUI'
import uiContentEpisode from './contentEpisodeUI'
import uiContentSeries from './contentSeriesUI'

import './metadataeditor.css'

const OSDMetadataEditor = (props) => {

	const isTranslationAgencyUser = hasRole("onestopdrop.translation-agency");
	// const isProductionCompanyUser = hasRole("onestopdrop.production-company");

	return (
		<Editor
			layout="grid"
			// submitAsPatch={true}
			submitAsPatch={false}
			api="metadata"
			entity="program"
			getSchema={getSchema}
			getUiSchema={getUiSchema}
			loadPayloadTransform={loadPayloadTransform}
			savePayloadTransform={savePayloadTransform}
			customFields={{ approval: Approval, statuses: Statuses, crew: Crew }}
			getCommands={getCommands}
			hasEditAccess={hasAccessToPath(props.routes, "editor")}
			canApprove={isTranslationAgencyUser}
			context={{ workListItemId: props.location.query.todoId }}
			{...props}
		>
			<ProgramPreview />
		</Editor>
	);
};

export default OSDMetadataEditor;

function getCommands({ entity, location, route, params }) {
	return {
		fetchItem: async ({id}) => {
			const programs = await API.metadata.fetchPrograms({ programGuid: id });
			const program = programs.items[0];

			if (appConfig.features?.useContentSourceMetadata) {
				const guid = params.id;
				const container = await API.star.fetchContainerByGuid(guid);
				return Promise.resolve({
					...program,
					versions: container?.contentSourceMetadata?.versions?.length ? container.contentSourceMetadata.versions : program.versions,
					statuses: container?.contentSourceMetadata?.statuses,
				});
			}

			return Promise.resolve(program);
		},
		updateItem: async ({id}, body) => {
			// Mark workListItem/todo as in progress
			const todo = await API.osd.fetchTodo({ id: location.query.todoId });
			if (todo?.status === "Unhandled") {
				await API.osd.updateTodo(
					{ id: todo.workListItemId },
					{ ...todo, status: "InProgress" }
				);
			}
			
			if (appConfig.features?.useContentSourceMetadata) {
				const program = body;
				const guid = params.id;
				const container = await API.star.updateContentSourceMetadata(guid, body, location.query.todoId);
				return Promise.resolve({
					...program,
					id: guid,
					versions: container?.contentSourceMetadata?.versions ?? program.versions,
					statuses: container?.contentSourceMetadata?.statuses,
				});
			}

			const program = await API.metadata.patchProgram({id}, body);
			return Promise.resolve({ ...program, id: program.guid });
		},
	};
}

function loadPayloadTransform({ model, entity, location, route, params }) {
	const versionId = parseInt(location.query?.versionId ?? 0);
	return {
		...model,
		versions: versionId !== 0
			? model.versions.filter(v => v.versionId === versionId)
			: model.versions,
	};
}

function savePayloadTransform({ location, ...rest }) {
	const { todoType, versionId, todoId } = location?.query ?? {};

	const queryString = Qs.stringify({
		todoType, versionId, todoId
	}, { skipNulls: true });
	return {
		...rest,
		location,
		queryString: `?${queryString}`,
	}
}

function getSchema(model, isNew, location, route, params, routes) {
	let schema;
	const type = (model.type || params.type || "").toLowerCase();
	const todoType = location.query?.todoType;
	switch(type) {
		case "series":
			schema = schemaContentSeries;
			break;
		case "season":
			schema = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? schemaContentSeason
				: schemaSeason;
			break;
		case "episode":
			schema = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? schemaContentEpisode
				: schemaEpisode;
			break;
		default:
			schema = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? schemaContentSingle
				: schemaSingle;
			break;
	}

	if (appConfig.features?.useContentSourceMetadata) {
		delete schema.properties.versions?.items?.properties?.approval;
		schema.properties.statuses = {
			type: "array",
			items: {
				type: "object",
				properties: {
					complete: { type: "boolean" },
					workListItemId: { type: "number" },
				},
			},
		};
	}

	const isTranslationAgencyUser = hasRole("onestopdrop.translation-agency");
	// const isProductionCompanyUser = hasRole("onestopdrop.production-company");
	if (!isTranslationAgencyUser && schema?.properties?.versions?.items?.properties?.approval) {
		delete schema.properties.versions.items.properties.approval;
	}

	return schema;
}

function getUiSchema(model, isNew, location, route, params, routes) {
	let ui;
	const type = (model.type || params.type || "").toLowerCase();
	const todoType = location.query?.todoType;
	switch(type) {
		case "series":
			ui = uiContentSeries;
			break;
		case "season":
			ui = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? uiContentSeason
				: uiSeason;
			break;
		case "episode":
			ui = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? uiContentEpisode
				: uiEpisode;
			break;
		default:
			ui = appConfig.features.osdExtendedEditorForContentTodos && todoType === "Content"
				? uiContentSingle
				: uiSingle;
			break;
	}

	if (appConfig.features?.useContentSourceMetadata) {
		delete ui.versions?.items?.approval;
		ui.statuses = {
			"ui:field": "statuses",
			classNames: "actionbar-right",
		};

		const workListItemId = location.query.todoId;
		const status = model?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(workListItemId));
		const isDone = status?.complete;
		Object.keys(ui).forEach(key => {
			if (ui.hasOwnProperty(key) && typeof ui[key] !== "string") {
				ui[key]["ui:readonly"] = isDone;
			}
		});
	}

	// We don't want generic approval to make versions readonly
	// So we set readonly for all properties except versions (they are handled below)
	const genericApproved = model.approval?.status?.toLowerCase() === "approved";
	Object.keys(ui).forEach(key => {
		if (ui.hasOwnProperty(key) && key !== "versions" && typeof ui[key] !== "string") {
			ui[key]["ui:readonly"] = genericApproved;
		}
	});

	// Don't set readonly for approved versions for now
	// // Set readonly for approved versions
	// if (model.versions?.length) {
	// 	model.versions.forEach((v, i) => {
	// 		const versionApproved = v.approval?.status?.toLowerCase() === "approved";
	// 		if (ui.versions.items[i]) {
	// 			ui.versions.items[i]["ui:readonly"] = versionApproved;	
	// 		}
	// 		if (versionApproved && !v.versionName.includes("(version approved)")) {
	// 			v.versionName += " (version approved)";
	// 		}
	// 	});
	// }

	return parseUi(ui, API);
}

const Statuses = ({ formData = [], onChange, formContext }) => {
	const { hasEditAccess = true, canApprove = true, workListItemId } = formContext;
	const status = formData.find(s => parseInt(s.workListItemId) === parseInt(workListItemId));
	const isDone = status?.complete;

	const approval = { status: isDone ? "approved" : undefined };
	const approvalReadOnly = isDone && !status?.ignoreReadOnlyUntilSaved;

	return (
		<Approval
			formData={approval}
			onChange={(approvalObject) => {
				const newFormData = [...formData];
				const index = newFormData.findIndex(status => parseInt(status.workListItemId) === parseInt(workListItemId));
				const updatedItem = {
					complete: approvalObject?.status === "approved",
					workListItemId: parseInt(workListItemId),
					ignoreReadOnlyUntilSaved: true,
				}
				if (index >= 0) {
					newFormData.splice(index, 1, updatedItem);
				} else {
					newFormData.push(updatedItem);
				}
				onChange(newFormData);
			}}
			formContext={{}}
			simpleLabel={true}
			uiSchema={{ overrideReadOnly: approvalReadOnly }}
		/>
	);
};