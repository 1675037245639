// Based on https://github.com/Azure-Samples/media-services-javascript-azure-media-player-zoom-plugin/blob/master/amp-zoom.js

const playersAlreadyHandled = [];

export function addWatermark() {
	return (function (mp) {
		mp.plugin("watermark", function ({ watermark }) {
			const player = this;

			const func = function () {
				// I somehow managed to end up with 2 of each button but that might have been because of a HMR issue
				// This just makes sure that we don't add the buttons more than once for each player
				if (playersAlreadyHandled.includes(player.id())) {
					return;
				}
				playersAlreadyHandled.push(player.id());

				if (!watermark?.length) {
					return;
				}

				// We add the watermark using a plugin because it's easier to make sure that the element stays visible
				// even if user enables fullscreen
				const element = document.createElement("div");
				element.innerText = watermark;
				element.className = "watermark";
				player.el().appendChild(element);
			};

			player.addEventListener("loadeddata", func);
		});
	}(window.amp));
}