import { browserHistory } from 'browserHistory'
import appConfig from 'config'

import alt from '../../core/services/alt'
import * as APIS from '../../apis/'

import * as Alert from '../../core/services/alert'

class Actions {
	registerUploader(uploaderId, uploader, uploaderInformationText, pathname, modal) { return { uploaderId, uploader, uploaderInformationText, pathname, modal } }
	unregisterUploaderIfUnused(uploaderId) { return uploaderId }
	// updateUploaderProps(uploaderId, newProps, uploaderInformationText, pathname, modal) { return { uploaderId, newProps, uploaderInformationText, pathname, modal } }
	fileStatusChange(data) { return data }
	removeCompletedFiles() { return true }
	removeFile(uploaderId, fileId) { return { uploaderId, fileId } }
}

export default alt.createActions(Actions);