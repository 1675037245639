import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'
import moment from 'moment'

import DataBite from '../../../components/ui/databite'
import { Right } from '../../../components/filter'
import { hasAccessToPath } from '../../../core/services/auth'
import Const from '../../../core/constants'
import Status from '../../../components/ui/status'
import { localeCompareSortNumeric } from '../../../core/services/sorting'

import Actions from '../actions'

import styles from '../../../components/listItems/standard.css'

@withRouter
export default class WorklistContent extends PureComponent {

	static propTypes = {
		displayName: PropTypes.string,
		dueDate: PropTypes.string,
		status: PropTypes.string,

		className: PropTypes.string,
		noLink: PropTypes.bool,

		location: PropTypes.object, // From @withRouter
	}

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id, pathname, wideModal, typeId, query, e) {
		const { location } = this.props;

		Actions.edit({
			id,
			pathname,
			query,
			returnTo: `${location.pathname}${location.search}`,
			wideModal,
			typeId
		});
	};

	render() {
		const {
			displayName,
			status,
			className = "",
			thread,
			workType,
			workListItemId,
			hasUnreadComment,
		} = this.props;

		const type = workListItemId !== undefined && <p>{getType(workType)}</p>;

		const { statusText, statusType } = getStatus(status);

		return (
			<div className={`flex flex-dyn-1 ${className}`}>
				<div className="c6-content">
					<h1>{displayName}</h1>
					{type}
				</div>
				<div className="c6-content">
					{getCommentsInfo(thread, hasUnreadComment)}
				</div>
				<div className="c6-content">
					<Right>
						<Status status={statusType}>{statusText}</Status>
					</Right>
				</div>
			</div>
		);
	}
}

function getType(workType) {
	if (workType === "AgeRating") {
		return <span className="icon-stars">&nbsp;Rating</span>;
	}

	return <span className="icon-insert_drive_file">&nbsp;Metadata</span>;
}

const sortComments = localeCompareSortNumeric("created");

function getCommentsInfo(thread, hasUnreadComment) {
	if (thread?.numberOfComments > 0) {
		const latestComment = (thread.comments ?? []).sort(sortComments).reverse()[0];
		const latestCommentWhen = latestComment && moment(latestComment.created).format(Const.DATE_TIME_FORMAT);
		
		let hoverTitle = "";
		if (hasUnreadComment) {
			hoverTitle += "This item has new comments since you last opened it.\n\n";
		}
		if (latestComment) {
			hoverTitle += `Latest comment by ${latestComment.createdBy} at ${latestCommentWhen}:\n${latestComment.text}`;
		}
		return (
			<div className={hasUnreadComment ? "unread-comment" : ""}>
				<DataBite
					icon="chat"
					hoverTitle={hoverTitle.trim()}
				>
					{thread.numberOfComments}
				</DataBite>
			</div>
		);
	}

	return null;
}

function getStatus(status) {
	let statusText = status, statusType = status;
	if (status === "Ignored") {
		statusType = "Disabled";
	}
	if (status === "Unhandled") {
		statusType = "Undefined";
	}
	if (status === "InProgress") {
		statusText = "In progress";
		statusType = "Unhandled";
	}
	return { statusText, statusType };
}