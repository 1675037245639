import React from 'react'

import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

const MuiToggle = ({
	value = false,
	onChange,
	options = {},
	schema,
	label = "",
	title = "",
	disabled = false,
	className = "",
	switchStyle = {},
	switchInputStyle = {},
	rootStyle = {},
	textStyle = {}
}) => {

	const handleChange = (event) => { onChange(event.target.checked, event); };

	return (
		<FormControlLabel
			control={
				<Switch
					checked={value}
					color={options.color}
					disabled={disabled}
					onChange={handleChange}
					style={switchStyle}
					inputProps={{
						sx: {
							"&:disabled": {
								opacity: 0
							},
						},
						...switchInputStyle,
					}}
				/>
			}
			title={title}
			label={label || schema.description}
			labelPlacement="start"
			className={`c6-mui-toggle ${className}`}
			style={rootStyle}
			componentsProps={{
				typography: {
					style: textStyle
				}
			}}
		/>
	);
};

export default MuiToggle;
