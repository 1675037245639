import merge from 'lodash/merge'
import overrideConfig from 'overrideConfig'
import defaultConfig from '../../config/default.config.js'

function deleteTemplateValuesAndParseJSONValues(config) {
	for (let [key, value] of Object.entries(config)) {
		// Object: go deeper
		if (typeof value === "object" && value !== null) {
			deleteTemplateValuesAndParseJSONValues(config[key]);

			// Delete empty objects
			if (Object.entries(value).length === 0) {
				delete config[key];
			}
			continue;
		}

		// String: delete if template value
		if (typeof value === "string" && value.startsWith("#{")) {
			delete config[key];
		} else if (typeof value === "string" && value.startsWith("{")) {
			try {
				const json = JSON.parse(value);
				// JSON parse was successful, replace value in config
				config[key] = json;
			} catch (e) {

			}
		}
	}
}

// Try replacing values added by Octopus.
// I have tried doing this in a PowerShell deployment script in Octopus but I have given up, for now.
function cleanupOctopusMess(config) {
	const str = JSON.stringify(config)
		.replace(/["']true["']/gi, "true") 		// "True" => true
		.replace(/["']false["']/gi, "false") 	// "False" => false
		.replace(/"\[/g, "[") 					// "[" => [
		.replace(/\]"/g, "]")					// "]" => ]
		.replace(/'/g, "\"")					// single quotation => double quotation
	return JSON.parse(str);
}

deleteTemplateValuesAndParseJSONValues(overrideConfig);
let appConfig = merge(defaultConfig, overrideConfig);
appConfig = cleanupOctopusMess(appConfig);
export default appConfig;