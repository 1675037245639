import React from 'react'
import { Link, withRouter } from 'react-router'
import upperFirst from 'lodash/upperFirst'

import UserStore from './store'
import { getModuleIcon } from '../constants'
import { fullModuleStructure, hasModuleAccess } from '../sideNavigation'
import { NEW_NAVIGATION } from '..'

import '../../components/dashboard/dashboard.css'

import appConfig from 'config'

@withRouter
export default class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			...UserStore.getState(),
		}
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	render() {
		const { title = "Your modules", routes } = this.props;
		const { accesslevels } = this.state;

		let modules = this.state.modules.filter(m => !m.hidden);
		let heading = modules.length ? <h1>{title}</h1> : null;

		const newCometNavigation = appConfig.features.newCometNavigation && (localStorage.getItem(NEW_NAVIGATION) === "yes");
		if (newCometNavigation) {
			modules = fullModuleStructure;
			heading = "";
		}

		return (
			<div className={`c6-dashboard ${newCometNavigation ? " new-navigation" : ""}`}>
				{heading}
				<div className="apps">
					{modules.map(module => {
						const { displayName, key, url, icon, children } = module;
						const apps = (module.apps || module.children || []).filter(m => !m.hidden);
						//const linkUrl = apps.length ? apps[0].url : url;

						// If the user has a module-wide access level we should render all available apps in that module
						const moduleWideAccess = appConfig.features.enableModuleWideAccess && moduleWideAccessLevel(key, accesslevels);
						const moduleApps = moduleWideAccess ? getModuleApps(moduleWideAccess, routes) : apps;
						if (!newCometNavigation && !moduleApps.length) {
							return null;
						}

						// const moduleLink = !moduleApps.length
						// 	? <a href={ url } className="icon-chevron_right">Go to the {displayName} module</a>
						// 	: null ;

						return (
							<section key={key ?? url ?? displayName} className={module.key}>
								<header className={getIcon(key, icon)}><span>{displayName}</span></header>
								<div>
									{renderChildApps(moduleApps)}
								</div>
								{/* { moduleLink } */}
							</section>
						);
					})}
				</div>
			</div>
		);
	}
}

// HELPERS
function renderChildApps(apps) {
	return apps.map(app => {
		const { displayName, key, url, icon, children } = app;

		if (children?.length) {
			return (
				<div key={key ?? url ?? displayName} className={`${getIcon(key, icon)} folder`}>
					{displayName && <article>{displayName.trim()}</article>}
					{renderChildApps(children)}
				</div>
			);
		}

		const appEl = (
			<article className={getIcon(key, icon)}>
				{displayName?.trim()}
			</article>
		);

		const disabled = !hasModuleAccess(app);
		return <Link key={key ?? url ?? displayName} to={url} disabled={disabled}>{appEl}</Link>;
	});
}

function getIcon(key, icon) {
	if (icon) {
		return `icon-${icon}`;
	}
	return key && getModuleIcon(key) || "";
}

function moduleWideAccessLevel(key, accesslevels) {

	// Get all matching levels with only module.access format
	const moduleLevels = accesslevels.filter(al => {
		const accesslevel = al.split(".");
		return accesslevel[0] === key && accesslevel.length === 2;
	});

	// Return the first matching module (there can only be one in shield, but just to be safe)
	return moduleLevels.length ? moduleLevels[0] : null;
}

// TODO: Maybe this should actually come from the userToken instead? If a user only has access to the module
// the token api should make sure all apps are added with the same access level.
// If the user has access to an app, everything should work as it does today.
function getModuleApps(accessLevel, routes) {
	const [module, level] = accessLevel.split(".");
	const currentRoute = routes[routes.length - 1];

	if(currentRoute.module !== module || !currentRoute.childRoutes) {
		return [];
	}

	let moduleApps = [];
	currentRoute.childRoutes.forEach(r => {
		if(r.indexRoute) {
			moduleApps.push({
				displayName: upperFirst(r.app),
				description: null,
				key: `${currentRoute.module}.${r.app}`,
				url: `/${currentRoute.path}/${r.path}`,
			});
		}
	});

	return moduleApps;
}