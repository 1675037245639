import React from 'react'
import './main.css'

// const Main = ({className = "", padding = false, inputRef = null, children}) => (
// 	<main ref={inputRef} className={`c6-main${padding ? " pad26" : ""} ${className}`}>
// 		{children}
// 	</main>
// );

// export default Main;

// export const Secondary = ({padding = false, inputRef = null, children}) => (
// 	<aside ref={inputRef} className={`c6-main${padding ? " pad26" : ""}`}>
// 		{children}
// 	</aside>
// );

// HACK: To force Chrome Windows clients to have an explicit height (and not fuck up the scrolling)
// When it gets its act together we can go back to the code above
export default class Main extends React.Component {
	constructor(props) {
		super(props);

		this.handleResize = this.handleResize.bind(this);
		this.timers = [];
		this.el = null;

		this.state = {
			height: null,
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		const t = window.setTimeout(this.handleResize, 0);
		this.timers.push(t);
	}

	UNSAFE_componentWillReceiveProps() {
		const t = window.setTimeout(this.handleResize, 0);
		this.timers.push(t);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.timers.forEach(t => window.clearTimeout(t));
		this.timers = [];
	}

	handleResize(e) {
		const { modal = false } = this.props;
		const type = modal ? { type: "modal" } : {};
		this.setState({height: getScrollHeight(type, this.el)});
	}

	render() {
		const {
			modal = false,
			className = "",
			padding = false,
			scrollBehavior = null,
			inputRef = null,
			children,
		} = this.props;

		const { height } = this.state;

		return (
			<main
				style={{
					height,
					scrollBehavior,
				}}
				ref={el => { this.el = el; inputRef && inputRef(el) }}
				className={`c6-main${padding ? " pad26" : ""}${modal ? " modal" : ""} ${className}`} >
				{children}
			</main>
		);
	}
}

export class Secondary extends React.Component {
	constructor(props) {
		super(props);

		this.handleResize = this.handleResize.bind(this);

		this.state = {
			height: null,
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		window.setTimeout(this.handleResize, 0);
	}

	UNSAFE_componentWillReceiveProps() {
		window.setTimeout(this.handleResize, 0);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize(e) {
		this.setState({height: getScrollHeight({type: "secondary"})});
	}

	render() {
		const { padding = false, inputRef = null, children, className = "" } = this.props;
		const { height } = this.state;

		return (
			<aside
				style={{height}}
				ref={inputRef}
				className={`c6-main ${padding ? "pad26" : ""} ${className}`} >
				{children}
			</aside>
		);
	}
}

// HELPERS
function getScrollHeight({ type }, el) {
	let mainElement = null;
	switch (type) {
		case "modal":
			const modalElement = document.querySelector(".c6-modal-body");
			mainElement = el || document.querySelector(".c6-modal main.c6-main");
			return modalElement && mainElement ? modalElement.offsetHeight - mainElement.offsetTop : null;
		case "secondary":
			mainElement = document.querySelector("aside.c6-main");
			break;
		default:
			mainElement = document.querySelector("main.c6-main");
			break;
	}

	return mainElement ? window.innerHeight - mainElement.offsetTop : null;
}