/**
 * USAGE:
 * 1. In your list component, tell the controller what items are available using setItems().
 *    Or, if you are using a functional component like GenericListApp, use the hook useEditorNavigation().
 * 2. In your editor component, pass enableEditorNavigation={true} to the <Editor />.
 * 3. When opening the editor, tell the controller what item was opened using setCurrentItem() and it will take care of the rest.
 *    This can be done in Actions.edit() (for older apps using alt.js store) or in your "Edit" item action.
 */

import { browserHistory } from 'browserHistory'

class _EditorNavigationController {
    _items = [];
    _currentItem = null;
    _getId = null;

    setItems        = (items)   => this._items = items;
    setCurrentItem  = (item)    => this._currentItem = item;
    setGetId        = (getId)   => this._getId = getId;

    goLeft      = () => this._navigateToAdjacentItem(-1);
    goRight     = () => this._navigateToAdjacentItem(1);
    canGoLeft   = () => !!this._getAdjacentItem(-1);
    canGoRight  = () => !!this._getAdjacentItem(1);
    getLeftItem = () => this._getAdjacentItem(-1);
    getRightItem = () => this._getAdjacentItem(1);

    _getAdjacentItem = (indexIncrement) => {
        const currentId = this._getId ? this._getId(this._currentItem ?? {}) : this._currentItem?.id;
        const currentIndex = this._items.findIndex(i => {
            const itemId = this._getId ? this._getId(i) : i.id;
            return `${itemId}` === `${currentId}`;
        });
        if (currentIndex === -1) {
            return;
        }
        
        const newItem = this._items[currentIndex + indexIncrement];
        return newItem;
    }

    _navigateToAdjacentItem = (indexIncrement) => {
        const newItem = this._getAdjacentItem(indexIncrement);
        const currentId = this._getId ? this._getId(this._currentItem ?? {}) : this._currentItem?.id;
        if (!newItem) {
            return;
        }

        const location = browserHistory.getCurrentLocation();
        const newPath = location.pathname.replace(currentId, newItem.id);
        browserHistory.replace({
            ...location,
            pathname: newPath,
        });

        this._currentItem = newItem;
    }
}

const EditorNavigationController = new _EditorNavigationController();
export default EditorNavigationController;
