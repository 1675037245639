// import * as Sentry from '@sentry/browser'
// import appConfig from 'config'
import { generateKey } from '../../components/ui/editorFields'

export function displayAlert(type = "info", message, details = null, action = null, duration = null, htmlMessage = null) {

    // Manipulate error messages
    if (!message?.length && !htmlMessage?.length) {
        console.error("displayAlert() was called with empty message. This should not happen :(");
        return;
    }
    
    if (message?.includes("Failed to fetch") || message?.includes("The wait operation timed out")) {
        message = "Could not communicate with the API. If your network connection is fine, there might be something wrong with the API servers. Please contact support@junefirst.tv if the problem persists.";
    }
    else if (typeof(details) === "string" && details.includes("Invalid JSON data")) {
        message = "Your JSON code does not validate, please correct it and click save again.";
        details = null;
    }

    const id = generateKey();

    window.dispatchEvent(new CustomEvent("display_alert_event", {
        detail: {
            alertData: {
                id,
                type,
                message,
                htmlMessage,
                details,
                action,
                duration,
            }
        }
    }));

    // Return a function that can be run to remove this alert
    return () => {
        window.dispatchEvent(new CustomEvent("remove_alert_event", {
            detail: {
                alertData: {
                    id,
                }
            }
        }));
    };

    // // Log error alerts to Sentry
	// if (type === "error" && appConfig.features?.trackUserDisplayAlertsInSentry) {
	// 	Sentry.captureEvent({
	// 		message: `User saw alert: ${details ?? message ?? "??"}`,
	// 		level: type,
	// 		extra: {
	// 			message_displayed_to_user: message,
	// 		},
	// 	});
    // }
}