import React from 'react'
import { browserHistory } from 'browserHistory'
import moment from 'moment'

import Editor, { parseUi }  from '../../../components/editor/'
import * as API from '../../../apis'

import schema from './schema'
import uiSchema from './uiSchema'

export default class TodoEditor extends React.PureComponent {
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.location.pathname.includes("/edit")) {
			if (nextProps.params.id) {
				browserHistory.replace({
					...nextProps.location,
					pathname: `/onestopdrop/todos/${nextProps.params.id}`,
				})
			} else {
				browserHistory.replace(nextProps.location.state.returnTo);
			}
		}
	}

	render() {
		return (
			<Editor
				layout="grid"
				api="osd"
				entity="todo"
				getSchema={getSchema}
				getUiSchema={getUiSchema}
				getCommands={getCommands}
				savePayloadTransform={savePayloadTransform}
				{...this.props}
			/>
		);
	}
}

function getCommands({ location }) {
	return {
		createItem: payload => {
			return new Promise((resolve, reject) => {
				const { displayName, seasonNumber, containerTypeId, dueDate } = payload;
				
				const containerPayload = {
					containerTypeId,
					displayName,
					sortName: `${displayName}${containerTypeId === 3 && seasonNumber ? ` S${("0000" + seasonNumber).slice(-4)}`: ""}`
				};
				
				API.osd.createContainer(containerPayload, seasonNumber, undefined, location.query.contentSource)
					.then(container => {
						const formattedDisplayName = `${displayName}${containerTypeId === 3 && seasonNumber ? ` S${seasonNumber}` : ""}`;
						const todoPayload = { reference: container.id, displayName: formattedDisplayName, dueDate, workListId: location.query.workListId, type: "container" };
						API.osd.createTodo(todoPayload)
							.then(todo => {
								resolve({
									id: todo.workListItemId,
									...todo
								});
							}, error => reject(error));
					}, error => reject(error));
			});
		}
	}
}

function getSchema(model, isNew, location, route, params, routes) {
	schema.required = model.containerTypeId === 3
		? ["displayName", "seasonNumber"]
		: ["displayName"];
	return schema;
}

function getUiSchema(model, isNew) {
	let ui = uiSchema;
	ui.seasonNumber["ui:widget"] = model.containerTypeId === 3 ? "text" : "hidden";
	ui["ui:readonly"] = !isNew;
	return parseUi(ui, API);
}

function savePayloadTransform({ formData, ...rest }) {
	return {
		...rest,
		formData: {
			...formData,
			dueDate: moment().add(1, "week").format("YYYY-MM-DD"),
		},
	};
}
