import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from '@mui/material/Slider'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { Left, Right } from '../../../filter'
import Button from '../../../ui/controls/button'
import TextArea from '../../../ui/textarea-confirm'

// import Const from '../../../../core/constants'
// import { hasAccessToPath } from '../../../../core/services/auth'
import { Regions } from '../../../../core/constants'

import { hasStarEditAccess } from '../../../../apps/star2/shared/utils'

import CropPreviews from './cropPreviews'
import Restrictions from './restrictions'
import ExifContent from './exif'
import MediaInfoContent from './mediainfo'
import AssetTags from './assetTags'
import RegionSelect from './regionSelect'

import Actions from '../actions'

import AuthStore from '../../../../core/authentication/store'

import './sidebar.css'

import appConfig from 'config'

const STORAGE_TAB_KEY = "asset-editor-tab";
const STORAGE_METADATA_REGION = "asset-editor-metadata-region";

export default class AssetSidebar extends Component {

	static propTypes = {
		asset: PropTypes.object,
		assetCategoryId: PropTypes.number,
		disabled: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		// const starRoute = [{ module: "star", app: "home", access: "reader" }];
		// const hasStarAccess = hasAccessToPath(starRoute);

		this.user = AuthStore.getState().user;

		const mostRecentTab = localStorage.getItem(STORAGE_TAB_KEY);
		
		let regionId;
		if (appConfig.features && appConfig.features.starDAMFeatures) {
			regionId = parseInt(localStorage.getItem(STORAGE_METADATA_REGION));
			if (regionId > 0 && !Regions.find(r => r.id === parseInt(regionId))) {
				regionId = Regions[0].id;
			}
		}

		this.state = {
			activeTab: mostRecentTab || "crop", //hasStarAccess ? "metadata" : "crop",
			regionId: typeof(regionId) === "number" ? regionId : 0,
			// hasStarAccess,
		};
	}

	changeTab = (tabKey) => {
		this.setState({ activeTab: tabKey });
		localStorage.setItem(STORAGE_TAB_KEY, tabKey);
	}

	changeRegion = (regionId) => {
		this.setState({ regionId });
		localStorage.setItem(STORAGE_METADATA_REGION, regionId);
	}

	render() {
		// const { hasStarAccess } = this.state;
		const { asset, assetCategoryId, brandings, disabled, hideTabs, transformations } = this.props;
		const tabs = getTabs(asset, assetCategoryId, hideTabs);
		const activeTab = tabs.some(tab => tab.key === this.state.activeTab)
			? this.state.activeTab
			: getDefaultActiveTab(asset, tabs);

		return (
			<div className="asset-sidebar">
				{tabs.length > 1 && (
					<div className="tabs">
						{tabs.map(tab => (
							<div
								key={tab.key}
								className={`tab icon-${tab.icon} ${activeTab === tab.key ? "active" : ""}`}
								onClick={() => {
									this.changeTab(tab.key);
									if (tab.key !== "crop") {
										Actions.cancelCrop();
									}
								}}
							></div>
						))}
					</div>
				)}
				<div className="sections c6-main">
					{asset.type === "Image" && !(tabs.length === 1 && activeTab !== "crop") && (
						<div style={{ display: hideTabs || activeTab === "crop" ? "block" : "none" }}>
							<CropPreviews
								asset={asset}
								disabled={disabled}
							/>
						</div>
					)}
					{getSections(activeTab, asset, disabled, this.user, transformations, this.state.regionId, brandings, this.changeRegion)}
				</div>
			</div>
		);
	}
}

function getTabs(asset, assetCategoryId, hideTabs) {
	if (!asset.id || hideTabs) {
		return [];
	}
	const tabs = [];
	const useMinimalImageEditor = (appConfig.features && appConfig.features.useMinimalImageEditor);

	switch (asset.type) {
		case "Image":
			// if (assetCategoryId !== undefined && assetCategoryId !== 0) {
			if (assetCategoryId !== 0 && !useMinimalImageEditor) {
				tabs.push({ key: "crop", icon: "crop" });
				tabs.push({ key: "modify", icon: "rotate_90_degrees_ccw" });
			}
	}

	tabs.push({ key: "metadata", icon: "info_outline" });
	return tabs;
}

function getDefaultActiveTab(asset, tabs) {
	if (!asset.id) {
		return null;
	}
	return !!tabs.length ? tabs[0].key : null;
}

function getSections(tabKey, asset, disabled, user, transformations, regionId, brandings, onRegionChange) {

	// const userHasNoEditAccess = !hasStarEditAccess();
	const useMinimalImageEditor = (appConfig.features && appConfig.features.useMinimalImageEditor);
	const userHasNoEditAccess = useMinimalImageEditor ? false : !hasStarEditAccess(); // For OSD we don't care about StarEditAccess, because it's only two fields

	const userHasNoEditAccessOrContainerIsApproved = userHasNoEditAccess && disabled;
	const region = Regions.find(r => r.id === regionId);
	const metadataVersion = region
		? (asset.versionData || []).find(vd => vd.versionId === region.id) || {}
		: { description: asset.description, source: asset.source };
	const assetBranding = brandings?.length ? (asset?.branding?.name ?? "Undefined") : "";


	if (tabKey === "modify") {
		const rotation = transformations.rotation;
		const brightness = (transformations.brightness + 100) / 100;
		const contrast = (transformations.contrast + 100) / 100;
		return (
			<>
				<Section
					sectionKey="rotation"
					headerIcon="rotate_90_degrees_ccw"
					leftHeaderContent="Rotation"
					rightHeaderContent={`${rotation}°`}
				>
					{getRotationContent(rotation, userHasNoEditAccessOrContainerIsApproved)}
				</Section>
				<Section
					sectionKey="brightness"
					headerIcon="wb_sunny"
					leftHeaderContent="Brightness"
					rightHeaderContent={brightness.toFixed(2)}
				>
					{getSlider(brightness, "brightness", userHasNoEditAccessOrContainerIsApproved)}
				</Section>
				<Section
					sectionKey="contrast"
					headerIcon="tonality"
					leftHeaderContent="Contrast"
					rightHeaderContent={contrast.toFixed(2)}
				>
					{getSlider(contrast, "contrast", userHasNoEditAccessOrContainerIsApproved)}
				</Section>
			</>
		);
	}

	if (tabKey === "metadata") {
		const BrandingItems = brandings.map(brand => (
			<MenuItem
				key={brand.name}
				value={brand.name}
			>
				{brand.displayName}
			</MenuItem>
		));

		return (
			<>
				{appConfig.features && appConfig.features.starDAMFeatures && (
					<Section
						sectionKey="region-select"
					>
						<RegionSelect region={region} onChange={onRegionChange} />
					</Section>
				)}
				<Section
					sectionKey="description"
					headerIcon="subject"
					leftHeaderContent={`Description${region ? ` (${region.languageDisplayName})` : "" }`}
				>
					<TextArea defaultValue={metadataVersion.description} readOnly={userHasNoEditAccess} maxLength={500} region={region} onSave={(description) => { Actions.updateAssetMetadata(asset, { ...metadataVersion, description }, region); }} />
				</Section>
				<Section
					sectionKey="source"
					headerIcon="person_pin"
					leftHeaderContent={`Source / Photo${region ? ` (${region.languageDisplayName})` : "" }`}
				>
					<TextArea defaultValue={metadataVersion.source} readOnly={userHasNoEditAccess} maxLength={100} region={region} onSave={(source) => { Actions.updateAssetMetadata(asset, { ...metadataVersion, source }, region); }}/>
				</Section>
				<Section
					sectionKey="branding"
					headerIcon="branding_watermark"
					leftHeaderContent="Branding"
				>
					<Select
						className="margin mui-select"
						value={assetBranding}
						onChange={(e) => handleBrandingSelected(asset.id, e.target.value)}
						variant="standard"
						readOnly={userHasNoEditAccess}
					>
						{BrandingItems}
					</Select>
				</Section>
				{appConfig.features && appConfig.features.starDAMFeatures && (
					<Section
						sectionKey="tags"
						headerIcon="local_offer"
						leftHeaderContent="Tags"
					>
						<AssetTags asset={asset} readOnly={userHasNoEditAccess} />
					</Section>
				)}
				{(!appConfig.features?.assetEditorHideRestrictions && !useMinimalImageEditor) && (
					<Section
						sectionKey="restrictions"
						headerIcon="verified_user"
						leftHeaderContent="Restrictions"
					>
						<Restrictions asset={asset} user={user} readOnly={userHasNoEditAccess} region={region} />
					</Section>
				)}
				{asset.type === "Image" && (
					<Section
						sectionKey="exif"
					>
						<ExifContent asset={asset} />
					</Section>
				)}
				{["Trailer", "Clip", "Video"].includes(asset.type) && (
					<Section
						sectionKey="exif"
					>
						<MediaInfoContent asset={asset} />
					</Section>
				)}
			</>
		);
	}

	return null;
}

function handleBrandingSelected(assetId, brandValue) {
	Actions.updateBranding(assetId, brandValue);
}

function getSlider(value, property, disabled) {
	return (
		<Slider
			className="slider"
			value={value}
			max={2}
			min={0}
			step={0.05}
			onChange={(e, newValue) => Actions.updateLocalTransformations(property, Math.round(newValue * 100 - 100))}
			sx={{
				margin: "5px 15px",
    			width: "calc(100% - 32px)",
			}}
			disabled={disabled}
		/>
	);
}

function getRotationContent(value, disabled) {
	if (disabled) {
		return null;
	}

	return (
		<div className="flex rotation">
			<Left>
				<Button type="rotate_left" title="Left" noBackground onClick={e => Actions.updateLocalTransformations("rotation", (value - 90) % 360)} />
			</Left>
			<Right>
				<Button type="rotate_right" title="Right" noBackground onClick={e => Actions.updateLocalTransformations("rotation", (value + 90) % 360)} />
			</Right>
		</div>
	);
}

function Section({ assetId, sectionKey, leftHeaderContent, rightHeaderContent, headerIcon, content, children }) {
	return (
		<section key={`${assetId}-${sectionKey}`} className={sectionKey}>
			{(leftHeaderContent || rightHeaderContent) && (
				<div className="header">
					<Left>
						{headerIcon && (<div className={`icon-${headerIcon}`}></div>)}
						<div>{leftHeaderContent}</div>
					</Left>
					<Right>{rightHeaderContent}</Right>
				</div>
			)}
			<div className="content">
				{children ?? content}
			</div>
		</section>
	);
}