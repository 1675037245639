import React from 'react'
import moment from 'moment'
import nanoid from 'nanoid'
import Flatpickr from 'react-flatpickr'
import FlatpickrButtonPlugin from './flatpickrButtonPlugin'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'

import Button from '../button'
import './picker.css'

export default class Picker extends React.Component {

	constructor(props) {
		super(props);

		this.htmlID = "input" + nanoid(); // Needed for label <-> input connection

		this.state = {
			date: this.props.value || undefined,
			isFocused: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = nextProps;
		if (value !== this.props.value || !value !== !this.props.value) {
			this.setState({
				date: value || undefined,
			});
		}
	}

	// Needed to prevent Flatpickr from re-initializing and thereby crashing with a "Cannot read property 'children' of undefined"
	// (in updateWeekdays) error when new values are picked. Probably something with react-flatpickr <-> flatpickr.
	shouldComponentUpdate(nextProps, nextState) {
		const { value: nextValue, hidden: nextHidden, readOnly: nextReadOnly } = nextProps;
		const { value, hidden, readOnly } = this.props;

		const { date: nextDate, isFocused: nextIsFocused } = nextState;
		const { date, isFocused } = this.state;

		let propValueHasUpdated = nextValue !== value || !nextValue !== !value;
		let stateDateHasUpdated = nextDate !== date || !nextDate !== !date;
		const focusHasUpdated = nextIsFocused !== isFocused;
		const hiddenHasUpdated = nextHidden !== hidden;
		const readOnlyHasUpdated = nextReadOnly !== readOnly;

		if (Array.isArray(nextValue) && Array.isArray(value)) {
			propValueHasUpdated = nextValue[0] !== value[0] || nextValue[1] !== value[1];
		}

		if (Array.isArray(nextDate) && Array.isArray(date)) {
			stateDateHasUpdated = nextDate[0] !== date[0] || nextDate[1] !== date[1];
		}


		return readOnlyHasUpdated || propValueHasUpdated || stateDateHasUpdated || focusHasUpdated || hiddenHasUpdated;
	}

	// Called when the input changes, either by picking a date or typing and blurring input
	handleChange = (eventOrValue) => {
		if (this.props.readOnly) {
			return false;
		}

		const { range, onChange, options = {}, defaultValue, value } = this.props;
		const data = Array.isArray(eventOrValue) ? eventOrValue : eventOrValue.target && eventOrValue.target.value;
		const format = options.format || this.props.format;

		let date = "";

		// Is this a range picker?
		if (range) {
			// If data comes as one string ("17/4 to 20/4"), split string
			if (typeof(data) === "string") {
				if (data.length) {
					const dateRange = data.split(" to ");
					const fromDate = dateRange[0] ? moment(dateRange[0], format).format(format) : "";
					const toDate = dateRange[1] ? moment(dateRange[1], format).format(format) : "";
					date = [fromDate, toDate];
				}
				else {
					date = ["",""];
				}
			}
			// Else, data comes as two seperate values already
			else {
				const fromDate = data[0] && moment(data[0], format).format(format);
				const toDate = data[1] && moment(data[1], format).format(format);
				date = [fromDate, toDate];
			}
		}
		// Single date picker
		else {
			const dataPart = Array.isArray(data) ? data[0] : data;
			if (dataPart) {
				const updatedMoment = moment(dataPart, format);
				date = updatedMoment.isValid()
					? updatedMoment.format(format)
					: defaultValue || value || "" ;
			}
		}

		if (this.props.value && date && moment(this.props.value).isSame(moment(date))) {
			// Same, don't update
		} else {
			this.setState({
				date,
				isFocused: false,
			}, () => {
				onChange(date);
			});
		}
	}

	handleFocus = () => {
		this.setState({ isFocused: true });
	}

	render() {
		if (this.props.hidden) {
			return null;
		}

		const {
			options = {},
			range,
			onChange,
			showConfirmButton,
			openIcon,
			format,
			defaultValue,
			value,
			label,
			required,
			placeholder,
			disabled,
			readOnly = false,
			showTodayButton,
			inputStyle = null,
			floatingLabelStyle = null,
			staticRender = true,
			...rest
		} = this.props;

		const { date, isFocused } = this.state;

		// Options sent to Flatpickr
		const flatpickrOptions = {
			// Allow manual input (typing in textfield)
			allowInput: true,

			// Open datepicker when textfield is clicked
			clickOpens: false,

			// 00-24 instead of AM/PM
			time_24hr: true,
			defaultHour: 0,

			// localization
			locale: {
				firstDayOfWeek: 1 // Monday
			},

			// Function called to parse dates
			parseDate: dateString => parseDate(dateString, options.format || format),

			// Renders the picker calendar at the same place in the DOM as the input field instead of rendering it
			// inside the BODY of the page. Works bad when used inside a narrow parent with for example overflow
			// hidden so in those cases we have to send static: false.
			static: staticRender,

			// Put flatpickr in a wrapper so that we can have open-button and clear-button
			wrap: true,

			// Add ConfirmDatePlugin to get the confirm button (seems to only work when enableTime is true)
			plugins: [new FlatpickrButtonPlugin({
				showConfirmButton: showConfirmButton,
				showTodayButton: showTodayButton
			})],

			// HACK: For some reason we need force showMonths to 0 to prevent time-only pickers from trying to re-initialize and thereby
			// crashing with a "Cannot read property 'children' of undefined" (in updateWeekdays) since there are no weekdays to
			// update when we have no calendar visible.
			showMonths: rest.noCalendar ? 0 : 1,

			// errorHandler: error => {
			// 	console.log(error);
			// },

			...options,

			...rest
		};

		const placeHolderValue = options.placeholder || placeholder || options.format || format;
		const labelText = label ? `${label}${required ? " *" : ""}` : null;
		const placeholderText = isFocused || !label ? placeHolderValue : "";
		const className = range ? "c6-picker c6-range-picker" : "c6-picker";
		const hasValue = Array.isArray(date) ? date[0] && date[0].length > 0 : date && date.length > 0;

		return (
			<Flatpickr
				options={flatpickrOptions}
				onChange={this.handleChange}
				value={date}
				className={className}
				ref={ref => flatpickrRef(ref, options)}
			>
				<input
					id={this.htmlID}
					data-input
					autoComplete="off"
					type="text"
					name={this.htmlID}
					style={inputStyle}
					disabled={options.disabled || disabled}
					readOnly={readOnly}
					onFocus={this.handleFocus}
					onBlur={this.handleChange}
					placeholder={placeholderText}
					data-hasvalue={hasValue}
				/>
				<span className="c6-picker-border" />
				<label
					style={floatingLabelStyle}
					className="c6-picker-floating-label"
					htmlFor={this.htmlID}>
					{labelText}
				</label>

				{!readOnly && <div className={`button-wrapper ${options.disabled || disabled ? "hide" : ""}`}>
					<Button
						data-toggle
						type={openIcon}
						noBackground
						disabled={options.disabled || disabled}
						onClick={() => {}}
					/>
					{!options.hideClear && (
						<Button
							data-clear
							data-close
							type="clear"
							noBackground
							disabled={options.disabled || disabled}
							onClick={() => {}}
						/>
					)}
				</div>}
			</Flatpickr>
		);
	}
};

// Function that parses dates that are typed/pasted in the text input
const parseDate = (dateString, format) => {
	const dateMoment = moment(dateString, format);
	return dateMoment.isValid() ? dateMoment.toDate() : "";
};

// HACK: DOM manipulation
const flatpickrRef = (ref, options) => {
	if (ref) {
		// Get parent node (flatpickr-wrapper)
		const node = ref.node;
		const parentNode = node.parentNode;

		// Options from ui schema
		if (options.halfWidth) {
			parentNode.classList.add("half-width");
		}
		if (options.thirdWidth) {
			parentNode.classList.add("third-width");
		}
		
		// HACK: Because minDate/maxDate can mess up the month list https://github.com/flatpickr/flatpickr/issues/2049
		if (options.minDate || options.maxDate) {
			ref.flatpickr.changeMonth(0);
		}
	}
};