import { get, put, post, del } from '../core/c6'
import appConfig from 'config'

const API = "horizon";

/* **************** Packages ***************** */
export const fetchPackages = filters => {
	if (filters.status && filters.status.startsWith("queued")) {
		return get(API, "queues", filters);
	}
	return get(API, "packages", filters);
};
export const fetchPackage  = ({id})  => get(API, `packages/${id}`);

/* **************** Excel ***************** */
export const fetchExcel				= filters => get(API, "excel/export", filters);

/* **************** Package actions ***************** */
export const resendAll 				= payload => put(API, "packages/resend/all", payload);
export const resendMetadata 		= payload => put(API, "packages/resend/metadata", payload);
export const publishMetadata 		= ({id})  => put(API, `packages/${id}/publishmetadata`);
export const resendSeason			= ({id})  => put(API, `packages/${id}/resend/all/season`);
export const addToDeliveryList 		= payload => put(API, "packages/deliveryList/add", payload);
export const addToBlockList 		= payload => put(API, "packages/blockList/add", payload);
export const removeFromDeliveryList = payload => put(API, "packages/deliveryList/remove", payload);
export const removeFromBlockList 	= payload => put(API, "packages/blockList/remove", payload);

/* **************** Queues ***************** */
export const fetchQueues = filters => get(API, "queues", filters);
export const fetchQueue  = ({id})  => get(API, `queues/${id}`);

/* **************** Services ***************** */
export const fetchServices 				= filters 			=> get(API, "services", filters);
export const fetchService  				= ({id})  			=> get(API, `services/${id}`);
export const fetchServiceConfiguration 	= ({id}) 			=> get(API, `services/${id}/configuration`);
export const updateServiceConfiguration = ({id}, payload) 	=> put(API, `services/${id}/configuration`, payload);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);