import { get, put, post, del } from '../core/c6'
import Qs from 'qs'

import appConfig from 'config'

const API = "sport";
const SKIP_TOKEN = true;

/* **************** Participants ***************** */
export const fetchParticipants 	= filters 			=>  get(API, "participants", filters);
export const fetchParticipant 	= ({id}) 			=>  get(API, `participants/${id}`);
export const updateParticipant 	= ({id}, payload) 	=>  put(API, `participants/${id}`, payload);
export const createParticipant  = (payload)         => post(API, "participants/add", payload);

/* **************** Series ***************** */
export const fetchSeriesList 	= filters 				=>  get(API, "series", filters);
export const fetchSeries 		= ({id}) 				=>  get(API, `series/${id}`);
export const updateSeries 		= ({id}, payload) 		=>  put(API, `series/${id}`, payload);
export const createSeries   	= (payload)             => post(API, "series/add", payload);

/* **************** Seasons ***************** */
export const fetchSeasons 	= filters 	=>  get(API, "seasons", filters);
export const fetchSeason 	= ({id}) 	=>  get(API, `seasons/${id}`);

/* **************** Arenas ***************** */
export const fetchArenas 	= filters 			=>  get(API, "arenas", filters);
export const fetchArena 	= ({id}) 			=>  get(API, `arenas/${id}`);
export const updateArena 	= ({id}, payload) 	=>  put(API, `arenas/${id}`, payload);
export const createArena    = (payload)         => post(API, "arenas/add", payload);

/* **************** Genres ***************** */
export const fetchGenres    = filters           =>  get(API, "genres", filters);
export const fetchGenre     = ({id})            =>  get(API, `genres/${id}`);
export const updateGenre    = ({id}, payload)   =>  put(API, `genres/${id}`, payload);
export const createGenre    = (payload)         => post(API, "genres/add", payload);

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);