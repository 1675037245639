import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { getSearchPayload } from '../shared/utils'

import '../shared/app.css'

const MODULE = "onestopdrop";
const DATASTORE = "todos";
const PARENT_ENTITY = "worklist";
const TEXT_HEADING = "Your TODOs";

export default class WorklistApp extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);

		this.scroll = null;

		this.workListId = this.props.params.workListId;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.workListId) {
			Actions.fetchItems(DATASTORE, {
				...getSearchPayload(this.state),
				workListId: this.workListId,
			});
			Actions.fetchItem(PARENT_ENTITY, { id: this.workListId });
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	onFilter = (type, e) => {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter = (type, event) => {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions[type](value, name, DATASTORE);
		Actions.fetchItems(DATASTORE, getSearchPayload(this.state));

		this.setState({
			searchText: type !== "search" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	loadMore = () => {
		if (this.state.nextPageUrl) {
			Actions.pageItems(DATASTORE, this.state.nextPageUrl);
		}
	}

	shouldUpdateScroll = (prevRouterProps, currentRouterProps) => {
		return currentRouterProps.location.action === "POP";
	}

	handleCreateNew = () => {
		const contentSource = this.state.item.worklist.contentSource;
		Actions.create({
			pathname: `/onestopdrop/worklists/items/create`,
			returnTo: window.location.pathname,
			search: `?workListId=${this.workListId}&contentSource=${contentSource}`,
		});
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter, list, item } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const todos = list[DATASTORE];
		const worklist = item[PARENT_ENTITY];

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading}
			>
				<Header
					title={worklist.displayName}
					searchText={searchText}
					filters={todos.filters}
					filters={filters}
					unselectedFilters={unselectedFilter || !!searchText.length}
					handleFilter={this.onFilter}
					handleCreateNew={this.handleCreateNew}
					worklist={worklist}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el} padding={true}>
						<List
							items={todos.items}
							isLoading={this.state.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={todos.nextPageUrl}
							loadMore={this.loadMore} />
					</Main>
				</ScrollContainer>
				<Info>{getInfo(this.props.location, this.state)}</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, { numberOfItems, list, ...rest }) {
	const ds = list[DATASTORE];
	const items = ds.length ? `- displaying ${ds.length} of ${numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}