import React from 'react'

import alt from '../../../core/services/alt'
import withDnDContext from '../../../core/services/withDnDContext'
import { isModalRoute } from '../../../core'
import { hasRole } from '../../../core/services/auth'

import App from '../../../components/app'
import { UICenter } from '../../../components/ui/alignment'
import { Filter, Left, Right } from '../../../components/filter'

import Actions from '../actions'
import Store from '../store'
import FilesSection from './files'
import ActivitySection from './activity'
import RatingsSection from './ratings'

import './todo.css'

class Todo extends React.Component {

	constructor(props) {
		super(props);

		this.todoId = props.params.id;


		this.isTranslationAgencyUser = hasRole("onestopdrop.translation-agency");
		// console.log("isTranslationAgencyUser", this.isTranslationAgencyUser);
		this.isProductionCompanyUser = hasRole("onestopdrop.production-company");
		// console.log("isProductionCompanyUser", this.isProductionCompanyUser);

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		Actions.fetchTodo(this.todoId);

		// HACK: Trigger dialog padding-top calculation to fix dialog position
		// https://github.com/mui-org/material-ui/issues/1676#issuecomment-143798818
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 100);
	}

	componentWillUnmount() {
		Actions.unmountTodo();
		Store.unlisten(this.onChange);
	}

	onChange = state => {
		this.setState(state);
	}

	render() {
		const {
			isLoading,
			todoPartsLoading,
		} = this.state;

		const todo = this.state.item["todo"];
		const container = this.state.item["container"];
		const containerIsGone = todo?.workListItemId && !container?.id && !todoPartsLoading["container"];
		if (containerIsGone) {
			return (
				<App name="c6-osd-todo container-missing">
					<UICenter>
						The folder associated with this item is no longer available.
					</UICenter>
				</App>
			);
		}

		let leftSection, type;
		if (todo?.workType === "AgeRating") {
			leftSection = (
				<RatingsSection
					todo={todo}
					program={this.state.item["program"]}
					childPrograms={this.state.list["childPrograms"].items}
					childContainers={this.state.list["childContainers"].items}
				/>
			);
		} else /*if (todo?.workType === "Content")*/ {
			leftSection = (
				<FilesSection
					todo={todo}
					container={container}
					parentContainer={this.state.item["parentContainer"]}
					containerLoading={todoPartsLoading["container"]}
					childContainers={this.state.list["childContainers"].items}
					childContainersLoading={todoPartsLoading["childContainers"]}
					program={this.state.item["program"]}
					parentProgram={this.state.item["parentProgram"]}
					childPrograms={this.state.list["childPrograms"].items}
					isTranslationAgencyUser={this.isTranslationAgencyUser}
					isProductionCompanyUser={this.isProductionCompanyUser}
				/>
			);
		}
		
		return (
			<App
				name="c6-osd-todo"
				layout="grid"
			>
				<Filter>
					<Left>
						<h1 className="c6-title">
							{todoPartsLoading["todo"] ? "Loading..." : todo.displayName}
						</h1>
					</Left>
				</Filter>
				{!todoPartsLoading["todo"] && (
					<React.Fragment>
						{leftSection}
						<ActivitySection
							todo={todo}
							todoLoading={todoPartsLoading["todo"]}
							container={container}
							comments={this.state.list["comments"].items}
							commentsLoading={todoPartsLoading["comments"]}
							isTranslationAgencyUser={this.isTranslationAgencyUser}
							isProductionCompanyUser={this.isProductionCompanyUser}
						/>
					</React.Fragment>
				)}
			</App>
		);
	}
}

export default withDnDContext(Todo);