export const Symbols = [
	{ key: "Väkivalta", swedish: "Våldsamt", finnish: "Väkivalta", descriptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], iconSource: "/client/img/V.png" },
	{ key: "Seksi", swedish: "Sexuellt", finnish: "Seksi", descriptions: [15, 16, 17, 18, 19, 20], iconSource: "/client/img/X.png" },
	{ key: "Ahdistus", swedish: "Ångestframkallande", finnish: "Ahdistus", descriptions: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34], iconSource: "/client/img/A.png" },
	{ key: "Päihteet", swedish: "Rusmedel", finnish: "Päihteet", descriptions: [35, 36, 37], iconSource: "/client/img/P.png" },
];

export const Ages = [
	{ key: "S", descriptions: [20, 34, 14], color: "#7fd331" },
	{ key: "7", descriptions: [12, 13, 19, 31, 32, 33], color: "#ffff00" },
	{ key: "12", descriptions: [9, 10, 11, 17, 18, 26, 27, 28, 29, 30, 37], color: "#ffc100" },
	{ key: "16", descriptions: [4, 5, 6, 7, 8, 16, 23, 24, 25, 36], color: "#ffc100" },
	{ key: "18", descriptions: [1, 2, 3, 15, 21, 22, 35], color: "#ff0000" },
];

export const RatingPersons = [
	{ displayName: "eMeku", name: "eMeku" },
	{ displayName: "Aija Ronkainen", name: "Aija Ronkainen" },
	{ displayName: "Beritta Markkula", name: "Beritta Markkula" },
	{ displayName: "Broadcast Text International Oy", name: "Broadcast Text International Oy" },
	{ displayName: "Eliisa Rajasaari", name: "Eliisa Rajasaari" },
	{ displayName: "Elina Eskola", name: "Elina Eskola" },
	{ displayName: "Hanna Kallankari", name: "Hanna Kallankari" },
	{ displayName: "Heidi Skippari", name: "Heidi Skippari" },
	{ displayName: "Henri Heikkinen", name: "Henri Heikkinen" },
	{ displayName: "Jaana Wiik", name: "Jaana Wiik" },
	{ displayName: "Janne Mökkönen", name: "Janne Mökkönen" },
	{ displayName: "Jarkko Viiman", name: "Jarkko Viiman" },
	{ displayName: "Jenni Rajala", name: "Jenni Rajala" },
	{ displayName: "Jouni Luoma", name: "Jouni Luoma" },
	{ displayName: "Kaisa Herlevi", name: "Kaisa Herlevi" },
	{ displayName: "Kari Loman", name: "Kari Loman" },
	{ displayName: "Karoliina Kivijärvi", name: "Karoliina Kivijärvi" },
	{ displayName: "Kati Metsola", name: "Kati Metsola" },
	{ displayName: "Kimmo Kröger", name: "Kimmo Kröger" },
	{ displayName: "Liina Härkönen", name: "Liina Härkönen" },
	{ displayName: "Maaret Dufva", name: "Maaret Dufva" },
	{ displayName: "Maria Haaja", name: "Maria Haaja" },
	{ displayName: "Maria Stenbäck", name: "Maria Stenbäck" },
	{ displayName: "Marja Reinikainen", name: "Marja Reinikainen" },
	{ displayName: "Marko Pohjanrinne", name: "Marko Pohjanrinne" },
	{ displayName: "Meri Peltola", name: "Meri Peltola" },
	{ displayName: "Merja Lampo", name: "Merja Lampo" },
	{ displayName: "Mette Bono", name: "Mette Bono" },
	{ displayName: "Minna Franssila", name: "Minna Franssila" },
	{ displayName: "Mira Ruotsalainen", name: "Mira Ruotsalainen" },
	{ displayName: "Olli Nikunen", name: "Olli Nikunen" },
	{ displayName: "Olli Rusama", name: "Olli Rusama" },
	{ displayName: "Riitta Pesola", name: "Riitta Pesola" },
	{ displayName: "Sanna Tilli", name: "Sanna Tilli" },
	{ displayName: "Sari Vauhkonen", name: "Sari Vauhkonen" },
	{ displayName: "SDI:n kääntäjä", name: "SDI:n kääntäjä" },
	{ displayName: "Sirpa Solgaard", name: "Sirpa Solgaard" },
	{ displayName: "Suvi Viitala", name: "Suvi Viitala" },
	{ displayName: "Taina Komu", name: "Taina Komu" },
	{ displayName: "Tarja Sahlstén", name: "Tarja Sahlstén" },
	{ displayName: "Timo Halme", name: "Timo Halme" },
	{ displayName: "Titta Raappana - Haapio", name: "Titta Raappana - Haapio" },
	{ displayName: "Toni Koskelainen", name: "Toni Koskelainen" },
	{ displayName: "Juho Lähde", name: "Juho Lähde" },
	{ displayName: "Iira Tuominen", name: "Iira Tuominen" },
	{ displayName: "Emilia Siponen", name: "Emilia Siponen" },
	{ displayName: "Tony Johansson", name: "Tony Johansson" },
	{ displayName: "Elisa Kuitunen", name: "Elisa Kuitunen" },
];

export const AgeRatings = {
	swedish: [
		{ key: "noRating", text: "Unrated" },
		{ key: "A", text: "All" },
		{ key: "7", text: "7" },
		{ key: "11", text: "11" },
		{ key: "15", text: "15" },
	],
	norwegian: [
		{ key: "noRating", text: "Unrated" },
		{ key: "A", text: "All" },
		{ key: "6", text: "6" },
		{ key: "9", text: "9" },
		{ key: "12", text: "12" },
		{ key: "15", text: "15" },
		{ key: "18", text: "18" },
	],
	danish: [
		{ key: "noRating", text: "Unrated" },
		{ key: "A", text: "All" },
		{ key: "7", text: "7" },
		{ key: "11", text: "11" },
		{ key: "15", text: "15" },
	],
};

export const FinnishAgeRatingMap = {
	"noRating": { swedish: "noRating", norwegian: "noRating", danish: "noRating" },
	"S": { swedish: "A", norwegian: "A", danish: "A" },
	"7": { swedish: "7", norwegian: "6", danish: "7" },
	"12": { swedish: "11", norwegian: "12", danish: "11" },
	"16": { swedish: "15", norwegian: "15", danish: "15" },
	"18": { swedish: "15", norwegian: "18", danish: "15" },
};