import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'

import FineUploaderTraditional from 'fine-uploader-wrappers'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
// import ProgressBar from 'react-fine-uploader/progress-bar'
// import Filename from 'react-fine-uploader/filename'
// import Filesize from 'react-fine-uploader/filesize'
// import Status from 'react-fine-uploader/status'
// import PauseResumeButton from 'react-fine-uploader/pause-resume-button'
// import Thumbnail from 'react-fine-uploader/thumbnail'

import ItemTypes from '../../core/itemTypes'
import StarConstants from '../../core/constants/star'
import { getServiceToken } from '../../core/services/auth'
import * as Alert from '../../core/services/alert'

import './upload.css'
import appConfig from 'config'

class StarUpload2 extends Component {
	static propTypes = {
		categoryName: PropTypes.string,
		update: PropTypes.func,
		canDrop: PropTypes.bool,
		multipleUploads: PropTypes.bool,
		autoUpload: PropTypes.bool,
		disabled: PropTypes.bool,
		assetType: PropTypes.string,
	};

	static defaultProps = {
		autoUpload: true
	};

	constructor(props) {
		super(props);

		this.state = {
			visibleFiles: [],
			initializing: true,
		};

		this.uploader = null;
		this.initialize = initialize.bind(this);
		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
		this.initialize(this.props);
	}

	// We can't only init the uploader in the constructor since props can, and will, change.
	UNSAFE_componentWillReceiveProps(nextProps) {
		const { endpoint } = nextProps;
		if(endpoint !== this.props.endpoint) {
			this.initialize(nextProps);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.uploader.off('statusChange', this.onStatusChange);
	}

	onStatusChange = (id, oldStatus, status) => {
		if (status === 'submitted') {
			this.addFile({ id, status });
		}
		else if (isFileGone(status)) {
			removeVisibleFile(id, this.state.visibleFiles, this.updateFiles);
		}
		else if (status === 'upload successful') {
			this.updateVisibleFileStatus(id, status);
		}
		else if (status === 'upload failed') {
			this.updateVisibleFileStatus(id, status);
		}
	}

	updateFiles = (files) => {
		if (this._isMounted) {
			this.setState({ visibleFiles: files });
		}
	}

	addFile = (file) => {
		const { visibleFiles } = this.state;

		const newFiles = [...visibleFiles];
		newFiles.push(file);

		if (this._isMounted) {
			this.setState({ visibleFiles: newFiles });
		}
	}

	updateVisibleFileStatus(id, status) {
		const newFiles = this.state.visibleFiles.map((item) => {
			if (item.id === id) {
				const itemWithNewStatus = item;
				itemWithNewStatus.status = status;
				return itemWithNewStatus;
			}
			return item;
		});
		this.updateFiles(newFiles);
	}

	render() {
		const {
			multipleUploads,
			// canDrop,
			disabled,
			connectDropTarget,
			indexDisplayName,
			required,
			assetType,
		} = this.props;

		const { visibleFiles, initializing } = this.state;

		// const hasError = visibleFiles.some(file => file.status === "upload failed");

		let instructionsType, accept;
		if (["Trailer", "Clip", "Video"].includes(assetType)) {
			instructionsType = "a video";
			accept = "video/*";
		} else if (assetType === "Image") {
			instructionsType = "an image";
			accept = "image/*";
		}

		return connectDropTarget(
			<div className="uploader">
				{required && <div className="status-ribbon required"><span>REQUIRED</span></div>}
				{indexDisplayName && <div className="index-name">{indexDisplayName}</div>}
				<Dropzone
					className="drop-zone"
					uploader={this.uploader || {}}
					multiple={multipleUploads}
					disabled={disabled || initializing}
				>
					<FileInput
						className="drop-zone"
						uploader={this.uploader || {}}
						multiple={multipleUploads}
						disabled={disabled || initializing}
						accept={accept}
					>
						{renderUploadInstructions(visibleFiles, initializing, instructionsType)}
					</FileInput>
				</Dropzone>
			</div>
		);
	}
}

/* REACT DND */
const targetSpec = {
	drop: (props, monitor) => {
		if (props.addAssetsToContainer) {
			const updateType = 'parent';
			const containerId = props.containerId;
			const categoryName = props.categoryName;
			const categoryIndex = props.categoryIndex;
			const item = monitor.getItem();
			props.addAssetsToContainer(updateType, containerId, [item.data.id], categoryName, categoryIndex);
		} else if (props.onDrop) {
			const item = monitor.getItem();
			props.onDrop(item.data);
		}
	},
	canDrop: (props, monitor) => !props.disabled,
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	};
}

export default DropTarget([ItemTypes.ASSET, ItemTypes.ITEM], targetSpec, collect)(StarUpload2);

const isFileGone = status => ['canceled', 'deleted', 'failed'].includes(status);

const getExtension = (filename) => {
	const extIdx = filename.lastIndexOf('.') + 1;

	if (extIdx > 0) {
		return filename.substr(extIdx, filename.length - extIdx);
	}

	return false;
};


const removeVisibleFile = (id, files, updateFiles) => {
	const visibleFileIndex = files.findIndex(file => file.id === id);

	if (visibleFileIndex >= 0) {
		const visibleFiles = files;
		visibleFiles.splice(visibleFileIndex, 1);
		updateFiles(visibleFiles);
	}
};

function initialize(props) {
	if (this._isMounted) {
		this.setState({ initializing: true });
	}

	const { categoryIndex = null, categoryName = "Undefined", autoUpload = false } = props;

	const onSubmit = (id, name) => {
		const params = {
			assetType: props.assetType && props.assetType !== "Undefined" ? props.assetType : decideAssetType(getExtension(name)),
			containerAssetIndex: categoryIndex,
			category: categoryName,
		};
		if (props.searchable !== undefined) {
			params.searchable = props.searchable
		}
		this.uploader.methods.setParams(params, id);
	};

	const onComplete = (id, name, response) => {
		if (response.success) {
			removeVisibleFile(id, this.state.visibleFiles, this.updateFiles);
			props.onComplete(response);
		}
		else {
			if (response.error && response.error.includes("could not be parsed")) {
				Alert.displayAlert("error", "The file could not be parsed and could be corrupt. Check by opening it in a relevant program.");
			}

			const file = this.state.visibleFiles.find(item => item.id === id);
			if (file) {
				file.message = response.error;
			}
			this.updateFiles(this.state.visibleFiles);
		}
	};

	const onError = (id, name, errorReason, xhr) => {
		Alert.displayAlert("error", `[${name}] ${errorReason}`);
		const fileIndex = this.state.visibleFiles.findIndex(file => file.id == id);
		this.state.visibleFiles.splice(fileIndex, 1);
		this.updateFiles(this.state.visibleFiles);
	};

	this.uploader = new FineUploaderTraditional({
		options: {
			autoUpload,
			chunking: {
				enabled: true,
			},
			request: {
				endpoint: getEndpoint(props),
				paramsInBody: false,
			},
			callbacks: {
				onSubmit,
				onComplete,
				onError,
			},
		},
	});

	this.uploader.on("statusChange", this.onStatusChange);

	if (props.skipAuth) {
		if (this._isMounted) {
			this.setState({ initializing: false });
		}
		if (props.uploaderRef && typeof (props.uploaderRef) === "function") {
			props.uploaderRef(this.uploader);
		}
	} else {
		getServiceToken('star').then(response => {
			this.uploader.methods.setCustomHeaders({ authorization: `Bearer ${response}` });
			if (this._isMounted) {
				this.setState({ initializing: false });
			}
			if (props.uploaderRef && typeof (props.uploaderRef) === "function") {
				props.uploaderRef(this.uploader);
			}
		});
	}
};

// HELPERS
function renderUploadInstructions(files, initializing, instructionsType = "a file") {
	if (initializing) {
		return <div className="upload-instructions">Initializing...</div>
	}

	return files.length === 0
		? (
			<div className="upload-instructions icon-cloud_upload">
				<div>Drag {instructionsType} <br />or click to upload</div>
			</div>
		)
		: (
			<div className="upload-instructions">
				<span className="c6-spinner"><span /></span>
				<div>Uploading {files.length > 1 ? `${files.length} files` : "1 file"}</div>
			</div>
		);
}

function decideAssetType(extension) {
	const extensionInLower = extension.toLowerCase();
	if (StarConstants.IMAGE_FORMATS.includes(extensionInLower)) {
		return "Image"; // assetTypeId = image (1)
	}
	else if (StarConstants.DOCUMENT_FORMATS.includes(extensionInLower)) {
		return "Document"; // assetTypeId = document (3)
	}
	else if (StarConstants.CLIP_FORMATS.includes(extensionInLower)) {
		return "Clip"; // assetTypeId = clip (4)
	}
	return "Undefined"; // assetTypeId = undefined (0)
};

// function getEndpoint({ audience, endpoint, mySpace, containerId, contentSource }) {
function getEndpoint({ audience, endpoint, containerId, contentSource }) {
	const queryParams = `?audience=${audience}&contentSource=${contentSource}`;

	if (endpoint) {
		return endpoint;
	}
	// else if (mySpace) {
	// 	return `${appConfig.api.star}files/upload${queryParams}`;
	// }
	else if (containerId) {
		return `${appConfig.api.star}containers/${containerId}/uploadFile${queryParams}`;
	}

	// console.error("No endpoint, mySpace or containerId prop provided to the Upload component.");
	console.error("No endpoint or containerId prop provided to the Upload component.");
	return undefined;
}