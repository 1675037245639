import React from 'react'
import PropTypes from 'prop-types'

import styles from './preview.css'

import {
	getImageGuid,
	getVideoGuid,
	getImage,
	getPrimary,
	getPrimaryProgram,
	getVideo,
	getPlaceholder,
	getVideoPrimary,
} from '../../utils/assets'

const Preview = ({
	assets = [],
	programId = null,
	guid = null,
	imageGuid = null,

	contentType = null,
	size = "small",
	onClick = null,
	extension,

	isGame = false,
	isVideo = false,

	placeholderClassName,
	displayMessageIfImgFailed,
}) => {

	let image = {}, video = {};

	if(programId) {
		const s = isGame && ["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(contentType)
			? "smallTeamLogos"
			: size;
		image = getPrimaryProgram(programId, s, onClick);
	}
	else if(guid) {
		image = isVideo ? getVideoPrimary(guid, size, onClick) : getPrimary(guid, size, onClick);
	}
	else if(imageGuid) {
		image = getImage(imageGuid, size, onClick, extension, displayMessageIfImgFailed);
	}
	else if(assets.length) {
		const imageGuid = getImageGuid(assets);
		const videoGuid = getVideoGuid(assets);
		image = getPrimary(imageGuid, size);
		video = getVideo(videoGuid, image.url, size);
	}

	return (
		video && video.component
		|| image && image.component
		|| getPlaceholder(size, placeholderClassName)
	);
};

Preview.propTypes = {
	assets: PropTypes.array,
	programId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	guid: PropTypes.string,
	contentType: PropTypes.string,
	size: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]),
	placeholderClassName: PropTypes.string,
}
export default Preview;