import React from 'react'
import PropTypes from 'prop-types'
// import { browserHistory } from 'browserHistory'
import appConfig from 'config'
import { withRouter } from 'react-router'

import alt from '../../../core/services/alt'
import { hasRole } from '../../../core/services/auth'
import keyboardNavigation from '../../../core/decorators/keyboardNavigation'
// import StarConstants from '../../../core/constants/star'
// import { downloadResource } from '../../../apis/star'
// import { saveBlobToDisc, saveUrlToDisc } from '../../../utils/save'

import App from '../../../components/app'
import Button from '../../../components/ui/controls/button'

import Actions from './actions'
import Store from './store'
import AssetHeader from './header'
import AssetSidebar from './sidebar'
import ImageAsset from './imageAsset'
import VideoAsset from './videoAsset'
import DocumentAsset from './documentAsset'

import { download } from './assetActions'

import './editor.css'

@keyboardNavigation({
	getId: item => item.type && item.subType ? item.reference || item.id : item.id,
})
@withRouter
export default class StarAsset extends React.Component {

	static propTypes = {
		assetId: PropTypes.number,
		assetCategoryId: PropTypes.number,
		disabled: PropTypes.bool,
		hideTabs: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.id = props.assetId || props.params.assetId;

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		if(this.id) {
			Actions.fetchAsset(this.id);
		}
		else {
			console.error("Did not recieve an assetId through props.assetId or params.assetId so we can't load any asset!");
		}
	}

	componentDidUpdate() {
		const nextId = this.props.assetId || this.props.params.assetId;
		if (nextId !== this.id) {
			this.id = nextId;

			if (this.id) {
				Actions.fetchAsset(this.id);
			}
		}
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
		alt.recycle(Store);
	}

	onChange = (state) => {
		this.setState(state);
	}

	getAssetContent = (asset, isLoading, disabled, assetCategoryId) => {
		const { assetId = undefined } = this.props.params || {};

		if (isLoading) {
			return <h3 className="no-asset-message">Loading asset...</h3>;
		}

		if (!asset || !asset.id) {
			return (
				<div className="no-asset-message">
					<h2 className="icon-security">Could not load asset with id {assetId}</h2>
					<p>You either don't have access to this asset or it doesn't exist.</p>
				</div>
			);
		}

		switch (asset.type) {
			case "Image":
				return (
					<ImageAsset
						asset={asset}
						forceRefreshImage={this.state.forceRefreshImage}
						assetCategoryId={assetCategoryId}
						disabled={disabled}
					/>
				);
			case "Video":
			case "Clip":
			case "Trailer":
				return <VideoAsset asset={asset} />
			case "Document":
				return <DocumentAsset asset={asset} />;
			default:
				return (
					<div className="no-asset-message">
						<h2>No preview available for this type of asset.</h2>
						<div>
							<Button
								title="Download"
								onClick={() => download(asset, false, null)}
								type="download"
							/>
						</div>
					</div>
				);
		}
	}

	render() {
		const { asset, brandings, isLoading, transformations } = this.state;
		const { assetCategoryId, hideTabs } = this.props;

		const disabled = this.props.disabled || !hasStarEditAccess();

		return (
			<App name="c6-asset-editor c6-star-asset-editor" isLoading={isLoading}>
				<AssetHeader asset={asset} disabled={disabled} />
				<AssetSidebar asset={asset} brandings={brandings} assetCategoryId={assetCategoryId} disabled={disabled} hideTabs={hideTabs} transformations={transformations} />
				{this.getAssetContent(asset, isLoading, disabled, assetCategoryId)}
			</App>
		);
	}
}

function hasStarEditAccess() {
	if (["star.AdminUser", "star.RegularUser"].some(role => hasRole(role))) {
		return true;
	}

	return false;
}