import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import InfiniteScroll from 'react-infinite-scroller'

import { CONTENT } from '../../../components/actions'
import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Actions from '../actions'
import Content from '../shared/listItem'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;
const DUE_DATE_MISSING = "__due_date_missing__";

const uiActions = {
	targetComponent: "Item",
	actions: [{
		title: "View worklist items",
		method: (data, sourceProps, location) => {
			Actions.edit({
				pathname: `/onestopdrop/todos/${data.workListItemId}`,
				modal: true,
			});
		},
		type: CONTENT,
	}, ]
};

@decoratorUIActions(uiActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);

		// this.throttledLoadMore = throttle(props.loadMore, 300); TODO!: Why thottled?
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			filters,
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const noResult = !(items && items.length);

		let response;

		if (noResult) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST
				? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.`
				: "";
			response = <Empty v2={true} key="empty" isLoading={isLoading}>{textTooFewCharacters || textEmpty}</Empty>;
		}
		else {
			response = (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{this.renderGroups(items, searchText, filters)}
				</InfiniteScroll>
			);
		}

		return response;
	}

	renderGroups(items, searchText, filters) {
		if (searchText) {
			return (
				<ItemGroup title={`Items matching "${searchText}"`}>
					{this.renderItems(items)}
				</ItemGroup>
			);
		}

		const groupedItems = groupItems(items, filters);
		return Object.entries(groupedItems).sort().map(([groupKey, groupItems]) => (
			<ItemGroup
				key={groupKey}
				title={getGroupTitle(groupKey)}
			>
				{this.renderItems(groupItems)}
			</ItemGroup>
		));
	}

	renderItems(items) {
		return items.map(item => (
			<Item
				key={item.workListItemId}
				id={item.workListItemId}
				actionData={item}>
				<Content {...item} />
			</Item>
		));
	}
}

// HELPERS
function groupItems(items, filters) {
	return groupBy(items, item => item.dueDate ? moment(item.dueDate).format("YYYYMMDD") : DUE_DATE_MISSING);
}

function getGroupTitle(group) {
	if (group === DUE_DATE_MISSING) {
		return <span style={{ color: "var(--text-light-color)" }}>Not yet planned</span>;
	}

	const now = moment();
	const isOverdue = moment(group).isBefore(now);

	return (
		<span>{renderDate(group)} {isOverdue && <span style={{ color: "var(--attention-color)" }}>{"(Overdue)"}</span>}</span>
	);
}

function renderDate(date) {
	const now = moment().format("YYYYMMDD");
	const prem = moment(date);
	const day = prem.format("YYYYMMDD");

	if (now.substr(0, 4) !== day.substr(0, 4)) {
		return prem.format("D MMMM YYYY");
	}

	return prem.format("D MMMM");
}