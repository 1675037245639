import React, { Component } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MuiButton from '@mui/material/Button'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import App from '../../../components/app'
import Editor from '../../../components/editor'
import Scroll from '../../../components/scroll'
import { Filter, Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Approval from '../../../components/ui/editorFields/approval'
import { getReturnTo } from '../../../core'
import { displayAlert } from '../../../core/services/alert'
import EditorNavigationController from '../../../core/ui/editorNavigationController'
import { KEYBOARD_SHORTCUTS } from '../../../core/constants'

import ProgramPreview from '../shared/preview'
import Actions from './actions'
import Store from './store'
import { DescriptionsContent, SymbolsAgeContent, NameDateContent, OtherCountriesRatingsContent } from './stepContents'
import { programHasRatings } from '../shared/utils'

import appConfig from 'config'

import './app.css'

const SWEDISH_VERSION = 1;
const NORWEGIAN_VERSION = 2;
const DANISH_VERSION = 3;
const FINNISH_VERSION = 4;
const LANGUAGE_STORAGE_KEY = "ratingsEditorLanguage";

const localRatingType = appConfig.features.metadataC70 || appConfig.features.metadataC80
	? "localRatingType"
	: "finnishRatingType";
const localRatingContactPerson = appConfig.features.metadataC70 || appConfig.features.metadataC80
	? "localRatingContactPerson"
	: "finnishRatingContactPerson";

@withRouter
export default class RatingsEditor extends Component {
	constructor(props) {
		super(props);

		this.freeTextContactPerson = !!props.route.freeTextContactPerson;
		this.hideReferences = !!props.route.hideReferences;

		this.useContentSourceMetadata = props.route?.useContentSourceMetadata;
		this.state = {
			...Store.getState(),
			step: 0,
			language: localStorage.getItem(LANGUAGE_STORAGE_KEY) === "swedish" ? "swedish" : "finnish",
		};
	}

	componentDidMount() {
		if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
			displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
		}

		Store.listen(this.onChange);
		Actions.fetchDataSources();
		Actions.fetchProgram({
			programId: this.props.params.id,
			programGuid: this.props.location.query.guid,
			useContentSourceMetadata: this.useContentSourceMetadata,
		});
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.params.id !== prevProps.params.id) {
			Actions.fetchProgram({
				programId: this.props.params.id,
				programGuid: this.props.location.query.guid,
				useContentSourceMetadata: this.useContentSourceMetadata,
			});
		}
	}

	onChange = state => {
		this.setState(state);
	}

	handleStep = newStep => {
		this.setState({ step: newStep });
	}

	renderTopActions = (error) => {
		const { programLoading, dataSourceLoading, metadataProgram, isDirty } = this.state;
		const disableActions = programLoading || dataSourceLoading;
		const finnishVersion = metadataProgram.versions?.find(v => v.versionId === FINNISH_VERSION) || {};
		const disableSave = !isDirty || !!error;

		const workListItemId = this.props.location.query.todoId;
		const status = metadataProgram?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(workListItemId));
		const isDone = status?.complete;

		const approval = this.useContentSourceMetadata
			? { status: isDone ? "approved" : undefined }
			: finnishVersion.finnishRatingApproval;

		const approvalReadOnly = this.useContentSourceMetadata && isDone && !status?.ignoreReadOnlyUntilSaved;

		let overrideApprovalLabel = null;
		if (this.useContentSourceMetadata && approvalReadOnly) {
			overrideApprovalLabel = "Data has been committed";
		} else if (this.useContentSourceMetadata && isDone) {
			overrideApprovalLabel = "Click save to commit and send off your data";
		}

		let canGoLeft = false;
		let canGoRight = false;
		if (appConfig.features.enableEditorNavigation) {
			const leftItem = EditorNavigationController.getLeftItem();
			canGoLeft = programHasRatings(leftItem);
			const rightItem = EditorNavigationController.getRightItem();
			canGoRight = programHasRatings(rightItem);
		}

		return (
			<Filter className="c6-actionbar">
				<Left>
					<Button
						title="Save"
						type="submit"
						noBackground={true}
						onClick={() => Actions.saveProgram(this.state, this.useContentSourceMetadata, this.props.location)}
						disabled={disableActions || disableSave}
					/>
					<Button
						title="Close"
						type="cancel"
						noBackground={true}
						onClick={e => handleCancel(e, this.props.location, this.props.routes, this.props.params)}
						disabled={disableActions}
					/>
					{appConfig.features.enableEditorNavigation && (
						<>
							<Button
								type="prev"
								hoverTitle={`Navigate to the previous item in the list (${KEYBOARD_SHORTCUTS.prev})`}
								noBackground={true}
								onClick={() => EditorNavigationController.goLeft()}
								disabled={!canGoLeft || !EditorNavigationController.canGoLeft()}
							/>
							<Button
								type="next"
								hoverTitle={`Navigate to the next item in the list (${KEYBOARD_SHORTCUTS.next})`}
								noBackground={true}
								onClick={() => EditorNavigationController.goRight()}
								disabled={!canGoRight || !EditorNavigationController.canGoRight()}
							/>
						</>
					)}
				</Left>
				<Right>
					{finnishVersion.versionId && (
						<Approval
							formData={approval}
							onChange={approvalObject => {
								if (this.useContentSourceMetadata) {
									Actions.updateStatus({ complete: !isDone, workListItemId, ignoreReadOnlyUntilSaved: true });
									Actions.updateApproval(approvalObject);
								} else {
									Actions.updateApproval(approvalObject);
								}
							}}
							formContext={{}}
							simpleLabel={this.useContentSourceMetadata}
							overrideApprovalLabel={overrideApprovalLabel}
							uiSchema={{ overrideReadOnly: approvalReadOnly }}
						/>
					)}
				</Right>
			</Filter>
		);
	}

	setLanguage = language => {
		this.setState({ language });
		localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
	}

	renderLanguageSwitch = () => {
		return (
			<div className="language-switch">
				<a onClick={() => this.setLanguage("swedish")} title="Show description and symbol names in Swedish">🇸🇪</a>
				<a onClick={() => this.setLanguage("finnish")} title="Show description and symbol names in Finnish">🇫🇮</a>
			</div>
		);
	}

	getTabContent = (tabIndex, readonly) => {
		const {
			step,
			language,

			metadataProgram,
			tagsProgram,
			programLoading,

			descriptionTags,
			symbols,
			dataSourceLoading,
		} = this.state;

		const isLoading = programLoading || dataSourceLoading;
		const finnishVersion = metadataProgram.versions?.find(v => v.versionId === FINNISH_VERSION) || {};
		const swedishVersion = metadataProgram.versions?.find(v => v.versionId === SWEDISH_VERSION) || {};
		const norwegianVersion = metadataProgram.versions?.find(v => v.versionId === NORWEGIAN_VERSION) || {};
		const danishVersion = metadataProgram.versions?.find(v => v.versionId === DANISH_VERSION) || {};

		let ikluSearchText = finnishVersion.title || swedishVersion.title || metadataProgram.displayName;
		if (metadataProgram.type === "Episode") {
			ikluSearchText = metadataProgram.name.replace(" EP", "E");
		}

		let content;
		switch (tabIndex) {
			default:
				content = <h1>{isLoading ? "Loading..." : "Please select which type of rating you aim to provide above!"}</h1>;
				break;
			case "noRating":
				content = <h1><span className="icon-checkbox-marked-circle-outline"></span>&nbsp;No rating is needed for this content.</h1>;
				break;
			case "stRating":
				content = (
					<>
						<h1><span className="icon-checkbox-marked-circle-outline"></span>&nbsp;This content is rated S (T).</h1>
						<NameDateContent
							version={finnishVersion}
							isLoading={isLoading}
							readonly={readonly}
							freeTextContactPerson={this.freeTextContactPerson}
						/>
						{appConfig.features.metadataOtherCountriesRatings && (
							<OtherCountriesRatingsContent
								swedishVersion={swedishVersion}
								norwegianVersion={norwegianVersion}
								danishVersion={danishVersion}
								readonly={readonly}
							/>
						)}
					</>
				);
				break;
			case "iklu":
				content = (
					<>
						<SymbolsAgeContent
							version={finnishVersion}
							dataSource={symbols}
							isLoading={isLoading}
							language={language}
							setLanguage={this.setLanguage}
							ikluSearchText={ikluSearchText}
							readonly={readonly}
						/>
						<NameDateContent
							version={finnishVersion}
							isLoading={isLoading}
							readonly={readonly}
							freeTextContactPerson={this.freeTextContactPerson}
						/>
						{appConfig.features.metadataOtherCountriesRatings && (
							<OtherCountriesRatingsContent
								swedishVersion={swedishVersion}
								norwegianVersion={norwegianVersion}
								danishVersion={danishVersion}
								readonly={readonly}
							/>
						)}
					</>
				);
				break;
			case "criterias":
				const stepStyle = { cursor: "pointer" };
				return (
					<div className="wizard">
						<div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
							<MuiButton
								className="step-button"
								disabled={step === 0 || isLoading}
								onClick={this.handleStep.bind(this, step - 1)}
							>
								Back
							</MuiButton>
							<Stepper nonLinear activeStep={step}>
								<Step style={stepStyle} onClick={this.handleStep.bind(this, 0)}><StepLabel>Descriptions</StepLabel></Step>
								<Step style={stepStyle} onClick={this.handleStep.bind(this, 1)}><StepLabel>Ratings & Signature</StepLabel></Step>
							</Stepper>	
							<MuiButton
								className="step-button"
								disabled={step === 1 || isLoading}
								color="primary"
								variant="contained"
								onClick={this.handleStep.bind(this, step + 1)}
							>
								Next
							</MuiButton>
						</div>
						{step === 0 && (
							<DescriptionsContent
								tagsProgram={tagsProgram}
								dataSource={descriptionTags}
								isLoading={isLoading}
								language={language}
								setLanguage={this.setLanguage}
								readonly={readonly}
							/>
						)}
						{step === 1 && (
							<>
								<SymbolsAgeContent
									version={finnishVersion}
									dataSource={symbols}
									isLoading={isLoading}
									language={language}
									setLanguage={this.setLanguage}
									ikluSearchText={ikluSearchText}
									readonly={readonly}
								/>
								<NameDateContent
									version={finnishVersion}
									isLoading={isLoading}
									readonly={readonly}
									freeTextContactPerson={this.freeTextContactPerson}
								/>
								{appConfig.features.metadataOtherCountriesRatings && (
									<OtherCountriesRatingsContent
										swedishVersion={swedishVersion}
										norwegianVersion={norwegianVersion}
										danishVersion={danishVersion}
										readonly={readonly}
									/>
								)}
							</>
						)}
					</div>
				);
		}

		return (
			<div className="tab-content">
				{content}
			</div>
		);
	}

	render() {
		const { metadataProgram, tagsProgram } = this.state;
		const finnishVersion = metadataProgram.versions?.find(v => v.versionId === FINNISH_VERSION) || {};
		let readonly = finnishVersion.finnishRatingApproval?.status?.toLowerCase() === "approved";
		if (this.useContentSourceMetadata) {
			const workListItemId = this.props.location.query.todoId;
			const status = metadataProgram?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(workListItemId));
			readonly = status?.complete;
		}
		const error = validateRatings(finnishVersion);

		const tabs = [
			{ value: "noRating", label: "No rating" },
			{ value: "stRating", label: "Rating S/T" },
			{ value: "iklu", label: "Rating from IKLU" },
			{ value: "criterias", label: "Rating based on criterias" },
		];

		return (
			<App name={`c6-editor c6-ratings ${!!error ? `error-${error.errorField}` : ""} ${this.hideReferences ? "hide-references" : ""}`}>
				<Scroll nopadding={true} inModal={true}>
					<Editor>
						{!!error && (<div className="panel panel-danger errors" />)}
						{this.renderTopActions(error)}
						<ProgramPreview model={metadataProgram} withQCLinkOnImage={true} />
						{metadataProgram?.id && tagsProgram?.id && (
							<div className={`tabs ${readonly ? "readonly" : ""}`} title={readonly ? "Ratings can not be modified while this editor is approved" : ""}>
								{tabs.map(tab => {
									const isActiveTab = tab.value === finnishVersion[localRatingType];
									return (
										<div
											key={tab.value}
											className={`tab ${isActiveTab ? "active" : ""}`}
											onClick={() => {
												if (readonly) {
													return;
												}

												this.setState({ step: 0 });
												Actions.updateRatingType(tab.value);
											}}
										>
											<p className="icon-checkbox-marked-circle-outline">&nbsp;{tab.label}</p>
										</div>
									);
								})}
							</div>
						)}
						{this.getTabContent(finnishVersion[localRatingType], readonly)}
					</Editor>
				</Scroll>
			</App>
		);
	}
}

// HELPERS
function handleCancel(e, location, routes, params) {
	e.preventDefault();
	if (location.state && location.state.modal) {
		browserHistory.goBack();
	} else {
		const returnTo = getReturnTo(location, routes, params);
		browserHistory.replace(returnTo);
	}
}

function validateRatings(finnishVersion) {
	const type = finnishVersion[localRatingType];
	const isApproved = finnishVersion.finnishRatingApproval?.status?.toLowerCase() === "approved";
	if (isApproved) {
		if (!type) {
			return { errorField: "type", errorText: "Please choose a rating type" };
		}
		if (type !== "noRating" && (!finnishVersion[localRatingContactPerson]?.length)) {
			return { errorField: "name", errorText: "Please specify who did this rating" };
		}
	}

	return false;
}