import { get, put, post, del } from '../core/c6'

const API = "acquisitions";

/* **************** CONTRACTS ***************** */
export const fetchContracts      =       filters => (  get(API, "contracts", filters) );
export const fetchContract       =            id => (  get(API, `contracts/${id}`) );
export const updateContract      = (id, payload) => (  put(API, `contracts/${id}`, payload) );
export const createContract      =       payload => ( post(API, "contracts", payload) );
export const deleteContract      =            id => (  del(API, `contracts/${id}`) );
export const matchContracts      =       payload => (  put(API, "match/contracts", payload) );
export const disconnectContracts =       payload => (  put(API, "disconnect/contracts", payload) );

/* **************** LICENSES ***************** */
export const fetchContractLicenses =       filters => ( get(API, "licenses", filters) );
export const fetchLicense          =           id  => ( get(API, `licenses/${id}`) );
export const updateLicense         = (id, payload) => ( put(API, `licenses/${id}`, payload) );

export const createLicense = (payload, contractId) => {
	payload.ContractId = contractId;
	return post(API, "licenses", payload);
};
export const deleteLicense      =      id => ( del(API, `licenses/${id}`) );
export const matchLicenses      = payload => ( put(API, "match/licenses", payload) );
export const disconnectLicenses = payload => ( put(API, "disconnect/licenses", payload) );


/* **************** DISTRIBUTORS ***************** */
export const fetchDistributors = () => ( get(API, "distributors") );

/* **************** PROGRAMS ***************** */
export const fetchPrograms = searchText => (  get(API, "programs", { searchText }) );
export const fetchProgram  =         id => (  get(API, `programs/${id}`) );
export const createProgram =    payload => ( post(API, "programs", payload) );

/* **************** COUNTRIES ***************** */
export const fetchCountries = searchText => ( get(API, "countries", { searchText }) );

/* **************** GENRES ***************** */
export const fetchGenres = searchText => ( get(API, "genres", { searchText }) );