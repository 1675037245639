import { get, put, post, del } from '../core/c6'
import moment from 'moment'
import Qs from 'qs'

const API = "star";
const API_C70 = "star_c70";

/* **************** SEARCH ***************** */
export const fetchSearchItems = filters => get(API, "searchitems", filters);
export const fetchUrl         =     url => get(API, url);

/* **************** CONTAINERS ***************** */
export const fetchContainers            =                            filters => get(API, 'containers', filters);
export const fetchContainer             = (id, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return get(API, `containers/${id}?${params.toString()}`);
};
export const fetchContainerByReference  = (reference, providerId, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("providerId", providerId);
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return get(API, `containers/ref-${reference}?${params.toString()}`);
};
export const fetchContainerByGuid       = (guid, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return get(API, `containers/guid-${guid}?${params.toString()}`);
};
export const fetchChildContainers       =                         parentId =>  get(API, `containers/?parent=${parentId}`); // DEPRECATED: Same as using fetchContainers and providing the parent property in the filter object
export const updateContentSourceMetadata =          (programGuid, payload, workListItemId) => {
    const params = new URLSearchParams();
    // AutoComplete will check if all items in the todo are done and in that case mark the todo as complete
    if (workListItemId) {
        params.set("autoComplete", true);
        params.set("workListItemId", workListItemId);
    }
    return put(API, `containers/${programGuid}/contentSourceMetadata?${params.toString()}`, payload);
};
export const createContainer            =                        (payload) => post(API, "containers", payload);
export const deleteContainers			=				  	(containerIds) =>  put(API, `containers/delete?containerIds=${containerIds.join(",")}`);
export const updateContainer            =           (containerId, payload, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return put(API, `containers/${containerId}?${params.toString()}`, payload);
};
export const trashContainer				=					   containerId =>  put(API, `containers/${containerId}/trash`);
export const untrashContainer			=					   containerId =>  put(API, `containers/${containerId}/untrash`);
export const connectAssetsToContainer   = (containerId, payload, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return post(API, `containers/${containerId}/assets?${params.toString()}`, payload);
};
export const removeAssetsFromContainer  = (containerId, payload, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return put(API, `containers/${containerId}/assets?${params.toString()}`, payload);
};
export const updateAssetPublishFrom     = (containerId, payload, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams(payload);
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    return put(API, `containers/${containerId}/publishFrom/assets?${params.toString()}`);
};
export const approveContainer           = (containerId, isApproved, exposeBlockedContainerAssets) => {
    const params = new URLSearchParams();
    params.set("exposeBlockedContainerAssets", exposeBlockedContainerAssets);
    params.set("approved", isApproved);
    return put(API, `containers/${containerId}/approve?${params.toString()}`);
};
export const copyAssetsFromContainer    = (containerId, sourceProgramGuid) =>  put(API, `containers/${containerId}/copyAssets/${sourceProgramGuid}`);
export const mergeContainer             =    (containerId, newContainerId) =>  put(API, `containers/${containerId}/replace?newContainerId=${newContainerId}`);
export const createSeasonsWithEpisodes	= 			(containerId, payload) => post(API, `containers/${containerId}/seasons?${Qs.stringify(payload)}`);

/* **************** ASSETS ***************** */
export const fetchAssets                =               filters => get(API, 'assets', filters);
export const fetchAsset                 =    (assetId, filters) => get(API, `assets/${assetId}`, filters);
export const fetchAssetByGUID           =    (assetId, filters) => get(API, `assets/guid-${assetId}`, filters);
export const trashAsset					=				assetId => put(API, `assets/${assetId}/trash`);
export const untrashAsset				=				assetId => put(API, `assets/${assetId}/untrash`);
export const deleteAssets               =          	 (assetIds) => put(API, `assets/delete?assetIds=${assetIds.join(",")}`); // Used in OLD Star
export const deleteAsset                =                 asset => put(API, 'assets/delete', [asset.id]); // Used in Star2 TODO!!!!: DEL should NOT be a PUT
export const updateAssetMetadata        =    (assetId, payload) => put(API, `assets/${assetId}/metadata`, payload);
export const updateAssetTransformations =    (assetId, payload) => put(API, `assets/${assetId}/transformations?rotation=${payload.rotation}&brightness=${payload.brightness}&contrast=${payload.contrast}`);
export const updateAssetRights          =    (assetId, payload) => put(API, `assets/${assetId}/rights`, payload);
export const addAssetRights             =    (assetId, payload) => post(API, `assets/${assetId}/rights`, payload);
export const deleteAssetRights			=	 (assetId, payload) => del(API, `assets/${assetId}/rights`, payload);
export const fetchAssetDownloadLink		=	  		  (assetId) => get(API, `assets/${assetId}/downloadlink`);
export const fetchVideoAssetSources		=	 (assetId, filters) => get(API, `assets/${assetId}/play`, filters);
export const fetchVideoAssetSourcesC70	=	  		  (assetId) => get(API_C70, `mediaservices/${assetId}/play?accessPolicy=internal`);

// TODO: Use links in asset object instead for dling
export const downloadResource           =  (assetGuid, filters) => get(API, `assets/${assetGuid}/content`, filters);

/* ************** ALBUMS ****************** */
export const fetchAlbums           = filters              =>  get(API, "albums", filters);
export const fetchAlbum            = id                   =>  get(API, `albums/${id}`);
export const fetchSharedAlbum      = (albumGuid, payload) =>  get(API, `albums/shared/${albumGuid}`, payload, true);
export const addAssetsToAlbum      = (albumId, payload)   => post(API, `albums/${albumId}/assets`, payload);
export const deleteAssetsFromAlbum = (albumId, payload)   =>  del(API, `albums/${albumId}/assets`, null, payload);
export const removeAssetsFromAlbum = (albumId, payload)   =>  put(API, `albums/${albumId}/assets`, payload); // DEPRECATED: Use deleteAssetsFromAlbum instead
export const shareAlbum            = (albumId, payload)   =>  put(API, `albums/${albumId}/share`, payload);
export const createAlbum           = payload              => post(API, "albums", payload);
export const updateAlbum           = (albumId, payload)   =>  put(API, `albums/${albumId}`, payload);
export const deleteAlbum           = ({id})               =>  del(API, `albums/${id}`);

/* ************** ASSET CATEGORIES *********** */
export const fetchCategories          = filters                 =>    get(API, 'categories', filters);
export const fetchCategory            = categoryId              =>    get(API, `categories/${categoryId}`);
export const createCategory           = (name, categoryTypeId)  =>   post(API, `categories?name=${name}&categoryTypeId=${categoryTypeId}`);
export const addCategoryToAsset       = (assetId, categoryId)   =>   post(API, `assets/${assetId}?categoryId=${categoryId}`);
export const removeCategoryFromAsset  = (assetId, categoryId)   =>    del(API, `assets/${assetId}?categoryId=${categoryId}`);

/* **************** CROPS ***************** */
export const fetchCategoryCropTypes     = id                    => 	get(API, `categories/${id}`);
export const fetchAllCropTypes          = ()                    => 	get(API, 'croptypes');
export const fetchCroptypes          	= ()                    => 	get(API, 'croptypes');
export const fetchCroptype 				= ({id}) 				=> 	get(API, `croptypes/${id}`);
export const createCroptype				= payload 				=> post(API, "croptypes", payload);
export const updateCroptype				= ({id}, payload) 		=>	put(API, `croptypes/${id}`, payload);
export const updateAssetCrop            = (assetId, payload)    =>	put(API, `assets/${assetId}/crop`, payload);

/* **************** BRANDING ***************** */
export const fetchBrandings = () => get(API, 'brandings');

/* ************** RIGHTS ******************** */
export const fetchRights = filters => get(API, "rights", filters);

/* *************** REGIONS **************** */
export const fetchRegions = filters => get(API, "regions", filters);

/* *************** DESTINATIONS **************** */
export const fetchDestinations = filters => get(API, "destinations", filters);

/* *************** DELIVERIES **************** */
export const fetchDeliveries = filters => get(API, "deliveries", filters);
export const createDelivery = payload => post(API, "deliveries", payload);

/* ************** CATALOGS ******************* */
export const fetchCatalogs          = filters       =>  get(API, "catalogs", filters);
export const fetchCatalog           = catalogId     =>  get(API, `catalogs/${catalogId}`);
export const fetchCatalogItems      = catalogId     =>  get(API, `catalogs/${catalogId}/items`);

/* ************** TYPES ***************** */
export const fetchTypes = () => get(API, 'containerTypes');

/* ************** FILE FORMATS ***************** */
export const fetchFileformats 	= filters 			=> 	get(API, "fileformats", filters);
export const fetchFileformat 	= ({id}) 			=> 	get(API, `fileformats/${id}`);
export const createFileformat 	= payload 			=> post(API, "fileformats", payload);
export const updateFileformat 	= ({id}, payload) 	=> 	put(API, `fileformats/${id}`, payload);

/* ************** FILE TYPES ***************** */
export const fetchFiletypes	= filters 			=> 	get(API, "files/filetype", filters);
export const fetchFiletype	= ({id}) 			=> 	get(API, `files/filetype/${id}`);
export const createFiletype	= payload 			=> post(API, "files/filetype", payload);
export const updateFiletype	= ({id}, payload) 	=> 	put(API, `files/filetype/${id}`, payload);

/* ************** FILES ***************** */
export const fetchFiles = filters => get(API, "files", filters);

/* ************** TAGS ***************** */
export const fetchTags				= filters 					=> 	get(API, "tags", filters);
export const addTagToAsset 			= (assetId, payload) 		=> post(API, `assets/${assetId}/addTag${payload.ordinal !== undefined ? `?ordinal=${payload.ordinal}` : ""}`, payload);
export const removeTagFromAsset		= (assetId, payload) 		=> 	del(API, `assets/${assetId}/removeTag/${payload.tagId}`);
export const moveTagOnAsset			= (assetId, payload) 		=> post(API, `assets/${assetId}/moveTag/${payload.tagId}?ordinal=${payload.ordinal}`);
export const addTagToContainer		= (containerId, payload) 	=> post(API, `containers/${containerId}/addTag${payload.ordinal !== undefined ? `?ordinal=${payload.ordinal}` : ""}`, payload);
export const removeTagFromContainer	= (containerId, payload) 	=>  del(API, `containers/${containerId}/removeTag/${payload.tagId}`);
export const moveTagOnContainer		= (containerId, payload) 	=> post(API, `containers/${containerId}/moveTag/${payload.tagId}?ordinal=${payload.ordinal}`);