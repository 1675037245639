import React from 'react'
import PropTypes from 'prop-types'

import { formatBytes } from '../../../utils/misc'

import Actions from './actions'
import AssetActions from './assetActions'
import AssetCropper from './assetCropper'

import Store from './store'

export default class ImageAsset extends React.Component {
	static propTypes = {
		asset: PropTypes.shape({
			name: PropTypes.string.isRequired,
			links: PropTypes.arrayOf(PropTypes.shape({
				href: PropTypes.string.isRequired,
				rel: PropTypes.string,
			})),
			files: PropTypes.arrayOf(PropTypes.shape({
				width: PropTypes.number.isRequired,
				height: PropTypes.number.isRequired,
			}))
		}),
		forceRefreshImage: PropTypes.bool,
		assetCategoryId: PropTypes.number,
		disabled: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);

		// Fetch specific category crop types if we're opening the editor from an asset category area
		// or fetch all crop types if this for example is an asset in STAR.
		const { assetCategoryId } = this.props;

		if(assetCategoryId) {
			Actions.fetchCategoryCropTypes.defer(assetCategoryId);
		}
		else {
			Actions.fetchAllCropTypes.defer();
		}
		Actions.fetchBrandings.defer();
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	render() {
		const { asset, forceRefreshImage, disabled } = this.props;
		const { assetCrops, activeCrop, isCropping, transformations, cropHasChanged } = this.state;

		// const modifiedCropsIds = assetCrops.map(assetCrop => assetCrop.id);

		const orgFile = asset.files && asset.files.find(f => f.fileFormatId === 0 && (f.status === 'Ok' || f.status === 'Undefined'));
		const original = {
			width: orgFile.width,
			height: orgFile.height,
			size: formatBytes(orgFile.size, 0),
			fileName: orgFile.name
		};

		return (
			<div className="c6-asset c6-asset-image">
				<AssetActions
					asset={asset}
					assetCrops={assetCrops}
					activeCrop={activeCrop}
					isCropping={isCropping}
					disabled={disabled}
					transformations={transformations}
					cropHasChanged={cropHasChanged}
				>
					{renderCropInfo(activeCrop, original)}
				</AssetActions>
				<AssetCropper
					asset={asset}
					activeCrop={activeCrop}
					orgFile={orgFile}
					forceRefreshImage={forceRefreshImage}
					disabled={disabled}
					transformations={transformations}
				/>
			</div>
		);
	}
}


function renderCropInfo(crop, original) {
	if(!crop.id) {
		return null;
	}

	const { displayName, name, minHeight, minWidth, cropData } = crop;
	const imageSmallerThanCropMinimum = crop && (original.height < minHeight || original.width < minWidth);
	const imageEqualToCropMinimum = crop && (cropData.height === minHeight || cropData.width == minWidth);

	const constraint = minHeight || minWidth ? ` (min crop ${minWidth}x${minHeight})` : "";
	const cropDimensions = !imageSmallerThanCropMinimum && cropData && cropData.width > 0 && cropData.height > 0 ? `${cropData.width}×${cropData.height} px ${imageEqualToCropMinimum ? constraint : ""}` : "";

	const tooSmall = imageSmallerThanCropMinimum ? " Image is too small" + constraint : "";

	return (
		<React.Fragment>
			<span style={{ display: "inline-block" }}>{displayName || name}&nbsp;-&nbsp;</span>
			<span style={{ display: "inline-block" }}>{tooSmall || cropDimensions}</span>
		</React.Fragment>
	);
}