import React from 'react'
import PropTypes from 'prop-types'
import './switch.css'

import debounce from 'lodash/debounce'

export default class Switch extends React.Component {
	static propTypes = {
		states: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		currentState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		title: PropTypes.string,
		renderDescription: PropTypes.bool,
		disabled: PropTypes.bool,
		readOnly: PropTypes.bool,
		unselected: PropTypes.bool,
		name: PropTypes.string,
		value: PropTypes.string,
		label: PropTypes.string,
		triggerOnChangeWhenCurrentValueIsClicked: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.debouncedChange = props.onChange
			? debounce(props.onChange, 200, { leading: true })
			: () => { alert("onChange handler missing from parent component!")};

		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);

		this.state = {
			hoverDescription: null,
			hoverOverDisabled: false,
		}
	}

	handleMouseEnter(description, disabledDescription, disabled) {
		if(this.props.renderDescription && !this.props.readOnly) {
			this.setState({
				hoverDescription: disabled ? disabledDescription : description,
				hoverOverDisabled: disabled,
			});
		}
	}

	handleMouseLeave() {
		if(this.props.renderDescription && !this.props.readOnly) {
			this.setState({
				hoverDescription: null,
				hoverOverDisabled: false,
			});
		}
	}

	handleChange = e => {
		e.persist();
		this.debouncedChange(e);
	}

	handleClick = e => {
		if (this.props.triggerOnChangeWhenCurrentValueIsClicked && e.target.value === this.props.currentState) {
			this.handleChange(e);
		}
	}

	render() {
		const {
			states = [],
			currentState = "",
			title = "",
			disabled = false,
			readOnly = false,
			unselected = false,
			name = "status",
			value = "key",
			label = "text",
			renderDescription = false,
			// children,
		} = this.props;

		if(!states.length) {
			return null;
		}

		const { hoverDescription, hoverOverDisabled } = this.state;
		let description = null;

		const labels = states.map((s, index) => {
			if(!s) {
				console.warn("Switch component got undefined State in %o", states);
				return null;
			}
			const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
			const subSwitch = children?.find(child => child?.props?.whenParentState === s[value] && currentState === s[value]);
			const textLabel = s[label] ? <span>{s[label]}</span> : null;
			const checked = currentState === s[value];

			if(renderDescription && (checked || hoverDescription)) {
				const className = `c6-switch-description${hoverDescription ? " sel" : ""}${hoverOverDisabled ? " c6-text-unapproved" : ""}`;
				description = <div className={className}>{hoverDescription || s.description}</div>;
			}

			const labelClassName = `${s.className}${checked ? " sel" : ""}${s.disabled || (readOnly && !checked) ? " dimmed" : ""}${readOnly ? " readOnly" : ""}${subSwitch ? " has-sub" : ""}`;
			const title = !renderDescription ? s.description : null;

			return [
				<label
					key={index}
					title={title}
					className={labelClassName}
					onMouseEnter={this.handleMouseEnter.bind(this, s.description, s.disabledDescription, s.disabled)}
					onMouseLeave={this.handleMouseLeave}>
					<input
						disabled={disabled || s.disabled || readOnly}
						type="radio"
						name={name}
						checked={checked}
						value={s[value]}
						onClick={this.handleClick}
						onChange={this.handleChange} />
					{textLabel}
				</label>,
				subSwitch
			];
		});

		const classes = unselected ? "c6-switch isUnselected" : "c6-switch";
		const heading = title ? <span>{title}</span> : null;

		return (
			<div className={classes} disabled={disabled}>
				{heading}
				{labels}
				{description}
			</div>
		);
	}
}