import React from 'react'
import PropTypes from 'prop-types'
import Spinner from './spinner'

class Image extends React.Component {
	static propTypes = {
		src: PropTypes.string,
		alt: PropTypes.string,
		onClick: PropTypes.func,
		onLoad: PropTypes.func,
		onFail: PropTypes.func,
		minHeight: PropTypes.string,
		minWidth: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.state = {
			status: undefined,
			loaded: false,
			loadedOnce: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.src !== nextProps.src) {
			this.setState({
				loaded: false,
			});
		}
	}

	handleImageLoaded = (e) => {
		if (this.props.onLoad) {
			this.props.onLoad(e);
		}
		this.setState({
			loaded: true,
			loadedOnce: true,
		});
	}

	handleImageErrored = () => {
		this.setState({ status: "No preview available" });
		if (this.props.onFail) {
			this.props.onFail();
		}
	}

	handleClick = (e) => {
		e.stopPropagation();
		typeof(this.props.onClick) === "function" && this.props.onClick();
	}

	render() {
		const {
			loaded,
			loadedOnce,
			status,
		} = this.state;
		const {
			minWidth,
			minHeight,
			alt,
			src,
			assetTransformations,
			aspectRatio,
			hideImageWhileSpinnerIsShown = false,
		} = this.props;

		const spinner = loaded || status ? null : <Spinner loading /> ;
		const statusEl = status
			? (
				<span style={{
					textAlign: 'center',
					padding: '20px',
					fontSize: '14px',
				}}>
					{status}
				</span>
			)
			: null;

		let classNames = 'c6-img'; // TODO: There is already a c6-image in use, merge these?
		if (status) { classNames += ' has-failed'; }

		const failedStyle = status
			? { display: 'none' }
			: {};

		let containerStyle = {
			transform: loaded && assetTransformations ? `rotate(${assetTransformations.rotation}deg)` : null,
			writingMode: loaded && assetTransformations && assetTransformations.rotation % 180 !== 0 ? "vertical-lr" : null,
			minWidth,
			minHeight,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		};

		const cssBrightness = assetTransformations ? (assetTransformations.brightness + 100) / 100 : null;
		const cssContrast = assetTransformations ? (assetTransformations.contrast + 100) / 100 : null;
		let filter = cssBrightness !== null && `brightness(${cssBrightness}) ` || "";
		filter += cssContrast !== null && `contrast(${cssContrast}) ` || "";
		const imgStyle = {
			...failedStyle,
			filter,
			aspectRatio,
			visibility: hideImageWhileSpinnerIsShown && loadedOnce && !loaded ? "hidden" : null,
		};

		return (
			<div style={containerStyle} className={classNames} onClick={this.handleClick}>
				<img
					alt={alt}
					src={src}
					onLoad={this.handleImageLoaded}
					onError={this.handleImageErrored}
					style={imgStyle}
					decoding="async"
				/>
				{spinner}
				{statusEl}
			</div>
		);
	}
}
export default Image;
