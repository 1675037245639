import { downloadVideo } from "./utils";

// Based on https://github.com/Azure-Samples/media-services-javascript-azure-media-player-zoom-plugin/blob/master/amp-zoom.js
const playersAlreadyHandled = [];

export function addButtons() {
	return (function (mp) {
		mp.plugin("c6-custom-buttons", function({ endCreditTimestamp, assetGuid, hideDL, hideEC }) {
			const player = this;
			
			const func = function() {
				// I somehow managed to end up with 2 of each button but that might have been because of a HMR issue
				// This just makes sure that we don't add the buttons more than once for each player
				if (playersAlreadyHandled.includes(player.id())) {
					return;
				}
				playersAlreadyHandled.push(player.id());
				
				const controlBar = player.controlBar.el();
				let childNodeCounter = 0;

				// Download resource
				if (!hideDL) {
					const download = createButton({
						onClick: e => downloadVideo(assetGuid),
						innerText: "DL",
						title: "Download this video.",
					});
					controlBar.insertBefore(download, controlBar.childNodes[childNodeCounter++]);
				}

				// -10s
				const minus10 = createButton({
					onClick: e => player.currentTime(player.currentTime() - 10),
					innerText: "-10s",
				});
				controlBar.insertBefore(minus10, controlBar.childNodes[childNodeCounter++]);

				// +10s
				const plus10 = createButton({
					onClick: e => player.currentTime(player.currentTime() + 10),
					innerText: "+10s",
				});
				controlBar.insertBefore(plus10, controlBar.childNodes[childNodeCounter++]);

				// Jump to endCreditTimestamp
				if (!hideEC) {
					const endCredit = createButton({
						onClick: e => player.currentTime(getTansformedTimestamp(endCreditTimestamp)),
						innerText: "EC",
						disabled: !endCreditTimestamp,
						title: endCreditTimestamp ? `Jump to the end credit timestamp (${endCreditTimestamp}).` : null,
					});
					controlBar.insertBefore(endCredit, controlBar.childNodes[childNodeCounter++]);
				}
			};

			player.addEventListener("loadeddata", func);
		});
	}(window.amp));
}

function createButton({ className, onClick, innerText, disabled, title }) {
	const btn = document.createElement("div");
	btn.className = `amp-c6-custom-button vjs-control ${className}`;
	if (title) {
		btn.setAttribute("title", title);
	}
	if (disabled) {
		btn.setAttribute("disabled", "disabled");
	} else {
		btn.removeAttribute("disabled");
	}
	btn.addEventListener("click", onClick);

	const inner = document.createElement("div");
	inner.className = "amp-c6-custom-inner";
	inner.innerText = innerText;

	btn.appendChild(inner);
	return btn;
}

// 00:10:05.33 => 605.33
function getTansformedTimestamp(timestamp) {
	if (!timestamp || !timestamp.length)
		return 0;
	
	const [hours, minutes, seconds] = timestamp.split(":").map(str => parseInt(str));
	return hours * 60 + minutes * 60 + seconds;
}
