import React from 'react'

import LazyLoad from '../components/lazyload'
import appConfig from 'config'

const BASE_URL = appConfig.media.star;
const LAZYLOAD_OFFSET_VERTICAL = 300;

// URLS
export function getImageSrc(guid, size) {
	const baseUrl = guid ? `${BASE_URL}${guid}` : null;
	const props = getImageProps(baseUrl, size, null, null, null, "");

	return props.src;
}

// IMAGES
export function getImageGuid(assets) {
	const imageAsset = assets.filter(asset => asset.type === "Image")[0]; // TODO: Maybe look for a primary image to start with?
	return imageAsset && imageAsset.guid || null;
}

export function getImage(guid, size, onClick, extension, displayMessageIfImgFailed) {
	const baseUrl = guid ? `${BASE_URL}${guid}` : null;
	return getImageElement(guid, baseUrl, size, null, onClick, null, "", extension, displayMessageIfImgFailed);
}

export function getPrimary(guid, size, onClick) {
	const baseUrl = guid ? `${BASE_URL}${guid}` : null;
	return getImageElement(guid, baseUrl, size, null, onClick);
}

export function getPrimaryProgram(programId, size, onClick) {
	const baseUrl = programId ? `${BASE_URL}program/${programId}` : null;
	return getImageElement(programId, baseUrl, size, null, onClick);
}

export function getCoverProgram(programId, type, size) {
	const baseUrl = programId ? `${BASE_URL}program/${programId}/cover` : null;
	return getImageElement(programId, baseUrl, size, type, null, null);
}

export function getPuffCover(guid, type, size = "puffCover") {
	const baseUrl = guid ? `${BASE_URL}${guid}` : null;
	return getImageElement(guid, baseUrl, size, type);
}

export function getVideoPrimary(guid, size, onClick) {
	const baseUrl = guid ? `${BASE_URL}${guid}` : null;
	return getImageElement(guid, baseUrl, size, null, onClick, "MiniThumb.img");
}

// VIDEOS
export function getVideoGuid(assets) {
	const videoAsset = assets.filter(asset => asset.type === "Video")[0]; // TODO: Maybe look for a primary video to start with?
	return videoAsset && videoAsset.guid || null;
}

export function getVideo(guid, imageUrl, size) {
	const { width, height } = getImageFormat(size);

	// HACK: Azure only populates some of the image formats so we need to hard code the video preview image #shame
	if(!imageUrl) {
		imageUrl = `${BASE_URL}${guid}/MiniThumb.img`;
	}

	if(!appConfig.media.playVideoURL) {
		console.error("No media.playVideoURL in app.config.");
	}

	const url = guid && appConfig.media.playVideoURL ? appConfig.media.playVideoURL.replace("${guid}", guid) : null;
	const component = url ? (
		<LazyLoad height={height} offsetVertical={LAZYLOAD_OFFSET_VERTICAL} debounce={false} className="c6-preview c6-preview-video">
			<video onClick={playPause} poster={imageUrl} controls="" preload="none" width={width} height={height} >
				<source src={url} type="video/mp4" />
			</video>
		</LazyLoad>
	) : null;

	return {
		url,
		component,
	}
}

// PLACEHOLDER
export function getPlaceholder(size, className) {
	const { mode = "" } = getImageFormat(size);
	return <div className={`c6-preview ${mode} ${className}`}></div>
}

// HELPERS
function getImageProps(baseUrl, size, type, onClick, overrideFormat, imageCategory = "main", extension) {
	const format = getImageFormat(size, imageCategory, extension);
	const { width, height } = format;
	const { url, srcset } = getSources(baseUrl, format, overrideFormat);

	let imageProps = {
		src: url,
		srcSet: srcset,
		width: width,
		height: ["LiveSport", "SportClipHighlights", "SportClipSummary"].includes(type) ? 28 : height,
		alt: "",
		decoding: "async",
	}

	if(typeof(onClick) === "function") {
		imageProps.onClick = onClick;
	}

	return imageProps;
}

function getImageElement(reference, baseUrl, size, type, onClick, overrideFormat, imageCategory = "main", extension, displayMessageIfImgFailed) {
	const format = getImageFormat(size, imageCategory, extension);
	const { width, height, mode } = format;
	const imageProps = getImageProps(baseUrl, size, type, onClick, overrideFormat, imageCategory, extension);

	const component = imageProps.src
		? (
			<LazyLoad
				width={width}
				height={height}
				offsetVertical={LAZYLOAD_OFFSET_VERTICAL}
				debounce={false}
				className={`c6-preview ${mode}`}
				displayMessageIfImgFailed={displayMessageIfImgFailed}
			>
				<img draggable="false" data-reference={reference} {...imageProps} />
			</LazyLoad>
		)
		: (<div title="Needs image!" data-reference={reference} className={`c6-preview ${mode || ""} is-visible icon-error large-icon`} />);

	return {
		url: imageProps.src,
		component,
	}
}

function getSources(baseUrl, format, overrideFormat = false) {
	return {
		url: baseUrl ? `${baseUrl}/${overrideFormat || format.key}` : null,
		// srcset: baseUrl ? `${baseUrl}/${overrideFormat || format.key} ${format.width}w, ${baseUrl}/${overrideFormat || format.key2x} ${2 * format.width}w` : null,
		srcset: baseUrl ? `${baseUrl}/${overrideFormat || format.key2x} 2x` : null,
	}
}

function playPause(event) {
	const video = event.target;
	if(video.paused) {
		video.play();
	} else {
		video.pause();
	}
}

function getImageFormat(size, imageCategory = "main", extension) {
	extension = extension && extension.toLowerCase() || "jpg";
	const { name = size, width, height } = size;
	const category = imageCategory ? `${imageCategory}/` : "";

	switch(name) {
		case "small":
			return {
				key: `${category}Comet_16x9_100x56.${extension}`,
				key2x: `${category}Comet_16x9_200x112.${extension}`,
				width: 100,
				height: 56,
			};

		case "medium":
			return {
				key: `${category}Comet_16x9_200x112.${extension}`,
				key2x: `${category}Comet_16x9_400x224.${extension}`,
				width: 200,
				height: 112,
				mode: "thumbnail"
			};

		case "smallStarAsset":
			return {
				key: `Star_16x9_100x100.${extension}`, //11.jpg
				key2x: `Star_16x9_200x.${extension}`, //36.jpg
				width: 100,
				height: 56,
			};

		// TODO: Get Star_None_x200 and Star_None_x400 formats
		case "starAsset":
			return {
				key: `Star_None_x300.${extension}`, // 6.jpg
				key2x: `Star_None_x600.${extension}`, // 9.jpg
				width,
				height,
			};

		/* Used in Live > Metadata for team logos */
		case "smallLogoPng":
			return {
				key: "Star_1x1_200x.png",
				key2x: "Star_1x1_400x.png",
				width: 50,
				height: 50,
			};

		/* Used in Live > Metadata for primary images */
		// case "smallPng":
		// 	return {
		// 		key: "Comet_16x9_100x56.png",
		// 		key2x: "Comet_16x9_200x112.png",
		// 		width: 100,
		// 		height: 56,
		// 	};

		/* Used in Live > Metadata for cover images */
		case "smallCoverPng":
			return {
				key: "Star_7x10_200x.png",
				key2x: "Star_7x10_400x.png",
				width: 40,
				height: 56,
			};

		case "smallCover":
			return {
				key: `Star_7x10_100x100.${extension}`,
				key2x: `Star_7x10_200x.${extension}`,
				width: 40,
				height: 56,
				mode: "cover",
			};
		
		case "largeCover":
			return {
				key: `Star_7x10_200x.${extension}`,
				key2x: `Star_7x10_400x.${extension}`,
				// width: 80,
				// height: 112,
				mode: "cover",
			};

		default:
			console.error("No image format defined in assets.js/getImageFormat() for size %s", size);
			return {
				key: null,
				key2x: null,
				width: 100,
				height: 56,
			};
	}
}