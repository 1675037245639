import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import AzureMediaPlayer from '../../../components/amp'
import Button from '../../../components/ui/controls/button'

import * as StarAPI from '../../../apis/star'
import * as Alert from '../../../core/services/alert'
import { download } from './assetActions'

import appConfig from 'config'
import Const, { AllRegions } from '../../../core/constants'
import { downloadFile } from '../../amp/utils'
import { hasAccessToPath, hasRole } from '../../../core/services/auth'

const SUBS_FORMATS = ["TTML", "SMI", "VTT", "SRT"];
const DESTINATION_FORMAT_STORAGE_KEY = "c6-last-used-destination";
const SUBS_DOWNLOAD_FORMAT_STORAGE_KEY = "c6-last-used-subs-format";

class VideoAsset extends React.Component {
	state = {
		sources: [],
		destinations: null,
		deliveries: null,
		lastUsedSubsOption: localStorage.getItem(SUBS_DOWNLOAD_FORMAT_STORAGE_KEY) ?? "",
		lastUsedDestination: localStorage.getItem(DESTINATION_FORMAT_STORAGE_KEY) ?? "",
	};
	fetchDeliveriesTimeout = null;
	canOrderMezz = false;

	async componentDidMount() {
		this.canOrderMezz = hasRole("metadata.media-download")
			|| hasAccessToPath([{ module: "metadata" }], "admin")
			|| hasAccessToPath([{ module: "star" }], "admin");

		const sources = await getSources(this.props.asset);
		this.setState({ sources });

		if (appConfig.features.extendedMezzSupport && this.props.asset.type === "Video") {
			this.fetchDestinations();
			this.fetchDeliveries();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.fetchDeliveriesTimeout);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.asset?.id !== this.props.asset?.id
			|| nextState.sources?.length !== this.state.sources?.length
			|| nextState.destinations?.length !== this.state.destinations?.length
			|| nextState.deliveries?.length
			|| nextState.destinations !== null && this.state.destinations === null
			|| nextState.deliveries !== null && this.state.deliveries === null;
	}

	fetchDestinations = async () => {
		try {
			const destinationsReq = StarAPI.fetchDestinations();
			const destinations = (await destinationsReq)?.items ?? [];
			this.setState({ destinations });
		} catch {
			this.setState({ destinations: [] });
		}
	}

	fetchDeliveries = async () => {
		try {
			const deliveriesReq = StarAPI.fetchDeliveries({ assetId: this.props.asset.id });
			const deliveries = (await deliveriesReq)?.items ?? [];
			this.setState({ deliveries });
		} catch {
			this.setState({ deliveries: [] });
		} finally {
			this.fetchDeliveriesTimeout = setTimeout(this.fetchDeliveries, 15 * 1000);
		}
	}

	createDelivery = async (destination, subsFormat) => {
		const mezzFileId = this.props.asset.files.find(f => f.fileFormatId === 0 && f.status === "Ok").id;
		let options;
		if (!subsFormat) {
			options = {
				fileIds: [mezzFileId],
			};
		} else {
			options = {
				subtitleFormat: subsFormat,
			};
		}
		try {
			const lastUsedDestination = `${destination.id}:${subsFormat}`;
			localStorage.setItem(DESTINATION_FORMAT_STORAGE_KEY, lastUsedDestination);
			const newDelivery = await StarAPI.createDelivery({
				destinationId: destination.id,
				assetId: this.props.asset.id,
				options,
			});
			Alert.displayAlert("success", "Your delivery order has been queued!");
			this.setState(state => ({
				deliveries: [...state.deliveries, newDelivery],
				lastUsedDestination,
			}));
		} catch (e) {
			Alert.displayAlert("error", e.exceptionMessage);
			console.error(e);
		}
	}

	renderMezzButton = () => {
		const { asset } = this.props;
		const { destinations, deliveries } = this.state;

		const subtitleFiles = asset.files
			.filter(a => a.assetType === "Subtitle" && a.status === "Ok")
			.sort((fA, fB) => fA.versionId - fB.versionId);
		const hasSubs = !!subtitleFiles.length;

		let title, type, onClick, disabled, hoverTitle, options;

		const [destinationId, _format] = this.state.lastUsedDestination.split(":");
		const destination = destinationId && destinations.find(d => d.id === Number(destinationId));
		if (destination) {
			const format = hasSubs && _format ? SUBS_FORMATS.find(f => f === _format) : null;
			title = `Mezz: ${destination.displayName}`;
			if (hasSubs) {
				title += format ? ` + ${format}` : " (no subs)";
			}
			type = "download";
			onClick = () => this.createDelivery(destination, format);
			const destinationDeliveries = deliveries.filter(d => d.destinationId === destination.id);
			disabled = shouldDisableDestination(destinationDeliveries);
			hoverTitle = getDestinationHoverTitle(destinationDeliveries, disabled);
		} else {
			title = "Delivery destination";
		}

		options = destinations.reduce((opts, destination) => {
			const destinationDeliveries = deliveries.filter(d => d.destinationId === destination.id);
			const disabled = shouldDisableDestination(destinationDeliveries);
			const hoverTitle = getDestinationHoverTitle(destinationDeliveries, disabled);
			const destinationOptions = getDestinationOptions(destination, this.createDelivery, disabled, hoverTitle, hasSubs);
			return [
				...opts,
				...destinationOptions,
			];
		}, []);

		if (!this.canOrderMezz) {
			hoverTitle = "Your account does not have the required access level to order deliveries of Mezzanine files.";
		}

		return (
			<Button
				title={title}
				type={type}
				onClick={onClick}
				options={this.canOrderMezz ? options : null}
				disabled={!this.canOrderMezz || disabled}
				hoverTitle={hoverTitle}
			/>
		);
	}

	renderSubsButton = () => {
		const { asset } = this.props;

		const subtitleFiles = asset.files
			.filter(a => a.assetType === "Subtitle" && a.status === "Ok")
			.sort((fA, fB) => fA.versionId - fB.versionId);
		
		if (!subtitleFiles.length) {
			return null;
		}
		
		const options = subtitleFiles.reduce((opts, sub) => {
			const lang = AllRegions.find(r => r.id === sub.versionId)?.languageDisplayName ?? "";
			const subsOptions = SUBS_FORMATS.map(f => ({
				key: `${sub.versionId}-${f}`,
				text: `${lang} - ${f}${f === sub.fileTypeName ? " (original)" : ""}`,
				onClick: () => {
					const lastUsedSubsOption = `${lang}:${f}`;
					localStorage.setItem(SUBS_DOWNLOAD_FORMAT_STORAGE_KEY, lastUsedSubsOption);
					downloadSubs(asset, sub, f);
					this.setState({ lastUsedSubsOption });
				},
			}));
			return [
				...opts,
				...subsOptions,
			];
		}, []);

		let title, type, onClick;
		const [lang, format] = this.state.lastUsedSubsOption.split(":");
		const version = lang && AllRegions.find(r => r.languageDisplayName === lang);
		const sub = version && subtitleFiles.find(f => f.versionId === version.id);
		if (version && sub && format) {
			title = `Subs: ${version.languageDisplayName} - ${format}`;
			type = "download";
			onClick = () => downloadSubs(asset, sub, format);
		} else {
			title = "Subtitle format";
			type = null;
			onClick = null;
		}

		return (
			<Button
				title={title}
				type={type}
				onClick={onClick}
				options={options}
			/>
		);
	}

	renderButtons = () => {
		const { asset } = this.props;
		const { destinations, deliveries } = this.state;
		if (
			appConfig.features.extendedMezzSupport
			&& asset.type === "Video"
			&& destinations !== null
			&& deliveries !== null
		) {
			return (
				<div className="download">
					{this.renderSubsButton()}
					{this.renderMezzButton()}
				</div>
			);
		}

		if (!appConfig.features.extendedMezzSupport || asset.type !== "Video") {
			return (
				<Button
					title="Download"
					className="download"
					onClick={() => download(this.props.asset, false, null)}
					type="download"
				/>
			);
		}

		return null;
	}

	render() {
		return (
			<div className="c6-asset c6-asset-video-v2">
				<AzureMediaPlayer
					sources={this.state.sources}
					asset={this.props.asset}
				/>
				{this.renderButtons()}
			</div>
		);
	}
};

VideoAsset.propTypes = {
	asset: PropTypes.shape({
		// id: PropTypes.number,
		assetGuid: PropTypes.string,
	}).isRequired,
};

export default VideoAsset;

function getMimeType(files = []) {
	const file = files.find(f => f.fileFormatId === 0) ?? files[0];
	return file?.mimeType ?? "video/mp4";
}

export async function getSources(asset) {
	const { type: _type, assetGuid, links, files } = asset;
	const type = _type?.toLowerCase();
	const enableAdvancedSourcesLookupForVideoAssets = appConfig.features.starDAMFeatures
		|| appConfig.features.extendedMezzSupport
		|| appConfig.features.playVideoFromAzureMediaServices;
	
	if (type === "trailer" || type === "clip" || (type === "video" && enableAdvancedSourcesLookupForVideoAssets)) {
		try {
			if (appConfig.api.star_c70 && appConfig.features.playVideoFromAzureMediaServices) {
				const response = await StarAPI.fetchVideoAssetSourcesC70(assetGuid);

				let sources;
				const allHasSameUrl = response?.items?.every(i => i.url === response.items[0].url);
				if (allHasSameUrl) {
					const protectionInfo = response.items.map(({ token, encryption }) => ({
						authenticationToken: `Bearer ${token}`,
						type: encryption,
					}));
					sources = [{
						src: response.items[0].url,
						protectionInfo,
					}];
				} else {
					sources = response?.items?.map(({ url, token, type, encryption }) => ({
						src: url,
						protectionInfo: [{
							authenticationToken: `Bearer ${token}`,
							type: encryption.split(",").shift(),
						}],
					}));
				}
				return sources;
			} else {
				const filters = {
					assetType: appConfig.features.videoSourcesLookupAssetType,
				};
				const response = await StarAPI.fetchVideoAssetSources(assetGuid, filters);
				return response?.items?.pop()?.urls.map(url => ({
					src: url,
					type: getMimeType(files),
				}));
			}
		} catch (e) {
			if (e.message?.includes("Could not find a playable file with format 0")) {
				Alert.displayAlert("warning", "File is being processed to a playable format. Please try again later!");
			} else if (e.message?.includes("does not have permission")) {
				Alert.displayAlert("error", e.message);
			} else {
				console.error("Error from Star C70:", e);
				Alert.displayAlert("error", "Could not find a source to play this video.");
			}

			return [];
		}
	}
	
	const downloadLink = await StarAPI.fetchAssetDownloadLink(assetGuid);
	return [{
		src: downloadLink,
		type: getMimeType(files),
	}];
}

async function downloadSubs(asset, sub, format) {
	await downloadFile(
		asset.assetGuid,
		sub.id,
		format === sub.fileTypeName ? null : format,
	);
}

function shouldDisableDestination(destinationDeliveries) {
	const latestDelivery = destinationDeliveries[destinationDeliveries.length - 1];
	return ["Processing", "Queued", "New"].includes(latestDelivery?.status);
}

function getDestinationHoverTitle(destinationDeliveries, disabled) {
	let hoverTitle = destinationDeliveries.map((d, i) => {
		return `Delivery ${i+1} - ${d.status} ${moment(d.delivered ?? d.modified).format(Const.DATE_TIME_FORMAT)}\n`
			+ `Ordered by ${d.createdByUser}\n`
			+ `Mezz ${d.options?.subtitleFormat ? "+ " + d.options.subtitleFormat : "(no subs)"}`;
	}).join("\n\n").trim();
	if (disabled) {
		hoverTitle += "\n\nCannot order new delivery to this destination right now, wait for existing delivery to finish processing.";
	}
	return hoverTitle;
}

function getDestinationOptions(destination, createDelivery, disabled, hoverTitle, hasSubs) {
	const options = [
		{
			key: `${destination.id}-no-subs`,
			text: `${destination.displayName}${hasSubs ? " (no subs)" : ""}`,
			hoverTitle,
			disabled,
			onClick: () => {
				if (!disabled) {
					createDelivery(destination);
				}
			},
			isLoading: disabled,
		},
	];
	if (hasSubs) {
		options.push(...SUBS_FORMATS.map(format => ({
			key: `${destination.id}-${format}`,
			text: `${destination.displayName} (+ ${format})`,
			hoverTitle,
			disabled,
			onClick: () => {
				if (!disabled) {
					createDelivery(destination, format);
				}
			},
			isLoading: disabled,
		})));
	}
	return options;
}