import cloneDeep from 'lodash/cloneDeep'
import alt from '../../core/services/alt'
import moment from 'moment'

import Actions from './actions'
import EditorActions from '../../components/editor/actions'
import RatingsEditorActions from '../metadata/ratingseditor/actions'
import { localeCompareSortNumeric } from '../../core/services/sorting'

class OneStopDropStore {

	constructor() {
		// this.isLoading = false;
		this.isLoadingNumber = 0;
		this.nextPageUrl = null;
		this.numberOfItems = 0;
		this.latestRequestTime = null;

		this.item = {
			todo: {},
			worklist: {},
			container: {},
			parentContainer: {},
			program: {},
			parentProgram: {},
		};

		this.todoPartsLoading = {
			todo: false,
			container: false,
			childContainers: false,
			comments: false,
			program: false,
			// childPrograms: false,
		};

		this.list = {};
		this.initStore("worklists");
		this.initStore("todos");
		this.initStore("childContainers");
		this.initStore("comments");
		this.initStore("childPrograms");

		this.filters = {
			searchText: "",
			filter: {
				status: "unhandled,inprogress",
				workType: "",
			},
		};
		this._prevFilter = null;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			onFilter: Actions.FILTER,
			onSearch: Actions.SEARCH,

			onFetchItems: Actions.FETCH_ITEMS,
			onPageItems: Actions.PAGE_ITEMS,
			onItemsUpdated: Actions.ITEMS_UPDATED,
			onFetchItem: Actions.FETCH_ITEM,
			onItemLoaded: [Actions.ITEM_LOADED, Actions.ITEM_UPDATED],

			onChildContainerUpdated: Actions.CHILD_CONTAINER_UPDATED,
			onFetchTodo: Actions.FETCH_TODO,
			onStartEditingComment: Actions.START_EDITING_COMMENT,
			onStopEditingComment: Actions.STOP_EDITING_COMMENT,
			onUnmountTodo: Actions.UNMOUNT_TODO,

			onUnmount: Actions.UNMOUNT,

			// External component actions go here
			onEditorSaved: EditorActions.MODEL_SAVED,
			onContentSourceMetadataContainerLoaded: RatingsEditorActions.CONTENT_SOURCE_METADATA_CONTAINER_LOADED,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
		this.isLoadingNumber = 0;
	}

	/* Filters */
	onSearch({ searchText, targetStore }) {
		if (searchText === '') {
			this.filters.filter = this._prevFilter;
		} else {
			if (!this._prevFilter) {
				this._prevFilter = cloneDeep(this.filters.filter);
			}
			this.filters.filter = {};
		}
		this.filters.searchText = searchText;
		this.list[targetStore].filters = this.filters.filter;
		this.list[targetStore].items = [];
	}

	onFilter(payload) {
		const filterParams = Object.keys(this.filters.filter).length
			? this.filters.filter
			: this._prevFilter;

		const { targetStore, ...filterPayload } = payload;

		this.filters.filter = this.list[targetStore].filters = {
			...filterParams,
			...filterPayload,
		};

		this.filters.searchText = "";
		this._prevFilter = null;
		this.list[targetStore].filters = this.filters.filter;
		this.list[targetStore].items = [];
	}


	/* Items */
	onFetchItems({ payload, store, requestTime }) {
		this.isLoading = true;
		const l = this.list[store];

		l.nextPageUrl = null;
		l.numberOfItems = 0;
		l.latestRequestTime = requestTime;
	}

	onPageItems({ entity, url, store }) {
		this.isLoading = true;
		const l = this.list[store || entity];
		l.nextPageUrl = null;
	}

	onItemsUpdated({ datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		const l = this.list[datastore];
		if (l.latestRequestTime > requestTime) {
			console.log("[%s] Ignoring result with %s items since there have been newer requests.", datastore, numberOfItems);
		} else {
			l.items = appendToExistingItems ? l.items.concat(items) : items;
			l.nextPageUrl = nextPageUrl;
			l.numberOfItems = numberOfItems;

			if (datastore === "childContainers") {
				l.items = l.items.sort((a, b) => (a.sortName || a.name).localeCompare(b.sortName || b.name));
			}
			if (datastore === "comments" && numberOfItems > 0) {
				this.item.todo.thread = {
					id: items[0].threadId,
					numberOfComments: numberOfItems,
				};
			}
			if (datastore === "todos") {
				storeTodoCommentsData(items);
			}
			this.isLoading = false;
			this.todoPartsLoading[datastore] = false;
		}
	}

	/* Item */
	onFetchItem() {
		this.isLoading = true;
	}

	onItemLoaded({ entity, model }) {
		this.item[entity] = model;
		this.isLoading = false;

		if (entity === "todo") {
			// Temp "fix" because we don't get thread.comments when fetching single todo
			const tempThread = this.list.todos.items.find(t => t.workListItemId === model.workListItemId)?.thread;
			this.updateTodoInList(model);
			handleTodoWasOpened(this.list.todos.items, model, tempThread);
		}

		this.todoPartsLoading[entity] = false;
	}



	onUnmount() {
		this.item = {
			...this.item,
			todo: {},
			worklist: {},
			container: {},
		};
		
		this.initStore("childContainers");

		this.isLoading = false;
	}

	// CUSTOM
	onChildContainerUpdated(container) {
		const index = this.list.childContainers.items.findIndex(c => c.id === container.id);
		if (index >= 0) {
			this.list.childContainers.items[index] = container;
		} else {
			console.warn("That's strange...");
		}
	}

	updateTodoInList(todo) {
		const index = this.list.todos.items.findIndex(t => t.workListItemId === todo.workListItemId);
		if (index >= 0) {
			this.list.todos.items[index] = todo;
		} else {
			this.list.todos.items.push(todo);
		}
	}

	onFetchTodo() {
		this.todoPartsLoading = {
			todo: true,
			container: true,
			childContainers: true,
			comments: true,
			program: true,
			// childPrograms: true,
		};
	}

	onStartEditingComment(commentId) {
		this.list.comments.items.forEach(comment => comment.isEditing = comment.id === commentId);
	}

	onStopEditingComment() {
		this.list.comments.items.forEach(comment => comment.isEditing = false);
	}

	// EXTERNAL COMPONENT ACTIONS
	onEditorSaved(payload) {
		if (payload.entity === "todo") {
			this.updateTodoInList(payload.model);
		}

		if (payload.entity === "program" && payload.todoId) {
			// Fetch updated todo in case the todo status was updated after completing an item
			Actions.fetchItem.defer("todo", { id: payload.todoId });
		}
	}

	onContentSourceMetadataContainerLoaded({ payload, todoId }) {
		if (payload && todoId) {
			// Fetch updated todo in case the todo status was updated after completing an item
			Actions.fetchItem.defer("todo", { id: todoId });
		}
	}

	onUnmountTodo() {
		this.item.todo = {};
		this.item.parentContainer = {};
		this.item.program = {};
		this.item.parentProgram = {};
		this.initStore("childContainers");
		this.initStore("comments");
	}


	// HELPERS
	getItem(entity, id) {
		const store = this[`${entity}s`];
		return store.find(e => e.id === id);
	}

	removeItem(entity, id) {
		const store = this[`${entity}s`];
		const index = store.findIndex(e => e.id === id);
		store.splice(index, 1);
	}

	persistToStore(store, model) {
		if (!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if (index >= 0) {
			this[store][index] = model;
		} else {
			this[store].push(model);
		}

		return true;
	}

	updateStore(store, model) {
		if (!this[store]) {
			return false;
		}

		const index = this[store].findIndex(m => (m.id === model.id));

		if (index < 0) {
			return false;
		}

		this[store][index] = model;
		return true;
	}

	initStore(store, filters = null, items = []) {
		this.list[store] = {
			items,
			nextPageUrl: null,
			numberOfItems: 0,
			latestRequestTime: null,
			filters,
		};
	}

}
export default alt.createStore(OneStopDropStore, '[OneStopDrop]Store');

const sortComments = localeCompareSortNumeric("created");
const commentsDataKey = "osd_comments_data";

function storeTodoCommentsData(items) {
	const storedCommentsData = JSON.parse(localStorage.getItem(commentsDataKey) ?? "{}");
	const updatedCommentsData = { ...storedCommentsData };
	
	const todosWithComments = items.filter(t => t.thread?.comments?.length);
	todosWithComments.forEach(todo => {
		const latestComment = todo.thread.comments.sort(sortComments).reverse()[0];
		const data = updatedCommentsData[todo.workListItemId];
		if (!(data?.id === latestComment.id && data?.hasSeen)) {
			updatedCommentsData[todo.workListItemId] = { id: latestComment.id, hasSeen: false };

			const index = items.findIndex(i => i.workListItemId === todo.workListItemId);
			items[index].hasUnreadComment = true;
		}
	});
	localStorage.setItem(commentsDataKey, JSON.stringify(updatedCommentsData));
}

function handleTodoWasOpened(items, todo, tempThread) {
	const storedCommentsData = JSON.parse(localStorage.getItem(commentsDataKey) ?? "{}");
	const updatedCommentsData = { ...storedCommentsData };

	// Temp "fix" because we don't get thread.comments when fetching single todo
	const latestComment = (tempThread?.comments?.sort(sortComments).reverse() ?? [])[0];
	// const latestComment = (todo.thread?.comments.sort(sortComments).reverse() ?? [])[0];
	if (latestComment) {
		updatedCommentsData[todo.workListItemId] = { id: latestComment.id, hasSeen: true };
		const index = items.findIndex(i => i.workListItemId === todo.workListItemId);
		items[index].hasUnreadComment = false;
	}

	localStorage.setItem(commentsDataKey, JSON.stringify(updatedCommentsData));
}