import React, { useState } from 'react'
import { DragSource } from 'react-dnd'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

import ItemTypes from '../../core/itemTypes'
import Image from '../../components/image'
import Button from '../../components/ui/controls/button'
import LazyLoad from '../../components/lazyload'

import './asset.css'
import ExtensionIconMap from './extensionIconMap'
import { ConfirmPlugin } from '../cms/components/datetimepicker'
import '../cms/components/datetimepicker.css'

const Asset = ({
	asset,
	onClick,
	onRemove,
	connectDragSource,
	index,
	needsCrop,
	format = "Star_None_x300",
	enableEmbargo,
	onEmbargoChange,
	onAddEmbargo,
	hideEmbargoDatePicker,
}) => {
	const [isRemoving, setIsRemoving] = useState(false);
	const [hasError, setHasError] = useState(false);
	let classNames = "asset";
	if (asset.type) classNames += " " + asset.type.toLowerCase();
	if (asset.publishFrom && !hideEmbargoDatePicker) classNames += " publish-in-future";
	if (asset.isOld) classNames += " old";
	if (hasError) classNames += " has-error";
	const fileIcon = getFileIcon(asset);
	
	let assetElement, imageLink;
	switch (asset.type) {
		case "Image":
		case "Video":
		case "Clip":
		case "Trailer":
			onClick = onClick && onClick.bind(this, asset.id, asset.categoryId, false);
			const extension = asset.assetData && asset.assetData.type.toLowerCase() === "png" ? "png" : "jpg";
			imageLink = asset.links && asset.links.find(l => l.rel === "image");
			let iconClasses = null;
			if (asset.type !== "Image" && onClick) {
				iconClasses = `large-icon video-icon ${fileIcon}`;
			} else if (asset.type !== "Image") {
				// If video can not be played (no onClick), display movie icon instead of play icon
				iconClasses = `large-icon video-icon icon-movie`;
			}
			if (imageLink) {
				assetElement = (
					<LazyLoad debounce={false} className={iconClasses}>
						<Image
							src={`${imageLink.href}/${format}.${extension}`}
							alt={asset.displayName}
							onClick={onClick}
							onFail={() => setHasError(true)}
						/>
					</LazyLoad>
				);
			} else {
				assetElement = <div className={iconClasses}></div>;
			}
			break;
		case "Document":
			const documentContent = asset.links && asset.links.find(link => link.rel === "content");
			onClick = (e) => {
				if (e) {
					e.preventDefault();
					e.stopPropagation();
				}
				if (documentContent) {
					window.open(documentContent.href, "_blank");
				} else {
					window.alert("This document can not be downloaded.")
				}
			};
			imageLink = asset.links && asset.links.find(link => link.rel === "image");
			if (imageLink) {
				assetElement = (
					<LazyLoad debounce={false}>
						<Image
							src={`${imageLink.href}/${format}.jpg`}
							alt={asset.displayName}
							onClick={onClick}
							onFail={() => setHasError(true)}
						/>
					</LazyLoad>
				);
			} else {
				assetElement = <div role="link" tabIndex={0} className={`large-icon document-icon ${fileIcon}`} onClick={onClick} />;
			}
			break;
		default:
			onClick = onClick && onClick.bind(this, asset.id, null, false)
			assetElement = <div role="link" tabIndex={0} className={`large-icon ${fileIcon}`} onClick={onClick} />
			break;
	}

	const handleRemoveClick = e => {
		e.stopPropagation();
		setIsRemoving(true);
		onRemove(asset);
	};

	const shouldRenderEmbargoLink = enableEmbargo && onAddEmbargo;
	const shouldRenderEmbargoDatePicker = enableEmbargo && asset.publishFrom && !hideEmbargoDatePicker;
	return connectDragSource(
		<div key={asset.id} className={classNames} onClick={onClick}>
			{needsCrop && <div className="status-ribbon needs-crop"><div><span>CROP THIS</span></div></div>}
			<div className="asset-text">{asset.displayName}</div>
			{index && <div className="index-name">{index.displayName}</div>}
			{assetElement}
			{onRemove && <Button type="cancel" onClick={handleRemoveClick} disabled={isRemoving} className={isRemoving ? "disabled" : ""} />}
			{shouldRenderEmbargoLink && renderEmbargoLink(asset, onAddEmbargo)}
			{shouldRenderEmbargoDatePicker && renderEmbargoDatePicker(asset, onEmbargoChange)}
		</div>
	);
}

const sourceSpec = {
	beginDrag(props) {
		return {
			data: props.asset,
		};
	},
};

export default DragSource(ItemTypes.ASSET, sourceSpec, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))(Asset);

export function getFileIcon(asset) {
	const nameParts = (asset.displayName || "").toLowerCase().split(".");
	const extension = nameParts.length > 1 ? nameParts[nameParts.length - 1] : null;
	return ExtensionIconMap[extension] || "icon-insert_drive_file";
}

export function renderEmbargoLink(asset, onClick) {
	return (
		<Button
			hoverTitle="Add future publication"
			type="schedule"
			onClick={(e) => {
				e.stopPropagation();
				onClick(asset);
			}}
			className="embargo-link"
		/>
	);
}

const onFlatpickrChange = () => {};

export function renderEmbargoDatePicker(asset, onChange) {
	const flatpickrOptions = {
		enableTime: true,
		enableSeconds: false,
		altInput: true,
		time_24hr: true,
		locale: { firstDayOfWeek: 1 }, // Monday
		plugins: [new ConfirmPlugin({ onChange: (value) => onChange(asset, value) })],
		altFormat: "Y-m-d H:i",
		minDate: moment().format(),
	};

	return (
		<div
			className={`embargo-datetimepicker-wrapper ${!onChange ? "readonly" : "icon-insert_invitation"}`}
			onClick={(e) => e.stopPropagation()}
		>
			<Flatpickr
				value={asset.publishFrom}
				onChange={onFlatpickrChange}
				options={flatpickrOptions}
				onReady={onFpReady}
				className="embargo-datetimepicker"
			/>
		</div>
	);
}

function onFpReady(_, __, fp) {
	fp.calendarContainer?.classList.add("c6-cms-datetimepicker");
}