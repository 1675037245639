import React from 'react'
import PropTypes from 'prop-types'

import styles from './editor.css'

const Editor = ({scrollHeight: height, children}) => {

	return (
		<div style={{height}} className="c6-editor">
			{children}
		</div>
	);
};
Editor.propTypes = {
	scrollHeight: PropTypes.number
}
export default Editor;