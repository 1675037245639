import React, { Component } from 'react'
import { browserHistory } from 'browserHistory'

import Main from '../../../components/ui/main'
import Button from '../../../components/ui/controls/button'
import Upload from '../../../components/upload'
import Asset from '../../../components/assets/asset'

import Actions from '../actions'

import './files.css'
import appConfig from 'config'

const SEASON = 3;

export default class Files extends Component {

	state = {
		expandedContainerIDs: [],
	}

	removeAsset = (asset, containerId, isChildContainer) => {
		Actions.removeAssetFromContainer(containerId, [asset.id], asset.category, isChildContainer);
	}

	openAsset = (assetId) => {
		if (appConfig.features && appConfig.features.useMinimalImageEditor) {
			const route = {
				pathname: `/onestopdrop/asset/${assetId}/edit`,
				// search,
				state: {
					modal: true,
					returnTo: `${location.pathname}${location.search}`,
				}
			};
	
			browserHistory.push(route);
		}
	}

	renderAsset = (asset, container, disabled, isChildContainer) => {
		const isAvailableAndEnabled = !asset.isUsed && !disabled;
		const onRemove = isAvailableAndEnabled && this.removeAsset.bind(this, asset, container.id, isChildContainer);
		const onEdit = isAvailableAndEnabled ? this.openAsset : undefined;

		return (
			<div className="file-wrapper" key={asset.id}>
				<Asset
					key={asset.id}
					asset={asset}
					onRemove={onRemove}
					onClick={onEdit}
				/>
			</div>
		);
	}

	toggleExpand = (containerId) => {
		const expandedContainerIDs = this.state.expandedContainerIDs;
		const index = expandedContainerIDs.findIndex(c => c === containerId);
		if (index >= 0) {
			expandedContainerIDs.splice(index, 1);
		} else {
			expandedContainerIDs.push(containerId);
		}
		this.setState({ expandedContainerIDs });
	}

	openMetadataEditor = (programGuid) => {
		Actions.edit({
			pathname: `/onestopdrop/metadata/${programGuid}/edit`,
			query: {
				todoType: this.props.todo?.workType,
				versionId: this.props.todo?.versionId,
				todoId: this.props.todo?.workListItemId,
			},
			returnTo: window.location.pathname,
		});
	}

	renderContainer = (container, parentName, isChildContainer, isLoading) => {
		const assets = (container.assets || []).map(asset => ({
			...asset,
			isUsed: container.assets.some(a => a.id === asset.id && a.category !== "Undefined"),
		}));
		const images = assets.filter(asset => asset.type === "Image" && asset.category === "Undefined");
		const documents = assets.filter(asset => asset.type === "Document" && asset.category === "Undefined");
		const clips = assets.filter(asset => (asset.type === "Trailer" || asset.type === "Clip") && asset.category === "Undefined");
		// const approvedInfo = !!container.approved ? <span className="approved-info">{"(APPROVED)"}</span> : null;
		const disabled = !!container.approved || this.props.todo.status === "Complete";
		const expanded = container.type === "SingleProgram" || this.props.todo?.workType !== "Content" || this.state.expandedContainerIDs.includes(container.id);
		const approvalClass = getApprovalClass(this.props.todo, this.props.program, this.props.childPrograms, container, this.props.isTranslationAgencyUser);
		const status = container.contentSourceMetadata?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(this.props.todo?.workListItemId));
		const isComplete = !!status?.complete;
		const metadataDisabled = !container.programGuid || isComplete && appConfig.features.useContentSourceMetadata;
		const metadataHidden = this.props.isProductionCompanyUser && appConfig.features.osdProductionCompanyRestrictions;
		const expandIcon = expanded ? "expand_less" : "expand_more";
		let imagesDocsColor = !!container.approved ? "fg-green" : "fg-red";
		if (appConfig.features.useContentSourceMetadata) {
			imagesDocsColor = "fg-blue";
		}
		
		return (
			<div className="container" key={container.id}>
				<div className="container-header">
					<h2>{isLoading ? "Loading folder..." : <span>{container.displayName}{/*approvedInfo*/}</span>}</h2>
					{this.props.todo?.workType === "Content" && (
						<div className="content-buttons">
							{!this.props.isTranslationAgencyUser && (
								<Button
									type={!!container.approved ? "check" : expandIcon}
									title="Images/Docs"
									noBackground
									onClick={this.toggleExpand.bind(this, container.id)}
									className={imagesDocsColor}
								/>
							)}
							{!metadataHidden && (
								<Button
									className={`icon-create ${approvalClass}`}
									title="Metadata"
									noBackground
									disabled={metadataDisabled}
									onClick={this.openMetadataEditor.bind(this, container.programGuid)}
								/>
							)}
						</div>
					)}
				</div>
				{!isLoading && expanded && !this.props.isTranslationAgencyUser && (
					<div className={`files ${disabled ? "container-disabled" : ""}`}>
						<div className="file-wrapper">
							<Upload
								key={`upload-${container.id}`}
								categoryName="Undefined"
								containerId={container.id}
								onComplete={container => Actions.updateContainer(container, isChildContainer)}
								multipleUploads={true}
								contentSource={this.props.todo.contentSource}
							/>
						</div>
						{images.map(asset => this.renderAsset(asset, container, disabled, isChildContainer))}
						{clips.map(clip => this.renderAsset(clip, container, disabled, isChildContainer))}
						{documents.map(doc => this.renderAsset(doc, container, disabled, isChildContainer))}
					</div>
				)}
			</div>
		);
	}

	render() {
		const {
			todo,
			container,
			containerLoading,
			childContainers,
			childContainersLoading,
			parentContainer,
		} = this.props;

		const complete = todo.status === "Complete";

		return (
			<div className="todo-files">
				<h2>Upload files</h2>
				{!complete && <p>Click Images/Docs to upload files. You can drag and drop multiple files to upload them at once.</p>}
				{complete && <p>You can not add or remove files while this TODO is marked as Done.</p>}
				<Main modal={true}>
					{parentContainer?.id && this.renderContainer(parentContainer)}
					{container?.id && this.renderContainer(container, null, false, containerLoading)}
					{childContainers.map(childContainer => this.renderContainer(childContainer, todo.displayName, true))}
					{todo?.workType === "Undefined" && !complete && container?.containerTypeId === SEASON && (
						<Button
							noBackground={true}
							type="add"
							title="Add one more episode"
							onClick={Actions.createEpisode.bind(this, container, childContainers)}
						/>
					)}
				</Main>
			</div>
		);
	}
}

function getApprovalClass(todo, program, childPrograms, container, isTranslationAgencyUser) {
	if (!todo.versionId || !container.programGuid || !isTranslationAgencyUser) {
		return "";
	}

	const metadataProgram = [program, ...childPrograms].find(p => p.guid === container.programGuid);
	if (!metadataProgram) {
		return "";
	}

	const status = container.contentSourceMetadata?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(todo.workListItemId));
	const isComplete = !!status?.complete;
	
	const version = metadataProgram?.versions?.find(v => v.versionId === parseInt(todo.versionId));
	const approved = version?.approval?.status?.toLowerCase() === "approved"
		|| isComplete && appConfig.features.useContentSourceMetadata;
	return approved ? "fg-green" : "fg-red";
}