import { get, put, post, del } from '../core/c6'
import Qs from 'qs'

import appConfig from 'config'

const API = appConfig.features.tagsC80 ? "tagsc8" : "tags";

/* **************** TAGS ***************** */
export const fetchTags                  =           filters =>  get(API, "tags", filters);
export const fetchContentDiscoveryTags  =                () =>  get(API, "tags?tagTypes=0|1|2|3&excludeItems=true&orderby=displayname&pageSize=1000");
export const fetchAdTags                =                () =>  get(API, "tags?tagType=4&excludeItems=true&orderby=displayname");
export const fetchPublisherTags         =                () =>  get(API, "tags?tagType=10&excludeItems=true&orderby=displayname");
export const fetchCategoryTags          =                () =>  get(API, "tags?tagType=5&excludeItems=true&orderby=displayname");
export const fetchAnnoTags              =                () =>  get(API, "tags?tagType=6&excludeItems=true&orderby=displayname");
export const fetchAmanTags              =                () =>  get(API, "tags?tagType=7&excludeItems=true&orderby=displayname");
export const fetchDescriptionTags       =                () =>  get(API, "tags?tagType=8&excludeItems=true");
export const fetchBrandTags				=				 () =>	get(API, "tags?tagType=9&excludeItems=true");
export const fetchTag                   =     (requestOrId) =>  {
    const { id } = requestOrId;
    return get(API, `tags/${id || requestOrId}`);
};

export const createTag  =                   body => post(API, "tags", body);
export const updateTag  = (requestOrId, payload) => {
    const { id, body } = requestOrId;
    return put(API, `tags/${id || requestOrId}`, body || payload);
};
export const enableTag  =          (requestOrId) => {
    const { id } = requestOrId;
    return put(API, `tags/${id || requestOrId}/enable`);
};
export const disableTag =          (requestOrId) => {
    const { id } = requestOrId;
    return put(API, `tags/${id || requestOrId}/disable`);
};

export const removeItemFromTag = (tagId, itemId)           => del(API, `tags/${tagId}/removeItem/${itemId}`);
export const addItemToTag      = (tagId, itemId, position) => {
    const positionParam = position ? `?position=${position}` : "";
    return put(API, `tags/${tagId}/addItem/${itemId}${positionParam}`);
};




/* **************** ITEMS ***************** */
export const fetchItems =                filters => get(API, "items", filters);
export const fetchItem  =          (requestOrId) => {
    const { id } = requestOrId;
    return get(API, `items/${id || requestOrId}`);
};
export const createItem =                payload => post(API, "items", payload);
export const updateItem = (requestOrId, payload) =>  {
    const { id, body } = requestOrId; // requestOrId => TO BE BACKWARD COMPATIBLE WITH DEPRECATED DISCOVERY MODULE
    const createTagsParam = id ? "?createTags=true" : ""; // set createTags=true if this is called from new Tags app (in Metadata)
    const extraC80PartToHandleIncompleteObjects = appConfig.features.tagsC80 ? "/data" : ""; // By default C80 does not handle incomplete tags (objects with just an ID) so we added a new endpoint that handles this instead
    return put(API, `items/${id || requestOrId}${extraC80PartToHandleIncompleteObjects}${createTagsParam}`,  body || payload); //  body || payload => TO BE BACKWARD COMPATIBLE WITH DEPRECATED DISCOVERY MODULE
};
export const fetchOrCreateItem = (payload) => {
	if (appConfig.features.tagsC70 || appConfig.features.tagsC80) {
		payload.itemType = { id: payload.itemTypeId };
		delete payload.itemTypeId;
		return post(API, "items/register", payload);
	}

	const qparams = Qs.stringify(payload, { skipNulls: true });
	return post(API, `items/register?${qparams}`);
};



/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);