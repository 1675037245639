import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const MuiCheckbox = ({ value, required, options, onChange, schema, disabled = false }) => {

	const handleChange = (event, value) => { onChange(value); };
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={value ?? false}
					onChange={handleChange}
					required={required}
					disabled={disabled}
					style={{ marginLeft: "-11px" }}
				/>
			}
			label={schema.description}
		/>
	);
};

export default MuiCheckbox;