import { get } from '../core/c6'
import Qs from 'qs'

const SKIP_TOKEN = true;
const API = "discovery";
// C60 Discovery
export const search = filters => {

	// Freetext search over all program types
	// if(filters.searchText && filters.searchText.length) {
	// 	filters.programType = "single|season|sport";
	// }

	const excludeTypeFilter = (prefix, value) => (prefix === "type" ? undefined : value);
	const queryString = Qs.stringify(filters, { skipNulls: true, filter: excludeTypeFilter });

	return get(API, `contents?${queryString}`)
};
export const fetchItem  = (id, newDiscoveryAPI = false) => {
	if(newDiscoveryAPI) {
		return get(API, `items/${id}`);
	}
	else {
		return get(API, `contents/${id}`);
	}
};

// C70 Discovery
export const fetchItems = filters => {
	// const excludeTypeFilter = (prefix, value) => (prefix === "type" ? undefined : value);
	const queryString = Qs.stringify(filters, { skipNulls: true/*, filter: excludeTypeFilter*/ });

	return get(API, `items?${queryString}`, null, SKIP_TOKEN);
};