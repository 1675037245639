import React from 'react'
import { useLocalStorage } from './useStorage'

import appConfig from 'config'

const STORAGE_KEY = "c6-theme";
const CUSTOM_EVENT_KEY = "c6-theme-change-event";

export const useDarkMode = () => {
    const [theme, setTheme] = useLocalStorage(STORAGE_KEY, "light");

    // Reset theme to light if dark mode is not enabled
    React.useEffect(
        () => {
            if (theme === "dark" && !appConfig.features.enableDarkModeSwitch) {
                setTheme("light");
            }
        },
        [theme]
    );

    React.useEffect(
        () => {
            setHtmlTheme(theme);
            window.dispatchEvent(new Event(CUSTOM_EVENT_KEY));
        },
        [theme]
    );

    const darkMode = theme === "dark" && appConfig.features.enableDarkModeSwitch;
    const setDarkMode = React.useCallback(
        (isDarkMode) => {
            setTheme(isDarkMode ? "dark" : "light");
        },
        [setTheme]
    );

    return [darkMode, setDarkMode];
};

export const getCometTheme = () => {
    const theme = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return theme === "dark" && appConfig.features.enableDarkModeSwitch ? "dark" : "light";
};

export const setHtmlTheme = (theme) => {
    console.log("theme", theme);
    const html = document.querySelector("html");
    html.classList.remove("c6-theme-light");
    html.classList.remove("c6-theme-dark");
    html.classList.add(`c6-theme-${theme}`);
};

export const listenForThemeChange = (callback) => {
    window.addEventListener(CUSTOM_EVENT_KEY, () => {
        callback();
    });
};
