import React, { useState } from 'react'

const MediaInfo = ({ asset }) => {
	const originalFile = asset.files && asset.files.length && asset.files.find(f => f.fileFormatId === 0 && f.status === "Ok");

	let mediaInfo;
	try {
		if (originalFile && originalFile.exif) {
			mediaInfo = JSON.parse(originalFile.exif);
		}
	} catch (e) {
		console.warn("Failed to parse MediaInfo from originalFile.exif", e);
	}

	if (!mediaInfo)
		return (<p style={{ padding: "10px" }} className="icon-code">Media info not available</p>);

	const { generalStream, videoStreams, audioStreams, textStreams } = mediaInfo;

	return (
		<div className="exif">
			<JSONTree data={generalStream} title="General" icon="icon-insert_drive_file" />
			{videoStreams.map((vs, i) => <JSONTree key={i} data={vs} title={`Video #${i + 1}`} icon="icon-videocam" />)}
			{audioStreams.map((vs, i) => <JSONTree key={i} data={vs} title={`Audio #${i + 1}`} icon="icon-hearing" />)}
			{textStreams.map((vs, i) => <JSONTree key={i} data={vs} title={`Text #${i + 1}`} icon="icon-subtitles" />)}
		</div>
	);
}

export default MediaInfo;

const JSONTree = ({ data, title, icon }) => {
	console.log("data", data);
	const [expanded, setExpanded] = useState(false);
	return (
		<div key={title}>
			<p className={`json-tree-title ${icon}`} onClick={() => setExpanded(!expanded)}>
				{title}<span className={expanded ? "icon-expand_less" : "icon-expand_more"}></span>
			</p>
			<ul className="exif" style={{ height: expanded ? "auto" : 0 }}>
				{Object.entries(data).filter(([key, value]) => value !== "" && typeof value !== "object").map(([key, value]) => (
					<li key={key}>
						<div>{key}</div>
						<div>{typeof value === "object" ? "<< object >>" : value}</div>
					</li>
				))}
			</ul>
		</div>
	);
}