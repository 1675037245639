import { QueryClient } from '@tanstack/react-query'
import appConfig from 'config'
import { handleRequestFailed } from '../services/errorhandling'

export default {
    DATE_FORMAT: "YYYY-MM-DD",
	DATE_FORMAT_TZ: "YYYY-MM-DDZ",
	DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm",
	DATE_TIME_FORMAT_TZ: "YYYY-MM-DDTHH:mmZ",
	PERIOD_FORMAT: "D MMM YYYY",
	SHORT_TIME_FORMAT: "HH:mm D MMM",
	DATE_WITH_DAY_OF_WEEK_FORMAT: "dddd, MMMM D, YYYY",
	IMAGE_FORMATS: ["jpeg","jpg","jif","jfif","gif","tif","tiff","jp2","jpx","j2k","j2c","fpx","pcd","png","bmp","thm","svg","eps","webp"],
	DOCUMENT_FORMATS: ["doc","docx","log","msg","pages","rtf","txt","wpd","wps","ai","psd","lsr","drw","ps","csv","dat","efx","gbr","key","pps","ppt","pptx","sdf","tax2010","vcf","xml","indd","pct","pdf","qxd","qxp","rels","xlr","xls","xlsx","asp","cer","csr","css","htm","html","js","jsp","php","rss","xhtml","fnt","fon","otf","ttf","cfg","ini","keychain","prf","7z","deb","gz","pkg","rar","rpm","sit","sitx","tar.gz","zip","zipx","c","class","cpp","cs","dtd","fla","java","m","pl","py"],
  	VIDEO_FORMATS: ["3g2","3gp","asf","asx","avi","flv","mkv","mov","mp4","m4v","mpg","rm","swf","vob","wmw"]
}

const _regions = [
	{
		id: 1,
		country: "se",
		language: "sv",
		countryDisplayName: "Sweden",
		languageDisplayName: "Swedish",
		languageDisplayNameShort: "Swe",
		momentTimezone: "Europe/Stockholm",
	},
	{
		id: 2,
		country: "no",
		language: "no",
		countryDisplayName: "Norway",
		languageDisplayName: "Norwegian",
		languageDisplayNameShort: "Nor",
		momentTimezone: "Europe/Oslo",
	},
	{
		id: 3,
		country: "dk",
		language: "da",
		countryDisplayName: "Denmark",
		languageDisplayName: "Danish",
		languageDisplayNameShort: "Dan",
		momentTimezone: "Europe/Copenhagen",
	},
	{
		id: 4,
		country: "fi",
		language: "fi",
		countryDisplayName: "Finland",
		languageDisplayName: "Finnish",
		languageDisplayNameShort: "Fin",
		momentTimezone: "Europe/Helsinki",
	},
	{
		id: 5,
		country: "gb",
		language: "en",
		countryDisplayName: "United Kingdoms",
		languageDisplayName: "English",
		languageDisplayNameShort: "Eng",
		momentTimezone: "Europe/London",
	}
]

const _getAvailableRegions = () => {
	const ar = appConfig.app && appConfig.app.availableRegions || ["se", "no", "dk", "fi"];
	return _regions
		.filter(r => ar.includes(r.country))
		.sort((a, b) => ar.findIndex(r => r === a.country) - ar.findIndex(r => r === b.country)) // Sort by position in availableRegions
}
export const Regions = _getAvailableRegions();
export const AllRegions = _regions;

const ModuleIcons = {
	"home": "icon-home",

	// C5
	"security": "icon-security",
    "broadcast": "icon-movie",
    "svod": "icon-play_circle_filled",
    "planner": "icon-access_time",
    "material": "icon-traffic",
    "diva": "icon-insert_photo",
    "sport": "icon-directions_bike",
    "sales": "icon-shopping_basket",
    "website": "icon-language",
    "authenticator": "icon-vpn_lock",
    "promotiontool": "icon-thumb_up",
    "grids": "icon-dashboard",

	// Shield
	"shield": "icon-security",
	"shield.users": "icon-person",
	"shield.modules": "icon-view_module",
	"shield.services": "icon-extension",

	// Selections
	"selections": "icon-looks_one",
	"selections.ott": "icon-phonelink",
	"selections.onair": "icon-view_list",
	"selections.squeeze": "icon-view_module",
	"selections.sections": "icon-line_style",
	"selections.admin-groups": "icon-line_style",
	"selections.admin-templates": "icon-auto-fix",
	"selections.admin-areas": "icon-dashboard",
	"selections.press": "icon-newspaper",
	"selections.press-fph": "icon-wb_iridescent",
	"selections.press-cfh": "icon-local_play",
	"selections.press-sh": "icon-directions_bike",
	"selections.press-pd": "icon-account_circle",
	"selections.press-faq": "icon-chat",
	"selections.press-utskick": "icon-create",

	// Acquisition
	"acquisitions": "icon-shop",
	"acquisitions.contracts": "icon-monetization_on",
	"acquisitions.match": "icon-switch_camera",

	// ACQ (Acquisitions v3)
	"acq": "icon-shop",
	"acq.licenses": "icon-subscriptions",
	"acq.contracts": "icon-shop",

	// Discovery
	"discovery": "icon-explore",
	"discovery.tags": "icon-local_offer",
	"discovery.programs": "icon-movie",

	// Metadata
	"metadata": "icon-movie",
	"metadata.library": "icon-movie",
	"metadata.tags": "icon-local_offer",
	"metadata.families": "icon-line_style",
	"metadata.linear": "icon-view_week",
	"metadata.translation": "icon-translate",
	"metadata.schedule": "icon-view_week",

	// Star (legacy)
	"star-legacy": "icon-star",
	"star-legacy.home": "icon-dashboard",
	"star-legacy.myspace": "icon-lock",

	// Star
	"star": "icon-star",
	"star.home": "icon-star",
	"star.assets": "icon-insert_photo",
	"star.albums": "icon-photo_album",
	"star.trashcan": "icon-delete",

	// Schedules
	"schedules": "icon-list",
	"schedules.changes": "icon-low_priority",
	"schedules.releases": "icon-date_range",

	// VOD
	"vod": "icon-ondemand_video",
	"vod.admin-services": "icon-archive",
	"vod.packages": "icon-archive",
	"vod.packages-new": "icon-archive",
	"vod.tv4": "icon-archive",
	"vod.tv4-new": "icon-archive",
	"vod.dai": "icon-archive",
	"vod.kavi": "icon-archive",

	// Horizon
	"horizon": "icon-weather-sunet",
	"horizon.dashboard": "icon-dashboard",
	"horizon.packages": "icon-list",

	// Reviews
	"reviews": "icon-rate_review",
	"reviews.reviews": "icon-rate_review",
	"reviews.sources": "icon-language",
	"reviews.programs": "icon-movie",
	"reviews.blockbuster": "icon-movie",

	// Exchange
	"exchange": "icon-swap_horiz",
	"exchange.svt": "icon-insert_drive_file",

	// Planner
	"planner": "icon-access_time",
	"planner.calendar": "icon-access_time",

	// Traffic
	"traffic": "icon-traffic",
	"traffic.programs": "icon-movie",

	// Event Planner
	"eventplanner": "icon-timer",
	"eventplanner.encoders": "icon-settings_input_antenna",
	"eventplanner.events" : "icon-timer",
	"eventplanner.mcr" : "icon-server_check",
	"eventplanner.metadata": "icon-movie",
	"eventplanner.cms-template": "icon-border_color",
	"eventplanner.webtv": "icon-view_day",
	"eventplanner.channelview": "icon-chart-gantt",
	"eventplanner.resources": "icon-chart-gantt",
	"eventplanner.schedule": "icon-view_week",
	"eventplanner.entertainment": "icon-celebration",
	"eventplanner.entertainment-metadata": "icon-movie",

	// Misc
	"_help": "icon-help",
	"_admin": "icon-settings",
};

export const getModuleIcon = module => {
	if (ModuleIcons[module]) {
		return ModuleIcons[module];
	}

	// Various metadata libraries will share the same icon
	if (module.startsWith("metadata.library")) {
		return ModuleIcons["metadata.library"];
	}
	// External login lists will have the same icon as shield.users
	if (module.startsWith("shield") && !ModuleIcons[module]) {
		return ModuleIcons["shield.users"];
	}
	// Various eventplanner lists will share the same icon
	if (module.startsWith("eventplanner.cms-events-metadata")) {
		return ModuleIcons["eventplanner.metadata"];
	}
	if (module.startsWith("eventplanner.events") || module.startsWith("eventplanner.cms-events")) {
		return ModuleIcons["eventplanner.events"];
	}
	if (module.startsWith("reviews.reviews")) {
		return ModuleIcons["reviews.reviews"];
	}
	if (module.startsWith("reviews.programs")) {
		return ModuleIcons["reviews.programs"];
	}
	// For "Help"-apps (which can exist in multiple modules), use the same icon
	if (module.endsWith(".help")) {
		return ModuleIcons["_help"];
	}
	// For "Admin"-apps (which can exist in multiple modules), use the same icon
	if (module.endsWith(".admin")) {
		return ModuleIcons["_admin"];
	}

	return "";
};

export const ShieldAccessLevels = ["Admin", "Editor", "Contributor", "Reader", "Undefined"];
export const ShieldAccessLevelValues = {
	admin: 4,
	editor: 3,
	contributor: 2,
	reader: 1,
};

export const MetadataContainerTypes = [
	{ id: 1, name: "Single", starContainerTypeName: "SingleProgram" },
	{ id: 2, name: "Episode", starContainerTypeName: "EpisodeProgram" },
	{ id: 3, name: "Season", starContainerTypeName: "SeasonProgram" },
	{ id: 4, name: "Series", starContainerTypeName: "SeriesProgram" },
	{ id: 9, name: "Family", starContainerTypeName: "ProgramFamily" },
];

export const GUID_REGEXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const DEFAULT_QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
			onError: (err) => handleRequestFailed(err, true),
		},
	},
});

export const KEYBOARD_SHORTCUTS = {
	approve: 	"CTRL+SHIFT+A",
	save: 		"CTRL+SHIFT+S",
	translate: 	"CTRL+SHIFT+T",
	prev: 		"CTRL+SHIFT+LEFT",
	next: 		"CTRL+SHIFT+RIGHT",
};
