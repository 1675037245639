import React from 'react'

export default class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
		console.log("Error below was caught in ErrorBoundary.");
		console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			return <h1>{this.props.message ?? "An unknown error has occurred."}</h1>;
		}

		return this.props.children;
	}
}