import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import cloneDeep from 'lodash/cloneDeep'

import Button from '../../controls/button'

import CrewCategory from './crewCategory'
import { generateKey } from '../index'

export default class Crew extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.addPerson = this.addPerson.bind(this);
		this.renderCrewCategories = this.renderCrewCategories.bind(this);
		this.renderAddButtons = this.renderAddButtons.bind(this);
	}

	handleChange(categoryKey, data) {
		const { formData, onChange } = this.props;

		formData[categoryKey] = data;
		onChange(formData);
	}

	addPerson(categoryKey) {
		const { formData = {}, onChange } = this.props;

		if (!formData[categoryKey]) {
			formData[categoryKey] = [];
		}

		formData[categoryKey].push({
			reactKey: generateKey(formData[categoryKey]),
			name: ""
		});

		onChange(formData);
		this.forceUpdate(); // HACK: Need this because lists won't update until they are focused, why? :/

		// I thought it was since we're only adding/updating properties on the crew/credits object, not updating the entire object, but forcing deep cloning does not help. Hmm...
		// onChange({...formData});
		// onChange(cloneDeep(formData));
	}

	renderCrewCategories() {
		const {
			schema,
			uiSchema,
			idSchema,
			formData,
			disabled,
			readonly
		} = this.props;

		if (formData === undefined) {
			return null;
		}

		const crewCategoryKeys = Object.keys(schema.properties);
		return crewCategoryKeys.map(categoryKey => {
			if (!formData[categoryKey] || formData[categoryKey].length == 0) {
				return null;
			}

			const category = schema.properties[categoryKey];
			const categoryUiSchemaOptions = uiSchema[categoryKey] && uiSchema[categoryKey]["ui:options"];
			const withRole = categoryUiSchemaOptions && categoryUiSchemaOptions.withRole;
			const limitToSuggestions = categoryUiSchemaOptions && categoryUiSchemaOptions.limitToSuggestions;

			return (
				<CrewCategory
					key={categoryKey}
					categoryKey={categoryKey}
					id={idSchema[categoryKey].$id}
					title={category.title}
					description={category.description}
					formData={formData[categoryKey]}
					disabled={disabled}
					withRole={withRole}
					readOnly={readonly}
					limitToSuggestions={limitToSuggestions}
					onChange={(data) => this.handleChange(categoryKey, data)}
				/>
			);
		});
	}

	renderAddButtons() {
		const {
			schema
		} = this.props;

		const crewCategoryKeys = Object.keys(schema.properties);
		return crewCategoryKeys.map((categoryKey, i) => {
			const category = schema.properties[categoryKey];
			const title = category.addTitle ? category.addTitle : category.title;
			return (
				<Button
					key={i}
					title={title}
					onClick={() => this.addPerson(categoryKey)}
					noBackground
				/>
			);
		});
	}

	render() {
		const { readonly, schema } = this.props;
		return (
			<div className="c6-crewfield">
				{this.renderCrewCategories()}
				{!readonly && (
					<div className="add-crew">
						<div>{schema.title || "Add"}:</div>
						{this.renderAddButtons()}
					</div>
				)}
			</div>
		);
	}
}