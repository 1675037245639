import { get, put, post, del } from '../core/c6'

const API = "reviews";
const API_SCRAPING = "reviewsScraping";
const SKIP_TOKEN = true;

/* **************** Reviews ***************** */
export const fetchReviews 	    = filters 			=> get(API, "reviews", filters);
export const fetchReview 	    = ({id}) 			=> get(API, `reviews/${id}`);
export const updateReview       = ({id}, payload)   => put(API, `reviews/${id}`, payload);
export const deleteReview       = ({id})            => del(API, `reviews/${id}`);
export const createReview       = (payload)         => post(API, "reviews", payload);

/* **************** Programs ***************** */
export const fetchPrograms 		= filters 	        => get(API, "programs", filters);
export const fetchProgramsLite 	= filters 	        => get(API, "programs/lite", filters);
export const fetchProgram 		= ({id}) 	        => get(API, `programs/${id}`);
export const createProgram 		= (payload)         => post(API, "programs", payload);
export const updateProgram 		= ({id}, payload)   => put(API, `programs/${id}`, payload);

/* **************** Sources ***************** */
export const fetchSources 	    = filters 			=> get(API, "sources", filters);
export const fetchSource 	    = ({id}) 			=> get(API, `sources/${id}`);
export const updateSource       = ({id}, payload)   => put(API, `sources/${id}`, payload);
export const deleteSource       = ({id})            => del(API, `sources/${id}`);

/* **************** Scraping ***************** */
export const fetchScrapingBody  = (link, payload)    => post(API_SCRAPING, `scrape/body?link=${link}`, payload, SKIP_TOKEN);
export const fetchScrapingTests = payload            => post(API_SCRAPING, "scrape/test", payload, SKIP_TOKEN);

/* **************** Languages ***************** */
export const fetchLanguages 	= filters 			=> get(API, "languages", filters);

/* **************** Customers ***************** */
export const fetchCustomers         = filters                       => get(API, "customers", filters);
export const fetchCustomerPrograms 	= ({ customer, ...filters })    => get(API, `customers/${customer}/programs`, filters);