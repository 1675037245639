import React from 'react'
import PropTypes from 'prop-types'

import localStyles from './empty.module.css'

const Empty = ({ title = "No items found", v2 = false, isLoading, children }) => {

	title = isLoading ? "Loading..." : title;
	const description = isLoading ? "This should just take a moment." : null;
	const content = [
		<h3 key="a">{title}</h3>,
		<p key="b">{description || children}</p>
	];
	return (
		<div className={localStyles[v2 ? "empty-v2" : "empty"]}>
			{v2 ? <div className="c6-color-light">{content}</div> : content}
		</div>
	);
};

Empty.propTypes = {
	isLoading: PropTypes.bool,
	v2: PropTypes.bool,
	title: PropTypes.string,
}
export default Empty;