import React from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import throttle from 'lodash/throttle'

import ItemTypes from '../../core/itemTypes'
import Actions, { ContentActionItem } from '../actions'

import './list.css'
import '../listItems/standard.css'

const SCROLL_INCREMENT = 50;
const scrollElement = throttle((element, increment) => {
	element.scrollBy({
		top: increment,
		behavior: "smooth",
	});
}, 100);

const itemTarget = {
	drop(props, monitor) {
		const droppedItem = monitor.getItem();
		props.onItemDrop(droppedItem, props.type);
	},
	hover(props, monitor, component) {
		props.onTargetHover(props.type);


		// TODO: Find a way to move this logic to SortableList.hover
		// This should probably be handled in SortableList instead but I could not get hover() to fire there.
		if (!props.useCustomScrollWhileDragging) {
			return;
		}

		// Find scrolling element to interact with
		const element = findDOMNode(component);
		const scrollingParent = element?.closest("main");
		if (!scrollingParent) {
			return;
		}

		// Find top and bottom thresholds
		const boundingRect = scrollingParent.getBoundingClientRect();
		const topThreshold = ((boundingRect.bottom - boundingRect.top) / 10) * 2; // top 20%
		const bottomThreshold = ((boundingRect.bottom - boundingRect.top) / 10) * 8; // bottom 80%

		// Cursor Y position
		const clientOffset = monitor.getClientOffset();
		const offsetY = clientOffset.y - boundingRect.top;

		// Cursor is in the top X%, scroll up!
		if (offsetY < topThreshold) {
			scrollElement(scrollingParent, 0 - SCROLL_INCREMENT);
		}
		// Cursor is in the bottom X%, scroll down!
		else if (offsetY > bottomThreshold) {
			scrollElement(scrollingParent, SCROLL_INCREMENT);
		}

		return;
	}
};
@DropTarget(ItemTypes.ITEM, itemTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))
export class DroppableItemGroup extends React.Component {
	static propTypes = {
		type: PropTypes.string,
		title: PropTypes.string,
		onItemDrop: PropTypes.func.isRequired,
		useCustomScrollWhileDragging: PropTypes.bool,
	}

	render() {
		const {
			connectDropTarget,
			//isOver,
			title,
			children,
			disabled,
		} = this.props;

		const groupClassName = title
			? "c6-itemGroup hasTitle"
			: "c6-itemGroup" ;

	    return connectDropTarget(
			<div className={groupClassName} disabled={disabled}>
				{title ? <h1>{title}</h1> : null}
				{children}
			</div>
	    );
  	}
}

// TODO: Maybe use this.ItemGroup above to not repeat code?
export class ItemGroup extends React.Component {

	static propTypes = {
		title: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
		]),
		visible: PropTypes.bool,
		collapsible: PropTypes.bool,
		initiallyCollapsed: PropTypes.bool,
		className: PropTypes.string,
		containerClassName: PropTypes.string,
	}

	constructor(props) {
		super(props);

		this.state = {
			collapsed: props.initiallyCollapsed,
		};
	}

	toggleCollapse = () => {
		this.setState({ collapsed: !this.state.collapsed });
	}

	render() {
		const {
			title,
			visible = true,
			collapsible = false,
			initiallyCollapsed = false,
			children,
			className = "",
			containerClassName = "",
			groupRef,
			...otherProps
		} = this.props;

		const { collapsed = false } = this.state;

		if (!visible) {
			return null;
		}

		// let collapseExpandIcon;
		// if (collapsible && collapsed) {
		// 	collapseExpandIcon = <span>&nbsp;<span className="icon-expand_more"></span></span>
		// } else if (collapsible) {
		// 	collapseExpandIcon = <span>&nbsp;<span className="icon-expand_less"></span></span>
		// }

		const collapseExpandIcon = collapsible
			? <span>&nbsp;<span className={`icon-expand_${collapsed ? "more" : "less"}`}></span></span>
			: null;

		const header = title
			? <h1 className={className} onClick={collapsible ? this.toggleCollapse : null}>{title}{collapseExpandIcon}</h1>
			: null;

		let groupClassName = "c6-itemGroup";
		groupClassName += title ? " hasTitle" : "";
		groupClassName += title && collapsible ? " collapsible" : "";
		groupClassName += ` ${containerClassName}`;

	    return (
			<div className={groupClassName} ref={groupRef} {...otherProps}>
				{header}
				<div className="items">
					{!collapsed && children}
				</div>
			</div>
	    );
  	}
}

// DEPRECATED: Use ContentItem from ./contentItem.jsx instead
export class Item extends React.Component {
	static displayName = "Item"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		actionData: PropTypes.object,
		flex: PropTypes.string,
		sel: PropTypes.bool,
		disabled: PropTypes.bool,
		status: PropTypes.string,
		small: PropTypes.bool,
		onSelect: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}


	handleClick(e) {
		const nodeName = e.target.nodeName;
		const excludedNodeNames = ["BUTTON", "A", "SPAN"];
		if (!excludedNodeNames.includes(nodeName)) {
			this.props.onSelect(e);
		}
	}

	render() {
		const {
			actionData,
			flex,
			sel,
			disabled,
			status,
			small,
			onSelect,
		} = this.props;

		const flexClass = flex ? `flex flex-${flex}` : "";
		const selClass = sel ? " sel" : "";
		const disabledClass = disabled ? " disabled" : "";
		const statusClass = status ? ` status-${status.toLowerCase()}` : "";
		const smallClass = small ? ` small` : "";
		const selectableClass = onSelect ? " selectable" : "";
		const classNames = `c6-item ${flexClass}${selClass}${statusClass}${disabledClass}${smallClass}${selectableClass}`;

		if(actionData) {
			return (
				<div className={classNames} ref="item" onClick={onSelect ? this.handleClick : null}>
					<ContentActionItem data={actionData}>
						{this.props.children}
						<Actions data={actionData} target={this.constructor.displayName} selected={sel} />
					</ContentActionItem>
				</div>
			);
		}

		return (
			<div className={classNames} ref="item" onClick={onSelect ? this.handleClick : null}>
				{this.props.children}
			</div>
		);
  	}
}