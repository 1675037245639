import React from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { Regions } from '../../../../core/constants'

const RegionSelect = ({ region, onChange }) => {
	return (
		<Select
			className="mui-select margin"
			value={region ? region.id : 0}
			onChange={(e) => onChange(e.target.value)}
			variant="standard"
			fullWidth
		>
			<MenuItem value={0}>Generic metadata</MenuItem>
			{Regions.map(region => (
				<MenuItem
					key={region.id}
					value={region.id}
				>
					{region.languageDisplayName} metadata
				</MenuItem>
			))}
		</Select>
	);
};

export default RegionSelect;