export const saveBlobToDisc = (blob, fileName) => {
	const a = document.createElement("a");
	document.body.appendChild(a);

	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName || "comet_download";
	a.click();
	document.body.removeChild(a);
	window.URL.revokeObjectURL(url);
};