import { get, put, post, del } from '../core/c6'

import appConfig from 'config'

const API = appConfig.features.acqC80 ? "acqc8" : "acq";

/* **************** LICENSES ***************** */
export const fetchLicenses =       filters => get(API, "licenses", filters);
export const fetchLicense  =           id  => get(API, `licenses/${id}`);
export const fetchAdsAllowedExcel  =   id  => get(API, `licenses/adsAllowedExcel`);
export const updateLicense = (id, payload) => put(API, `licenses/${id}`, payload);

export const createLicense      = payload => post(API, "licenses", payload);
export const deleteLicense      =  ({id}) =>  del(API, `licenses/${id}`);

/* **************** LICENSE WINDOWS ***************** */
export const activateLicenseWindow   = ({id, licenseId})          => put(API, `licenses/${licenseId}/windows/${id}/activate`);
export const inactivateLicenseWindow = ({id, licenseId})          => put(API, `licenses/${licenseId}/windows/${id}/inactivate`);
export const confirmLicenseWindow    = ({id, licenseId})          => put(API, `licenses/${licenseId}/windows/${id}/confirm`);
export const updateLicenseWindow     = ({id, licenseId}, payload) => put(API, `licenses/${licenseId}/windows/${id}`, payload);
export const removeLicenseWindow     = ({id, licenseId})          => del(API, `licenses/${licenseId}/windows/${id}`);

/* **************** CONTRACTS ***************** */
export const fetchContracts      =       filters =>  get(API, "contracts", filters);
export const fetchContract       =            id =>  get(API, `contracts/${id}`);
export const updateContract      = (id, payload) =>  put(API, `contracts/${id}`, payload);
export const createContract      =       payload => post(API, "contracts", payload);
export const deleteContract      =            id =>  del(API, `contracts/${id}`);

/* **************** PROGRAMS ***************** */
export const fetchPrograms = (searchText, programType) => get(API, "programs", { searchText, programType });
export const fetchProgram  =                        id =>   get(API, `programs/${id}`);
export const createProgram =                   payload => post(API, "programs", payload);

/* **************** PROVIDERS ***************** */
export const fetchProviders = filters => get(API, "providers", filters);

// /* **************** COUNTRIES ***************** */
export const fetchCountries = searchText => get(API, "countries", { searchText });

// /* **************** GENRES ***************** */
export const fetchGenres = searchText => get(API, "genres", { searchText });

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);