import React from 'react'

import Tags from '../../../ui/editorFields/tags'
import * as StarAPI from '../../../../apis/star'
import { handleRequestFailed } from '../../../../core/services/errorhandling'

export default class AssetTags extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			allTags: [],
			tags: props.asset.tags,
			movedTag: null,
			movedTagNewIndex: null,
		};
	}

	componentDidMount() {
		// Get the list of all tags in Star so that we can offer some suggestions when the user starts typing
		StarAPI.fetchTags().then(response => {
			this.setState({ allTags: response.items });
		}, handleRequestFailed);
	}

	handleChange = newList => {
		// We store the moved tag in state (in handleMove)
		if (this.state.movedTag !== null) {
			const payload = { tagId: this.state.movedTag.id, ordinal: this.state.movedTagNewIndex };
			StarAPI.moveTagOnAsset(this.props.asset.id, payload)
				.then(response => {
					this.setState({ tags: response.tags });
				}, handleRequestFailed);

			this.setState({
				movedTag: null,
				movedTagNewIndex: null,
			});
		}
	}

	// Invoked when dragging a tag over another tag, we wait with updating the asset until the tag is dropped
	handleMove = (movedTag, newIndex) => this.setState({ movedTag, movedTagNewIndex: newIndex });

	handleDelete = deletedTag => {
		const payload = { tagId: deletedTag.id };
		StarAPI.removeTagFromAsset(this.props.asset.id, payload)
			.then(response => {
				this.setState({ tags: response.tags });
			}, handleRequestFailed);	
	}

	handleAdd = newTag => {
		const payload = { ...newTag, type: "metadata" };
		StarAPI.addTagToAsset(this.props.asset.id, payload)
			.then(response => {
				this.setState({ tags: response.tags });
			}, handleRequestFailed);
	}

	render() {
		const options = {
			createNewTags: true,
			autoComplete: false,
		};

		return (
			<div className="asset-tags">
				<Tags
					formData={this.state.tags}
					uiSchema={options}
					dataSourceConfig={{ value: "id", text: "displayName" }}
					suggestions={this.state.allTags}
					onChange={this.handleChange}
					onMoveTag={this.handleMove}
					readonly={this.props.readOnly}
					onDelete={this.handleDelete}
					onAdd={this.handleAdd}
				/>
			</div>
		);
	}
}