import React, { Component } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import isNumber from 'lodash/isNumber'

import './muiSelect.css'

export default class MuiSelect extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.renderMenuItems = this.renderMenuItems.bind(this);

		this.state = {
			opt: []
		};
	}

	componentDidMount() {
		const { options } = this.props;
		if (options) {
			const { dataSource, enumOptions } = options;
			if (typeof (dataSource) === "function") {
				dataSource().then(response => {
					this.setState({ opt: response.items });
				});
			} else if (Array.isArray(dataSource)) {
				this.setState({ opt: dataSource });
			} else if (Array.isArray(enumOptions)) {
				this.setState({ opt: enumOptions });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { options } = nextProps;
		if (options) {
			const { dataSource, enumOptions } = options;
			if (Array.isArray(dataSource)) {
				this.setState({ opt: dataSource });
			} else if (Array.isArray(enumOptions)) {
				this.setState({ opt: enumOptions });
			}
		}
	}
	

	handleChange(event, index) {
		const { onChange, options } = this.props;

		let value = event.target.value;
		if (!options?.skipIntCheck && !isNaN(parseInt(value))) {
			value = parseInt(value);
		}
		onChange(value);
	}

	renderMenuItems() {
		const { value, options } = this.props;
		const { opt } = this.state;

		const { hiddenValues, clearable } = options;

		let menuItems = [];
		if (clearable) {
			menuItems.push(
				<MenuItem
					key="deselect"
					value={null}
				>
					<span style={{ color: "var(--attention-color)" }}>CLEAR</span>
				</MenuItem>
			);
		}
		opt.forEach((opt, i) => {
			if (!hiddenValues || (!hiddenValues.includes(opt.id) && !hiddenValues.includes(opt.value))) {
				const rightText = opt.rightText ? <span style={{ color: "var(--text-light-color)" }}>{opt.rightText}</span>: null;
				const content = (
					<div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%" }}>
						<span>{opt.name || opt.label || opt.id }</span>
						{rightText}
					</div>
				);

				if (opt.divider) {
					menuItems.push(
						<MenuItem
							key={`mi${i}`}
							disabled={true}
						>
							{content}
						</MenuItem>
					);
				} else {
					let itemValue = opt.id || opt.value || opt.label || "ERROR: NO VALUE!";
					// Make sure an option can have id: 0
					if (isNumber(opt.id) && opt.id == 0) {
						itemValue = opt.id;
					} else if (isNumber(opt.value) && opt.value == 0) {
						itemValue = opt.value;
					}

					menuItems.push(
						<MenuItem
							key={`mi${i}${itemValue}`}
							value={itemValue.toString()}
							disabled={opt.disabled}
						>
							{content}
						</MenuItem>
					);
				}
			}
		});

		return menuItems;
	}

	render() {
		if (!this.state.opt.length) {
			return null;
		}

		const { value, label, placeholder = "(Make a selection)", readonly = false, disabled = false, options, id, required } = this.props;

		const { fullWidth = false } = options;
		const styles = fullWidth
			? { width: "calc(100% - 40px)", marginTop: "15px" }
			: { width: "256px", marginTop: "15px" };

		const valueString = value?.toString() ?? "";
		const labelText = `${label}${required ? " *" : ""}`;

		return (
			<FormControl
				variant="standard"
				style={styles}
			>
				<InputLabel id={`c6-mui-select-label-${id}`}>{labelText}</InputLabel>
				<Select
					className="c6-mui-select"
					labelId={`c6-mui-select-label-${id}`}
					value={valueString}
					onChange={this.handleChange}
					label={labelText}
					variant="standard"
					placeholder={placeholder}
					disabled={disabled || readonly}
					MenuProps={{ className: "c6-mui-select-menu" }}

					// HACK because mui hides the input using opacity=0, but we use opacity=0.45 on disabled elements
					inputProps={disabled || readonly ? { style: { opacity: 0 }} : {}}
				>
					{this.renderMenuItems()}
				</Select>
			</FormControl>
		);
	}
}