import React from 'react'
import throttle from 'lodash/throttle'
import { browserHistory } from 'browserHistory'

import { CometLogo } from '../../components/comet'
import { hasAccessToPath, hasRole } from '../services/auth'
import { useLocalStorage } from '../hooks/useStorage'

import appConfig from 'config'
import './sideNavigation.css'

const SideNavigation = (props) => {
    const {
        user,
    } = props;

    const ref = React.useRef(null);
    const [pinned, setPinned] = useLocalStorage("c6-side-nav-pinned", false);
    const [expand, setExpand] = React.useState(pinned);

    const currentVersion = document.querySelector("html").getAttribute("data-version");
    const version = currentVersion !== "#version#"
        ? `v${currentVersion}`
        : "local dev server";

    useOpenAndCloseOnMouseOver(ref, expand, setExpand, pinned);

    const onPinExpandClick = React.useCallback(
        (e) => {
            e.stopPropagation();
            if (pinned) {
                setPinned(false);
                setExpand(false);
            } else if (expand) {
                setPinned(true);
            }
        },
        [pinned, expand, setPinned, setExpand]
    );

    const onHomeClick = React.useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window.location.pathname !== "/") {
                browserHistory.push({ pathname: "/" });
            }
        },
        []
    );

    const pinExpandButtonIcon = pinned ? "icon-expand_less" : "icon-pin";

    return (
        <div
            className={`c6-side-navigation ${expand ? "expand" : ""} ${pinned ? "pinned" : ""}`}
            ref={ref}
            onClick={() => {
                if (!expand) {
                    setExpand(true);
                }
            }}
        >
            <div
                className="section comet"
                onClick={onHomeClick}
            >
                <CometLogo fill={appConfig.features.headerHighlightedTextBackgroundColor} />
                <div className="info">
                    <div className="title">{appConfig.product ?? "Comet"}</div>
                    <div className="version">{version}</div>
                </div>
                <div className="buttons">
                    <button
                        className={`pin-expand-button ${pinExpandButtonIcon} ${pinned ? "pinned" : ""}`}
                        onClick={onPinExpandClick}
                    />
                </div>
            </div>
            <div className="section new-version">
                <span className="newVersionAvailable hide">(please <a className="c6-link" onClick={reloadBrowser}>reload your browser</a><br/> for a newer version)</span>
            </div>
            <div className="section modules">
                <Modules
                    {...props}
                    navIsExpanded={expand}
                    openNav={() => setExpand(true)}
                />
            </div>
            <div className="section account">
                {appConfig.features.enableSupportBeacon && (
                    <div className="help" onClick={openBeacon}>
                        <div className="icon-help"><span>Help</span></div>
                    </div>
                )}

                <div
                    className="user"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onToggleUserSettingsOpen();
                    }}>
                    <div className="icon-person"><span>{user.name}</span></div>
                </div>
            </div>
        </div>
    );
};

export default SideNavigation;

const Modules = (props) => {
    const moduleStructure = getModuleStructure();
    return (
        <React.Fragment>
            {moduleStructure.map((node) => (
                <Module
                    key={node.key ?? node.displayName}
                    node={node}
                    navIsExpanded={props.navIsExpanded}
                    openNav={props.openNav}
                    isRoot={true}
                />
            ))}
        </React.Fragment>
    );
};

function Module(props) {
    const { node, navIsExpanded, openNav, isRoot } = props;
    const isActive = isModuleActive(node);
    const isChildActive = isModuleChildActive(node);
    let icon = "";
    if (isActive && !isRoot) {
        // icon = "icon-arrow_right_alt";
    } else if (node.icon) {
        icon = `icon-${node.icon}`;
    } else if (node.url) {
        // icon = "icon-circle-small";
    }

    const [expand, setExpand] = React.useState(!isRoot || isActive);

    let className = "node";
    className += isActive ? " active" : "";
    className += isChildActive ? " active-child" : "";
    className += isRoot ? " root" : "";
    className += expand ? " expand" : "";
    className += node.url === "/" ? " home" : "";
    className += node.children && !isRoot ? " folder" : "";

    return (
        <div
            key={node.key ?? node.displayName}
            className={className}
        >
            {/* We use <a> instead of <Link> from react-router because their logic for active routes does not work well with nested routes */}
            <a
                className={`node-header ${icon}`}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (node.url) {
                        browserHistory.push({ pathname: node.url });
                    }

                    if (node.children?.length && isRoot && navIsExpanded) {
                        setExpand(!expand);
                    }

                    if (!navIsExpanded) {
                        openNav();
                        setExpand(true);
                    }

                    if (!expand) {
                        const nodeElement = e.target.closest(".node");
                        setTimeout(
                            () => {
                                nodeElement?.scrollIntoView({ behavior: "smooth", block: "center" });
                            },
                            100
                        );
                    }
                }}
                href={node.url}
            >
                <span>{node.displayName}</span>
                {node.children?.length > 0 && isRoot && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setExpand(!expand);
                        }}
                        className={`expand ${expand ? "icon-expand_less" : "icon-expand_more"}`}
                    />
                )}
            </a>
            {node.children && expand && (
                <div className="node-children">
                    {node.children.map(child => <Module {...props} key={child.key ?? child.displayName} node={child} isRoot={false} />)}
                </div>
            )}
        </div>
    );
}

function reloadBrowser(e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload(true);
}

function openBeacon(e) {
    e.stopPropagation();
    if (window.Beacon) {
        window.Beacon("toggle");
    } else {
        console.warn("No window.Beacon :(");
    }
}

function isModuleActive(module) {
    const pathname = location.pathname.replace(appConfig.app.basePath ?? "", "").replace("//", "/");
    if (module.url === "/") {
        return pathname === "/";
    }

    return (pathname + "/").startsWith("/" + module.url + "/");
    //     || module.children?.some(child => isModuleActive(child));
}

function isModuleChildActive(module) {
    return module.children?.some(child => isModuleActive(child) || isModuleChildActive(child));
}

let mouseOutsideTimeout = null;
const mouseOutsideTimeoutDuration = 50;
let mouseInsideTimeout = null;
const mouseInsideTimeoutDuration = 150;
function useOpenAndCloseOnMouseOver(ref, expand, setExpand, pinned) {
    const mouseOutsideTimeoutCallback = React.useCallback(
        () => {
            setExpand(false);
            clearTimeout(mouseOutsideTimeout);
            mouseOutsideTimeout = null;
        },
        [setExpand]
    );
    const mouseOutsideTimeoutSet = React.useCallback(
        throttle(
            (e) => {
                const hoverTarget = e.target;
                const isChildOfRef = ref.current?.contains(hoverTarget);
                if (!isChildOfRef && mouseOutsideTimeout === null) {
                    mouseOutsideTimeout = setTimeout(mouseOutsideTimeoutCallback, mouseOutsideTimeoutDuration);
                } else if (isChildOfRef && mouseOutsideTimeout !== null) {
                    clearTimeout(mouseOutsideTimeout);
                    mouseOutsideTimeout = null;
                }
            },
            50,
            { leading: false, trailing: true }
        ),
        [mouseOutsideTimeoutCallback]
    );
    const mouseInsideTimeoutCallback = React.useCallback(
        () => {
            setExpand(true);
            clearTimeout(mouseInsideTimeout);
            mouseInsideTimeout = null;
        },
        [setExpand]
    );
    const mouseInsideTimeoutSet = React.useCallback(
        throttle(
            (e) => {
                const hoverTarget = e.target;
                const isChildOfRef = ref.current?.contains(hoverTarget);
                if (isChildOfRef && mouseInsideTimeout === null) {
                    mouseInsideTimeout = setTimeout(mouseInsideTimeoutCallback, mouseInsideTimeoutDuration);
                } else if (!isChildOfRef && mouseInsideTimeout !== null) {
                    clearTimeout(mouseInsideTimeout);
                    mouseInsideTimeout = null;
                }
            },
            50,
            { leading: false, trailing: true }
        ),
        [mouseInsideTimeoutCallback]
    );

    const mouseOutsideDocumentListener = React.useCallback(
        () => {
            clearTimeout(mouseInsideTimeout);
            mouseInsideTimeout = null;
            mouseInsideTimeoutSet.cancel(); // cancel throttled calls

            clearTimeout(mouseOutsideTimeout);
            mouseOutsideTimeout = null;
            mouseOutsideTimeoutSet.cancel(); // cancel throttled calls

            if (!pinned) {
                setExpand(false);
            }
        },
        [pinned, setExpand]
    );

    React.useEffect(
        () => {
            if (expand && !pinned) {
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.addEventListener("mousemove", mouseOutsideTimeoutSet);
            } else {
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                window.addEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseOutsideTimeout);
                mouseOutsideTimeout = null;
            }

            if (expand || pinned) {
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseInsideTimeout);
                mouseInsideTimeout = null;
            }
        },
        [expand, pinned, mouseOutsideTimeoutSet, mouseInsideTimeoutSet]
    );

    React.useEffect(
        () => {
            document.addEventListener("mouseleave", mouseOutsideDocumentListener);

            return () => {
                document.removeEventListener("mouseleave", mouseOutsideDocumentListener);
                window.removeEventListener("mousemove", mouseOutsideTimeoutSet);
                window.removeEventListener("mousemove", mouseInsideTimeoutSet);
                clearTimeout(mouseInsideTimeout);
                mouseInsideTimeout = null;
                clearTimeout(mouseOutsideTimeout);
                mouseOutsideTimeout = null;
            };
        },
        [pinned]
    );
}

function getModuleStructure(moduleStructure = fullModuleStructure) {
    return moduleStructure.map(module => {
        const filteredChildren = module.children?.filter(m => hasModuleAccess(m));
        return {
            ...module,
            children: filteredChildren ? getModuleStructure(filteredChildren) : null,
        };
    }).filter(module => module.children?.length !== 0 || module.url === "/");
}

export function hasModuleAccess(module) {
    if (module.shieldKey) {
        const [modulePart, appPart] = module.shieldKey.split(".");
        const hasAppAccess = hasAccessToPath([{ module: modulePart, app: appPart }]);
        let hasRoleAccess = true;
        if (module.shieldRole) {
            // Use hasRole instead of "roles" argument in hasAccessToPath since that one is less strict
            hasRoleAccess = hasRole(module.shieldRole);
        }
        return hasAppAccess && hasRoleAccess;
    }
    return true;
}

export const fullModuleStructure = [
    {
        key: "vod",
        displayName: "VOD",
        icon: "play_circle_filled",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "Plan",
                        url: "planner/calendar",
                        shieldKey: "planner.calendar",
                    },
                    {
                        displayName: "Traffic",
                        url: "traffic/programs",
                        shieldKey: "traffic.programs",
                    },
                ],
            },
            {
                displayName: "Distribution",
                children: [
                    {
                        displayName: "API services",
                        url: "vod/packages-new",
                        shieldKey: "vod.packages-new",
                    },
                    {
                        displayName: "DAI",
                        url: "vod/dai",
                        shieldKey: "vod.dai",
                    },
                    {
                        displayName: "Kavi",
                        url: "vod/kavi",
                        shieldKey: "vod.kavi",
                    },
                ],
            },
            {
                displayName: "Rights",
                children: [
                    {
                        displayName: "Contracts",
                        url: "acq/contracts-vod",
                        shieldKey: "acq.contracts",
                    },
                    {
                        displayName: "Licenses",
                        url: "acq/licenses-vod",
                        shieldKey: "acq.licenses",
                    }
                ]
            },
        ],
    },
    {
        key: "live",
        displayName: "Live",
        icon: "television-classic",
        children: [
            {
                displayName: "Plan",
                children: [
                    {
                        displayName: "MTV",
                        url: "eventplanner/events/mtv",
                        shieldKey: "eventplanner.cms-events-mtv",
                    },
                    {
                        displayName: "TV4",
                        url: "eventplanner/events/tv4",
                        shieldKey: "eventplanner.cms-events-tv4",
                    },
                    {
                        displayName: "All",
                        url: "eventplanner/events",
                        shieldKey: "eventplanner.cms-events",
                    }
                ]
            },
            {
                displayName: "Metadata",
                children: [
                    {
                        displayName: "MTV",
                        url: "eventplanner/events-metadata/mtv",
                        shieldKey: "eventplanner.cms-events-metadata-mtv",
                    },
                    {
                        displayName: "TV4",
                        url: "eventplanner/events-metadata/tv4",
                        shieldKey: "eventplanner.cms-events-metadata-tv4",
                    },
                    {
                        displayName: "All",
                        url: "eventplanner/events-metadata",
                        shieldKey: "eventplanner.cms-events-metadata",
                    }
                ]
            },
            {
                key: "group1",
                children: [
                    {
                        displayName: "Encoder allocation",
                        url: "eventplanner/resources",
                        shieldKey: "eventplanner.resources",
                    },
                    {
                        displayName: "MCR",
                        url: "eventplanner/events-mcr",
                        shieldKey: "eventplanner.mcr",
                    },
                    {
                        displayName: "Admin",
                        url: "eventplanner/admin-live",
                        shieldKey: "eventplanner.admin",
                    },
                ],
            },
        ],
    },
    {
        key: "linear",
        displayName: "Linear",
        icon: "view_week1",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "Ingest",
                        url: "schedules/changes",
                        shieldKey: "schedules.changes",
                    },
                    {
                        displayName: "EPG",
                        url: "metadata/linear-epg",
                        shieldKey: "metadata.linear",
                    },
                    {
                        displayName: "Traffic",
                        url: "schedules/releases",
                        shieldKey: "schedules.releases",
                    },
                ],
            },
            {
                displayName: "Rights",
                children: [
                    {
                        displayName: "Catchup",
                        url: "metadata/schedule-catchup",
                        shieldKey: "metadata.schedule",
                    },
                    {
                        displayName: "Contracts",
                        url: "acq/contracts-linear",
                        shieldKey: "acq.contracts",
                    },
                    {
                        displayName: "Licenses",
                        url: "acq/licenses-linear",
                        shieldKey: "acq.licenses",
                    },
                ],
            },
            {
                key: "group2",
                children: [
                    {
                        displayName: "Admin",
                        url: "eventplanner/admin-linear",
                        shieldKey: "eventplanner.admin",
                    },
                ],
            },
        ],
    },
    {
        key: "metadata",
        displayName: "Metadata",
        icon: "movie",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "Broadcast texts",
                        url: "metadata/schedule",
                        shieldKey: "metadata.schedule",
                    },
                    {
                        displayName: "Program families",
                        url: "metadata/families",
                        shieldKey: "metadata.families",
                    },
                    {
                        displayName: "Tags",
                        url: "metadata/tags",
                        shieldKey: "metadata.tags",
                    },
                ]
            },
            {
                displayName: "Catalogues",
                children: [
                    {
                        displayName: "All",
                        url: "metadata/programs/all",
                        shieldKey: "metadata.library-all",
                    },
                    {
                        displayName: "Britbox",
                        url: "metadata/programs/britbox",
                        shieldKey: "metadata.library-britbox",
                    },
                    {
                        displayName: "C More",
                        url: "metadata/programs/cmore",
                        shieldKey: "metadata.library-cmore",
                    },
                    {
                        displayName: "C More NO",
                        url: "metadata/programs/cmore-no",
                        shieldKey: "metadata.library-cmore-no",
                    },
                    {
                        displayName: "C More DK",
                        url: "metadata/programs/cmore-dk",
                        shieldKey: "metadata.library-cmore-dk",
                    },
                    {
                        displayName: "Hayu",
                        url: "metadata/programs/hayu",
                        shieldKey: "metadata.library-hayu",
                    },
                    {
                        displayName: "Katsomo+",
                        url: "metadata/programs/katsomo",
                        shieldKey: "metadata.library-katsomo",
                    },
                    {
                        displayName: "MTV",
                        url: "metadata/programs/mtvfi",
                        shieldKey: "metadata.library-mtvfi",
                    },
                    {
                        displayName: "TV4",
                        url: "metadata/programs/tv4",
                        shieldKey: "metadata.library-tv4",
                    },
                    {
                        displayName: "TV4 Play+",
                        url: "metadata/programs/tv4playplus",
                        shieldKey: "metadata.library-tv4playplus",
                    },
                ]
            },
            {
                displayName: "Translations",
                children: [
                    {
                        displayName: "Swedish",
                        url: "metadata/translation-swedish",
                        shieldKey: "metadata.translation",
                        shieldRole: "metadata.swedish",
                    },
                    {
                        displayName: "Norwegian",
                        url: "metadata/translation-norwegian",
                        shieldKey: "metadata.translation",
                        shieldRole: "metadata.norwegian",
                    },
                    {
                        displayName: "Danish",
                        url: "metadata/translation-danish",
                        shieldKey: "metadata.translation",
                        shieldRole: "metadata.danish",
                    },
                    {
                        displayName: "Finnish",
                        url: "metadata/translation-finnish",
                        shieldKey: "metadata.translation",
                        shieldRole: "metadata.finnish",
                    },
                ]
            }
        ],
    },
    {
        key: "star",
        displayName: "Star MAM",
        icon: "star",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "Assets",
                        url: "star/search",
                        shieldKey: "star.assets",
                    },
                    {
                        displayName: "Albums",
                        url: "star/albums",
                        shieldKey: "star.albums",
                    },
                    {
                        displayName: "Trashcan",
                        url: "star/trashcan",
                        shieldKey: "star.trashcan",
                    },
                    {
                        displayName: "Admin",
                        url: "star/admin",
                        shieldKey: "star.admin",
                    },
                ],
            },
        ],
    },
    {
        key: "storefront",
        displayName: "Storefront",
        // icon: "storefront",
        icon: "storefront_custom_4",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "On Air",
                        url: "selections/service/2",
                        shieldKey: "selections.onair",
                    },
                    {
                        displayName: "OTT",
                        url: "selections/service/1",
                        shieldKey: "selections.ott",
                    },
                    {
                        displayName: "Help",
                        url: "selections/help",
                        shieldKey: "selections.help",
                    },
                    {
                        displayName: "Admin",
                        url: "selections/admin",
                    },
                ],
            },
        ],
    },
    {
        key: "shield",
        displayName: "Shield security",
        icon: "security",
        children: [
            {
                key: "group1",
                children: [
                    {
                        displayName: "Comet users",
                        url: "shield/logins-users",
                        shieldKey: "shield.users",
                    },
                    {
                        displayName: "API keys",
                        url: "shield/logins-apikeys",
                        shieldKey: "shield.users",
                    },
                    {
                        displayName: "Horizon users",
                        url: "shield/horizon",
                        shieldKey: "shield.horizon",
                    },
                    {
                        displayName: "One Stop Drop users",
                        url: "shield/onestopdrop",
                        shieldKey: "shield.onestopdrop", 
                    },
                    {
                        displayName: "Press users",
                        url: "shield/press",
                        shieldKey: "shield.press",
                    },
                    {
                        displayName: "Admin",
                        url: "shield/admin",
                        shieldKey: "shield.modules",
                    }
                ],
            },
        ],
    },
];
