import React from 'react'
import PropTypes from 'prop-types'
import './search.css'

const ENTER_KEY_CODE = 13;

export default class Search extends React.PureComponent {

	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onEnterKeyDown: PropTypes.func,
		onBlur: PropTypes.func,
		disabled: PropTypes.bool,
		searchText: PropTypes.string,
		placeholder: PropTypes.string,
		autocomplete: PropTypes.string,
		big: PropTypes.bool,
		autoFocus: PropTypes.bool,
		onFocus: PropTypes.func,
		inputRef: PropTypes.func,
		suggestions: PropTypes.array,
	}

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.setFocus = this.setFocus.bind(this);

		this.el = null;

		const { searchText = "" } = this.props;

		this.state = {
			value: searchText,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.searchText !== this.props.searchText) {
			this.setState({value: nextProps.searchText});
			if (this.el && this.props.autoFocus) {
				setTimeout(() => this.el.focus(), 0);
			}
		}
	}

	handleChange(e) {
		this.setState({value: e.target.value});
		this.props.onChange(e);
	};

	handleKeyDown(e) {
		if (e.keyCode === ENTER_KEY_CODE && typeof(this.props.onEnterKeyDown) === "function") {
			e.target.blur();
			this.setState({value: e.target.value});
			this.props.onEnterKeyDown(e);
		}
	}

	setFocus(el) {
		if (this.props.autoFocus && el) {
			this.el = el;
			setTimeout(() => el.focus(), 0);
		}
	}

	render() {
		const {
			disabled = false,
			placeholder = "Search",
			name = "searchText",
			// autoFocus = false,
			onFocus,
			onBlur,
			inputRef,
			suggestions = [],
			autocomplete,
			title,
		} = this.props;

		const classNames = this.props.big ? 'c6-search big' : 'c6-search';

		const hasSuggestions = suggestions.length > 0;
		const datalist = hasSuggestions
			? (
				<datalist id="c6-search-suggestions">
					{suggestions.map(s => <option key={s} value={s} />)}
				</datalist>
			)
			: null;

			return (
			<label className={classNames}>
				<input
					autoComplete={autocomplete}
					ref={inputRef || this.setFocus}
					/*autoFocus={autoFocus}*/
					disabled={disabled}
					type="search"
					name={name}
					onChange={this.handleChange}
					onKeyDown={this.handleKeyDown}
					onBlur={onBlur}
					onFocus={onFocus}
					placeholder={placeholder}
					value={this.state.value || ""}
					list={hasSuggestions ? "c6-search-suggestions" : null}
					title={title}
				/>
				<span className="icon-search" />
				{datalist}
			</label>
		);
	}
}