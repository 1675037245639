import React from 'react'
import PropTypes from 'prop-types'
import Picker from './picker'

const DatePicker = props => (
	<Picker
		openIcon={props.range ? "date_range" : "insert_invitation"}
		dateFormat="Y-m-d"
		mode={props.range ? "range" : "single"}
		{...props} />
);

export default DatePicker;

DatePicker.propTypes = {
	range: PropTypes.bool,
	showConfirmButton: PropTypes.bool,
	showTodayButton: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	format: PropTypes.string,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string, // Single date
		PropTypes.array // Date range
	]),
	value: PropTypes.oneOfType([
		PropTypes.string, // Single date,
		PropTypes.array // Date range
	])
};

DatePicker.defaultProps = {
	range: false,
	showConfirmButton: false,
	showTodayButton: true,
	format: "YYYY-MM-DD"
};