import React from 'react'
import localStyles from './alignment.module.css'

export class UICenter extends React.Component {
	render() {
		return (
			<div style={this.props.style} className={localStyles.center}>
				<div>{this.props.children}</div>
			</div>
		);
	};
};