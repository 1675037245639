import React from 'react'
import { Link } from 'react-router'

import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Search from '../../../components/ui/controls/search'
import Button from '../../../components/ui/controls/button'
import { getStatus, /*getMissing,*/ getWorkType } from '../shared/utils'

const TEXT_CREATE = "Create TODO"
const ICON_CREATE = "add"

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	handleCreateNew,
	worklist,
}) => {

	return (
		<Filter>
			<Left>
				<h1>
					<Link
						to={{
							pathname: "/onestopdrop/worklists",
							state: { preventRedirect: true },
						}}
					>
						Worklists
					</Link> / {title}
				</h1>
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
				/>
			</Left>
			<Right>
				<Switch
					name="status"
					title="Status"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getStatus()}
					currentState={filters.filter.status}
				/>
				<Switch
					name="workType"
					title="Type"
					unselected={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getWorkType()}
					currentState={filters.filter.workType}
				/>
				{/*
					<Switch
						name="filter"
						title="Missing"
						unselected={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getMissing()}
						currentState={filters.filter}
					/>
				 */}
				{worklist?.allowUserCreatedItems && (
					<Button
						type={ICON_CREATE}
						title={TEXT_CREATE}
						onClick={handleCreateNew}
					/>
				)}
			</Right>
		</Filter>
	);
}