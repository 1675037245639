import { createPatch as createRFC6902Patch } from 'rfc6902'

export function getTemplateWithValues(template, values, parentName) {
	
	const mappedData = [...template]
		.filter(t =>
			t.componentType === "filter"
			|| t.componentType !== "property" && t.style?.visible
			|| t.componentType === "metadata"
		)
		.map(t => {
			const value = getValue(values, t.name, parentName);
			const pathString = `${parentName ?? ""}${parentName ? "." : ""}${t.name}`;

			const childTemplates = (t.children ?? []).filter(c => {
				const visibilityKey = `${c.name}_visible`;
				return value?.[visibilityKey] === undefined || value?.[visibilityKey] === true; 
			});

			const childrenWithValues = getTemplateWithValues(
				childTemplates,
				values,
				pathString,
			);

			return {
				...t,
				children: childrenWithValues,
				value,
			};
		});

	return mappedData;
}

export function getValue(values, valueName, parentName) {
	let value;
	
	// For root level values
	if (!parentName) {
		value = values[valueName];
	}

	/*
	* No value was found at the root level, and this is a nested attribute (has parentName).
	* Example:
	* parentName: "ott.encoder"
	* valueName: "start"
	* values: {
	* 	ott: {
	* 		encoder: {
	* 			start: "2020-01-01 00:00", <-- code below will get this value
	* 		}
	* 	}
	* }
	*/
	if (value === undefined && parentName) {
		const path = parentName.split(".");
		let v = values[path[0]] ?? undefined;
		for (let p of path.slice(1)) {
			v = v?.[p] ?? undefined;
		}
		v = v?.[valueName];
		value = v;
	}

	return value;
}

export function getEntityWithUpdatedValue(original = {}, path = [], value) {
	if (typeof path === "string") {
		path = path.split(".");
	}
	const [current, ...restPath] = path;
	const currentValue = restPath?.length
		? getEntityWithUpdatedValue(original[current], restPath, value)
		: value;
	
	// If "current" is a number, it is the index of an array, which means that "original" needs to be an array
	if (typeof current === "number") {
		const arr = [...original];
		arr[current] = currentValue;
		return arr;
	}

	return {
		...original,
		[current]: currentValue
	} 
}

export function createPatch(originalItem, updatedItem, replaceObjectFields = false) {
	if (replaceObjectFields) {
		return createRFC6902Patch(originalItem, updatedItem);
	}
	
	return createRFC6902Patch(originalItem, updatedItem, avoidRecursingObjects);
}

// https://github.com/chbrown/rfc6902#createpatchinput-any-output-any-diff-voidablediff-operation
// This will make sure that we get one (and only one) "replace" operation when changing an object value
// instead of multiple remove/add/replace
export function avoidRecursingObjects(input, output, ptr) {
	const path = ptr.toString();
	if (
		path !== "" // Skip this check for the root object
		&& input != output
		&& (["string", "number"].includes(typeof input?.id) || ["string", "number"].includes(typeof output?.id))
		&& input?.id !== output?.id
	) {
		return [{ op: "replace", path: path, value: output }];
	}

	// Return undefined to fall back to default behaviour
	return undefined;
}