import React, { Component } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'

import Button from '../../controls/button'

import withDnDContext from '../../../../core/services/withDnDContext'
import { fetchCredits } from '../../../../apis/metadata'

import Person from './person'
import { generateKey } from '../index'
import './crew.css'

const KEYS = {
	ENTER: 13,
	TAB: 9,
	BACKSPACE: 8,
	UP_ARROW: 38,
	DOWN_ARROW: 40,
	ESCAPE: 27
};
const DEFAULT_DELIMITERS = [KEYS.ENTER, KEYS.TAB];

class CrewCategory extends Component {

	constructor(props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
		this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
		this.handleSuggestionHover = this.handleSuggestionHover.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);

		this.filteredSuggestions = this.filteredSuggestions.bind(this);
		this.movePerson = this.movePerson.bind(this);
		this.dropPerson = this.dropPerson.bind(this);
		this.setPerson = this.setPerson.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.renderPersons = this.renderPersons.bind(this);

		this.inputRefs = {};
		this.fetchCreditsThrottled = throttle((types, query, callback, error) => fetchCredits(types, query).then(callback, error), 1000);
		this.latestFetchTimestamp = 0;

		this.state = {
			persons: props.formData || [],
			suggestions: [],
			query: "",
			selectedIndex: -1,
			selectionMode: false,
			activePersonIndex: null
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ persons: nextProps.formData });
	}

	filteredSuggestions(_query = "", suggestions = [], persons = [], autoComplete = true, activePersonIndex = null) {
		const query = _query.trim().toLowerCase();
		const activePersonId = activePersonIndex != null && persons[activePersonIndex] ? persons[activePersonIndex].id : null;
		let personIds = persons.map(item => item.id);
		if (activePersonId) {
			personIds = personIds.filter(id => id != activePersonId);
		}

		if (query === "") {
			return [];
		}

		const match = (_item, query) => {
			const item = _item.trim().toLowerCase();
			// If autoComplete is true, allow partial match
			if (autoComplete) {
				return item.includes(query);
			}

			// Else, only allow exact match
			return item === query;
		};

		// Return suggestions that match the query and are not already added
		return suggestions.filter(item => {
			const title = item.title || item.name;
			const alreadySelected = !!personIds.find(personId => personId == item.id);
			return !alreadySelected && match(title, query);
		}).sort((a, b) => {
			// Put exact matches at the top
			const titleA = (a.title || a.name).trim().toLowerCase();
			if (titleA === query) {
				return -1;
			}

			const titleB = (b.title || b.name).trim().toLowerCase();
			if (titleB === query) {
				return 1;
			}

			return titleA.localeCompare(titleB);
		});
	}

	handleInputChange(e) {
		const { categoryKey } = this.props;
		const { persons, activePersonIndex, selectedIndex, suggestions } = this.state;

		const query = e.target.value.trim();
		const filteredSuggestions = this.filteredSuggestions(query, suggestions, persons, true, activePersonIndex);

		this.setState({ isLoading: true });
		const timestamp = Date.now();
		this.latestFetchTimestamp = timestamp;
		const types = categoryKey.substring(0, categoryKey.length - 1); // Remove trailing "s"
		this.fetchCreditsThrottled(types, query, response => {
			if (this.latestFetchTimestamp === timestamp) {
				this.setState({
					isLoading: false,
				});
			}
			this.setState({
				suggestions: this.filteredSuggestions(query, response.items, persons, true, activePersonIndex)
			})
		}, error => {
			if (this.latestFetchTimestamp === timestamp) {
				this.setState({
					isLoading: false,
				});
			}
			console.error(error);
		});


		let newSelectedIndex = selectedIndex;
		if (newSelectedIndex >= filteredSuggestions.length) {
			newSelectedIndex = filteredSuggestions.length - 1;
		}

		this.setState({
			query,
			suggestions: filteredSuggestions,
			selectedIndex: newSelectedIndex
		});
	}

	handleInputBlur(e) {
		e.persist();

		let keepIndex = false;
		Object.values(this.inputRefs).forEach(index => {
			if (index && (index["main"] == document.activeElement || index["role"] == document.activeElement)) {
				keepIndex = true;
			}
		});
		this.setState({
			activePersonIndex: keepIndex ? this.state.activePersonIndex : null,
			query: "",
			suggestions: []
		});
	}

	handleInputKeyDown(e) {
		const { query, suggestions, selectionMode, persons, activePersonIndex } = this.state;
		let selectedIndex = this.state.selectedIndex;

		// Delimiter pressed without shift key (default delimiters are TAB and ENTER)
		const delimiters = DEFAULT_DELIMITERS;
		if (delimiters.includes(e.keyCode) && !e.shiftKey) {
			if (e.keyCode !== KEYS.TAB || query !== "") {
				e.preventDefault();
			}

			// Call addPerson() with selected suggestion or query
			if (query !== "" || selectedIndex != -1) {
				let selected = query;
				if (selectionMode && selectedIndex != -1) {
					selected = suggestions[selectedIndex];
				}
				this.setPerson(selected, activePersonIndex);
			}
		}

		switch (e.keyCode) {
			// ESCAPE: clear suggestions
			case KEYS.ESCAPE:
				e.preventDefault();
				e.stopPropagation();
				this.setState({
					selectedIndex: -1,
					selectionMode: false,
					suggestions: [],
					activePersonIndex: null,
					query: ""
				});
				break;
			
			case KEYS.TAB:
				// HACK: Focus the role input if TAB is pressed while a main input is focused
				// Tabindex does not solve the problem since the person-component is replaced when we switch from key=reactKey to key=id (and the <body> is focused instead)
				const inputRefsAtIndex = this.inputRefs[activePersonIndex];
				if (!inputRefsAtIndex || inputRefsAtIndex.main !== document.activeElement) {
					break;
				}
				setTimeout(() => inputRefsAtIndex.role.focus(), 0);
				break;

			// UP: Select suggestion above current selection
			case KEYS.UP_ARROW:
				e.preventDefault();

				selectedIndex = selectedIndex <= 0 ? suggestions.length - 1 : selectedIndex - 1;
				this.setState({
					selectedIndex,
					selectionMode: true
				});
				break;

			// DOWN: Select suggestion below current selection
			case KEYS.DOWN_ARROW:
				e.preventDefault();

				selectedIndex = (selectedIndex + 1) % suggestions.length;
				this.setState({
					selectedIndex,
					selectionMode: true
				});
				break;
		}
	}

	handleInputFocus(index) {
		this.setState({ activePersonIndex: index });
	}

	handleSuggestionClick(index, disabled) {
		if (!disabled) {
			this.setPerson(this.state.suggestions[index], this.state.activePersonIndex);
		}
	}

	handleSuggestionHover(index, disabled) {
		if (!disabled) {
			this.setState({
				selectedIndex: index,
				selectionMode: true
			});
		}
	}

	movePerson(dragIndex, hoverIndex) {
		const { persons } = this.state;

		const dragPerson = persons[dragIndex];
		persons.splice(dragIndex, 1);
		persons.splice(hoverIndex, 0, dragPerson);
		this.setState({ persons });
	}

	dropPerson() {
		const { persons } = this.state;
		const { onChange } = this.props;
		onChange(persons);
	}

	setPerson(person, activePersonIndex) {
		const { persons, suggestions, isLoading } = this.state;
		const { onChange, autoComplete, limitToSuggestions = false } = this.props;

		if (isLoading) {
			return;
		}

		// Find possible matches
		const title = (typeof(person) === "object" ? person.title || person.name : person).trim();
		const possibleMatches = this.filteredSuggestions(title, suggestions, persons, autoComplete, activePersonIndex);

		if (limitToSuggestions && possibleMatches.length === 0) {
			return;
		}

		if (possibleMatches.length) {
			person = possibleMatches[0];
		} else {
			person = {
				name: title,
				reactKey: generateKey(),
				id: typeof(person) === "object" ? person.id : -1
			};
		}

		const role = persons[activePersonIndex].role;
		persons[activePersonIndex] = person;
		persons[activePersonIndex].role = role;
		persons[activePersonIndex].name = persons[activePersonIndex].name.trim();
		this.setState({
			persons,
			activePersonIndex: null,
			query: "",
			selectedIndex: -1,
			selectionMode: false
		});
		onChange(persons);
	}

	handleDelete(index) {
		const { onChange } = this.props;
		const { persons } = this.state;

		persons.splice(index, 1);
		this.setState({ persons });
		onChange(persons);
	}

	handleInputRef(ref, index, type) {
		if (!this.inputRefs[index]) {
			this.inputRefs[index] = {};
		}

		this.inputRefs[index][type] = ref;
	}

	handleActorChange(e, index) {
		const { persons } = this.state;
		persons[index].name = e.target.value.trim();
		this.setState({ persons });
		this.props.onChange(persons);
	}

	handleRoleChange(e, index) {
		const { persons } = this.state;
		persons[index].role = e.target.value.trim();
		this.setState({ persons });
		this.props.onChange(persons);
	}

	renderPersons() {
		const {
			readOnly,
			withRole,
			categoryKey
		} = this.props;
		const {
			persons,
			suggestions,
			query,
			activePersonIndex,
			selectedIndex
		} = this.state;

		return persons.map((person, i) => (
			<Person
				key={person.reactKey ?? person.id ?? person.name}
				inputRef={(ref, type) => this.handleInputRef(ref, i, type)}
				person={person}
				categoryKey={categoryKey}
				index={i}
				suggestions={suggestions}
				query={query}
				selectedIndex={selectedIndex}
				isActive={activePersonIndex == i}
				withRole={withRole}
				movePerson={this.movePerson}
				dropPerson={this.dropPerson}
				readOnly={readOnly}
				onDelete={() => this.handleDelete(i)}
				onSuggestionHover={this.handleSuggestionHover}
				onSuggestionClick={this.handleSuggestionClick}
				onInputChange={this.handleInputChange}
				onInputFocus={() => this.handleInputFocus(i)}
				onInputBlur={e => this.handleInputBlur(e, i)}
				onInputKeyDown={this.handleInputKeyDown}
				onActorChange={e => this.handleActorChange(e, i)}
				onRoleChange={e => this.handleRoleChange(e, i)}
				isLoading={this.state.isLoading}
			/>
		));
	}

	render() {
		const {
			id,
			title,
			description,
			readOnly,
		} = this.props;

		const {
			activePersonIndex,
			persons,
		} = this.state;

		const containerClassName = `c6-crew${readOnly ? " read-only" : ""}`;
		const categoryClassName = `c6-crew-category${activePersonIndex != null ? " is-focused" : ""}`;

		if (!persons || persons.length == 0) {
			return null;
		}

		return (
			<div className={categoryClassName}>
				{title && (<label htmlFor={id}>{title}</label>)}
				{description && (<p className="field-description">{description}</p>)}
				<div className={containerClassName}>
					{this.renderPersons()}
				</div>
			</div>
		);
	}
}

export default withDnDContext(CrewCategory);