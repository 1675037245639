import React from 'react'

import SideDrawer from './sideDrawer'
import ModuleMenu from './menu'

import appConfig from 'config'

export default class ModuleChooser extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { location, product, canOpen } = this.props;

		// HACK: We need a flag for deciding how the app behaves instead of going with product names
		// Since Horizon has it's own SideDrawer we're not rendering anything from here
		if (product === "Horizon") {
			return null;
		}

		// HACK: We need a flag for deciding how the app behaves instead of going with product names
		if (product?.includes("Comet") || appConfig.features.enableModuleSideDrawer) {
			const modules = this.props.modules.filter(m => !m.hidden);
			const currentModuleName = getCurrentModuleName(location, modules);

			return (
				<SideDrawer
					primaryText={product}
					highlightPrimaryText={product !== "Comet"}
					secondaryText={currentModuleName}
					canOpen={canOpen}
				>
					<ModuleMenu
						modules={modules}
						currentModule={currentModuleName}
					/>
				</SideDrawer>
			);
		}


		return (
			<div className="c6-product-name">
				<span className="product">
					{product} <span className="newVersionAvailable hide">(please <a className="c6-link" onClick={reloadBrowser}>reload your browser</a> for a newer version)</span>
				</span>
			</div>
		);
	}
}

// HELPERS
function getCurrentModuleName(location, modules = []) {
	if (!modules.length) {
		return "";
	}

	let modulePathnameIndex = 1;
	if (appConfig.app.basePath) {
		// Get number of slashes in basePath variable
		modulePathnameIndex += (appConfig.app.basePath.match(/\//g) || []).length;
	}

	// Get the current path name
	let pathname = location.pathname || location.basename;
	if (!pathname.startsWith("/")) {
		pathname = `/${pathname}`;
	}
	// Get the part of the url that contains the module name
	const modulePathname = pathname.split("/")[modulePathnameIndex].toLowerCase();

	// Find the module that has the same name
	let currentModuleName = "";
	if (modulePathname.length) {
		const currentModule = modules.find(module => (module.Url || module.url).toLowerCase() === `/${modulePathname}`);
		if (currentModule) {
			currentModuleName = currentModule.Name || currentModule.displayName;
		}
		else if (modulePathname !== "unauthorized") {
			currentModuleName = `Module ${modulePathname} missing`;
		}
	}

	return currentModuleName;
}

function reloadBrowser(e) {
	e.preventDefault();
	e.stopPropagation();
	window.location.reload(true);
}