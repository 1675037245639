import moment from 'moment'

export function getSearchPayload({ filters = {}, item }) {
	const { filter, ...rest } = filters;

	const workListId = item.worklist?.workListId;

	return {
		...rest,
		...filter,
		workListId,
	};
}

export const getStatus = () => [
	{ key: "unhandled,complete,inprogress", text: "Any", description: "Todo is either complete or incomplete." },
	{ key: "unhandled,inprogress", text: "To do", description: "Todo is incomplete." },
	{ key: "complete", text: "Complete", description: "Todo is marked as complete." },
	{ key: "ignored", text: "Ignored", description: "Todo is ignored." },
];

// export const getMissing = () => [
// 	{ key: "", text: "Any", description: "Todo is missing something." },
// 	{ key: "missingimages", text: "Images", description: "Todo is missing images." },
// 	{ key: "missingmetadatafile", text: "Metadata", description: "Todo is missing a metadata file." },
// ];

export const getWorkType = () => [
	{ key: "", text: "Any", description: "Any type of todo." },
	{ key: "ageRating", text: "Rating", description: "Todo requires age ratings." },
	{ key: "content", text: "Metadata", description: "Todo requires metadata (images and/or texts)." },
];