import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'

import DrawerToggler from './drawertoggler'

export default class SideDrawer extends React.Component {
	static propTypes = {
		icon: PropTypes.string,
		primaryText: PropTypes.string,
		secondaryText: PropTypes.string,
		children: PropTypes.object,
		highlightPrimaryText: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
		this.handleDrawerClose = this.handleDrawerClose.bind(this);

		this.state = {
			moduleDrawerOpen: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Hide module chooser if we're logged out
		if(!nextProps.canOpen) {
			this.handleDrawerClose();
		}
	}

	handleDrawerToggle() {
		this.setState({ moduleDrawerOpen: !this.state.moduleDrawerOpen });
	}

	handleDrawerClose() {
		this.setState({ moduleDrawerOpen: false });
	}

	render() {
		const {
			icon,
			primaryText,
			highlightPrimaryText,
			secondaryText,
			children,
		} = this.props;

		return (
			<div className="c6-module-chooser">
				<DrawerToggler
					icon={icon}
					open={this.state.moduleDrawerOpen}
					toggle={this.handleDrawerToggle}
					primaryText={primaryText}
					highlightPrimaryText={highlightPrimaryText}
					secondaryText={secondaryText}
				/>
				<Drawer
					anchor="left"
					variant="temporary"
					className="c6-drawer-root"
					classes={{
						paper: "c6-drawer-container",
					}}
					onClose={() => this.setState({ moduleDrawerOpen: false })}
					open={this.state.moduleDrawerOpen}
				>
					{children && React.cloneElement(children, { onClick: this.handleDrawerClose })}
				</Drawer>
			</div>
		);
	}
}