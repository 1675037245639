import React, { Component } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import isNumber from 'lodash/isNumber'

export default class MultiSelect extends Component {

	constructor(props) {
		super(props);

		this.renderMenuItems = this.renderMenuItems.bind(this);
		this.handleChange = this.handleChange.bind(this);

		const { uiSchema } = this.props;
		this.dataSourceConfig = uiSchema && uiSchema["ui:options"] && uiSchema["ui:options"].dataSourceConfig
			|| { value: "id", text: "name" };

		this.state = {
			dataSource: []
		};
	}

	componentDidMount() {
		const { uiSchema } = this.props;
		if (uiSchema && uiSchema["ui:options"]) {
			const { dataSource, enumOptions } = uiSchema["ui:options"];
			if (typeof (dataSource) === "function") {
				dataSource().then(response => {
					this.setState({ dataSource: Array.isArray(response) ? response : response.items });
				});
			} else if (Array.isArray(dataSource)) {
				this.setState({ dataSource: dataSource });
			} else if (Array.isArray(enumOptions)) {
				this.setState({ dataSource: enumOptions });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { uiSchema, formData } = nextProps;

		if (uiSchema && uiSchema["ui:options"]) {
			const dataSource = uiSchema["ui:options"].dataSource || uiSchema["ui:options"].enumOptions;
			if (Array.isArray(dataSource)) {
				this.setState({ dataSource });
			}
		}
	}

	renderMenuItems() {
		const { dataSource } = this.state;
		const { formData } = this.props;

		return dataSource.map((opt, i) => {
			let itemValue = opt[this.dataSourceConfig.value] || "ERROR: NO VALUE!";

			// Make sure an option can have id: 0
			if (isNumber(opt.id) && opt.id == 0) {
				itemValue = opt.id;
			}

			const checked = formData && formData.findIndex(val => val.id == itemValue.toString()) > -1;

			return (
				<MenuItem
					key={`mi${i}${itemValue}`}
					value={itemValue.toString()}
					disabled={opt.disabled}
				>
					{opt[this.dataSourceConfig.text]}
				</MenuItem>
			);
		});
	}

	handleChange(e) {
		const { onChange } = this.props;
		
		// Parse formData since we almost always work with array of objects [{ id }, { id }, ...]
		// but Material UI multiSelect wants array of strings ["id", "id", ...]
		const parsed = parseValues(e.target.value);
		onChange(parsed);
	}

	render() {
		const { formData = [], disabled, readonly, schema, uiSchema, id } = this.props;
		const { title, label } = schema;
		
		const options = uiSchema["ui:options"];
		const { fullWidth = false, labelFixed = false } = options;

		const styles = fullWidth
			? { width: "calc(100% - 40px)", marginTop: "15px" }
			: { marginTop: "15px" };

		const placeholder = uiSchema["ui:placeholder"] || "(Make a selection)";

		// Parse formData since we almost always work with array of objects [{ id }, { id }, ...]
		// but Material UI multiSelect wants array of strings ["id", "id", ...]
		const parsedFormData = parseFormData(formData);

		return (
			<FormControl
				variant="standard"
				style={styles}
			>
				<InputLabel id={`c6-mui-multiselect-label-${id}`}>{label || title}</InputLabel>
				<Select
					className="c6-mui-select"
					labelId={`c6-mui-multiselect-label-${id}`}
					value={parsedFormData}
					multiple={true}
					onChange={this.handleChange}
					label={label || title}
					variant="standard"
					placeholder={placeholder}
					disabled={disabled || readonly}
					MenuProps={{ className: "c6-mui-select-menu" }}

					// HACK because mui hides the input using opacity=0, but we use opacity=0.45 on disabled elements
					inputProps={disabled || readonly ? { style: { opacity: 0 }} : {}}
				>
					{this.renderMenuItems()}
				</Select>
			</FormControl>
		);
	}
}

// HELPERS

// ["id", "id", ...] => [{ id }, { id }, ...]
function parseValues(values) {
	return values.map(val => {
		return { id: parseInt(val) };
	});
}

// [{ id }, { id }, ...] => ["id", "id", ...]
function parseFormData(formData) {
	return formData.map(val => val.id.toString());
}