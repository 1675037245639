import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'


import Button from './ui/controls/button'

import './actions.css'

// CURRENT
export const     		CONTENT = "content";
export const      		 ACTION = "action";
export const      		 INLINE = "inline";
export const        	   MENU = "menu";
export const   		  CLICK_OPT = "clickWithMetaKey";
export const 		CLICK_SHIFT = "clickWithShiftKey";
export const  CLICK_CTRL_OR_OPT = "clickWithCtrlOrMetaKey";

// DEPRECATED
export const  LINK = "link";
export const COVER = "cover";

// DEPRECATED: Use ActionType (or really ContentItem instead of Item in listItems.jsx)
export default class Actions extends React.Component {

	static propTypes = {
		target: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired,
		type: PropTypes.string,
	}

	static contextTypes = {
		uiActions: PropTypes.object,
	}

	constructor(props) {
		super(props);
	}

	render() {
		let actions = getActions(this.props.target, this.context.uiActions);
		if(!actions.length) { actions = [actions]; }

	    return (
			<div className="c6-actions">
				<ActionType type={INLINE} actions={actions} {...this.props} />
				<ActionType type={COVER} actions={actions} {...this.props} /> {/* Deprecated */}
				<ActionType type={LINK} actions={actions} {...this.props} /> {/* Deprecated */}
			</div>
		);
  	}
}

const menuOpenClassName = "has-open-icon-menu";
export class ActionType extends React.PureComponent {
	static propTypes = {
		type: PropTypes.string,
		actions: PropTypes.array,
		data: PropTypes.object,
	}

	static contextTypes = {
		uiActions: PropTypes.object,
	}

	buttonRef = null;
	state = {
		menuOpen: false,
	}

	openMenu = (e) => {
		e.stopPropagation();

		// Make it more clear which item the user has opened the menu for
		const itemRef = this.props.itemRef;
		if (itemRef?.classList) {
			itemRef.classList.add(menuOpenClassName);
		}

		this.setState({ menuOpen: true });
	}

	closeMenu = (e) => {
		if (e?.stopPropagation) {
			e.stopPropagation();
		}

		const itemRef = this.props.itemRef;
		if (itemRef?.classList) {
			itemRef.classList.remove(menuOpenClassName);
		}

		this.setState({ menuOpen: false });
	}

	render() {
		const { type, actions, renderMenuWhenNoActions = false, ...props } = this.props;
		const currentActions = actions.filter(a => a.type === type);
		if (!currentActions.length) {
			return null;
		}

		// DEPRECATED
		if (type === COVER) {
			return <Action action={currentActions[0]} {...props}/>;
		}

		if (type === MENU) {
			const { data, location, params, ...itemProps } = props;
			const { uiActions } = this.context;
			const sourceProps = uiActions ? uiActions.sourceProps : null;

			const items = currentActions.map((action, i) => {
				const { method, confirm, confirmText } = action;

				const [actionHidden, actionDisabled, actionIcon, actionClasses, actionTitle] = getActionState(action, data, sourceProps, itemProps, location, params);

				if (actionHidden) {
					return null;
				}

				const runMethod = () => method(data, sourceProps, location, params, itemProps);
				const onClick = (e) => {
					e.stopPropagation();
					this.closeMenu();

					if (confirm) {
						displayConfirmDialog(runMethod, confirmText);
					} else {
						runMethod();
					}
				};

				const icon = <ListItemIcon><span className={`icon-${actionIcon}`} style={{ color: "var(--action-color)" }}></span></ListItemIcon>;

				return (
					<MenuItem
						key={actionTitle}
						className={actionClasses}
						onClick={onClick}
						disabled={actionDisabled}
					>
						{icon}
						{actionTitle}
					</MenuItem>
				);
			}).filter(item => !!item); // Remove nulls

			if (!items.length && renderMenuWhenNoActions) {
				items.push(
					<MenuItem key="no-actions" disabled={true}>
						No actions for this item
					</MenuItem>
				);
			}

			if (!items.length) {
				return null;
			}

			// We need to return an IconMenu here to support both clickAway as well as automatically closing
			// the menu when an action has been selected
			return (
				<div className="c6-actions-menu c6-content min">
					<Button
						buttonRef={ref => this.buttonRef = ref}
						onClick={this.openMenu}
						type="more_vert large-icon"
						noBackground
						style={{ display: "inline-block", width: "54px", height: "54px" }}
					/>
					<Menu
						className="c6-actions-menu"
						anchorEl={this.buttonRef}
						open={this.state.menuOpen}
						onClose={this.closeMenu}
					>
						{items}
					</Menu>
				</div>
			);
		}

		return (
			<div className={`c6-actions-${type}`}>
				{currentActions.map((a, i) => <Action key={i} action={a} {...props}/>)}
			</div>
		);
	}
}

@withRouter
class Action extends React.Component {
	// static muiName = "MenuItem";

	static propTypes = {
		action: PropTypes.object,
		data: PropTypes.object,
		type: PropTypes.string,

		location: PropTypes.object, // from  @withRouter
	}

	static contextTypes = {
		uiActions: PropTypes.object,
	}

	constructor(props) {
		super(props);
		this.handleCancelConfirm = this.handleCancelConfirm.bind(this);

		this.state = {
			actionNeedingConfirmation: null
		}
	}

	// Confirms or actually executes the action
	handleActionClick(action, data, e) {
		e.stopPropagation();
		if(!action.confirm || this.state.actionNeedingConfirmation === action) {
			const { uiActions } = this.context;
			const { location, params, ...itemProps } = this.props;
			const sourceProps = uiActions ? uiActions.sourceProps : null;
			action.method(data, sourceProps, location, params, itemProps);
			this.setState({ actionNeedingConfirmation: null });
		}
		else {
			this.setState({ actionNeedingConfirmation: action });
		}
	}

	// TODO: Cancel confirm on mouse out (possibly after a delay?)
	handleCancelConfirm(e) {
		e.stopPropagation();
		this.setState({ actionNeedingConfirmation: null });
	}

	render() {
		const confirmAction = this.state.actionNeedingConfirmation;

		const { action, data, location, params, ...itemProps } = this.props;
		const { uiActions } = this.context;

		// Skip rendering other actions when we need to confirm a specific action
		if(confirmAction && confirmAction !== action) {
			return null;
		}

		const sourceProps = uiActions ? uiActions.sourceProps : null;

		const { type, dangerous } = action;

		const [actionHidden, actionDisabled, actionIcon, actionClasses, actionTitle] = getActionState(action, data, sourceProps, itemProps, location, params, confirmAction);

		if(actionHidden) {
			return null;
		}

		// // Do we have a hidden check on the action? Then run it!
		// if(typeof(hidden)==="function" && hidden(data, sourceProps, location, params, itemProps)) {
		// 	return null;
		// }

		// const actionDisabled = typeof(disabled) === "function" && disabled(data);
		// const actionClasses = `${type}${dangerous ? " dangerous" : ""}${actionDisabled ? " disabled" : ""}${!title ? " no-title" : ""}`;
		// const actionTitle = confirmAction === action ? confirmationText || `${title} this item` : title;

		let actionButton = null;
		if (type === "menu") {
			const icon = <ListItemIcon><span className={`icon-${actionIcon}`} style={{ color: "var(--action-color)" }}></span></ListItemIcon>;
			actionButton = (
				<MenuItem
					className={actionClasses}
					onClick={this.handleActionClick.bind(this, action, data)}
					disabled={actionDisabled}
				>
					{icon}
					{actionTitle}
				</MenuItem>
			);
		}
		else {
			actionButton = (
				<button
					className={actionClasses}
					onClick={this.handleActionClick.bind(this, action, data)}
					disabled={actionDisabled}
				>
					<span className={"icon-" + actionIcon}>{actionTitle}</span>
				</button>
			);
		}

		// Regular action
		if(confirmAction !== action) {
			return actionButton;
		}

		// TODO!!!! REACT16: Use fragments to get rid of the wrapping <div>
		const temporaryDivStyle = dangerous
			? { display: "flex", marginLeft: "auto" }
			: { display: "flex", marginLeft: "-5px" };

		// Confirmation action
		return (
			<div style={temporaryDivStyle}>
				{actionButton}
				<button className={type} onClick={this.handleCancelConfirm}>
					<span>Nevermind...</span>
				</button>
			</div>
		);
	}
}

// DEPRECATED: Use ContentItem instead of Item in listItems.jsx
@withRouter
export class ContentActionItem extends React.Component {
	static propTypes = {
		data: PropTypes.object,
	}

	static contextTypes = {
		uiActions: PropTypes.object,
	}

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		const selection = window.getSelection();

		// Don't execute content action click if this is a text selection.
		if(selection.toString().length === 0) {
			const { data, location, params } = this.props;
			const { uiActions } = this.context;

			const contentAction = uiActions
				? uiActions.actions.find(a => a.type === CONTENT)
				: null;

			if(contentAction) {
				const sourceProps = uiActions ? uiActions.sourceProps : null;
				contentAction.method(data, sourceProps, location, params, e);
			}
		}
	}

	render() {
		const { uiActions } = this.context;
		const { data, location, params, children } = this.props;

		const contentAction = uiActions
			? uiActions.actions.find(a => a.type === CONTENT)
			: null;

		const sourceProps = uiActions ? uiActions.sourceProps : null;

		if(!contentAction || (contentAction && typeof(contentAction.hidden)==="function" && contentAction.hidden(data, sourceProps, location, params))) {
			return <div>{children}</div>;
		}

		const className = contentAction.method ? "c6-clickable" : null;
	    return <div className={className} onClick={this.handleClick}>{children}</div>;
  	}
}

// HELPERS
function getActions(target, actions) {
	return actions && actions.targetComponent === target
		? actions.actions
		: [];
}

function getActionState(action, data, sourceProps, itemProps, location, params, confirmAction,) {
	const { title, type, dangerous, hidden, disabled, confirmationText = null, icon } = action;


	const actionHidden = typeof(hidden) === "function" && hidden(data, sourceProps, location, params, itemProps);
	const actionDisabled = typeof(disabled) === "function" && disabled(data, sourceProps);
	const actionIcon = typeof(icon) === "function" ? icon(data) : icon;
	const actionClasses = `${type}${dangerous ? " dangerous" : ""}${actionDisabled ? " disabled" : ""}${!title ? " no-title" : ""}`;
	const actionTitle = confirmAction === action ? confirmationText || `${title} this item` : title;

	return [actionHidden, actionDisabled, actionIcon, actionClasses, actionTitle];
}

function displayConfirmDialog(method, text = "Are you sure?") {
	const confirm = window.confirm(text);
	if (confirm) {
		method();
	}
}