import appConfig from 'config'

const getDeviation = (deviation, recommendedLength) => {
	// If no deviation, return the smallest number of 10 and 10% of rec length
	if (!deviation) {
		let defaultDeviation = 10;
		const percentageDeviation = recommendedLength * 0.1 > 1 ? recommendedLength * 0.1 : 1;
		defaultDeviation = defaultDeviation < percentageDeviation ? defaultDeviation : percentageDeviation;
		return defaultDeviation;
	}
	return deviation;
};

export const getCharacterCountState = (characters, recommendedLength, deviation, maxLength) => {
	const actualDeviation = getDeviation(deviation, recommendedLength);
	const error = maxLength
		? characters > maxLength
		: false ;

	const recommended = !error && (
		characters >= (recommendedLength - actualDeviation) &&
		characters <= (recommendedLength + actualDeviation)
	);

	return { recommended, error };
};

export const getCharacterCountStyles = (recommended, error, readonly) => {
	const charCountStyles = {
		root: {
			float: "right",
			color: "var(--text-lighter-color)",
			fontSize: "12px",
		},
		recommended: {
			color: "var(--success-color)",
		},
		error: {
			color: "var(--error-color)",
		},
	};

	let ccStyle = Object.assign({}, charCountStyles.root);
	if (recommended) ccStyle = Object.assign({}, charCountStyles.root, charCountStyles.recommended);
	if (error) ccStyle = Object.assign({}, charCountStyles.root, charCountStyles.error);

	let labelStyle = {}, underlineStyle = {}, underlineFocusStyle = {};
	if (error) {
		labelStyle = { color: 'var(--error-color)' };
		underlineStyle = { borderColor: 'var(--error-color)' };
		underlineFocusStyle = { borderColor: 'var(--error-color)' };
	}

	if (recommended) {
		labelStyle = { color: 'var(--success-color)' };
		underlineFocusStyle = { borderColor: 'var(--success-color)' };
	}

	if (readonly) {
		labelStyle = { color: 'var(--text-lighter-color)' };
		underlineStyle = { borderBottomStyle: 'dotted' };
		underlineFocusStyle = { borderColor: 'var(--text-lighter-color)' };
	}

	return { ccStyle, labelStyle, underlineStyle, underlineFocusStyle };
};

export const sanitizeTextInput = (input = "") => {
	if (!appConfig.features.sanitizeTextInputs) {
		return input;
	}
	
	return input.replace(/\u00A0/gm, " ");
	// return input
	// 	.split("")
	// 	.map(char => {
	// 		const codePoint = char.codePointAt(0); 

	// 		// Replace non-breaking space with regular space
	// 		if (codePoint === 160) {
	// 			return " ";
	// 		}

	// 		return char;
	// 	})
	// 	// .filter(char => {
	// 	// 	const codePoint = char.codePointAt(0); 
	// 	// 	return ![160].includes(codePoint);
	// 	// })
	// 	.join("");
}