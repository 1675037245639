import React, { Component } from 'react'

import { Link } from 'react-router'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { getModuleIcon } from '../../core/constants'

import './menu.css'

import appConfig from 'config'

export default class ModuleMenu extends Component {

    getMenuItems = () => {
        const { currentModule, modules, onClick } = this.props;

        if (!modules) {
            return null;
        }

        const isHome = currentModule === "";
        const home = this.renderMenuItem({ displayName: "Comet home", key: "home", url: "/", ui: "React" }, isHome);
        const moduleItems = modules.map(module => {
            const { subModules } = module;
            const children = subModules?.map(submodule => this.renderMenuItem(currentModule === submodule.displayName, submodule)) ?? [];
            const isActive = currentModule === module.displayName;
            return this.renderMenuItem(module, isActive, children);
        });

        return [home, ...moduleItems];
    }

    render() {
        const menuItems = this.getMenuItems();
        return <List>{ menuItems }</List>;
    }

    renderMenuItem = (module, isActive = false, children = []) => {
        const { displayName, key, url } = module;
		const apps = module.apps ? module.apps.filter(a => !a.hidden) : false;

        const classNames = isActive ? "c6-drawer-item active-module" : "c6-drawer-item";
        const linkUrl = apps.length ? apps[0].url : url;

        let link = (
            <a
                href={linkUrl}
                className={getIcon(key)}
                onClick={this.props.onClick}
            >
                {displayName}
            </a>
        );

        if (module.ui === "React") {
            link = (
                <Link
                    to={linkUrl}
                    className={getIcon(key)}
                    onClick={this.props.onClick}
                >
                    {displayName}
                </Link>
            );
        }

        return (
            <ListItem
                className={classNames}
                key={key}
                button={true}
            >
                {link}
            </ListItem>
        );
    }
}

// HELPERS
function getIcon(key) {
	return getModuleIcon(key) || "";
}