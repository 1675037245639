import React from 'react'
import PropTypes from 'prop-types'
import styles from './spinner.css'

const Spinner = ({loading = false}) => {
	const spinner = loading ? <span className="c6-spinner"><span /></span> : null;
	return spinner;
};
Spinner.propTypes = {
	loading: PropTypes.bool
}
export default Spinner;