import Qs from 'qs'
import moment from 'moment'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import * as auth from './services/auth'
import AuthActions from './authentication/actions'
import appConfig from 'config'

const NUM_RETRIES = 2; // Try failed requests again before throwing an error
let _deprecated_retriesLeft = NUM_RETRIES; // TODO: Remove when appConfig.features.useFetchRequestRetries seems stable

function stringify(json) {

	const filter = (prefix, value) => {
		const emptyValue = value === ""; // Skip empty values like parameter: ""
		const internalParameter = prefix.charAt(0) === "_"; // Don't stringify our internal &_parameter=something attributes

		return !emptyValue && !internalParameter ? value : undefined;
	}

	return Qs.stringify(json, {
		skipNulls: true,
		filter,
	});
}

export function get(api, path, filters, skipToken = false, headers = null) {
	const fullPath = filters ? `${path}?${stringify(filters)}` : path;

	return doFetch({
		api,
		path: fullPath,
		skipToken,
		payload: {
			method: "GET",
			headers,
		}
	});
}

export function put(api, path, body, skipToken = false) {
	return doFetch({
		api,
		path,
		skipToken,
		payload: {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body)
		}
	});
}

export function patch(api, path, body, skipToken = false) {
	return doFetch({
		api,
		path,
		skipToken,
		payload: {
			method: "PATCH",
			headers: { "Content-Type": "application/merge-patch+json" },
			body: JSON.stringify(body)
		}
	});
}

export function post(api, path, body, skipToken = false) {
	return doFetch({
		api,
		path,
		skipToken,
		payload: {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body)
		}
	});
}

export function del(api, path, filters, body, skipToken = false) {
	const fullPath = filters ? `${path}?${stringify(filters)}` : path;

	return doFetch({
		api,
		path: fullPath,
		skipToken,
		payload: {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body)
		}
	});
}

export function upload(api, path, body, skipToken = false) {
	return doFetch({
		api,
		path,
		skipToken,
		payload: {
			method: "POST",
			body
		}
	});
}

export function	doFetch(request) {
	return new Promise((resolve, reject) => {
		if (!request.skipToken && !(appConfig.features && appConfig.features.skipApiTokens)) {

			const tokenRequest = auth.getServiceToken;

			tokenRequest(request.api)
				.then(token => _fetchFromAPI(request, resolve, reject, token))
				.catch(error => {

					// Logout user (while staying on page, resulting in a login modal)
					// when request fails because userJWT has expired
					if (isTokenError(error, request)) {
						const user = auth.getUser();
						const prematureExpirySeconds = 60 * 60; // 1 hour
						if (auth.isExpired(user, prematureExpirySeconds)) {
							const stayOnPage = true;
							const functionToRunAfterLogin = () => {
								tokenRequest(request.api)
									.then(token => _fetchFromAPI(request, resolve, reject, token))
									.catch(err => reject(err));
							};
							AuthActions.logout(user, stayOnPage, null, functionToRunAfterLogin);
							return;
						}
					}

					reject(error);
				});
		}
		else {
			_fetchFromAPI(request, resolve, reject);
		}

	});
}

const _fetchFromAPI = ({ api, path, payload, skipToken, retriesLeft, pathWithAPI }, resolve, reject, token) => {
	const API_URL = appConfig.api && appConfig.api[api];
	if (!pathWithAPI && !API_URL) {
		reject(new Error("Could not find the API BASE URL: appConfig.api." + api));
	}

	const fullPath = pathWithAPI ? pathWithAPI : API_URL + path;
	
	payload = _setDefaultHeaders(payload, token, api);

	fetch(fullPath, payload)
		.then(processStatus)
		.then(
			data => {
				if (!appConfig.features?.useFetchRequestRetries) {
					_deprecated_retriesLeft = NUM_RETRIES;
				}

				// HACK: Support the new C70 API structure while maintaining backwards compatibility with C60 (temporary until the majority of API:s are C70)
				const compatibleData = getCompatibleData(data);

				resolve(compatibleData);
			},
			error => { _handleError({ api, path, payload, skipToken, retriesLeft }, resolve, reject, error) }
		)
		.catch(error => { _handleError({ api, path, payload, skipToken, retriesLeft }, resolve, reject, error) })
		.finally(() => {
			if (appConfig.features?.checkForUpdates) {
				throttledVersionCheck();
			}
		});
}

const _setDefaultHeaders = (payload = {}, token, api) => {
	let requestHeaders = new Headers();

	// Add the auth token if we have one
	if (token) {
		const bearer = `Bearer ${token}`.replace("\n", ""); // HACK: Remove possible newlines to prevent an invalid header.
		requestHeaders.append("Authorization", bearer);
	}

	// Add other provided headers
	for (let prop in payload.headers) {
		requestHeaders.append(prop, payload.headers[prop]);
	}

	// Add a default Accept header if needed
	if (!requestHeaders.has("Accept")) {
		requestHeaders.append("Accept", "application/json");
	}

	if (api?.includes("cms")) {
		try {
			const cmsStage = JSON.parse(localStorage.getItem("x-cms-stage"));
			if (cmsStage?.length && cmsStage !== "null") {
				requestHeaders.append("x-cms-stage", cmsStage);
			}
		} catch {
			localStorage.removeItem("x-cms-stage");
		}
	}

	payload.headers = requestHeaders;
	return payload;
}

const _handleError = (request, resolve, reject, error) => {
	error = getCompatibleError(error);
	console.warn("_handleError message: %s - exception: %s - status: %s", error.message, error.exceptionMessage, error.status);

	// Let's try the request a couple of times before we actually believe it's an error
	if (appConfig.features?.useFetchRequestRetries && request.retriesLeft === undefined) {
		request.retriesLeft = NUM_RETRIES;
	}

	let canRetry = false;
	if (appConfig.features?.useFetchRequestRetries) {
		canRetry = isTokenError(error, request) && request.retriesLeft > 0;
	} else {
		canRetry = isTokenError(error, request) && _deprecated_retriesLeft > 0;
	}

	if (canRetry) { // We need to get a new token and try again
		if (appConfig.features?.useFetchRequestRetries) {
			request.retriesLeft--;
		} else {
			_deprecated_retriesLeft--;
		}

		auth.clearToken(request.api);
		doFetch(request)
			.then(data => resolve(data))
			.catch(error => {
				console.error("Error in the handle error fetch.");
				_handleError(request, resolve, reject, error)
			});
	}
	else if (appConfig.features?.hideTokenErrors && isTokenError(error, request)) {
		console.error("Token error. Probably you logged in to another Shield env than the API env you're trying to access: %s. Full error: %o", error.url, error);
		error.noDisplayAlert = true;
		reject(error);
	}
	else {
		console.error("Rejecting without handling error: ", error);
		if (!error?.status || error.status === 503) {
			debouncedCheckForApiOfflineFile(request);
		}
		reject(error); // TODO: Something else - throw a real error
	}
}

const debouncedCheckForApiOfflineFile = debounce(checkForApiOfflineFile, 10 * 1000, { leading: true, trailing: false });
let visibleDialogs = {};
async function checkForApiOfflineFile(request) {
	const { api } = request;

	if (visibleDialogs[api]) {
		return;
	}

	try {
		const url = `/client/${api}_offline_${moment().format("YYYYMMDD")}.html`;
		const response = await fetch(url);
		if (response.ok) {
			const html = (await response.text()) ?? "";

			// In production, 404 requests to /client/* will reply with 200 index.html
			// So if we receive index.html, don't show a dialog
			const hasHtmlTag = html.includes("<html");
			const hasCometUiTag = html.includes("comet-ui.6");
			const isProbablyIndexHtml = hasHtmlTag && hasCometUiTag;
			if (visibleDialogs[api] || !html.length || isProbablyIndexHtml) {
				return;
			}

			const rootEl = document.querySelector("#c6-api-dialog-root");
			const newDialog = document.createElement("div");
			newDialog.className = "dialog";
			newDialog.innerHTML = html;
			rootEl.appendChild(newDialog);

			const closeButton = document.createElement("button");
			closeButton.className = "close c6-button icon-close";
			closeButton.innerText = "Close";
			closeButton.addEventListener("click", () => {
				newDialog.remove();
				rootEl.classList.remove("visible");
				visibleDialogs[api] = false;
			});
			newDialog.appendChild(closeButton);

			rootEl.classList.add("visible");
			visibleDialogs[api] = true;
		}
	} catch {
		// Fail silently if offline HTML was not found
	}
}

export function processStatus(response) {
	const { status, headers } = response;
	const ct = headers.get("Content-Type");
	const cl = headers.get("Content-Length");
	const isJSON = ct?.includes("application/json");
	switch(status) {
		case 200:
		case 201:
			if (cl && parseInt(cl) === 0) {
				return Promise.resolve();
			}
			if (isJSON) {
				return Promise.resolve(response.json());
			}
			if (ct?.startsWith("text")) {
				return Promise.resolve(response.text());
			}
			if (ct && blobContentTypes.some(bct => ct.includes(bct))) {
				return Promise.resolve(response.blob());
			}
		case 204:
			return Promise.resolve();
		case 400:
		case 401: // Unauthorized
		case 404: // Not found
		case 409: // A conflict with an existing value
		case 500:
		default:
			if (!response.message) {
				response.message = response.statusText; // To be more backwards compatible with the old error handling
			}
			if (isJSON) {
				return response.json().then(json => {
					return Promise.reject(json);
				});
			}
			return Promise.reject(response);
	}
}

let isFetchingVersion = false;
const versionCheck = () => {
	const currentVersion = document.querySelector("html").getAttribute("data-version");
	if (currentVersion && !isFetchingVersion) {
		isFetchingVersion = true;
		fetch(`${appConfig.app.resourcePath || ""}index.html?${Date.now()}`)
			.then(response => Promise.resolve(response.text()))
			.then(html => {
				const re = /(?:data-version=")(.*\d)/gm;
				const capture = re.exec(html);
				const serverVersion = capture ? capture[1] : null;

				if (serverVersion && serverVersion !== currentVersion) {
					const newVersionText = document.querySelector(".newVersionAvailable");
					if (newVersionText) {
						newVersionText.classList && newVersionText.classList.remove("hide");
						newVersionText.title = `The new version is ${serverVersion} and you have ${currentVersion}`;
					}

					const sideNavCometSection = document.querySelector(".c6-side-navigation .section.comet");
					if (sideNavCometSection) {
						sideNavCometSection.classList.add("new-version-available");
					}
				}
				isFetchingVersion = false;
			})
			.catch(error => {
				isFetchingVersion = false;
			});
	}
}

// Only check for a new version max every five minutes (on network requests)
const throttledVersionCheck = throttle(versionCheck, 5*60*1000, {
	"leading": true,
	"trailing": false
});

// HACK: Support the new C70 API structure while maintaining backwards compatibility with C60 (temporary until the majority of API:s are C70)
export function getCompatibleData(data) {
	const isC70 = data && data.result && data.meta && data.meta.requestId;

	if (isC70 && !Array.isArray(data.result)) {
		return data.result; // If we get a simple JSON object we don't need any C70 -> C60 transformation
	}
	
	if (isC70) {
		const { result: items, meta } = data;
		const {
			totalItems: numberOfItems,
			next,
			previous,
			current,
			...rest
		} = meta;

		let links = [];
		next && links.push({ rel: "next", href: next });
		previous && links.push({ rel: "previous", href: previous });
		current && links.push({ rel: "self", href: current });

		return {
			...rest,
			items,
			numberOfItems,
			links,
		};
	}

	return data;
}

export function getCompatibleError(error) {
	const isC70 = error?.meta?.requestId;

	if (isC70) {
		return {
			...error.meta,
			exceptionMessage: error.meta.message || error.result,
		};
	}

	return error;
}

function isTokenError(error, request) {
	if (request?.skipToken) {
		return false;
	}
	
	return (
		error.exceptionMessage?.includes("Token not valid")
		|| ["Token has expired.", "Invalid token.", "Invalid signature.", "Illegal base64url string!"].some(m => error.message === m)
		|| [401, 403].some(s => error.status === s)
	);
}

const blobContentTypes = [
	"image",
	"video",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel",
	"csv",
	"application/zip",
];