import alt from '../../core/services/alt'
import Actions from './actions'
import { updateItemInMatchingQueries } from '../../core/hooks/useList'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

class EditorStore {
	constructor() {
		this.model = {};
		this.isDirty = false;

		this.bindListeners({
			onFetchItem: Actions.FETCH_ITEM,
			onModelChanged: Actions.MODEL_CHANGED,
			onMergeModel: Actions.MERGE_MODEL,
			onModelUpdated: Actions.MODEL_UPDATED,
			onItemSaved: Actions.MODEL_SAVED,
			onUpdateItem: Actions.UPDATE_ITEM,
			onSetDirty: Actions.SET_DIRTY,

			onRequestFailed: Actions.REQUEST_FAILED,

			onUnmount: Actions.UNMOUNT,
		});
	}

	onFetchItem() {
		this.isLoading = true;
	}

	onUpdateItem() {
		this.isLoading = true;
	}

	onModelChanged({ model, changeFieldId }) {
		if (!isUiOnlyField(changeFieldId)) {
			this.isDirty = true;
		}

		this.model = model;
	}

	onMergeModel(newModel) {
		this.model = {
			...this.model,
			...newModel,
		};
		this.isDirty = true;
	}

	onSetDirty() {
		this.isDirty = true;
	}

	onModelUpdated(model) {
		this.model = model;
		this.isLoading = false;
		this.isDirty = false;
	}

	onItemSaved({ datastore, entity, model, body, originalId }) {
		const store = entity ? `${entity}s` : datastore;
		updateItemInMatchingQueries({
			store,
			// Use body instead of model since model is sometimes transformed for the editor to use internally, right? hmm...
			// Unless body is an array which means it's a patch payload
			item: Array.isArray(body)
				? model
				: body ?? model,
			isDelete: false,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
	}

	onUnmount() {
		this.model = {};

		this.isLoading = false;
		this.isDirty = false;
	}
}

export default alt.createStore(EditorStore, 'EditorStore');

function isUiOnlyField(fieldId) {
	const fieldIdWithoutRoot = fieldId?.replace("root_", "") ?? "";
	return fieldIdWithoutRoot.startsWith("_");
}