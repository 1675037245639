import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'
import ProgressBar from 'react-fine-uploader/progress-bar'
import Filename from 'react-fine-uploader/filename'
import Filesize from 'react-fine-uploader/filesize'
import Status from 'react-fine-uploader/status'
import Thumbnail from 'react-fine-uploader/thumbnail'
import RetryButton from 'react-fine-uploader/retry-button'
import DeleteButton from 'react-fine-uploader/delete-button'

import Actions from './actions'
import Store from './store'

import './globalUploader.css'
import { displayAlert } from '../../core/services/alert'

export default class GlobalUploader extends Component {

	state = {
		...Store.getState(),
		expand: false,
	}

	componentDidMount() {
		Store.listen(this.onChange);
	}

	UNSAFE_componentWillMount() {
		Store.unlisten(this.onChange);
	}

	onChange = (state) => this.setState(state);

	toggleExpand = () => {
		this.setState(state => ({ expand: !state.expand }));
	}

	close = e => {
		e.stopPropagation();
		this.setState({ expand: false });
		Actions.removeCompletedFiles();
	}

	removeFile = (uploaderId, id) => {
		Actions.removeFile(uploaderId, id);
	}

	renderFiles = () => {
		const uploaders = Object.entries(this.state.uploaders).filter(([uploaderId, uploader]) => uploader.files.length);
		return uploaders.map(([uploaderId, uploader]) => (
			<div key={uploaderId} className="uploader-files">
				<p>{getUploaderHeading(uploader, uploaderId)}</p>
				{uploader.files.map(({ id, message, status }) => (
					<div key={id} className={`file ${status?.replace(/\s/g, "")}`}>
						<Thumbnail id={id} uploader={uploader} className="thumbnail" />
						<div className="file-info">
							<Filename id={id} uploader={uploader} className="name" />
							<button className="c6-button remove noBackground" onClick={this.removeFile.bind(this, uploaderId, id)}><span className="icon-close"></span></button>
							<Filesize id={id} uploader={uploader} className="size" />
							<RetryButton id={id} uploader={uploader} className="c6-button retry"><span className="icon-sync">Retry</span></RetryButton>
							<ProgressBar id={id} uploader={uploader} className="progress-bar" hideBeforeStart={false} hideOnComplete={false} />
						</div>
					</div>
				))}
			</div>
		));
	}

	render() {
		const { allFilesCount, uploadedFilesCount, hasFailedFiles } = getFileStats(this.state.uploaders);
		return (
			<div className={`c6-global-uploader ${allFilesCount > 0 ? "show" : ""} ${this.state.expand ? "expand" : ""}`}>
				<div className={`general-info ${hasFailedFiles ? "failed" : ""}`} onClick={this.toggleExpand}>
					{allFilesCount > 0 && (
						<span>{uploadedFilesCount}/{allFilesCount} file{allFilesCount > 1 ? "s" : ""} uploaded</span>
					)}
					<div title="Minimize" className={`expand large-icon icon-expand_${this.state.expand ? "more" : "less"}`}></div>
					<div title="Clear completed uploads and hide" className="close large-icon icon-close" onClick={this.close}></div>
				</div>
				<div className="file-details">
					{this.renderFiles()}
				</div>
				{this.props.onHide && (
					<div className="hide-forever">
						<span>
							<a
								className="c6-link"
								onClick={e => {
									e.stopPropagation();
									this.props.onHide();
									displayAlert("info", "The uploader popup is now hidden. If you change your mind, you can change this setting by clicking your username in the top right corner.", null, null, 20000);
								}}
							>
								Click here
							</a> to never show this popup again.
						</span>
					</div>
				)}
			</div>
		);
	}
}

function getFileStats(uploaders) {
	const allFiles = Object.values(uploaders).reduce((files, uploader) => [...files, ...uploader.files], []);
	const uploadedFiles = allFiles.filter(f => f.status === "upload successful");
	const hasFailedFiles = allFiles.some(f => f.status.includes("failed"));
	return {
		allFilesCount: allFiles.length,
		uploadedFilesCount: uploadedFiles.length,
		hasFailedFiles,
	};
}

function getUploaderHeading(uploader, uploaderId) {
	const text = uploader.uploaderInformationText ?? `Files for uploader: ${uploaderId}`;
	return uploader.pathname
		? <Link className="c6-link" onClick={handleLinkClick.bind(this, uploader)}>{text}</Link>
		: <span>{text}</span>;
}

function handleLinkClick({ pathname, modal }, e) {
	e.preventDefault();
	browserHistory.push({
		pathname,
		state: {
			returnTo: window.location.pathname,
			modal,
		},
	});
}