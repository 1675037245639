import React, { Component } from 'react'

import { formatBytes } from '../../../../utils/misc'

export default class ExifContent extends Component {
	state = { collapsed: true }

	render() {
		const asset = this.props.asset;
		const originalFile = asset.files && asset.files.length && asset.files.find(f => f.fileFormatId === 0 && f.status === "Ok");

		if (originalFile && originalFile.exif) {
			const exif = originalFile.path && originalFile.path.startsWith("star/diva") && originalFile.exif.startsWith("exif:")
				? parseDivaExif(originalFile.exif)
				: originalFile.exif;

			const exifDict = exif.split(";").reduce((dict, current) => {
				const [key, value] = current.split(":");
				if (key !== "")
					dict[key] = value;
				return dict;
			}, {});

			// File
			const fileName = originalFile.name;
			const fileSize = formatBytes(originalFile.size, 0);
			const colorMode = originalFile.colorMode;
			const resolution = `${originalFile.width}x${originalFile.height}`;
			const fileInfo = (
				<div className="info-summary">
					<p className="icon-insert_photo">{fileName}</p>
					<p>{resolution}px</p>
					<p>{colorMode} {fileSize}</p>
				</div>
			);

			// Date
			const dateTime = exifDict.DateTime || exifDict.DateTimeOriginal;
			const dateInfo = dateTime
				? (
					<div className="info-summary">
						<p className="icon-insert_invitation">{dateTime}</p>
					</div>
				)
				: null;

			// Camera
			const makeAndModelOrSoftware = exifDict.Make && exifDict.Model ? `${exifDict.Make} ${exifDict.Model}` : exifDict.Software;
			const fNumber = exifDict.FNumber && `${eval(exifDict.FNumber)}`;
			const exposure = exifDict.ExposureTime;
			const focalLength = exifDict.FocalLength && `${eval(exifDict.FocalLength)}mm`;
			const iso = exifDict.ISOSpeedRatings && `ISO ${exifDict.ISOSpeedRatings}`;
			const cameraInfo = makeAndModelOrSoftware || fNumber || exposure || focalLength || iso
				? (
					<div className="info-summary">
						<p className="icon-camera">{makeAndModelOrSoftware}</p>
						<p>{fNumber && <span style={{ display: "contents" }}>&#119891;/{fNumber}</span>} {exposure} {focalLength} {iso}</p>
					</div>
				)
				: null;

			const exifList = (
				<div>
					<p className="icon-code" onClick={() => this.setState({ collapsed: !this.state.collapsed })}>
						EXIF
						<span className={this.state.collapsed ? "icon-expand_more" : "icon-expand_less"}></span>
					</p>
					<ul className="exif" style={{ height: this.state.collapsed ? 0 : "auto" }}>
						{Object.keys(exifDict).map(key => {
							return (
								<li key={key}>
									<div>{key}</div>
									<div>{exifDict[key]}</div>
								</li>
							);
						})}
					</ul>
				</div>
			);

			return (
				<div>
					{fileInfo}
					{dateInfo}
					{cameraInfo}
					{exifList}
				</div>
			);
		}

		return <p style={{ padding: "10px" }} className="icon-code">Image has no EXIF data</p>;
	}
}

// For some files (ca 65k) we need to parse the exif data because it comes in a different format from DIVA
// It was too much work to do in SQL so we do it here instead
function parseDivaExif(exif) {
	return exif
		.replace(/exif:/gmi, "")
		.replace(/=/gmi, ":")
		.replace(/(\r\n|\n|\r)/gmi, ";");
}