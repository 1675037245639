import React from 'react'
import PropTypes from 'prop-types'
import Picker from './picker'

const TimePicker = props => (
	<Picker
		openIcon="schedule"
		dateFormat="H:i"
		noCalendar={true}
		enableTime={true}
		{...props} />
);

export default TimePicker;

TimePicker.propTypes = {
	showConfirmButton: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	format: PropTypes.string,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string, // Single date
		PropTypes.array // Date range
	]),
	value: PropTypes.oneOfType([
		PropTypes.string, // Single date,
		PropTypes.array // Date range
	])
};

TimePicker.defaultProps = {
	showConfirmButton: false,
	format: "HH:mm"
};