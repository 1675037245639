import React from 'react'
import { withRouter } from 'react-router'
import keydown, { keydownScoped } from 'react-keydown'
import { browserHistory } from 'browserHistory'

import appConfig from 'config'

const keyboardNavigation = (DecoratedComponent, options) => {
	@withRouter
	class EnhancedComponent extends DecoratedComponent {
		static displayName = `keyboardNavigation(${DecoratedComponent.displayName || DecoratedComponent.name || "Component"})`;

		@keydown("left")
		handleLeft() {
			this.navigateToItem(-1);
		}

		@keydown("right")
		handleRight() {
			this.navigateToItem(1);
		}

		navigateToItem(indexIncrement) {
			const list = this.props.location.state && this.props.location.state.list || [];
			const id = this.props.params.id;
			const currentIndex = list.findIndex(i => {
				const itemId = options.getId ? options.getId(i) : i.id;
				return parseInt(itemId) === parseInt(id);
			});
			if (currentIndex === -1)
				return;
			
			const newItem = list[currentIndex + indexIncrement];
			if (!newItem)
				return;
			
			const newId = options.getId ? options.getId(newItem) : newItem.id;
			const newPath = this.props.routes.reduce((completeRoute = "", route) => (
				route.path
					? completeRoute + "/" + route.path
						.replace("(:id)", newId)
						.replace(":id", newId)
					: ""
			));
			browserHistory.replace({
				pathname: newPath,
				state: {
					...this.props.location.state,
					returnTo: this.props.location.pathname,
				},
			});
		}
	};

	return EnhancedComponent;
}


const decorator = (options) => {
	if (appConfig.features.enableKeyboardNavigation) {
		return DecoratedComponent => keyboardNavigation(DecoratedComponent, options);
	} else {
		return DecoratedComponent => DecoratedComponent;
	}
};

export default decorator;