import React from 'react'
import AutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import debounce from 'lodash/debounce'

// Ska kunna ta array eller funktion som dataSource
// Ska väl automatiskt ha en egen remove om ingen remove-funktion skickats.
// Senare: Ska ha lokalt state för datasourcen
export default class MuiAutoComplete extends React.Component {
	constructor(props) {
		super(props);

		this.refreshDataSource = this.refreshDataSource.bind(this);
		this.commitChange = this.commitChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);

		const { value } = props;
		const { fetch, defaultText = "" } = props.options;

		this.debouncedFetch = fetch
			? debounce(fetch, 500)
			: () => { console.error("No fetch option defined on the autocomplete widget for the ui schema in app.jsx so I cannot search an API for you."); };

		this.dataSourceConfig = props.dataSourceConfig ?? { value: "id", text: "displayName" };

		this.state = {
			value,
			searchText: defaultText,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const prevOptions = prevProps.options;
		const { defaultText, key, fetch, preventAdd = false } = this.props.options;

		if (defaultText !== prevOptions.defaultText) {
			this.setState({ searchText: defaultText });
		}

		// We need to rebind the fetch method when we have a different key (for example new programType search filter in ACQ)
		if (key !== prevOptions.key && fetch) {
			this.debouncedFetch = debounce(fetch, 500);
		}
	}

	refreshDataSource(e, dataSource, reason) {
		if (reason === "reset") { // reset = user selects option from list
			this.setState({ searchText: dataSource });
			return;
		}
		
		const searchText = e?.target?.value ?? "";
		if (searchText !== this.state.searchText) {
			this.setState({ searchText });
		}
		if (this.props.options.fetch && searchText?.length > 1 && reason === "input") {
			this.debouncedFetch(searchText);
		}
	}

	commitChange(selectedItem, reason) {
		const { onChange, options } = this.props;
		if (reason === "clear") {
			this.setState({
				value: "",
				searchText: "",
			});
			onChange(-1);
			return;
		}

		// We can recieve either an object (if user clicked on autocomplete dropdown menu)
		// or a text string if user pressed enter in the autocomplete field
		let id = -1;
		if (typeof(selectedItem) === "object" && selectedItem?.id !== undefined) {
			id = selectedItem.id;
			selectedItem = selectedItem.name;
		}

		// Existing item
		if (typeof(options.select) === "function" && id !== -1) {
			options.remove();
			options.select(selectedItem, id);
			onChange(id);
			return;
		}

		// New item
		if (typeof(options.select) === "function") {
			options.select(selectedItem, id);
			return;
		}

		if (options.preventAdd && id === -1) {
			this.setState({
				value: "",
				searchText: "",
			});
			options.remove();
			return;
		}

		onChange(id);
	}

	handleKeyDown(e) {

		const { options } = this.props;

		//if(e.type === "blur" || e.which == 13 || e.keyCode == 13 || e.which == 9 || e.keyCode == 9) {
		if (e.which == 13 || e.keyCode == 13 || e.which == 9 || e.keyCode == 9) {
			e.preventDefault();
			e.target.blur();

			const text = e.target.value.trim();
			if (!text.length) {
				options.remove();
			}
			else if (e.which === 9) { // Commit when we tab away from the field
				this.commitChange(text);
			}
		}
	}

	render() {
		const {
			label = "",
			required,
			placeholder,
			options,
			readonly = false,
			filter = undefined,
			value,
			isLoading,
		} = this.props;

		const {
			dataSource,
			openOnFocus = false,
			key = null,
			fullWidth,
		} = options;

		let filterOptions = undefined;
		if (filter) {
			filterOptions = (options) => {
				return options.filter(filter)
			};
		}

		const labelText = `${label}${required ? " *" : ""}`

		const spinner = isLoading ? <CircularProgress size={20} thickness={2} style={{ margin: 0, position: "relative", right: "55px" }} /> : null;

		return (
			<React.Fragment>
				<AutoComplete
					key={key}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							className="c6-mui"
							name="muiAutocomplete"
							color="primary"
							label={labelText}
							placeholder={placeholder}
							onKeyDown={this.handleKeyDown}
							readOnly={readonly}
						/>
					)}
					getOptionLabel={(option) => {
						if (typeof option === "number") {
							return this.state.searchText;
						}

						return option[this.dataSourceConfig.text];
					}}
					isOptionEqualToValue={(option, value) => {
						return option[this.dataSourceConfig.value] === value;
					}}
					onInputChange={this.refreshDataSource}
					inputValue={this.state.searchText}
					value={value}
					filterOptions={filterOptions}
					onChange={(e, option, reason) => {
						this.commitChange(option, reason);
					}}
					freeSolo={true} // From docs: Set freeSolo to true so the textbox can contain any arbitrary value
					options={dataSource}
					ListboxProps={{ style: { maxHeight: 400 }}}
					sx={{
						minWidth: "200px",
						maxWidth: "calc(100% - 40px)",
						display: "inline-block",
					}}
					readOnly={readonly}
					openOnFocus={openOnFocus}
					fullWidth={fullWidth}
				/>
				{spinner}
			</React.Fragment>
		);
		// onBlur={this.handleKeyDown}
		// TODO: Fuzzy filter instead of case insensitive?
	}
}
