import React from 'react'
import Network from 'react-network'

import appConfig from 'config'

import './encoding.css'
import './colors.css'
import './comet.css'
import './module.css'
import '../../client/icomoon.css'

export const Comet = ({ children }) => {
	return (
		<div className="c6-core">
			{children}
			<Network render={({ online }) => (online ? null : (<div className="alert-network">Check your network connection!</div>))} />
		</div>
	);
};

export const Module = ({ children }) => (<div className="c6-module">{children}</div>);

export const Info = ({ children = "" }) => appConfig.features.newCometNavigation
	? null
	: (<div className="c6-bottom">{children}</div>);

export const StandardInfo = ({ location }) => (<Info>{getRouteName(location)}</Info>);

export function getRouteName(location) {
	return location.pathname.charAt(0) === "/"
		? location.pathname.substring(1)
		: location.pathname;
}

export const CometLogo = ({ fill = "var(--action-color)" }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.97 72.97" className="c6-comet-logo" fill={fill}>
		<path d="M4.77,36.49c0-11,32.91-28.7,68.2-28.77V5.84A5.85,5.85,0,0,0,67.13,0H5.84A5.85,5.85,0,0,0,0,5.84V67.13A5.85,5.85,0,0,0,5.84,73H67.13A5.85,5.85,0,0,0,73,67.13V65.25C37.68,65.18,4.77,47.5,4.77,36.49Z" />
		<path d="M21.41,36.49c0,17.61,28.32,26.17,51.33,26.22V10.27C49.73,10.31,21.41,19.11,21.41,36.49Z" />
	</svg>
);