import { createTheme } from '@mui/material/styles'

// For now, we can't use our CSS variables (like --text-color) in the theme object due to limitations in mui
// UNLESS we choose to use the experimental API:
// https://mui.com/material-ui/experimental-api/css-theme-variables/migration/

const baseTheme = {
	palette: {
		secondary: {
			main: "#fff",
			contrastText: "#fff",
		},
		error: {
			main: "#f44336",
			contrastText: "#fff",
		},
		success: {
			main: "#66bb6a",
			contrastText: "#fff",
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},
};

export const lightTheme = createTheme({
	...baseTheme,
	palette: {
		...baseTheme.palette,
		mode: "light",
		text: {
			primary: "#212121"	
		},
		primary: {
			main: "#06a7b1",
			contrastText: "#fff",
		},
	},
	components: {
		...baseTheme.components,
		MuiDialog: {
			styleOverrides: {
				paper: {
					color: "#212121",
				},
			},
		},
	}
});

export const darkTheme = createTheme({
	...baseTheme,
	palette: {
		...baseTheme.palette,
		mode: "dark",
		text: {
			primary: "#a9d8dc",
		},
		primary: {
			main: "#61bbc2",
			contrastText: "#fff",
		},
	},
	components: {
		...baseTheme.components,
		MuiDialog: {
			styleOverrides: {
				paper: {
					color: "#a9d8dc",
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#7bc4ca",
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					"&:before": {
						borderBottomColor: "#364c50",
					},
				}
			}
		}
	}
});
