import React from 'react'

import {Filter, Left } from '../../../components/filter'

const Metadata = ({ model }) => {
	const {
		originalTitle,
		name,
        displayName,
	} = model;

	return (
		<Filter className="c6-metadata c6-metadata-preview c6-osd-metadata-preview">
			<Left>
				<div>
					<h2>{displayName || name}</h2>
					<p>Original title:</p>
					<p><strong>{originalTitle}</strong></p>
				</div>
			</Left>
		</Filter>
	);
};

export default Metadata;