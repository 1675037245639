import React 				 from 'react'
import { Route, IndexRoute } from 'react-router'

import Module        		 from '../app'

import Worklists        	 from './worklists/app'
import Worklist				 from './worklist/app'
import Todo 				 from './todo/app'
import TodoEditor			 from './todoeditor/app'
import MetadataEditor		 from './metadataeditor/app'
import RatingsEditor		 from '../metadata/ratingseditor/app'
import StarAsset 			 from '../../components/assets/asseteditor/editor'

import appConfig from 'config'

export default class OneStopDropModule {
	static routes() {
		return (
			<Route path="onestopdrop" component={Module} module="onestopdrop">
				<Route path="worklists" component={Worklists} />
				<Route path="worklists/:workListId" component={Worklist} />
				<Route path="worklists/items/create" component={TodoEditor} modal={true} />
				<Route path="worklists/items/(:id)/edit" component={TodoEditor} modal={true} />
				<Route path="todos/:id" component={Todo} modal={true} wideModal={true} />
				<Route path="metadata/(:id)/edit" component={MetadataEditor} modal={true} />
				<Route
					path="ratings/(:id)/edit"
					component={RatingsEditor}
					modal={true}
					wideModal={true}
					freeTextContactPerson={false}
					hideReferences={true}
					useContentSourceMetadata={appConfig.features?.useContentSourceMetadata}
				/>
				<Route 
					path="asset/:id/edit"
					component={StarAsset}
					modal={true}
					wideModal={true}
					dialogMaxHeightNone={true}
				/>
			</Route>
		);
	}
}