import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { CONTENT, INLINE, MENU, CLICK_OPT, CLICK_SHIFT, CLICK_CTRL_OR_OPT, ActionType } from '../actions'

import './contentItem.css'

// New ContentItem component (only supports INLINE, MENU and CONTENT actions by design)
@withRouter
export default class ContentItem extends React.PureComponent {
	static displayName = "Item"; // Needed to support UI-actions since babel is minifying the class name (this.constructor.name) when we build to production

	static propTypes = {
		data: PropTypes.object,
		flex: PropTypes.string,
		selected: PropTypes.bool,
		disabled: PropTypes.bool,

		location: PropTypes.object, // From @withRouter
	}

	static contextTypes = {
		uiActions: PropTypes.object,
		listState: PropTypes.object,
	}

	itemRef = null;

	handleClick = (contentAction, e) => {
		const selection = window.getSelection();

		// Don't execute any action if this is a text selection.
		if(selection.toString().length === 0) {
			const { uiActions, listState } = this.context;
			const { data, location, params, ...itemProps } = this.props;

			// If the user is pressing the shift or meta key (CMD or OPT depending on OS), don't
			// execute the content action if there is a meta key click action defined!
			const clickOptAction = getAction(CLICK_OPT, uiActions, this.props);
			const clickShiftAction = getAction(CLICK_SHIFT, uiActions, this.props);
			const clickCtrlOrOptAction = getAction(CLICK_CTRL_OR_OPT, uiActions, this.props);

			if(e.metaKey && clickOptAction) {
				clickOptAction.method(data, uiActions.sourceProps, location, params, itemProps, e);
			}
			else if(e.shiftKey && clickShiftAction) {
				clickShiftAction.method(data, uiActions.sourceProps, location, params, itemProps, e);
			}
			else if((e.metaKey || e.ctrlKey) && clickCtrlOrOptAction) {
				clickCtrlOrOptAction.method(data, uiActions.sourceProps, location, params, itemProps, e);
			}
			else if(!e.metaKey && !e.shiftKey && contentAction){
				contentAction.method(data, uiActions.sourceProps, location, params, itemProps, e, listState);
			}
		}
	}

	handleItemRef = (ref) => {
		this.itemRef = ref;
		if (this.props.itemRef) {
			this.props.itemRef(ref);
		}
	}

	render() {
		const { uiActions } = this.context;
		const {	children, ...props } = this.props;

		const contentAction = getAction(CONTENT, uiActions, props);
		const classNames = getClassNames(props, contentAction);

		if (!uiActions) {
			return <div className={classNames} style={props.style}>{children}</div>;
		}

		return (
			<div
				className={classNames}
				onClick={this.handleClick.bind(this, contentAction)}
				ref={this.handleItemRef}
				style={props.style}
			>
				{children}
				<ActionType type={INLINE} actions={uiActions.actions} itemRef={this.itemRef} {...props} />
				<ActionType type={MENU} actions={uiActions.actions} itemRef={this.itemRef} {...props}  />
			</div>
		);
	}
}

export const PlaceholderItem = ({ className = "", onClick = null, children }) => (
	<div
		className={`c6-item c6-contentItem c6-placeholderItem ${className} ${typeof(onClick) === "function" ? "c6-clickable" : ""}`}
		onClick={onClick}>
		{children}
	</div>
);

export const Content = ({ size = "max", className = "", children }) => <div className={`c6-content ${size} ${className}`}>{children}</div>;

// HELPERS
const getAction = (type, actions = {}, { actionData, location, params }) => {
	const action = actions.actions ? actions.actions.filter(a => a.type === type)[0] : null;

	if(!action || (action && typeof(action.hidden) === "function" && action.hidden(actionData, actions.sourceProps, location, params))) {
		return null;
	}

	return action;
};

const getClassNames = ({ flex, selected, disabled, extraClasses }, contentAction)  => {
	const flexClass = flex ? ` flex flex-${flex}` : "";
	const selectedClass = selected ? " sel" : "";
	const disabledClass = disabled ? " disabled" : "";
	const clickableClass = contentAction && contentAction.method ? " c6-clickable" : "";

	return `c6-item c6-contentItem${flexClass}${selectedClass}${disabledClass}${clickableClass} ${extraClasses}`;
};
