// import React from 'react'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { browserHistory } from 'browserHistory'

import appConfig from 'config'

import AuthActions from '../authentication/actions'
import AuthStore from '../authentication/store'

import { ShieldAccessLevelValues as levels } from '../constants'
import { clearCookies } from '../index'

// // C5 SEC
// export function getToken(api) {

// 	return new Promise((resolve, reject) => {
// 		const AUTH_URL = `${appConfig.app.auth}?api=${api}`;
// 		const API_TOKEN = `jwt-${api}`;

// 		let jwt = localStorage.getItem(API_TOKEN);

// 		// Get a new token if we don't have one
// 		if (!jwt) {
// 			//fetch(AUTH_URL, { credentials: "include" }) // TODO: Cross domain cookies (Allow-Origin can not be set to *)

// 			fetch(AUTH_URL, { credentials: "same-origin" })
// 				.then(response => response.text())
// 				.then(data => {

// 					// TODO: Possibly remove when we #dumpC5sec since we should not get HTML responses (to the C5 login page) anymore
// 					if(data && data.slice(0, 9) === "<!DOCTYPE") {  // Check if response is HTML, redirect to login if it is
// 						const returnURL = encodeURIComponent(document.location.href);
// 						document.location.href = "/main/account/logon?ReturnUrl=" + returnURL;
// 					}
// 					else {
// 						localStorage.setItem(API_TOKEN, data);
// 						resolve(data);
// 					}
// 				})
// 				.catch(error => { reject(error, AUTH_URL); });
// 		}
// 		else {
// 			resolve(jwt)
// 		}
// 	});
// }

export function clearToken(api) {
	const API_TOKEN = `jwt-${api}`;
	localStorage.removeItem(API_TOKEN);
}

// SHIELD
export function getServiceToken(serviceKey) {

	return new Promise((resolve, reject) => {
		const AUTH_URL = `${appConfig.api.shield}services/${serviceKey}/token`;
		const SERVICE_TOKEN = `jwt-${serviceKey}`;

		const jwt = localStorage.getItem(SERVICE_TOKEN);

		// Get a new token if we don't have one
		if (!jwt) {
			const userToken = localStorage.getItem("userJWT");

			let headers = new Headers();
			const bearer = `Bearer ${userToken}`.replace("\n", ""); // HACK: Remove possible newlines to prevent an invalid header.
			headers.append("Authorization", bearer);

			fetch(AUTH_URL, { credentials: "same-origin", headers })
				.then(_processStatus)
				.then(data => {
					localStorage.setItem(SERVICE_TOKEN, data);

					// HACK: Also set a jwt-cookie which will be used by the star image-endpoint
					// and a secondary cookie (only valid for the star api path to prevent trouble)
					// which will be used by the star API.
					if (serviceKey === "star") {
						setSTARCookies(data);
					}

					resolve(data);
				},
				error => { reject(error, AUTH_URL); })
				.catch(error => { reject(error, AUTH_URL); });
		}
		else {
			// HACK: Also set a jwt-cookie which will be used by the star image-endpoint
			// and a secondary cookie (only valid for the star api path to prevent trouble)
			// which will be used by the star API.
			if (serviceKey === "star") {
				setSTARCookies(jwt);
			}

			resolve(jwt)
		}
	});
}

export function isExpired(userData, prematureExpirySeconds = 0) {
	if(userData && (userData.exp || userData.expiry)) {
		// const tokenMoment = moment.unix(userData.expiry).subtract(86100 || prematureExpirySeconds, "seconds");
		const tokenMoment = moment.unix(userData.exp || userData.expiry).subtract(prematureExpirySeconds, "seconds");
		// console.log("Token expires: %s", tokenMoment.format("YYYY-MM-DD HH:mm:ss"));
		return moment().isAfter(tokenMoment);
	}

	return true;
}

export function isAuthorized(routes, userLevels, userRoles, minimumLevel = null, versionId = null) {
	let route = {};
	routes.forEach(({ module, app, access, roles }) => {
		if(module) { route.module = module; }
		if(app)    { route.app = app; }
		if(access) { route.access = access; }
		if(roles)  { route.roles = typeof(roles) === "string" ? roles.split(",") : roles; }
	});

	// Default to reader access on empty routes
	route.access = route.access || "reader";

	// Pick the role access based on versionId if provided
	route.roles = versionId && typeof(route.roles) === "object" ? route.roles[versionId] : route.roles;

	return hasAccess(route, userLevels, userRoles, minimumLevel);
}

export function loginWithToken(userJWT, stayOnPage = false) {
	// Handle corrupt JWT
	if(typeof(userJWT) === "object" || userJWT === "[object Object]" || userJWT === "undefined") {
		logoutUser({}, stayOnPage);
		return false;
	}

	const userData = jwt_decode(userJWT);
	if(isExpired(userData)) {
		logoutUser(userData, stayOnPage);
	}
	else {
		localStorage.setItem("userJWT", userJWT);
		AuthActions.login(userData);
		clearCmsStageSettingForNonAdmins();
	}
}

export function logoutUser(userData, stayOnPage = false) {
	AuthActions.logout.defer(userData, stayOnPage);

	if (!stayOnPage) {
		browserHistory.push("/logout");
	}
}

// SHIELD DECORATOR
// export function withShield(DecoratedComponent) {
// 	class WithShield extends DecoratedComponent {
// 		render() {
// 			return <DecoratedComponent
// 						{...this.state}
// 						{...this.props}
// 						{...this.context} />
// 		}
// 	}
// 	WithShield.contextTypes = {
// 		userHasAccessToPath: React.PropTypes.func,
// 	};

// 	return WithShield;
// }

export function hasAccessToPath(routes, minimumLevel = null, versionId = null) {
	const { accesslevels, accessroles } = AuthStore.getState();
	return isAuthorized(routes, accesslevels, accessroles, minimumLevel, versionId);
}

export function hasRole(roleName) {
	const { accessroles } = AuthStore.getState();
	return accessroles.includes(roleName);
}

export function isLoggedIn() {
	return AuthStore.getState().loggedIn;
}

export function getModuleRoles(moduleName) {
	const { accessroles } = AuthStore.getState();
	return accessroles.filter(role => role.split(".")[0] === moduleName);
}

export function getUser() {
	return AuthStore.getState().user;
}

// Find the first accesslevel for specified module
// The last part of that string determines which level of access the user has in that module
// Example: if accesslevel selections.ott.admin is present, user is a Selections Admin
export function getUserAccessLevelForModule(moduleName) {
	const { accesslevels } = AuthStore.getState();
	const module = accesslevels?.find(al => al.startsWith(`${moduleName}.`));
	const moduleAccessLevel = module?.split(".").pop();
	return moduleAccessLevel;
}

// HELPERS
function setSTARCookies(jwt) {
	const link = document.createElement("a");
	link.href = appConfig.api.star;
	const { hostname, pathname } = link;
	// Used by the star image endpoint
	document.cookie = `starusertoken=${jwt};domain=${hostname};path=/;`;
	// Used by the STAR api
	document.cookie = `usertoken=${jwt};domain=${hostname};path=${pathname || "/star/"};`;
}

function hasAccess(route, userLevels, userRoles, minimumLevel) {
	// Match route access against the user access level list
	let hasValidAccessLevel = false;
	userLevels.forEach(ul => {
		const userLevelParts = ul.split(".");

		// Access levels can either have a module.app.access format or a module.access format
		let module, app, access;
		if(userLevelParts.length === 2) {
			[ module, access ] = userLevelParts;
		}
		else {
			[ module, app, access ] = userLevelParts;
		}

		// Also matches just the module, if we're not navigating to an app
		// or just the UI root if we're not navigating to a module
		if(
			!hasValidAccessLevel &&
			(route.module === module || !route.module) &&
			(route.app === app || !route.app) &&
			levels[route.access] <= levels[access]
		) {
			hasValidAccessLevel = minimumLevel
				? levels[minimumLevel] <= levels[access]
				: true ;
		}
	});

	// Match route roles against the user access role list (or role object
	// if we're after a specific role access level)
	let hasValidAccessRole = false;
	if(route.roles) {
		userRoles.forEach(r => {
			const [ module, role ] = r.split(".");
			if(!hasValidAccessRole && route.module === module) {
				if(Array.isArray(route.roles)) {
					hasValidAccessRole = route.roles.filter(rr => rr.trim() === role).length > 0;
				}
				else if(typeof(route.roles) === "object") {
					const roleAccessLevel = route.roles[role];
					hasValidAccessRole = minimumLevel
					? levels[minimumLevel] <= levels[roleAccessLevel]
					: !!roleAccessLevel ;
				}
			}
		});
	}

	return hasValidAccessLevel || hasValidAccessRole;
}

function _processStatus(response) {
	let data = null;
	switch(response.status) {
		case 200:
			data = response.text();
			return Promise.resolve(data);
		case 400:
		case 401:
		case 500:
			return response.json().then(json => {
				return Promise.reject(json);
			});
		default:
			return Promise.reject(new Error(response.statusText))
	}
}

function clearCmsStageSettingForNonAdmins() {
	const user = getUser();
	const isSysadmin = user.username === "sysadmin";
	const cmsAccessLevel = getUserAccessLevelForModule("eventplanner");
	if (!(isSysadmin || cmsAccessLevel === "admin")) {
		localStorage.removeItem("x-cms-stage");
	}
}