import React, { Component } from 'react'
import moment from 'moment'


import { Left, Right } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Main from '../../../components/ui/main'
import Comments from '../../../components/comments'

import Actions from '../actions'

import Const from '../../../core/constants'

import './activity.css'

import appConfig from 'config'

export default class TodoActivity extends Component {

	postComment = (newCommentText) => {
		if (newCommentText && newCommentText.length > 0) {
			const threadId = this.props.todo.thread && this.props.todo.thread.id || 0; 
			Actions.postComment(threadId, this.props.todo.workListItemId, newCommentText, () => {
				// this.scrollComments();
			});
		}
	}

	onDownloadMetadata = () => {
		const { id, name } = this.props.container;
		Actions.downloadMetadataTemplate(id, `metadata_template_${name}.xlsx`);
	}

	renderCommentsSection = () => {
		const { comments, commentsLoading } = this.props;
		return (
			<section className="comments">
				<h2>Comments</h2>
				<Comments
					commentsLoading={commentsLoading}
					comments={comments}
					postComment={this.postComment}
					startEditingComment={Actions.startEditingComment}
					stopEditingComment={Actions.stopEditingComment}
					// deleteComment={deleteComment}
					updateComment={Actions.updateComment}
					allowDelete={false}
					allowEdit={true}
				/>	
			</section>
		);
	}

	setStatus = (newStatus) => {
		const {
			workListItemId: id,
			workListId,
		} = this.props.todo;

		Actions.updateItem("todo", { id }, { status: newStatus, workListId });
	}

	openMetadataEditor = () => {
		const { programGuid } = this.props.container;
		Actions.edit({
			pathname: `/onestopdrop/metadata/${programGuid}/edit`,
			query: {
				todoType: this.props.todo?.workType,
				versionId: this.props.todo?.versionId,
				todoId: this.props.todo?.workListItemId,
			},
			returnTo: window.location.pathname,
		});
	}

	render() {
		const {
			todo,
			container,
			isProductionCompanyUser,
		} = this.props;

		const hideMetadataForProductionCompanies = isProductionCompanyUser && appConfig.features.osdProductionCompanyRestrictions;
		const status = container.contentSourceMetadata?.statuses?.find(s => parseInt(s.workListItemId) === parseInt(todo?.workListItemId));
		const isComplete = !!status?.complete;
		const metadataDisabled = !container?.id
			|| !container?.programGuid
			|| isComplete && appConfig.features.useContentSourceMetadata;

		return (
			<div className="todo-activity">
				<section className="due-date">
					<h2>Due date</h2>
					<div>
						<Left>
							{todo.dueDate && <h1>{moment(todo.dueDate).format(Const.DATE_WITH_DAY_OF_WEEK_FORMAT)}</h1>}
							{todo.dueDate && <p>"{todo.displayName}" {moment(todo.dueDate).isAfter(moment()) ? "needs" : "needed" } to be done {moment(todo.dueDate).fromNow()}.</p>}
							{!todo.dueDate && <p>"{todo.displayName}" does not have a due date.</p>}
							<a
								className="c6-link"
								onClick={this.setStatus.bind(this, todo.status === "Ignored" ? "Unhandled" : "Ignored")}
							>
								{todo.status === "Ignored" && <span className="icon-undo"></span>}
								{todo.status === "Ignored" && "Undo ignore"}
								{todo.status !== "Ignored" && "Ignore this task and remove it from my todo list"}
							</a>
						</Left>
						<Right>
							<Button
								type={todo.status === "Complete" ? "lock_open" : "submit"}
								title={todo.status === "Complete" ? "Open" : "All done"}
								hoverTitle={todo.status === "Complete" ? "" : "Click \"All done\" to indicate that you are done with this task. It will take it off your todo list."}
								onClick={this.setStatus.bind(this, todo.status === "Complete" ? "Unhandled" : "Complete")}
							/>
						</Right>
					</div>
				</section>
				{(appConfig.features.osdEnableExcel && todo?.workType === "Content" && !hideMetadataForProductionCompanies) && (
					<section>
						<h2>Metadata template</h2>
						<div>
							<Left>
								{!!container?.id && (
									<p>
										Download the <strong>{container.name}</strong> Excel file to provide metadata for {todo.displayName}.
										After you have entered the required info, save the file and upload it together with the program images.
									</p>
								)}
								{!container?.id && <p>Loading folder...</p>}
							</Left>
							<Right>
								<Button
									type="file-excel"
									title="Download metadata template"
									shortTitle="Download"
									disabled={!container?.id}
									onClick={this.onDownloadMetadata}
								/>
							</Right>
						</div>
					</section>
				)}
				{!metadataDisabled && todo?.workType === "Content" && !hideMetadataForProductionCompanies && (
					<section>
						<h2>Metadata editor</h2>
						<div>
							<Left>
								{!!container?.id && container.programGuid && (<p>You can provide metadata in the browser by using the editor.</p>)}
								{!!container?.id && !container.programGuid && (<p>The editor is not available for todos created manually.</p>)}
								{!container?.id && <p>Loading folder...</p>}
							</Left>
							<Right>
								<Button
									className="icon-create"
									title="Open metadata editor"
									shortTitle="Editor"
									disabled={metadataDisabled}
									onClick={this.openMetadataEditor}
								/>
							</Right>
						</div>
					</section>
				)}
				{this.renderCommentsSection()}
			</div>
		);
	}
}