import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

import './datetimepicker.css'

const DateTimePicker = ({
	componentType,
	dataType,
	style,
	name,
	className,
	value,
	displayName,
	onChange,
	options,
}) => {
	const requireConfirm = true;
	const parsedValue = React.useMemo(
		() => getParsedValue(value, dataType),
		[value, dataType]
	);

	const onFlatpickrChange = React.useCallback(
		(dates, dateStr) => {
			if (requireConfirm) {
				return;
			}

			const newValue = getValue(dateStr?.length ? dateStr : dates[0], dataType);
			if (newValue?.length) {
				onChange(newValue);
			}
		},
		[requireConfirm, onChange]
	);

	const flatpickrOptions = React.useMemo(
		() => {
			let plugins = [];
			const useTime = ["time-seconds", "time", "date-time", "hours-minutes"].includes(style?.format);
			if (useTime) {
				plugins.push(new CustomTimeSectionPlugin({
					text: options?.timeLabel ?? displayName,
					dataType,
				}));
			}

			if (requireConfirm) {
				plugins.push(new ConfirmPlugin({ 
					onChange,
					dataType,
				}));
			}

			return {
				enableTime: true,
				enableSeconds: style?.format === "time-seconds",
				altInput: true,
				time_24hr: true,
				locale: { firstDayOfWeek: 1 }, // Monday
				plugins,
				noCalendar: dataType === "timeSpan" && style?.format === "hours-minutes",
				altFormat: style?.format === "date-time" ? "Y-m-d H:i" : "H:i", // https://flatpickr.js.org/formatting/
			};
		},
		[style, componentType, onChange, requireConfirm]
	);

	return (
		<div
			key={name}
			className={`${className} time-picker ${dataType === "timeSpan" ? "time-compact" : ""} ${!parsedValue ? "value-missing" : ""} ${style?.format}`}
			title={parsedValue}
			onClick={e => e.stopPropagation()}
		>
			{displayName && <span>{displayName}</span>}
			<Flatpickr
				key={name}
				value={parsedValue}
				onChange={onFlatpickrChange}
				options={flatpickrOptions}
				onReady={onFpReady}
			/>
		</div>
	);
}

export default DateTimePicker;

function onFpReady(_, __, fp) {
	fp.calendarContainer?.classList.add("c6-cms-datetimepicker");
}

const CustomTimeSectionPlugin = (pluginConfig) => {
	const config = {
		text: pluginConfig?.text ?? "",
		theme: pluginConfig?.theme ?? "light",
	};

	const updateLabel = (fp, dateStr) => {
		if (fp.timeLabel && pluginConfig.dataType !== "timeSpan") {
			const value = moment(dateStr);
			fp.timeLabel.innerText = `${config.text} ${value.format("D MMM")}`;
		} else if (fp.timeLabel) {
			fp.timeLabel.innerText = config.text;
		}
	};

	// fp == flatpicker instance
	const fpFunc = fp => {
		const hooks = {
			onReady: () => {
				if (!fp.calendarContainer || !fp.timeContainer) {
					return;
				}
				fp.timeContainer.classList.add("custom-time-section");
				fp.timeLabel = fp._createElement("div", `visible ${config.theme}Theme time-label`, config.text);
				fp.timeLabel.tabIndex = -1;
				fp.timeContainer.appendChild(fp.timeLabel);
			},

			onOpen: (selectedDates, dateStr) => {
				updateLabel(fp, dateStr || fp.latestSelectedDateObj);
			},
			

			onChange: (selectedDates, dateStr) => {
				updateLabel(fp, dateStr || fp.latestSelectedDateObj);
			}
		};

		return hooks;
	};

	return fpFunc;
};

export const ConfirmPlugin = (pluginConfig) => {
	const config = {
		confirmText: pluginConfig?.confirmText ?? "OK",
		cancelText: pluginConfig?.cancelText ?? "CANCEL",
		theme: pluginConfig?.theme ?? "light",
		onChange: pluginConfig?.onChange ?? function() {},
	};

	// fp == flatpicker instance
	const fpFunc = fp => {
		let didSave = false;
		let resetValue = null;

		const save = (close = true) => {
			const selectedDate = fp.selectedDates[0];
			if (selectedDate) {
				const newValue = getValue(selectedDate, pluginConfig.dataType);
				config.onChange(newValue);
				didSave = true;
			}

			if (close) {
				fp.close();
			}
		};

		const hooks = {
			onReady: () => {
				if (!fp.calendarContainer) {
					return;
				}

				fp.confirmContainer = fp._createElement("div", `confirm-container visible ${config.theme}Theme`);
				fp.confirmContainer.tabIndex = -1;
				fp.calendarContainer.appendChild(fp.confirmContainer);

				fp.confirmButton = fp._createElement("button", `confirm ${config.theme}Theme`, config.confirmText);
				fp.confirmButton.addEventListener("click", save);
				fp.confirmContainer.appendChild(fp.confirmButton);

				fp.cancelButton = fp._createElement("button", `cancel ${config.theme}Theme`, config.cancelText);
				fp.cancelButton.addEventListener("click", () => {
					didSave = false;
					fp.close();
				});
				fp.confirmContainer.appendChild(fp.cancelButton);

				// Redraw because confirmContainer seems to appear outside the screen otherwise on small screens
				fp.redraw();
			},

			onKeyDown: (selectedDates, dateStr, fpInstance, e) => {
				switch (e.key) {
					case "Enter":
						// HACK: Blur to trigger time update in Flatpickr 
						fp.timeContainer.blur();
						// Save but don't close because Flatpickr will close it anyway and we don't want two calls to onClose
						save(false);
						return;
				}
			},


			onClose: () => {
				setTimeout(() => { // setTimeout so that this runs after the onKeyDown handler above
					if (!didSave) {
						fp.setDate(resetValue);
					}

					didSave = false;
					resetValue = null;
				});
			},

			onOpen: () => {
				const selectedDate = fp.selectedDates[0];
				if (selectedDate) {
					const value = getValue(selectedDate, pluginConfig.dataType);
					resetValue = getParsedValue(value, pluginConfig.dataType);
				}
			},

			// Adjust calendar position when close to bottom of screen
			onPreCalendarPosition: (_, __, fpInstance) => {
				setTimeout(() => {
					const rect = fpInstance.calendarContainer.getBoundingClientRect();
					if (rect.bottom >= window.innerHeight - 100) {
						fpInstance.calendarContainer.style.top = (parseInt(fpInstance.calendarContainer.style.top) - 100) + "px";
					}
				});
			},
		};

		return hooks;
	};

	return fpFunc;
};

function getParsedValue(value, dataType) {
	if (!value) {
		return null;
	}

	if (dataType === "timeSpan") {
		const [hours, minutes, seconds] = value.split(":");
		const m = moment();
		m.hours(Number(hours));
		m.minutes(Number(minutes));
		m.seconds(Number(seconds));
		return m.format("HH:mm");
	}

	return moment(value).format();
}

function getValue(selectedDate, dataType) {
	if (dataType === "timeSpan") {
		const m = moment(selectedDate);
		const hours = `${m.hours()}`.padStart(2, "0");
		const minutes = `${m.minutes()}`.padStart(2, "0");
		const seconds = `${m.seconds()}`.padStart(2, "0");
		return `${hours}:${minutes}:${seconds}`;
	}

	return moment(selectedDate).format();
}
