import React, { PureComponent } from 'react'
import { browserHistory } from 'browserHistory' // match
import jwt_decode from 'jwt-decode'

import { isExpired, isAuthorized } from '../services/auth' // hasAccessToPath

import appConfig from 'config'

class ShieldAuthentication extends PureComponent {
	// static childContextTypes = {
	// 	userHasAccessToPath: React.PropTypes.func
	// }

	// getChildContext() {
	// 	return { userHasAccessToPath: pathname => userHasAccessToPath(pathname, this.props.routes) };
	// }

	constructor(props) {
		super(props);

		this.state = {
			allowRender: false,
		};
	}

	// When the web app is rendered in the browser the first time (for example after a page refresh)
	componentDidMount() {
		const { location, routes } = this.props;
		const allowRender = checkValidity(location, routes);
		this.setState({ allowRender });
	}

	// When navigating routes in the app
	componentDidUpdate() {
		const { location, routes } = this.props;
		const allowRender = checkValidity(location, routes);
		if (allowRender !== this.state.allowRender) {
			this.setState({ allowRender });
		}
	}

	render() {
		return this.state.allowRender ? this.props.children : null;
	}
}

export default ShieldAuthentication;

// HELPERS
// function userHasAccessToPath(pathname, routes) {

// 	const data = {
// 		location: { pathname },
// 		routes,
// 	};

// 	// TODO: Return promise

// 	match(data, (error, redirectLocation, nextState) => {
// 		if(error) {
// 			console.error(error);
// 			return false;
// 		}
// 		return hasAccessToPath(nextState.routes);
// 	});

// 	return true;
// }

function checkValidity(location, routes) {

	const { expired, authorized, user } = validateToken(routes);

	if (expired) {
		browserHistory.push({
			pathname: "/login",
			state: location.pathname.endsWith("/logout") || location.pathname.endsWith("/login")
				? location.state ?? undefined
				: { nextLocation: { ...location } },
		});

		return false;
	}

	// Match the user access rights to the configured levels and roles on the routes
	if (!authorized) {
		browserHistory.replace({
			pathname: "/unauthorized",
			state: location.pathname.endsWith("/logout") || location.pathname.endsWith("/login")
				? location.state ?? undefined
				: { nextLocation: { ...location } },
		});

		return false;
	}

	return true;
}

function validateToken(routes) {

	const isPublicRoute = routes.some(r => r.public === true);
	if (isPublicRoute) {
		return {
			isValid: true,
			expired: false,
			authorized: true,
		};
	}

	const jwt = localStorage.getItem("userJWT");
	if (jwt) {
		const { accesslevels, accessroles, ...user } = jwt_decode(jwt);

		const expired = isExpired(user);
		const authorized = isAuthorized(routes, accesslevels, accessroles); // TODO!!!!!!!! SHIELD: Find a way to send the versionId from the route to isAuthorized

		return {
			isValid: !expired && authorized,
			expired,
			authorized,
			user,
		};
	}

	return {
		isValid: false,
		expired: true,
		authorized: false,
	};
}