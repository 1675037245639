import React from 'react'
import debounce from 'lodash/debounce'
import { ScrollContainer } from 'react-router-scroll'
import { browserHistory } from 'browserHistory'

import { getRouteName } from '../../../components/comet'
import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Main from '../../../components/ui/main'

import Header from './header'
import List from './list'

import Actions from '../actions'
import Store from '../store'

import { isLoggedIn } from '../../../core/services/auth'

import '../shared/app.css'

const MODULE = "onestopdrop";
const DATASTORE = "worklists";
const TEXT_HEADING = "Your worklists";

export default class WorklistsApp extends React.Component {

	constructor(props) {
		super(props);

		this.scroll = null;

		this.state = {
			...Store.getState(),
			searchText: "",
			unselectedFilter: false,
		};
	}

	componentDidMount() {
		const loggedIn = isLoggedIn();
		if (!loggedIn) {
			browserHistory.replace("/login");
			return;
		}

		const preventRedirect = this.props.location.state && this.props.location.state.preventRedirect;
		Store.listen(this.onChange);
		if (!this.state.list[DATASTORE].length) {
			Actions.fetchItems(DATASTORE, {}, null, preventRedirect);
		}
	}

	componentWillUnmount() {
		Actions.unmount();
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	loadMore = () => {
		if (this.state.nextPageUrl) {
			Actions.pageItems(DATASTORE, this.state.nextPageUrl);
		}
	}

	shouldUpdateScroll = (prevRouterProps, currentRouterProps) => {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { isLoading, searchText: componentSearchText, filters, nextPageUrl, unselectedFilter, list } = this.state;
		const searchText = componentSearchText || filters.searchText; // TODO!: We should probably only have one searchText since this will make people go mad.

		const worklists = list[DATASTORE];

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				layout="grid"
				isLoading={isLoading}
			>
				<Header
					title={TEXT_HEADING}
					searchText={searchText}
				/>
				<ScrollContainer scrollKey={`c6-${MODULE}-${DATASTORE}`} shouldUpdateScroll={this.shouldUpdateScroll}>
					<Main inputRef={el => this.scroll = el}>
						<List
							items={worklists.items}
							isLoading={this.state.isLoading}
							searchText={searchText}
							filters={filters}
							hasMore={worklists.nextPageUrl}
							loadMore={this.loadMore} />
					</Main>
				</ScrollContainer>
				<Info>{getInfo(this.props.location, this.state)}</Info>
			</App>
		);
	}
}

// HELPERS
function getInfo(location, { numberOfItems, list, ...rest }) {
	const ds = list[DATASTORE];
	const items = ds.length ? `- displaying ${ds.length} of ${numberOfItems}` : "";

	return `${getRouteName(location)} ${items}`;
}