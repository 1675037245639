import React from 'react'

import { Filter, Left, Right } from '../../../components/filter'

import Actions from '../actions'

export default ({
	title,
}) => (
	<Filter>
		<Left>
			<h1>{title}</h1>
		</Left>
	</Filter>
);