import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import update from 'immutability-helper'

// import Button from '../../../components/ui/controls/button'
import Cropper from '../../../components/crop/index'
import Image from '../../../components/image'

import Actions from './actions'

import appConfig from 'config'

export default class AssetCropper extends React.Component {

    static propTypes = {
        asset: PropTypes.object,
        activeCrop: PropTypes.object,
        orgFile: PropTypes.object,
        forceRefreshImage: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    constructor(props) {
		super(props);

        this.timestamp = Date.now();
    }

    shouldComponentUpdate(nextProps, nextState) {
		const { asset, forceRefreshImage, activeCrop, transformations } = this.props;
        return (
			!isEqual(nextProps.asset, asset)
			|| nextProps.forceRefreshImage !== forceRefreshImage
			|| nextProps.activeCrop !== activeCrop
			|| !isEqual(nextProps.transformations, transformations)
		);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextProps.forceRefreshImage !== this.props.forceRefreshImage) {
            this.timestamp = Date.now();
        }
    }

    onCompleteCrop = cropData => {
        const { activeCrop } = this.props;

        if (activeCrop.cropData) {

			const newModifiedCrop = {
				...activeCrop,
				cropData: {
					...activeCrop.cropData,
					...cropData,
					offsetX: cropData.x,
					offsetY: cropData.y,
					width: cropData.width,
					height: cropData.height,
				},
			};

			Actions.setCrops(newModifiedCrop);
		}
    }

    render() {
        const { asset, activeCrop, orgFile, forceRefreshImage, disabled, transformations } = this.props;
		const extension = asset.assetData && asset.assetData.type.toLowerCase() === "png" ? "png" : "jpg";

        const refreshImageParam = forceRefreshImage ? `&forceRefresh=true` : "";
        const format = "Star_None_x600_notransform";
        const imageLinkHref = asset.links.find(link => link.rel === 'image').href;
        const imageSource = `${imageLinkHref}/${format}.${extension}?timestamp=${this.timestamp}${refreshImageParam}`;

        let content;
        if (activeCrop.id) {
            const swapWidthHeight = asset.rotation % 180 !== 0;
            const width = swapWidthHeight ? orgFile.height : orgFile.width;
            const height = swapWidthHeight ? orgFile.width : orgFile.height;
            content = (
                <Cropper
                    src={imageSource}
                    crop={getCrop(activeCrop)}
                    originalWidth={width}
                    originalHeight={height}
                    minWidth={activeCrop.minWidth}
                    minHeight={activeCrop.minHeight}
                    onComplete={this.onCompleteCrop}
                    disabled={disabled}
                    cropId={activeCrop.id}
                    aspect={activeCrop.xRatio / activeCrop.yRatio}
                />
            );
        }
        else {
            content = (
                <Image
                    src={imageSource}
                    alt={asset.name}
					onLoad={() => { if(forceRefreshImage) { Actions.imageLoaded.defer(); } }}
					assetTransformations={transformations}
                    hideImageWhileSpinnerIsShown={true}
                />
            );
        }

        return <div className="image-wrapper">{content}</div>;
    }
}

function getCrop({ cropData, xRatio, yRatio }) {
	return {
		x: cropData.offsetX,
		y: cropData.offsetY,
		width: cropData.width,
		height: cropData.height,
	}
}