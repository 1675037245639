import React, { Component } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import moment from 'moment'

import DatePicker from '../../../ui/controls/pickers/datepicker'
import Switch from '../../../ui/controls/switch'
import TextArea from '../../../ui/textarea-confirm'

import * as StarAPI from '../../../../apis/star'

import Actions from '../actions'

export default class Restrictions extends Component {
	
	state = {
		initialFrom: "",
		initialUntil: "",
		rights: [],
	}

	componentDidMount() {
		StarAPI.fetchRights()
			.then(response => {
				this.setState({ rights: response.items ?? [] });
			});

		const dateRestriction = this.props.asset.rights.find(r => r.rightTypeId === 1);
		if (dateRestriction) {
			this.setInitialDates(dateRestriction);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// const dateRestriction = nextProps.asset.rights.filter(r => r.type === "DateRestriction");
		const dateRestriction = nextProps.asset.rights.find(r => r.rightTypeId === 1);
		if (dateRestriction) {
			this.setInitialDates(dateRestriction);
		}
	}

	setInitialDates = (dateRestriction) => {
		this.setState({
			initialFrom: dateRestriction.from ? moment(dateRestriction.from).format("YYYY-MM-DD") : "",
			initialUntil: dateRestriction.until ? moment(dateRestriction.until).format("YYYY-MM-DD") : "",
		});
	}

	handleUpdateAssetRestrictions = (e) => {
		const { asset } = this.props;
		const restriction = e.target.value;
		Actions.updateAssetRestrictions(asset.id, restriction);

		if (restriction !== "All,Protected") {
			this.handleUpdateAssetRoleRights(null, null, []);
		}
	}

	handleUpdateAssetRoleRights = (e) => {
		const roleIds = e.target.value;
		const asset = this.props.asset;
		const existingRoleRights = asset.rights?.filter(r => r.rightType === "Role"/* && !roleIds.includes(r.rightId)*/) ?? [];

		const removedRoles = existingRoleRights.filter(r => !roleIds.includes(r.rightId));
		if (removedRoles?.length) {
			removedRoles.forEach(role => {
				const payload = {
					assetRightId: role.assetRightId,
				};

				Actions.deleteAssetRights(asset.id, payload);
			});
			return;
		}

		const newRoleIds = roleIds.filter(roleId => !asset.rights?.find(r => r.rightType === "Role" && r.rightId === roleId));
		if (newRoleIds) {
			newRoleIds.forEach(roleId => {
				const payload = {
					rightId: roleId,
					regionId: this.props.region?.id ?? 0,
				};

				Actions.updateAssetRights(asset.id, payload);
			});
			return;
		}
	}

	handleUpdateAssetRights = (type, value) => {
		const { asset } = this.props;
		const { rights } = asset;

		if (
			type === "from" && this.state.initialFrom !== value
			|| type === "until" && this.state.initialUntil !== value
		) {
			console.log("this.state.rights", this.state.rights);
			const right = this.state.rights.find(r => r.type === "License"); // DateRestriction / SpecialRights
			Actions.updateAssetRights(
				asset.id,
				{
					licensing: asset.licensing,
					rightId: right.rightId,
					[type]: value || "0001-01-01",
				},
				rights,
			);
		}
	}

	handleUpdateAssetMetadata = (usage) => {
		Actions.updateAssetMetadata(this.props.asset, { usage }, this.props.region);
	}

	renderRoleOptions = () => {
		const roleRights = this.state.rights.filter(r => r.type === "Role");
		const assetRoleRights = this.props.asset?.rights?.filter(r => r.rightTypeId === 2) ?? [];

		return roleRights.map((opt, i) => {
			const checked = !!assetRoleRights.find(r => r.rightId === opt.rightId);

			return (
				<MenuItem
					key={opt.rightId}
					value={opt.rightId}
				>
					{opt.displayName}
				</MenuItem>
			);
		});
	}

	render() {
		const { asset, user, region } = this.props;
		const { id, audience, licensing, rights = [], createdByUser } = asset;

		const metadataVersion = region
			? (asset.versionData || []).find(vd => vd.versionId === region.id) || {}
			: { usage: asset.usage };

		// const dateRestriction = rights.filter(r => r.type === "DateRestriction");
		const dateRestriction = rights.find(r => r.rightTypeId === 1);
		let from = null, until = null;
		if (dateRestriction) {
			from = dateRestriction.from;
			until = dateRestriction.until;
		}

		const assetRoleRights = rights.filter(r => r.rightType === "Role");
		const assetCreatedByCurrentUser = createdByUser === user.username;
		const readOnly = this.props.readOnly || !this.state.rights.length;

		return (
			<div className={`c6-star-asset-restrictions ${readOnly ? "disabled" : ""}`}>
				<Switch
					readOnly={readOnly}
					name="restrictions"
					onChange={this.handleUpdateAssetRestrictions}
					states={getRestrictions(from, until, assetRoleRights, assetCreatedByCurrentUser)}
					currentState={`${audience},${licensing}`}
					renderDescription
				/>
				{audience === "All" && licensing === "Protected" && (
					<Select
						className="mui-select"
						value={assetRoleRights.map(r => r.rightId)}
						multiple={true}
						onChange={this.handleUpdateAssetRoleRights}
						variant="standard"
						fullWidth
					>
						{this.renderRoleOptions()}
					</Select>
				)}
				<DatePicker
					// readOnly={readOnly} // Don't set readonly for datepicker because it messes with the data-input button
					value={from}
					onChange={this.handleUpdateAssetRights.bind(this, "from")}
					label="Not available before"
					options={{ hideClear: true, static: false }}
				/>
				<DatePicker
					// readOnly={readOnly} // Don't set readonly for datepicker because it messes with the data-input button
					value={until}
					onChange={this.handleUpdateAssetRights.bind(this, "until")}
					label="Not available after"
					options={{ hideClear: true, static: false }}
				/>
				<TextArea
					readOnly={readOnly}
					defaultValue={metadataVersion.usage}
					label={`Special rights info ${region ? ` (${region.languageDisplayName})` : "" }`}
					onSave={this.handleUpdateAssetMetadata}
					maxLength={500}
					region={region}
				/>
			</div>
		);
	}
}

function getRestrictions(from, until, assetRoleRights, assetCreatedByCurrentUser) {

	let dateRestrictionText = ".";
	if (from && until) {
		dateRestrictionText = " during the period below.";
	}
	else if (from) {
		dateRestrictionText = " beginning on the date below.";
	}
	else if (until) {
		dateRestrictionText = " until and including the date below.";
	}

	let roleRestrictionText = "";
	if (assetRoleRights?.length) {
		roleRestrictionText = " and the necessary roles";
	}

	return [
		{
			key: "Private,Internal",
			className: "icon-account_circle large-icon",
			description: "This asset is only visible to you.",
			disabled: !assetCreatedByCurrentUser,
			disabledDescription: "Asset was created by another user so you can't make it visible only for you."
		},
		{
			key: "Admin,Internal",
			className: "icon-check_circle large-icon",
			description: "This asset is visible to all STAR editors.",
			disabled: !assetCreatedByCurrentUser,
			disabledDescription: "Asset was created by another user so you can't make it visible only for editors."
		},
		{
			key: "All,Internal",
			className: "icon-stars large-icon",
			description: "This asset is visible to everyone in STAR.",
		},
		{
			key: "All,Protected",
			className: "icon-vpn_lock large-icon",
			description: `This asset is visible to anyone with an authorization key${roleRestrictionText}${dateRestrictionText}`,
		},
		{
			key: "All,Public",
			className: "icon-public large-icon",
			description: `This asset is visible to anyone${dateRestrictionText}`,
		},
	];
}