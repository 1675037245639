import React from 'react'

import './status.css'

export default ({ status, classNames = "", title, label, children, onClick }) => {
	if(!status) {
		return null;
	}

	const el = (
		<div
			className={`c6-status c6-status-${status.replace(/\s/g,"").toLowerCase()} ${classNames}`}
			title={title}
			onClick={onClick}
		>
			{children || status}
		</div>
	);

	return label ? <div className="c6-status-group">{el}<span>{label}</span></div>: el;
};