import { get, put, post, del } from '../core/c6'
import appConfig from 'config'
import Qs from 'qs'

const API = "selections";
const groupsEndpoint = appConfig.features?.useNewSelections ? "sections" : "groups";

/* **************** LISTS ***************** */
export const fetchLists         =              filters =>  get(API, "lists", filters);
export const fetchList          =               listId =>  get(API, `lists/${listId}`);
export const updateList         =    (listId, payload, sectionId) =>  {
	if (sectionId !== undefined) {
		return put(API, `lists/${listId}?sectionId=${sectionId}`, payload);
	}

	return put(API, `lists/${listId}`, payload);
};
export const createList = payload => {
	if (appConfig.features?.useNewSelections) {
		const { sectionId, area, position, active, ...rest } = payload;
		const qParams = Qs.stringify({
			sectionId,
			area,
			position,
			active,
		}, { skipNulls: true });
		return post(API, `lists?${qParams}`, rest);
	}

	return post(API, "lists", payload);
};
export const deleteList         =               listId =>  del(API, `lists/${listId}`);
export const linkList           =    (listId, payload) =>  put(API, `lists/${listId}/link`, payload); // Link a list to another list
export const copyList           =    (listId, payload) =>  put(API, `lists/${listId}/copyitems`, payload); // Copy items from another list
export const disableList        =               listId =>  put(API, `lists/${listId}/disable`);
export const enableList         =    (listId, payload) =>  put(API, `lists/${listId}/enable`, payload);
// HACK: While we have the old selections module
export const fetchListInstances =    (requestOrId, payload) => {
	const { listId, filters } = requestOrId;
	return get(API, `lists/${listId || requestOrId}/instances`, filters || payload);
}
export const removeListInstance = (listId, instanceId) =>  del(API, `lists/${listId}/instances/${instanceId}`);

/* **************** LIST ITEMS ***************** */
export const fetchItem   =                    itemId =>  get(API, `items/${itemId}`);
export const insertItem  =                   payload => post(API, "items", payload);
export const updateItem  = (itemId, listId, payload) =>  put(API, `lists/${listId}/items/${itemId}`, payload);
export const deleteItems =            (listId, date) =>  del(API, `lists/${listId}/items/${date}`);

/* **************** LIST ITEM INSTANCES ***************** */
// HACK: While we have the old selections module
// export const fetchListItems         =        ({listId, filters}) => {
export const fetchListItems = (requestOrId, payload) => {
	const { listId, filters } = requestOrId;
	return get(API, `lists/${listId || requestOrId}/items`, filters || payload); // Get all items for a list for a specific date, or today if not set
}
export const updateListItem         = (listId, payload) 		=> put(API, `lists/${listId}/items?updateFutureInstances=true`, payload); // Add or move an existing item to the list
export const deleteListItem         = ({listId, itemId, date}) 	=> del(API, `lists/${listId}/items/${itemId}`, { date, updateFutureInstances: true });
export const fetchMultipleListItems = filters 					=> get(API, "lists/items", filters);

/* **************** GROUPS ***************** */
export const fetchGroups         =            filters =>  get(API, `${groupsEndpoint}`, filters);
export const fetchGroup          =            groupId =>  get(API, `${groupsEndpoint}/${groupId}`);
// export const addListToGroup      = (groupId, payload) => post(API, `${groupsEndpoint}/${groupId}/addList`, payload);
export const addListToGroup = (groupId, payload) => {
	if (appConfig.features?.useNewSelections) {
		const qParams = Qs.stringify(payload, { skipNulls: true });
		return post(API, `${groupsEndpoint}/${groupId}/addList?${qParams}`);
	}
	return post(API, `${groupsEndpoint}/${groupId}/addList`, payload);
};

export const removeListFromGroup =  (groupId, listId) =>  put(API, `${groupsEndpoint}/${groupId}/removeList/${listId}`);
export const moveListInGroup     = (groupId, payload) =>  put(API, `${groupsEndpoint}/${groupId}/movelist`, payload);

export const insertGroup =            payload => post(API, `${groupsEndpoint}`, payload);
export const deleteGroup =            groupId => del(API, `${groupsEndpoint}/${groupId}`);
export const updateGroup = (groupId, payload) => put(API, `${groupsEndpoint}/${groupId}`, payload);
export const hideGroup   =            groupId => put(API, `${groupsEndpoint}/${groupId}/hide`);
export const unhideGroup =            groupId => put(API, `${groupsEndpoint}/${groupId}/unhide`);
export const moveGroup   = (groupId, ordinal) => put(API, `${groupsEndpoint}/${groupId}/move/${ordinal}`);
export const fetchSectionAssets = ({ sectionId, ...filters }) => get(API, `${groupsEndpoint}/${sectionId}/listassets`, filters);

/* *************** AUDIENCE **************** */
export const fetchAudiences  = () => get(API, "audiences");

/* *************** TEMPLATE **************** */
export const fetchTemplates = filters 			=>  get(API, "templates", filters);
export const fetchTemplate 	= ({id}) 			=>  get(API, `templates/${id}`);
export const createTemplate = (payload) 		=> post(API, "templates", payload);
export const updateTemplate = ({id}, payload) 	=> 	put(API, `templates/${id}`, payload);
export const deleteTemplate = ({id}) 			=> 	del(API, `templates/${id}`);

/* *************** AREA **************** */
export const fetchAreas = filters 			=>  get(API, "areas", filters);
export const fetchArea 	= ({id}) 			=>  get(API, `areas/${id}`);
export const createArea = (payload) 		=> post(API, "areas", payload);
export const updateArea = ({id}, payload) 	=> 	put(API, `areas/${id}`, payload);
export const deleteArea = ({id}) 			=> 	del(API, `areas/${id}`);

/* *************** SERVICE **************** */
export const fetchService 	= ({ id }) 	=> get(API, `services/${id}`);
export const fetchServices 	= filters	=> get(API, "services", filters);

/* *************** CONFIGURATION **************** */
export const fetchConfiguration = () => get(API, `configuration`);