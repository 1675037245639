import React from 'react'
import styles from './error.css'

export default class Error extends React.Component {

	render() {
		let error = this.props.children
			? <div className="c6-error">{this.props.children}</div>
			: null;

	    return error;
  	}
}