import React from 'react'
import { withRouter, Link } from 'react-router'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import AuthActions from './actions'
import UserStore from './store'

import './login.css'

@withRouter
export default class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleInputKeyPress = this.handleInputKeyPress.bind(this);

		this.state = {
			email: "",
			...UserStore.getState(),
		}
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleSubmit(e) {
		e.preventDefault();
		const { router, location, modal = false } = this.props;
		const { email, isLoading } = this.state;

		if(isLoading) {
			return false;
		}

		if(email) {
			AuthActions.forgotPassword(email);
		}
		else {
			AuthActions.actionFailed({exceptionMessage: "Please type your email address!"});
		}
	}

	handleEmailChange(e) {
		this.setState({ email: e.target.value, error: false, errorMessage: "" });
	}

	handleLoginNav(e) {
		e.preventDefault();
		AuthActions.displayLogin();
	}

	handleInputKeyPress(e) {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	}

	render() {
		const { router, location } = this.props;
		const { email, error, errorMessage, loggedIn, isLoading } = this.state;
		const classNames = error
			? "c6-authentication-form c6-authentication-error"
			: "c6-authentication-form" ;

		const progress = isLoading ? <CircularProgress className="loading-icon" size={25} thickness={3.6} /> : null;

		return (
			<div className={classNames}>
				<header>
					<h1>{ error ? errorMessage : "Forgot password" }</h1>
				</header>
				<form onSubmit={this.handleSubmit}>
					<div>
						<p>When you press send, you will recieve an email with a password reset link.</p>
						<div>
							<TextField
								helperText={errorMessage}
								color={errorMessage ? "error" : "primary"}
								variant="standard"
								required={true}
								label="Login/E-mail"
								placeholder="user@something.com"
								value={email}
								onKeyDown={this.handleInputKeyPress}
								onChange={this.handleEmailChange}
								focused={errorMessage ? true : undefined}
								fullWidth
								className="c6-authentication-input"
							/>
						</div>
					</div>

					<div className="form-bottom">
						<div>
							<Link
								to="/login"
								className="c6-link"
								onClick={this.handleLoginNav}>
								Go to login
							</Link>
						</div>
						<div className="right">
							<Button
								type="submit"
								variant="contained"
								onClick={this.handleSubmit}
								startIcon={progress}
								color="primary"
							>
								Send
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}