import alt from '../../core/services/alt'
import Actions from './actions'
import { updateUploaderEndpoint } from './utils'

class UploaderStore {

	constructor() {
		this.allFiles = [];
		this.uploaders = {};

		this.bindListeners({
			onRegisterUploader: Actions.REGISTER_UPLOADER,
			onUnregisterUploaderIfUnused: Actions.UNREGISTER_UPLOADER_IF_UNUSED,
			// onUpdateUploaderProps: Actions.UPDATE_UPLOADER_PROPS,
			onFileStatusChange: Actions.FILE_STATUS_CHANGE,
			onRemoveCompletedFiles: Actions.REMOVE_COMPLETED_FILES,
			onRemoveFile: Actions.REMOVE_FILE,
		});
	}

	onRegisterUploader({ uploaderId, uploader, uploaderInformationText, pathname, modal }) {
		if (!this.uploaders[uploaderId]) {
			this.uploaders[uploaderId] = uploader;
			this.uploaders[uploaderId].files = [];
			this.uploaders[uploaderId].pathname = pathname;
			this.uploaders[uploaderId].uploaderInformationText = uploaderInformationText;
			this.uploaders[uploaderId].modal = modal;
		}
	}

	onUnregisterUploaderIfUnused(uploaderId) {
		if (this.uploaders[uploaderId]?.files?.length === 0) {
			// console.log("Deleting unused uploader!");
			delete this.uploaders[uploaderId];
		}
	}

	// onUpdateUploaderProps({ uploaderId, newProps, uploaderInformationText, pathname, modal }) {
	// 	const uploader = this.uploaders[uploaderId];
	// 	if (uploader) {
	// 		uploader.pathname = pathname;
	// 		uploader.uploaderInformationText = uploaderInformationText;
	// 		uploader.modal = modal;
	// 		updateUploaderEndpoint(uploader, newProps);
	// 	}
	// }

	onFileStatusChange({ uploaderId, id, status }) {
		if (status === "submitted") {
			this.addFile(uploaderId, { id, status });
		} else if (isFileGone(status)) {
			this.removeFile(uploaderId, { id });
		} else /*if (status === "upload successful" || status === "upload failed") */{
			this.updateFileStatus(uploaderId, { id, status });
		}
	}

	addFile(uploaderId, file) {
		this.allFiles.push({ ...file, uploaderId });
		this.uploaders[uploaderId].files.push(file);
	}

	updateFileStatus(uploaderId, file) {
		const fileInAllFiles = this.allFiles.find(f => f.id === file.id && f.uploaderId === uploaderId);
		if (fileInAllFiles) {
			fileInAllFiles.status = file.status;
		}

		const fileInUploaderFiles = this.uploaders[uploaderId]?.files.find(f => f.id === file.id);
		if (fileInUploaderFiles) {
			fileInUploaderFiles.status = file.status;
		}
	}

	removeFile(uploaderId, file) {
		const allFilesIndex = this.allFiles.findIndex(f => f.id === file.id && f.uploaderId === uploaderId);
		if (allFilesIndex >= 0) {
			this.allFiles.splice(allFilesIndex, 1);
		}

		const uploaderFilesIndex = this.uploaders[uploaderId]?.files.findIndex(f => f.id === file.id);
		if (uploaderFilesIndex >= 0) {
			this.uploaders[uploaderId].files.splice(uploaderFilesIndex, 1);
		}
	}

	onRemoveCompletedFiles() {
		this.allFiles = this.allFiles.filter(filterComplete);
		Object.values(this.uploaders).forEach(uploader => {
			uploader.files = uploader.files.filter(filterComplete);
		});
	}

	onRemoveFile({ uploaderId, fileId }) {
		this.removeFile(uploaderId, { id: fileId });
	}
}

export default alt.createStore(UploaderStore, "[Comet]UploaderStore");

function isFileGone(status) {
	return ["canceled", "deleted", "failed"].includes(status);
}

function filterComplete(f) {
	return f.status !== "upload successful";
}