import React from 'react'
import PropTypes from 'prop-types'
import './button.css'
import Spinner from '../../spinner'

export default class Button extends React.Component {
	static propTypes = {
		type: PropTypes.string,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		shortTitle: PropTypes.string,
		hoverTitle: PropTypes.string,
		disabled: PropTypes.bool,
		confirm: PropTypes.string,
		dangerous: PropTypes.bool,
		onClick: PropTypes.func,
		noBackground: PropTypes.bool,
		options: PropTypes.array,
	}

	state = {
		needsConfirmation: true,
		showSubOptions: false,
	}

	// Confirms or actually executes the action
	handleActionClick = (e) => {
		const { confirm, onClick, options } = this.props;

		if (!onClick && options?.length) {
			this.setState(state => ({ showSubOptions: !state.showSubOptions }));
			return;
		}

		if (!onClick && !options?.length) {
			return false;
		}

		if (!confirm || !this.state.needsConfirmation) {
			this.setState({ needsConfirmation: true });
			onClick(e);
		}
		else {
			this.setState({ needsConfirmation: false });
		}
	}

	// TODO: Cancel confirm on mouse out (possibly after a delay?)
	handleCancelConfirm = () => {
		this.setState({ needsConfirmation: true });
	}

	render() {
		const {
			type = "default",
			title,
			shortTitle,
			hoverTitle = "",
			onClick,
			noBackground,
			disabled,
			confirm,
			dangerous,
			className,
			buttonRef,
			options,
			...rest
		} = this.props;

		if (options?.length && confirm) {
			console.warn("Button component does not support sub options AND confirm");
		}

		let extraClasses = title ? "c6-text" : "";
		extraClasses += className ? ` ${className}` : "";
		extraClasses += noBackground ? " noBackground" : "";
		extraClasses += dangerous && !this.state.needsConfirmation ? " dangerous" : "";
		extraClasses += options?.length ? " has-sub-options": "";

		const classes = getIconClass(type, extraClasses);

		const opts = getButtonType(type);

		const actionTitle = !this.state.needsConfirmation ? confirm : title;

		const short = shortTitle ? { "data-shorttitle": shortTitle} : null;
		let groupExtraClasses = noBackground ? " noBackground" : "";
		groupExtraClasses += options?.length ? " has-sub-options" : "";

	    const button = (
			<button
				key={type}
				disabled={disabled}
				className={classes}
				onClick={this.handleActionClick}
				type="button"
				title={hoverTitle || actionTitle}
				ref={buttonRef}
				{...opts}
				{...short}
				{...rest}
			>
				<span>{actionTitle}</span>
			</button>
		);

		if (options?.length) {
			return (
				<div className={`c6-buttongroup${groupExtraClasses}`}>
					{button}
					<div
						className="sub-options-toggle icon-expand_more"
						onClick={(e) => {
							e.stopPropagation();
							this.setState(state => ({ showSubOptions: !state.showSubOptions }));
						}}
					></div>
					{this.state.showSubOptions && (
						<div className="sub-options-wrapper">
							{renderSubOptions(
								options,
								() => this.setState(state => ({ showSubOptions: !state.showSubOptions })),
							)}
						</div>
					)}
				</div>
			);
		}

		if (!this.state.needsConfirmation) {
			return (
				<div className={`c6-buttongroup${groupExtraClasses}`}>
					{button}
					<button
						key="nevermind"
						className={`c6 c6-text${groupExtraClasses}`}
						onClick={this.handleCancelConfirm}
						type="button"
						{...rest}
					>
						<span>Nevermind...</span>
					</button>
				</div>
			);
		}

		return button;
  	}
}

// HELPERS
function getButtonType(type) {
	return type === "submit" ? {type} : null;
}

function getIconClass(type, extras) {
	type = translateTypes(type);

	return type && type !== "default" ? `c6 icon-${type} ${extras}` : `c6 ${extras}`;
}

function translateTypes(type) {
	switch(type) {
		case "clipboard":
			type = "content_paste icon-smaller";
			break;
		case "clone":
			type = "content_copy";
			break;
		case "create":
			type = "add";
			break;
		case "submit":
			type = "check fg-lightgreen";
			break;
		case "approve":
			type = "check";
			break;
		case "cancel":
			type = "close fg-lightred";
			break;
		case "next":
			type = "chevron_right";
			break;
		case "prev":
			type = "chevron_left";
			break;
		case "edit":
			type = "settings";
			break;
		case "clear":
			type = "cancel";
			break;
		case "download":
			type = "cloud_download";
			break;
	}

	return type;
}

function renderSubOptions(options, closeSub) {
	return options.map(o => (
		<button
			key={o.key}
			className="c6 sub-option"
			onClick={async (e) => {
				e.stopPropagation();
				await o.onClick();
				closeSub();
			}}
			disabled={o.disabled}
			title={o.hoverTitle}
		>
			<Spinner loading={o.isLoading} />
			{o.text}
		</button>
	));
}