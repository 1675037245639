import { browserHistory } from 'browserHistory'
import debounce from 'lodash/debounce'

import alt from '../../../core/services/alt'
import * as StarData from '../../../apis/star'
import * as Alert from '../../../core/services/alert'
import { handleRequestFailed } from '../../../core/services/errorhandling'
import { GUID_REGEXP } from '../../../core/constants'

class Actions {

	/* ================ */
	/* ===== ASSET ==== */
	/* ================ */
	fetchAsset(id) {
		return (dispatch) => {
			dispatch();

			// HACK: Allow the asset editor to be opened by GUID (for the C5 Sports Planner -> C6 crop hack /JOL)
			if(isGuid(id)) {
				StarData.fetchAssetByGUID(id)
					.then(response => {
						this.assetUpdated(response);
					}, (error, api) => {
						this.requestFailed({ error, api });
						Alert.displayAlert('error', error.exceptionMessage);
					});
			}
			else {
				StarData.fetchAsset(id)
					.then(response => {
						this.assetUpdated(response);
					}, (error, api) => {
						this.requestFailed({ error, api });
						Alert.displayAlert('error', error.exceptionMessage);
					});
			}
		};
	}

	updateAssetMetadata(asset, payload, region) {
		return dispatch => {
			dispatch();

			if (region) {
				const versionData = asset.versionData ? [...asset.versionData] : [];
				const existingVersionDataIndex = (asset.versionData || []).findIndex(vd => vd.versionId === region.id);
				if (existingVersionDataIndex >= 0) {
					versionData[existingVersionDataIndex] = {
						...versionData[existingVersionDataIndex],
						...payload,
					};
				} else {
					versionData.push({
						versionId: region.id,
						...payload,
					});
				}
				payload = { versionData };
			}

			StarData.updateAssetMetadata(asset.id, payload)
				.then(this.assetMetadataUpdated,
				(error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}
	assetMetadataUpdated(response) {
		return response;
	}

	updateTitle(assetId, newTitle) {
		return (dispatch) => {
			dispatch();

			const payload = {
				displayName: newTitle
			};

			StarData.updateAssetMetadata(assetId, payload)
				.then(response => {
					this.titleUpdated(response.displayName);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert("error", error.exceptionMessage);
				});
		};
	}

	updateLocalTransformations(property, value) {
		return { property, value };
	}

	updateBranding(assetId, newBranding) {
		return (dispatch) => {
			dispatch();

			const payload = {
				branding: { name: newBranding }
			};

			StarData.updateAssetMetadata(assetId, payload)
				.then(response => {
					this.brandingUpdated(response?.branding.name);

					Alert.displayAlert("info", "Image branding update initiated. It can take a few minutes before the changes are visible in the system. Please refresh the page if you suspect that you are not seeing the latest version.", null, null, 10000);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert("error", error.exceptionMessage);
				});
		};
	}

	cancelTransformations() { return true }

	transformAsset(asset, { brightness, contrast, rotation }) {
		return dispatch => {
			dispatch();

			const payload = {
				brightness,
				contrast,
				rotation,
			};

			StarData.updateAssetTransformations(asset.id, payload)
				.then(response => {
					this.imageUpdated(response);
					const clearCrops = asset.rotation !== rotation;
					this.refreshCrops(clearCrops);
					this.assetTransformed(response.brightness, response.contrast, response.rotation);
				}, this.requestFailed);
		};
	}

	assetTransformed(brightness, contrast, rotation) { return { brightness, contrast, rotation }};

	updateAssetRestrictions(assetId, restriction) {
		return (dispatch) => {
			dispatch();

			const [audience, licensing] = restriction.split(",");

			StarData.updateAssetMetadata(assetId, { audience, licensing })
				.then(response => {
					this.assetUpdated(response);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert("error", error.exceptionMessage);
				});
		};
	}

	updateAssetRights(assetId, payload, rights = []) {
		return (dispatch) => {
			dispatch();
			const command = rights.length ? "updateAssetRights" : "addAssetRights";
			StarData[command](assetId, payload)
				.then(response => {
					this.assetUpdated(response);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		}
	}

	deleteAssetRights(assetId, payload) {
		return dispatch => {
			dispatch();

			StarData.deleteAssetRights(assetId, payload)
				.then(response => {
					this.assetUpdated(response);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		}
	}

	// updateImage(asset, rotationModified) {
	// 	return (dispatch) => {
	// 		dispatch();

	// 		const payload = {
	// 			rotation: asset.rotation,
	// 			brightness: asset.brightness,
	// 			contrast: asset.contrast
	// 		};

	// 		StarData.updateAssetTransformations(asset.id, payload)
	// 			.then(response => {
	// 				this.imageUpdated(response);
	// 				this.refreshCrops(rotationModified);
	// 			}, (error, api) => {
	// 				this.requestFailed({ error, api });
	// 				Alert.displayAlert("error", error.exceptionMessage);
	// 			});
	// 	};
	// }

	/* ================ */
	/* ===== CROPS ==== */
	/* ================ */
	fetchCategoryCropTypes(assetCategoryId) {
		return dispatch => {
			dispatch();

			StarData.fetchCategoryCropTypes(assetCategoryId)
				.then(response => {
					let cropTypes = response && response.cropTypes || [];
					this.updateCropTypes(cropTypes);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	selectCrop(crop) { return crop }
	cancelCrop() { return true }

	setCrops(activeCrop) { return activeCrop; }
	previewDidRefresh(cropTypeId) { return cropTypeId }

	fetchAllCropTypes() {
		return (dispatch) => {
			dispatch();
			StarData.fetchAllCropTypes()
				.then(response => {
					this.updateCropTypes(response.items);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	fetchBrandings() {
		return (dispatch) => {
			dispatch();
			StarData.fetchBrandings()
				.then(response => {
					this.updateBrandings(response.items);
				}, (error, api) => {
					this.requestFailed({ error, api });
					Alert.displayAlert('error', error.exceptionMessage);
				});
		};
	}

	updateAssetCrop(crops, assetId) {
		const payload = crops;
		return (dispatch) => {
			dispatch();
			StarData.updateAssetCrop(assetId, payload)
				.then(response => {
					this.assetUpdated(response);
					Alert.displayAlert('success', 'Successfully saved crops');
					this.cropsUpdated(crops);
				}, (error, api) => {
					this.requestFailed({ error, api, payload });
					Alert.displayAlert('error', 'Failed to save crops');
				});
		};
	}

	setTitle(newVal) { return newVal }
	resetTitle() { return true }
	assetUpdated(asset) { return { asset } }
	imageUpdated(asset) { return { asset } }
	titleUpdated(title) { return title }
	brandingUpdated(brand) { return brand }
	imageLoaded() { return true }
	updateCropTypes(cropTypes) { return { cropTypes } }
	updateBrandings(brandings) { return { brandings } }
	cropsUpdated(crops) { return crops }
	refreshCrops(clear) { console.log("refreshCrops clear", clear); return clear }


	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}
}

export default alt.createActions(Actions);

// HELPERS
const isGuid = str => (GUID_REGEXP.test(str));

