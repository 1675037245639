import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import InfiniteScroll from 'react-infinite-scroller'

import { CONTENT } from '../../../components/actions'
import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Actions from '../actions'
import Content from '../shared/listItem'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

const uiActions = {
	targetComponent: "Item",
	actions: [
		{
			title: "View worklist items",
			method: (data, sourceProps, location) => {
				Actions.edit({
					id: data.workListId,
					modal: false,
				});
			},
			type: CONTENT,
		},
	]
};

@decoratorUIActions(uiActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
	}

	constructor(props) {
		super(props);

		// this.throttledLoadMore = throttle(props.loadMore, 300); TODO!: Why thottled?
	}

	render() {
		const {
			items,
			isLoading,
			searchText = "",
			filters,
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
		} = this.props;

		const noResult = !(items && items.length);

		let response;

		if (noResult) {
			const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST
				? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.`
				: "";
			response = <Empty v2={true} key="empty" isLoading={isLoading}>{textTooFewCharacters || textEmpty}</Empty>;
		}
		else {
			response = (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}>
					{this.renderItems(items, searchText)}
				</InfiniteScroll>
			);
		}

		return response;
	}

	renderItems(items, searchText) {
		if (searchText) {
			return (
				<ItemGroup title={`Items matching "${searchText}"`}>
					{this.renderItems(items)}
				</ItemGroup>
			);
		}

		return items.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(item => (
			<Item
				key={item.workListId}
				id={item.workListId}
				actionData={item}>
				<Content {...item} />
			</Item>
		));
	}
}