import pick from 'lodash/pick'
import appConfig from 'config'

import alt from '../../../core/services/alt'
// import StarConstants from '../../../core/constants/star'

import Actions from './actions'

class AssetStore {
	constructor() {
		this.asset = {};
		this.cleanAsset = {};
		this.isLoading = true;

		this.isCropping = false;
		this.cropTypes = [];
		this.cropTypesIsLoading = false;
		this.previewsRefreshed = {};
		this.assetCrops = [];
		this.activeCrop = {};
		this.cropHasChanged = false;
		this.brandings = [];
		this.brandingsIsLoading = false;

		this.transformations = {
			brightness: 1,
			contrast: 1,
			rotation: 0,
		};

		this.rotationModified = false;
		this.forceRefreshImage = false;

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			// Asset
			onFetchAsset: Actions.FETCH_ASSET,
			onUpdateAsset: Actions.UPDATE_TITLE,
			onAssetUpdated: Actions.ASSET_UPDATED,
			onUpdateLocalTransformations: Actions.UPDATE_LOCAL_TRANSFORMATIONS,
			onCancelTransformations: Actions.CANCEL_TRANSFORMATIONS,
			onTransformAsset: Actions.TRANSFORM_ASSET,
			onAssetTransformed: Actions.ASSET_TRANSFORMED,
			onImageUpdated: Actions.IMAGE_UPDATED,
			onImageLoaded: Actions.IMAGE_LOADED,
			onResetTitle: Actions.RESET_TITLE,
			onSetTitle: Actions.SET_TITLE,
			onTitleUpdated: Actions.TITLE_UPDATED,
			onBrandingUpdated: Actions.BRANDING_UPDATED,
			onAssetMetadataUpdated: Actions.ASSET_METADATA_UPDATED,

			// Brandings
			onFetchBrandings: Actions.FETCH_BRANDINGS,
			onUpdateBrandings: Actions.UPDATE_BRANDINGS,

			// Crops
			onFetchCropTypes: [Actions.FETCH_CATEGORY_CROP_TYPES, Actions.FETCH_ALL_CROP_TYPES],
			onUpdateCropTypes: Actions.UPDATE_CROP_TYPES,
			onCropsUpdated: Actions.CROPS_UPDATED,
			onRefreshCrops: Actions.REFRESH_CROPS,
			onPreviewDidRefresh: Actions.PREVIEW_DID_REFRESH,
			onSelectCrop: Actions.SELECT_CROP,
			onCancelCrop: Actions.CANCEL_CROP,
			onSetCrops: Actions.SET_CROPS,
		});
	}


	/* ================ */
	/* ===== ASSET ==== */
	/* ================ */
	onFetchAsset() {
		this.isLoading = true;
	}

	onUpdateAsset() {
		this.isLoading = true;
	}

	onAssetUpdated(data) {
		if (data.asset) {
			this.asset = data.asset;
			this.transformations = {
				brightness: this.asset.brightness !== undefined ? this.asset.brightness : 100,
				contrast: this.asset.contrast !== undefined ? this.asset.contrast : 100,
				rotation: this.asset.rotation !== undefined ? this.asset.rotation : 0,
			};
			this.cleanAsset = pick(data.asset, ["source", "displayName"]);
		}
		this.isLoading = false;
		this.forceRefreshImage = false;
	}

	onUpdateLocalTransformations({ property, value }) {
		this.transformations = {
			...this.transformations,
			[property]: value,
		};
	}

	onCancelTransformations() {
		this.transformations = Object.assign({}, {
			brightness: this.asset.brightness,
			contrast: this.asset.contrast,
			rotation: this.asset.rotation,
		});
	}

	onTransformAsset() {
		// this.isLoading = true;
	}

	onAssetTransformed(transformations) {
		this.transformations = transformations;
		Object.keys(transformations).forEach(key => this.asset[key] = transformations[key]);
	}

	onImageUpdated(data) {
		if (data.asset) {
			this.forceRefreshImage = true;
		}
	}

	onImageLoaded() {
		this.forceRefreshImage = false;
	}

	onResetTitle() {
		this.asset.displayName = this.cleanAsset.displayName.slice(0);
	}

	onSetTitle(newVal) {
		this.asset.displayName = newVal;
	}

	onTitleUpdated(title) {
		this.asset.displayName = title;
		this.cleanAsset.displayName = title.slice(0);
		this.isLoading = false;
	}

	onBrandingUpdated(brand) {
		this.asset.branding.name = brand;
		this.isLoading = false;

		this.onRefreshCrops();
		this.forceRefreshImage = true;
	}

	onAssetMetadataUpdated(asset) {
		this.asset = asset;
	}

	/* =================== */
	/* ===== BRANDING ==== */
	/* =================== */
	onFetchBrandings() {
		this.brandingsIsLoading = true;
	}

	onUpdateBrandings(data) {
		if (data.brandings) {
			this.brandings = data.brandings;
		}
		this.brandingsIsLoading = false;
	}


	/* ================ */
	/* ===== CROPS ==== */
	/* ================ */
	onFetchCropTypes() {
		this.cropTypesIsLoading = true;
	}

	onUpdateCropTypes(data) {
		if (data.cropTypes) {
			this.cropTypes = data.cropTypes.filter(ct => ct.name !== "Freeform"); // HACK!: Remove the freeform crop since it's not used. Can be removed when the API stops sending it.
		}
		this.cropTypesIsLoading = false;
	}

	onCropsUpdated(crops) {
		this.previewsRefreshed = {};

		crops.forEach(crop => this.previewsRefreshed[crop.cropTypeId] = false);
	}

	onRefreshCrops(clear) {
		console.log("onRefreshCrops clear", clear);
		if (clear) {
			this.asset.crops = [];
		}

		this.previewsRefreshed = {};
		this.cropTypes.forEach(cropType => this.previewsRefreshed[cropType.id] = false);
		this.previewsRefreshed["original"] = false;
	}

	onPreviewDidRefresh(cropTypeId) {
		this.previewsRefreshed[cropTypeId] = true;
	}

	onSelectCrop(crop) {
		this.isCropping = true;
		this.activeCrop = crop;
	}

	onCancelCrop() {
		this.isCropping = false;
		this.activeCrop = {};
		this.assetCrops = [];
		this.cropHasChanged = false;
	}

	onSetCrops(crop) {
		this.activeCrop = crop;
		this.cropHasChanged = true;

		const index = this.assetCrops.findIndex(ac => ac.id === crop.cropData.cropTypeId);
		if (index >= 0) {
			this.assetCrops[index] = crop;
		}
		else {
			this.assetCrops.push(crop);
		}
	}

	onRequestFailed() {
		this.isLoading = false;
	}
}

export default alt.createStore(AssetStore, "AssetStore");