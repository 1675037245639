import cloneDeep from 'lodash/cloneDeep'

import alt from '../../../core/services/alt'

import Actions from './actions'
import { Symbols, Ages, FinnishAgeRatingMap } from './data'

import appConfig from 'config'

const FINNISH_VERSION = 4;
const FINNISH_DESCRIPTION = 8;

const localRatingType = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingType" : "finnishRatingType";
const localRatingSymbols = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingSymbols" : "finnishRatingSymbols";
const localRatingCreatedDate = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingCreatedDate" : "finnishRatingCreatedDate";
const localRatingAge = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingAge" : "finnishRatingAge";
const localRatingContactPerson = appConfig.features.metadataC70 || appConfig.features.metadataC80 ? "localRatingContactPerson" : "finnishRatingContactPerson";

class RatingsEditorStore {

	constructor() {
		this.programLoading = false;
		this.dataSourcesLoading = false;

		this.pristineMetadataProgram = {};
		this.metadataProgram = {};
		this.tagsProgram = {};
		
		this.descriptionTags = [];
		this.symbols = [];

		this.isDirty = false;

		this.bindListeners({
      		onRequestFailed: Actions.REQUEST_FAILED,
			onUnmount: Actions.UNMOUNT,

			onProgramRequestStarted: [Actions.FETCH_PROGRAM, Actions.SAVE_PROGRAM],
			onProgramLoaded: Actions.PROGRAM_LOADED,
			onDataSourceLoaded: Actions.DATA_SOURCE_LOADED,

			onUpdateTags: Actions.UPDATE_TAGS,
			onUpdateSymbols: Actions.UPDATE_SYMBOLS,
			onUpdateAge: Actions.UPDATE_AGE,
			onUpdateRatingName: Actions.UPDATE_RATING_NAME,
			onUpdateRatingDate: Actions.UPDATE_RATING_DATE,
			onUpdateApproval: Actions.UPDATE_APPROVAL,
			onUpdateRatingType: Actions.UPDATE_RATING_TYPE,
			onUpdateStatus: Actions.UPDATE_STATUS,
			onUpdateCountryAgeRating: Actions.UPDATE_COUNTRY_AGE_RATING,

			onUpdate: [
				Actions.UPDATE_TAGS,
				Actions.UPDATE_SYMBOLS,
				Actions.UPDATE_RATING_NAME,
				Actions.UPDATE_RATING_DATE,
				Actions.UPDATE_AGE,
				Actions.UPDATE_APPROVAL,
				Actions.UPDATE_RATING_TYPE,
				Actions.UPDATE_STATUS,
			],
		});
	}

	onRequestFailed() {
		// this.isLoading = false;
	}

	onUnmount() {
		this.programLoading = false;
		this.dataSourcesLoading = false;

		this.pristineMetadataProgram = {};
		this.metadataProgram = {};
		this.tagsProgram = {};
		
		this.descriptionTags = [];
		this.symbols = [];

		this.isDirty = false;
	}

	onProgramRequestStarted() {
		this.programLoading = true;
	}

	onProgramLoaded({ metadataProgram, tagsProgram }) {
		this.metadataProgram = metadataProgram;
		this.pristineMetadataProgram = cloneDeep(metadataProgram);
		this.tagsProgram = tagsProgram;
		this.programLoading = false;
		this.isDirty = false;
	}

	onDataSourceLoaded({ descriptionTags, symbols }) {
		this.descriptionTags = descriptionTags.items;
		this.symbols = symbols.items;
	}

	onUpdateTags({ tags, tag }) {
		const index = tags.findIndex(t => t.id === tag.id);
		if (index >= 0) {
			tags.splice(index, 1);
		} else {
			tags.push(tag);
		}

		this.tagsProgram.tags = tags;
		this.updateSymbolsAndAgeBasedOnDescriptions();
	}

	updateSymbolsAndAgeBasedOnDescriptions() {
		const descriptionTags = this.tagsProgram.tags.filter(t => t.tagType.id === FINNISH_DESCRIPTION);
		const { symbols, age } = getSymbolsAndAgeBasedOnDescriptions(descriptionTags, this.symbols);
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingAge] = age;
		finnishVersion[localRatingSymbols] = symbols;
		this.updateCountriesAgeRating();
	}

	onUpdateSymbols({ symbols }) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingSymbols] = symbols;
		this.updateCountriesAgeRating();
	}

	onUpdateAge(value) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingAge] = value;
		this.updateCountriesAgeRating();
	}

	onUpdateRatingName(value) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingContactPerson] = value;
	}
	
	onUpdateRatingDate(value) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingCreatedDate] = value;
	}

	onUpdateApproval(value) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion.finnishRatingApproval = value;
	}

	onUpdateRatingType(value) {
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		finnishVersion[localRatingType] = value;

		switch (value) {
			case "noRating": 
				finnishVersion[localRatingAge] = "noRating";
				finnishVersion[localRatingContactPerson] = "";
				finnishVersion[localRatingSymbols] = [];
				this.tagsProgram.tags = [];
				break;
			case "stRating":
				finnishVersion[localRatingAge] = "S";
				finnishVersion[localRatingContactPerson] = "";
				finnishVersion[localRatingSymbols] = [];
				this.tagsProgram.tags = [];
				break;
			case "iklu":
				finnishVersion[localRatingAge] = "S";
				finnishVersion[localRatingContactPerson] = "eMeku";
				finnishVersion[localRatingSymbols] = [...this.symbols];
				this.tagsProgram.tags = [];
				break;
			case "criterias":
				finnishVersion[localRatingContactPerson] = "";
				this.updateSymbolsAndAgeBasedOnDescriptions();
				break;
		}
		
		this.updateCountriesAgeRating();
	}

	onUpdateStatus({ complete, workListItemId, ignoreReadOnlyUntilSaved }) {
		const newStatuses = [...this.metadataProgram?.statuses ?? []];
		const index = newStatuses.findIndex(status => parseInt(status.workListItemId) === parseInt(workListItemId));
		if (index >= 0) {
			newStatuses[index].complete = complete;
			newStatuses[index].ignoreReadOnlyUntilSaved = ignoreReadOnlyUntilSaved;
		} else {
			newStatuses.push({ complete, workListItemId: parseInt(workListItemId), ignoreReadOnlyUntilSaved });
		}
		this.metadataProgram.statuses = newStatuses;
	}

	onUpdateCountryAgeRating({ versionId, value }) {
		const version = this.metadataProgram.versions.find(v => v.versionId === versionId);
		version["localRatingAge"] = value;
	}

	onUpdate() {
		this.isDirty = true;
	}

	updateCountriesAgeRating() {
		if (!appConfig.features.metadataOtherCountriesRatings) {
			return;
		}
		
		const finnishVersion = this.metadataProgram.versions.find(v => v.versionId === FINNISH_VERSION);
		const finnishAgeRating = finnishVersion[localRatingAge];
		this.metadataProgram.versions.forEach(version => {
			if (version.versionId !== FINNISH_VERSION) {
				const ageRating = FinnishAgeRatingMap[finnishAgeRating ?? "noRating"][version.versionName.toLowerCase()];
				version["localRatingAge"] = ageRating;
			}
		});
	}

}

export default alt.createStore(RatingsEditorStore, '[Metadata]RatingsEditorStore');


function getSymbolsAndAgeBasedOnDescriptions(descriptions, symbols) {
	const descriptionNumbers = descriptions.map(description => parseInt(description.name.split(".")[0]));

	const highestAge = Ages.reduce(
		(prev, age) => {
			return age.descriptions.some(number => descriptionNumbers.includes(number)) ? age : prev;
		},
		Ages[0]
	);

	let symbolsForDescriptionsAtHighestAge = [];
	if (highestAge.key !== "S") {
		symbolsForDescriptionsAtHighestAge = symbols.filter(symbol => {
			const symbolWithDescriptions = Symbols.find(s => s.key === symbol.name);
			return descriptionNumbers.some(number =>
				highestAge.descriptions.includes(number)
				&& symbolWithDescriptions.descriptions.includes(number)
			);
		});
	}

	return { symbols: symbolsForDescriptionsAtHighestAge, age: highestAge.key };
}