import React from 'react'
import * as Alert from './alert'
import * as Sentry from '@sentry/browser'

export function handleRequestFailed(error, isReactQuery = false) {
    if (error.breakChain) {
		return;
    }

    if (!error.noDisplayAlert) {
        let errorDetail = null;
        if (error?.innerException) {
            let exceptions = [];
            const stopCount = 10; // We'll allow 10 levels of innerExceptions to prevent infinite recursions or stack overflows
            addException(error, exceptions, stopCount);
            errorDetail = <ol className="error-stack">{exceptions.map((e, i) => <li key={i}>{e}</li>)}</ol>;
        }
        else if (error) {
            errorDetail = error.exceptionMessage || error.ExceptionMessage;
        }

        const errorMessage = error.message
            || error.Message
            || (error.error?.message)
            || error.exceptionMessage
            || "Unknown error!";
        
        // No need to display details if they are the same as message
        if (errorMessage === errorDetail) {
            errorDetail = null;
        }

        Alert.displayAlert("error", errorMessage, errorDetail);
    }

    error.breakChain = true;
	Sentry.captureException(
        new Error(`handleRequestFailed: ${errorMessage}`),
        {
            fingerprint: [window.location.pathname, errorMessage],
            extra: {
                errorObject: error,
                isReactQuery,
            }
        }
    );
}

// HELPERS
function addException({ exceptionMessage, innerException }, exceptions, count) {
    if (exceptionMessage) {
        exceptions.push(exceptionMessage);
    }

    if (innerException && count > 1) {
        return addException(innerException, exceptions, --count);
    }
}