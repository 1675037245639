import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import './textarea-confirm.css'

export default class TextAreaConfirm extends Component {
	static propTypes = {
		onSave: PropTypes.func.isRequired,
		defaultValue: PropTypes.string,
		label: PropTypes.string,
		disabled: PropTypes.bool,
		readOnly: PropTypes.bool,
		maxLength: PropTypes.number,
		region: PropTypes.object,
	}

	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue || "",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.defaultValue !== prevProps.defaultValue || (this.props.region && this.props.region.id) !== (prevProps.region && prevProps.region.id)) {
			this.setState({ value: this.props.defaultValue || "" });
		}
	}

	handleSave = () => {
		this.props.onSave(this.state.value);
	}

	handleCancel = () => {
		this.setState({ value: this.props.defaultValue || "" });
	}

	render() {
		const {
			defaultValue = "",
			disabled,
			readOnly,
			label,
			displayMaxLength = true,
			className = "",
			multiline = true,
		} = this.props;
		const { value } = this.state;

		const maxLength = this.props.maxLength || 100;

		return (
			<div className={`c6-textarea-confirm ${className}`}>
				<TextField
					variant="standard"
					label={label}
					disabled={disabled}
					multiline={multiline}
					name="textarea-confirm"
					value={value}
					onChange={e => this.setState({ value: e.target.value || "" })}
					fullWidth
					InputProps={{
						readOnly,
					}}
				/>
				{value !== defaultValue && (
					<div className="textarea-controls">
						<a title="Save changes" style={{ color: "var(--success-color)" }} className="icon-check" onClick={this.handleSave}></a>
						<a title="Cancel changes" style={{ color: "var(--attention-color)" }} className="icon-close" onClick={this.handleCancel}></a>
					</div>
				)}
				{displayMaxLength && (
					<span style={{ float: "right", color: "var(--text-lighter-color)", fontSize: "12px", padding: "3px" }}>
						<span>{(value || "").length}</span> / {maxLength}
					</span>
				)}
			</div>
		);
	}
}