import React from 'react'
import PropTypes from 'prop-types'

const UIActions = (DecoratedComponent, uiActions = null) => {
	return class extends DecoratedComponent {
		static displayName = DecoratedComponent.displayName || DecoratedComponent.name;

		static childContextTypes = {
			uiActions: PropTypes.object,
			listState: PropTypes.object,
		}

		static propTypes = {
			uiActions: PropTypes.object
		}

		getChildContext() {
			return {
				uiActions: this.props.uiActions || { ...uiActions, sourceProps: this.props},
				listState: this.state,
			};
		}
	};
}
export default UIActions;

export const decoratorUIActions = uiActions => (
	DecoratedComponent => UIActions(DecoratedComponent, uiActions)
);