import React from 'react'
import { withRouter } from 'react-router'
import Moment from 'moment'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'

import AuthActions from './actions'
import UserStore from './store'

import './login.css'

const MAX_TIMER = 3600 * 48; // 48h is the maximum for new users (so don't add a new user on a friday)

@withRouter
export default class ResetPassword extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleNewLink = this.handleNewLink.bind(this);
		this.handlePass1Change = this.handlePass1Change.bind(this);
		this.handlePass2Change = this.handlePass2Change.bind(this);
		this.handleInputKeyPress = this.handleInputKeyPress.bind(this);

		this.resetKey = this.props.params.guid;
		this.timerID = null;

		this.state = {
			timer: MAX_TIMER,
			password: "",
			confirmPassword: "",
			loginErrorMessage: "",
			passwordErrorMessage: "",
			...UserStore.getState(),
		}
	}

	componentDidMount() {
		AuthActions.fetchResetState(this.resetKey);
		this.startCountdown();
		UserStore.listen(this.onChange);
	}

	componentWillUnmount() {
		// AuthActions.unmount();
		clearInterval(this.timerID);
		UserStore.unlisten(this.onChange);
	}

	onChange(state) {
		this.setState(state);
	}

	handleSubmit(e) {
		e.preventDefault();
		const { password, confirmPassword, isLoading } = this.state;

		if(isLoading) {
			return false;
		}

		if(password && confirmPassword && password === confirmPassword) {
			AuthActions.resetPassword({ resetKey: this.resetKey, password, confirmPassword });
		}
		else {
			if(password && confirmPassword && password !== confirmPassword) {
				AuthActions.loginFailed({ exceptionMessage: "The passwords don't match." });
			}
			else {
				AuthActions.loginFailed({ exceptionMessage: "Please type the password twice!" });
			}


			if(!password) {
				const password1ErrorMessage = "Please type a new password!";
				this.setState({ password1ErrorMessage });
			}
			if(!confirmPassword) {
				const password2ErrorMessage = "Please retype the password!";
				this.setState({ password2ErrorMessage });
			}
		}
	}

	handleNewLink(e) {
		e.preventDefault();
		const { email } = this.state.resetState;
		AuthActions.forgotPassword(email);
	}

	handlePass1Change(e) {
		this.setState({
			password: e.target.value,
			password1ErrorMessage: "",
		});
	}

	handlePass2Change(e) {
		this.setState({
			confirmPassword: e.target.value,
			password2ErrorMessage: "",
		});
	}

	handleLoginNav(e) {
		e.preventDefault();
		AuthActions.displayForgotPassword();
	}

	startCountdown() {
		this.timerID = setInterval(() => {
			const { resetState } = this.state;
			if(resetState.expires) {
				const timer = Moment(resetState.expires).diff(Moment(), 'seconds');
				this.setState({ timer });
			}
		}, 1000);
	}

	handleInputKeyPress(e) {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	}

	render() {
		const {
			timer,
			password,
			confirmPassword,
			// loginErrorMessage,
			password1ErrorMessage,
			password2ErrorMessage,
			error,
			errorMessage,
			resetState,
			isLoading,
		} = this.state;

		if(timer === MAX_TIMER) {
			return null;
		}

		const classNames = error
			? "c6-authentication-form c6-authentication-error"
			: "c6-authentication-form" ;

		const progress = isLoading ? <CircularProgress className="loading-icon" size={25} thickness={3.6} /> : null;

		if (timer > 0) {
			const color = timer < 60 ? "error" : "primary";
			return (
				<div className={classNames}>
					<header>
						<h1>{ error ? errorMessage : "Reset password" }</h1>
					</header>
					<LinearProgress
						variant="determinate"
						value={normalise(timer, 0, resetState.secondsUntilExpiry)}
						color={color}
					/>
					<form onSubmit={this.handleSubmit}>
						<div>
							<div>
								<TextField
									type="password"
									helperText={password1ErrorMessage}
									color={password1ErrorMessage ? "error" : "primary"}
									variant="standard"
									required={true}
									label="New password"
									value={password}
									onKeyDown={this.handleInputKeyPress}
									onChange={this.handlePass1Change}
									focused={password1ErrorMessage ? true : undefined}
									className="c6-authentication-input"
									fullWidth
								/>
							</div>
							<div>
								<TextField
									type="password"
									helperText={password2ErrorMessage}
									color={password2ErrorMessage ? "error" : "primary"}
									variant="standard"
									required={true}
									label="Confirm new password"
									value={confirmPassword}
									onKeyDown={this.handleInputKeyPress}
									onChange={this.handlePass2Change}
									focused={password2ErrorMessage ? true : undefined}
									className="c6-authentication-input"
									fullWidth
								/>
							</div>
						</div>
						<div className="form-bottom right">
							<Button
								type="submit"
								variant="contained"
								onClick={this.handleSubmit}
								startIcon={progress}
								color="primary"
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			);
		}

		return (
			<div className="c6-authentication-form c6-authentication-error">
				<h1>Link expired!</h1>
				<form onSubmit={this.handleNewLink}>
					<p>The password reset links are only <strong>valid for a limited time</strong>.</p>
					<p>This one has already expired so you need to request a new one with the button below.</p>
					<p>It will be sent to <strong>{resetState.email}</strong>.</p>
					<div className="form-bottom right">
						<Button
							color="primary"
							variant="contained"
							onClick={this.handleNewLink}
						>
							Request new link
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

const normalise = (value, min, max) => ((value - min) * 100) / (max - min);
