import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import {
	inViewport,
	parentScroll
} from './utils'

import appConfig from 'config'

// Enable forcing lazyload to load visible images
window.customLazyEventName = "updateLazy";
window.customLazyEvent = new Event(window.customLazyEventName);

export default class LazyLoad extends Component {
	constructor(props) {
		super(props);

		this.lazyLoadHandler = this.lazyLoadHandler.bind(this);
		this.handleError = this.handleError.bind(this);

		if (props.throttle > 0) {
			if (props.debounce) {
				this.lazyLoadHandler = debounce(this.lazyLoadHandler, props.throttle);
			} else {
				this.lazyLoadHandler = throttle(this.lazyLoadHandler, props.throttle);
			}
		}

		this.state = {
			visible: false,
			failed: false,
		};
	}

	componentDidMount() {
		this._mounted = true;
		const eventNode = this.getEventNode();

		this.lazyLoadHandler();

		if (this.lazyLoadHandler.flush) {
			this.lazyLoadHandler.flush();
		}
		
		window.addEventListener(window.customLazyEventName, this.lazyLoadHandler);
		window.addEventListener("resize", this.lazyLoadHandler);
		eventNode.addEventListener("scroll", this.lazyLoadHandler);
	}

	UNSAFE_componentWillReceiveProps() {
		if (!this.state.visible && !this.state.failed) {
			this.lazyLoadHandler();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.visible;
	}

	componentWillUnmount() {
		this._mounted = false;
		if (this.lazyLoadHandler.cancel) {
			this.lazyLoadHandler.cancel();
		}

		this.detachListeners();
	}

	getEventNode = () => {
		return appConfig.features?.useWindowForLazyloadCalculations
			? window
			: parentScroll(findDOMNode(this));
	}

	getOffset() {
		const {
			offset, offsetVertical, offsetHorizontal,
			offsetTop, offsetBottom, offsetLeft, offsetRight, threshold,
    	} = this.props;

		const _offsetAll = threshold || offset;
		const _offsetVertical = offsetVertical || _offsetAll;
		const _offsetHorizontal = offsetHorizontal || _offsetAll;

		return {
			top: offsetTop || _offsetVertical,
			bottom: offsetBottom || _offsetVertical,
			left: offsetLeft || _offsetHorizontal,
			right: offsetRight || _offsetHorizontal,
		};
	}

	lazyLoadHandler() {
		if (!this._mounted) {
			return;
		}
		const offset = this.getOffset();
		const node = findDOMNode(this);
		const eventNode = this.getEventNode();

		if (inViewport(node, eventNode, offset)) {
			const { onContentVisible } = this.props;

			this.setState({ visible: true }, () => {
				if (onContentVisible) {
					onContentVisible();
				}
			});
			this.detachListeners();
		}
	}

	detachListeners() {
		const eventNode = this.getEventNode();

		window.removeEventListener("resize", this.lazyLoadHandler);
		eventNode.removeEventListener("scroll", this.lazyLoadHandler);
	}

	handleError(e) {
		// Set failed=true to prevent more requests for the image
		this.setState({ failed: true });
		this.detachListeners();
	}

	render() {
		const { children, className, height, width, displayMessageIfImgFailed } = this.props;
		const { visible, failed } = this.state;

		const elStyles = { height, width, display: failed && displayMessageIfImgFailed ? "flex" : undefined };
		const elClasses = (
			"LazyLoad" +
			(visible ? " is-visible" : "") +
			(className ? ` ${className}` : "") +
			(failed ? " failed" : "")
		);

		let child = visible && Children.only(children);
		if (child) {
			child = React.cloneElement(child, {
				src: failed ? null : child.props.src,
				srcSet: failed ? null : child.props.srcSet,
				onError: failed ? null : this.handleError
			});
		}

		return (
			<div className={elClasses} style={elStyles}>
				{!failed && child}
				{displayMessageIfImgFailed && failed && (
					<div style={{ margin: "auto", textAlign: "center", color: "rgba(200, 50, 50, 0.8)" }}>
						{typeof displayMessageIfImgFailed === "boolean" ? "No access or asset missing." : displayMessageIfImgFailed}
					</div>
				)}
			</div>
		);
	}
}

LazyLoad.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	debounce: PropTypes.bool,
	// elementType: PropTypes.string,
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	offset: PropTypes.number,
	offsetBottom: PropTypes.number,
	offsetHorizontal: PropTypes.number,
	offsetLeft: PropTypes.number,
	offsetRight: PropTypes.number,
	offsetTop: PropTypes.number,
	offsetVertical: PropTypes.number,
	threshold: PropTypes.number,
	throttle: PropTypes.number,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	onContentVisible: PropTypes.func,
};

LazyLoad.defaultProps = {
	// elementType: "div",
	debounce: true,
	offset: 0,
	offsetBottom: 0,
	offsetHorizontal: 0,
	offsetLeft: 0,
	offsetRight: 0,
	offsetTop: 0,
	offsetVertical: 0,
	throttle: 250,
};