import appConfig from 'config'

const getElementPosition = (element) => {
	const rect = element.getBoundingClientRect();

	return {
		top: rect.top + window.pageYOffset,
		left: rect.left + window.pageXOffset,
	};
}

const isHidden = (element) => element.offsetParent === null;

export const inViewport = (element, container, customOffset) => {
	if (isHidden(element)) {
		return false;
	}

	let top, bottom, left, right;
	if (typeof container === 'undefined' || container === window) {
		top = window.pageYOffset;
		left = window.pageXOffset;
		bottom = top + window.innerHeight;
		right = left + window.innerWidth;
	} else {
		const containerPosition = getElementPosition(container);

		top = containerPosition.top;
		left = containerPosition.left;
		bottom = top + container.offsetHeight;
		right = left + container.offsetWidth;
	}

	const elementPosition = getElementPosition(element);

	// If using window for scroll (like in Press) we need to not check horizontal scroll
	if (appConfig.features?.useWindowForLazyloadCalculations) {
		return (
			top <= elementPosition.top + element.offsetHeight + customOffset.top &&
			bottom >= elementPosition.top - customOffset.bottom
		);
	}

	return (
		top <= elementPosition.top + element.offsetHeight + customOffset.top &&
		bottom >= elementPosition.top - customOffset.bottom &&
		left <= elementPosition.left + element.offsetWidth + customOffset.left &&
		right >= elementPosition.left - customOffset.right
	);
}

const style = (element, prop) =>
	typeof getComputedStyle !== 'undefined'
		? getComputedStyle(element, null).getPropertyValue(prop)
		: element.style[prop];

const overflow = (element) =>
	style(element, 'overflow') + style(element, 'overflow-y') + style(element, 'overflow-x');

export const parentScroll = (element) => {
	if (!(element instanceof HTMLElement)) {
		return window;
	}

	let parent = element;

	while (parent) {
		if (!parent.parentNode || parent === document.body || parent === document.documentElement) {
			break;
		}

		if (/(scroll|auto)/.test(overflow(parent))) {
			return parent;
		}

		parent = parent.parentNode;
	}

	return window;
};