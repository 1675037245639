import React from 'react'
import PropTypes from 'prop-types'
import styles from './label.css'

const Label = ({status = "default", size, className = "", children}) => {

	const classNames = `c6-label c6-label-${size} c6-status-${status} ${className}`;

	return (
		<span className={classNames}>
			{children}
		</span>
	);
};
Label.propTypes = {
	status: PropTypes.string,
	children: PropTypes.string.isRequired
}
export default Label;