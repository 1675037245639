import { get, put, post, patch, del } from '../core/c6'

import appConfig from 'config'

const API = "traffic";

/* **************** Programs ***************** */
export const fetchPrograms    	            = filters	                        => get(API, "programs", filters);
export const fetchExcel   	                = filters	                        => get(API, "programs/excel", filters);
export const fetchProgram   	            = ({id})	                        => get(API, `programs/${id}`);

export const updateProgram                  = ({id}, payload)                   => put(API, `programs/${id}`, payload)
export const updateDistributionData         = ({id}, payload)                   => put(API, `programversions/${id}/distributionData`, payload)
export const applySeasonChangesToEpisodes   = (payload)                         => put(API, "programs/applyto", payload);

export const enableVideoWatermark           = ({id})                            => put(API, `programs/${id}/enableVideoWatermark`);
export const disableVideoWatermark          = ({id})                            => put(API, `programs/${id}/disableVideoWatermark`);

/* **************** Program Version ***************** */
// export const updateDistributionData      = ({id})                           => put(API, `programversions/${id}/distributionData`)

// export const fetchProgramversion         = filters                         => get(API, `programversions/`, filters)
export const fetchProgramversionPackages = ({id})                           => get(API, `programversions/${id}/packages`)
export const fetchEvents                 = ({id})                           => get(API, `programs/${id}/events`)

/* **************** Exposure ***************** */
// export const fetchExposures   	         = filters	                       => get(API, "exposures", filters);
export const fetchExposure   	         = ({id})	                        => get(API, `exposures/${id}`);
export const updateExposure              = ({id}, payload)                  => put(API, `exposures/${id}`, payload);

/* **************** Asset ***************** */
export const updateAsset                 = ({id}, payload)                  => put(API, `assets/${id}`, payload)

/* **************** Comment ***************** */
export const fetchThreads                 = ({threadId})                    =>  get(API, `threads/${threadId}/comments`);
export const postComment                  = ({threadId, objectId}, payload) =>  post(API, `threads/${threadId}?objectType=program&objectId=${objectId}`, payload);
export const putComment                   = ({threadId}, payload)           =>  put(API, `threads/comments/${threadId}`, payload);

// export const updateDLR = ({id}, payload) => put(API, `exposures/${id}`, payload)

// export const updateRegion       = ({exposureId}) => put(API, `exposures/${exposureId}/regions`);

/* **************** ExposuresTypes ***************** */
export const fetchExposuretypes         = filters                          => get(API, `exposuretypes`, filters)
export const fetchDistributors          = filters                          => get(API, `distributors`, filters)

/* **************** Languages ***************** */
export const fetchLanguages             = filters                          => get(API, `languages`, filters)

/* **************** Generic ***************** */
export const fetchUrl = url => get(API, url);