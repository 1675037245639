import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useQuery } from '@tanstack/react-query'

const MuiCheckboxes = ({ value: values, onChange, options, schema, label, disabled = false, idSchema }) => {
	const { dataSource, enumOptions, hiddenValues, useObjectsInsteadOfStrings = false } = options;

	const { data: dataSourceItems } = useQuery(
		["mui-checkboxes-datasource", schema.title],
		dataSource ?? (() => {}), // Can't use hook conditionally and useQuery expects a function so we pass an empty function
		{ enabled: !!dataSource },
	);

	if (!!dataSource && !dataSourceItems) {
		return null;
	}

	const opt = dataSourceItems || enumOptions || [];

	const handleChange = (value, event, active) => {
		const modifiedValues = [...values];
		if (active) {
			modifiedValues.push(value);
		}
		else {
			const index = useObjectsInsteadOfStrings ? modifiedValues.findIndex(v => v.id === value.id) : modifiedValues.indexOf(value);
			modifiedValues.splice(index, 1);
		}
		onChange(modifiedValues);
	};

	let Checkboxes = [];
	opt.forEach((opt, index) => {
		if (!hiddenValues || !hiddenValues.includes(opt.value)) {
			const value = useObjectsInsteadOfStrings ? opt.value : opt.value.toString();
			const description = schema?.items?.descriptions?.[index];
			Checkboxes.push(
				<div key={`cb${useObjectsInsteadOfStrings ? opt.value.id : opt.value}`} className="form-group field field-boolean">
					<FormControlLabel
						control={
							<Checkbox
								checked={useObjectsInsteadOfStrings
									? values.some(v => v.id === opt.value.id)
									: values.includes(value)}
								onChange={handleChange.bind(this, value)}
								disabled={disabled}
							/>
						}
						label={opt.label}
					/>
					{description && <p className="description">{description}</p>}
				</div>
			);
		}
	});

	return (
		<fieldset>
			<legend style={{ margin: "0 20px" }}>{schema.title}</legend>
			{Checkboxes}
		</fieldset>
	);
};

export default MuiCheckboxes;