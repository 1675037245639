import { hasRole } from '../../../core/services/auth'
import * as Alert from '../../../core/services/alert'
import { get } from '../../../core/c6'

export const starContentTypes = [
	{
		key: "container",
		text: "Folders",
	},
	// {
	// 	key: "album",
	// 	text: "Albums",
	// },
	{
		key: "image",
		text: "Images",
	},
	{
		key: "trailer",
		text: "Trailers",
	},
	{
		key: "clip",
		text: "Clips",
	},
	// {
	// 	key: "video",
	// 	text: "Videos",
	// },
	// {
	// 	key: "subtitle",
	// 	text: "Subs",
	// },
	{
		key: "document",
		text: "Documents",
	},
	
];

export const isThumbNailType = (type, subType) => {
	const thumbNailTypes = ["Image", "Screengrab"];

	return thumbNailTypes.includes(type) || thumbNailTypes.includes(subType);
};

export const isAssetEditorType = (type, subType) => {
	const types = ["Image", "Clip", "Video", "Document", "Trailer", "Audio"];

	return types.includes(type) || types.includes(subType);
};

export const getStarRole = () => {
	if (hasRole("star.AdminUser") || hasRole("star.RegularUser")) {
		return "editor";
	}
	if (hasRole("star.BrowserUser")) {
		return "reader";
	}

	return null;
}

export const hasStarEditAccess = () => (getStarRole() === "editor");

export const downloadAssets = async (selectedItems, id) => {
	return new Promise((resolve, reject) => {
		const assetIds = selectedItems
			.filter(i => ["Asset", "Image", "Document"].includes(i.type))
			.map(i => i.type === "Asset" ? i[id] ?? i["id"] : i["id"]); // Take reference from searchItems, id from assets
		const containerIds = selectedItems.filter(i => i.type === "Container").map(i => i[id] || i["id"]);

		if (assetIds.length || containerIds.length) {
			const queryParams = new URLSearchParams();
			queryParams.set("assetIds", assetIds);
			queryParams.set("containerIds", containerIds);
			const downloadLink = `assets/download?${queryParams.toString()}`;
			get("star", downloadLink)
				.then(blob => {
					// Modified example from:
					// http://plnkr.co/edit/J40cPoLJ2WwESRzsSRhL?p=preview&preview
					let type = blob.type.split("/").pop();
					type = type === "plain" ? "txt" : type;
					const d = document.createElement("a");
					d.className = "download";
					d.href = URL.createObjectURL(blob);
					document.body.appendChild(d);
					d.click();
					d.parentElement.removeChild(d);

					resolve();
				}, error => {
					Alert.displayAlert("error", error.exceptionMessage ?? error.message);
					console.log("error", error);
					reject(error);
				});
		} else {
			resolve();
		}
	});
}

export const getAssetOrContainerId = (item) => {
	return parseInt(item.type && item.subType ? item.reference || item.id : item.id);
}

// parentContainerTypeId: -1   => Can be selected when a _new_ folder is created (no parent)
// parentContainerTypeId: null => Can always be selected
export const CONTAINER_TYPE_OPTIONS = [
	{ key: 1, text: "Program content", parentContainerTypeId: -1, name: "SingleProgram" },
	{ key: 2, text: "Episode content", parentContainerTypeId: 3, name: "EpisodeProgram" },
	{ key: 3, text: "Season content", parentContainerTypeId: 4, name: "SeasonProgram" },
	{ key: 4, text: "Series content", parentContainerTypeId: -1, name: "SeriesProgram" },
	{ key: 10, text: "Other stuff", parentContainerTypeId: null, name: "Folder" },
];

export const findHighestNumberInSiblings = (siblings, typeName) => {
	const siblingsWithRelevantName = siblings.filter(s => s.displayName.toLowerCase().includes(typeName.toLowerCase()));
	const siblingsNumbers = siblingsWithRelevantName.map(s => {
		const regexMatch = s.displayName.match(/\s(\d+)/);
		return regexMatch ? parseInt(regexMatch[1]) : null;
	}).filter(n => !!n).sort((a, b) => b - a);
	return siblingsNumbers.length ? siblingsNumbers[0] : 0;
}