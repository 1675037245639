import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const MuiRadio = ({ value, onChange, options, schema, label, disabled = false, readonly = false, inline = false }) => {
	const valueString = typeof (value) !== "undefined" ? value.toString() : "";
	const { dataSource, enumOptions, hiddenValues } = options;
	const inlineStyle = inline ? { height: "26px", marginRight: "24px" } : {};

	const opt = dataSource || enumOptions || [];
	const radioButtons = [];
	opt.forEach(opt => {
		if (!hiddenValues || !hiddenValues.includes(opt.value)) {
			let text = opt.label;
			if (opt.label.includes("|")) {
				const [ optLabel, optDesc ] = opt.label.split("|");
				text = <div><strong>{optLabel}</strong><br/>{optDesc}</div>;
			}
			radioButtons.push(
				<FormControlLabel
					key={`rb${opt.value.toString()}`}
					value={opt.value.toString()}
					label={text}
					disabled={disabled || readonly}
					control={<Radio sx={{ padding: "5px" }} />}
					sx={{
						marginLeft: "-8px",
						...inlineStyle,
					}}
				/>
			);
		}
	});

	const handleChange = (event, value) => { onChange(value); };


	return (
		<div style={{ marginTop: "20px" }}>
			{label}
			<RadioGroup
				row={inline}
				name="rdio"
				value={valueString}
				onChange={handleChange}
			>
				{radioButtons}
			</RadioGroup>
		</div>
	);
};

export default MuiRadio;