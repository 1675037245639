import React from 'react'

import WYSIWYG from '../wysiwyg'

const labelStyle = {
    fontSize: "16px",
    transform: "scale(0.75)",
    transformOrigin: "left top",
    color: "rgb(144, 164, 174)",
    marginBottom: "3px",
    paddingTop: "15px",
};

const WYSIWYGWidget = ({
    value,
	onChange,
	options,
	schema,
	label = "",
	disabled = false,
    readonly = false,
	style = {},
	...rest
}) => {

    return (
        <div className="c6-wysiwyg-widget">
            <div style={labelStyle}>{label}</div>
            <WYSIWYG
                content={value}
                onChange={onChange}
                disabled={disabled || readonly}
            />
        </div>
    );
};

export default WYSIWYGWidget;