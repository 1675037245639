import React, { Component } from 'react'

import Main from '../../../components/ui/main'
import Scroll from '../../../components/scroll'
import { ItemGroup } from '../../../components/list/listItems'

import Item from './ratingsListItem'

import Actions from '../actions'

import './ratings.css'

export default class Ratings extends Component {

	renderPrograms = (disabled) => {
		const { program = {}, childPrograms, childContainers } = this.props;

		return (
			<React.Fragment>
				{program.type === "Season" && (
					<React.Fragment>
						<ItemGroup title="Season">
							<Item
								id={program.id}
								item={program}
								disabled={disabled}
								todo={this.props.todo}
							/>
						</ItemGroup>
						<ItemGroup title="Episodes">
							{childPrograms?.map(episode => {
								const container = childContainers?.find(c => c.programGuid === episode.guid);
								return (
									<Item
										key={episode.id}
										id={episode.id}
										item={episode}
										disabled={disabled}
										todo={this.props.todo}
										container={container}
									/>
								);
							})}
						</ItemGroup>
					</React.Fragment>
				)}
				{program.type === "Single" && (
					<Item
						id={program.id}
						item={program}
						disabled={disabled}
						todo={this.props.todo}
					/>
				)}
			</React.Fragment>
		);
	}

	render() {
		const {
			todo,
		} = this.props;

		const disabled = todo.status === "Complete";

		return (
			<div className="todo-ratings">
				<h2>Provide your ratings here</h2>
				{disabled && <p>You can not provide ratings while this TODO is marked as Done.</p>}
				<Scroll padding={true}>
					<Main modal={true}>
						{this.renderPrograms(disabled)}
					</Main>
				</Scroll>
			</div>
		);
	}
}